import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import './css/login.css';
import './css/register.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import 'primeicons/primeicons.css';

import 'react-phone-input-2/lib/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';

// import { Offline, Online } from 'react-detect-offline';
// import { Modal, ModalBody } from 'reactstrap';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function(err) {
      console.log('Service worker registration failed, error:', err);
    });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <ToastContainer autoClose={2000} limit={1} closeButton={false} />
      {/* <Offline>
        <Modal
          isOpen={true}
          centered={true}
          toggle={() => window.location.reload()}
          size='sm'
        >
          <ModalBody className='noConnectionModal'>
            <div>
              <p className='noConnectionText'>You are offline</p>
              <p className='noConnectionText' style={{ fontSize: 18 }}>
                Please check your internet connection
              </p>
            </div>
          </ModalBody>
        </Modal>
      </Offline> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
