import {DEV_SERVER} from './../services/servicesConfig';


export const checkActiveUrl = () => {
  let activeClass = ' ';
  if (
    window.location.pathname.includes ('notification') ||
    window.location.pathname.includes ('accountsettings') ||
    window.location.pathname.includes ('accountsettings') ||
    window.location.pathname.includes ('highlights')||
    window.location.pathname.includes ('welcomepage')|| 
    window.location.pathname.includes ('email')


    
  ) {
    activeClass = ' notificationBg ';
  }

  if (
    window.location.pathname.includes ('marketplacesellerconsolenew') ||
    window.location.pathname.includes ('marketplaceselleraddproduct') ||
    window.location.pathname.includes ('marketplacesellerorderdetail') ||
    window.location.pathname.includes ('marketplacesellerviewmylist')||
   

    window.location.pathname.includes ('uploadproducts')
  ) {
    activeClass = ' marketPlaceSellerbg ';
  }

  if (window.location.pathname.includes ('messenger') ) {
    activeClass = ' messengerPurple ';
  }

  if (
    window.location.pathname.includes ('payment') ||
    window.location.pathname.includes ('newTransaction') ||
    window.location.pathname.includes ('myrequest') 
  ) {
    activeClass = ' rdeePayBlue ';
  }

  

  if (
    window.location.pathname.includes ('adduserdetail') || 
    window.location.pathname.includes ('userkyc') ||
    window.location.pathname.includes ('businessregister') 
  ) {
    // activeClass = ' blackBGWithImage ';
    activeClass = ' gradientColorPurpleBlue ';

  }

  if (
    window.location.pathname.includes ('contentapps') ||
    window.location.pathname.includes ('contactrequest')
  ) {
    activeClass = ' lightBlackGrayBg ';
  }

  if (
    window.location.pathname.includes ('vault') ||
    window.location.pathname.includes ('pacs')

  ) {
    activeClass = ' vaultContainerBG ';
  }
  return activeClass;
};

export const checkRightPannelBg = () => {
  let activeClass = ' ';

  if (
    // window.location.pathname.includes ('profiledashboard') ||
    window.location.pathname.includes ('contacts')
    ||
    window.location.pathname.includes ('contactrequest')
    || 
    window.location.pathname.includes ('profile')
    || 
    window.location.pathname.includes ('businessdetail')
    || 
    window.location.pathname.includes ('businessedit')
    || 
    window.location.pathname.includes ('businessmanagement')
    || 
    window.location.pathname.includes ('reputationdetail')
    

  ) {
    activeClass = ' rightPanelContainer ';
  }

  if (
    window.location.pathname.includes ('notification') ||
    window.location.pathname.includes ('welcomepage') ||

    window.location.pathname.includes ('accountsettings') ||
    window.location.pathname.includes ('marketplacesellerconsolenew') ||
    window.location.pathname.includes ('marketplacesearch') ||
    window.location.pathname.includes ('marketplaceproductdetail') ||
    window.location.pathname.includes ('marketplacebrowseproduct') ||
    window.location.pathname.includes ('marketplaceplacedorders') ||
    window.location.pathname.includes ('marketplaceplacedorderdetail')
  ) {
    activeClass = ' marketPlaceRightSidePurple ';
  }

  if (
    window.location.pathname.includes ('payment') ||
    window.location.pathname.includes ('newTransaction') ||
    window.location.pathname.includes ('myrequest') ||
    window.location.pathname.includes ('marketplacesellerconsolenew') ||
    window.location.pathname.includes ('marketplacesellerorderdetail') ||
    window.location.pathname.includes ('marketplaceselleraddproduct') ||
    window.location.pathname.includes ('marketplacesellerviewmylist') ||
    window.location.pathname.includes ('uploadproducts') 

  ) {
    activeClass = ' grayBlackBg ';
  }

  return activeClass;
};

export const checkHeaderMenuColor = (checkCollapse) => {
  let activeColor = true;
  if (
    window.location.pathname.includes ('payment') ||
    window.location.pathname.includes ('marketplacesellerconsolenew')||
    window.location.pathname.includes ('messenger') ||
    window.location.pathname.includes ('vault') ||
    window.location.pathname.includes ('profiledashboard') ||
    window.location.pathname.includes ('contacts') ||
    window.location.pathname.includes ('profile') ||
    window.location.pathname.includes ('businessdetail') || 
    window.location.pathname.includes ('businessedit') || 
    window.location.pathname.includes ('businessmanagement')
    || 
    window.location.pathname.includes ('marketplaceplacedorderdetail') ||


    (!DEV_SERVER && window.location.pathname.includes ('marketplacesearch')) ||
    window.location.pathname.includes ('marketplacesearch') ||
    window.location.pathname.includes ('marketplaceplacedorders') ||
    window.location.pathname.includes ('highlights')||

    window.location.pathname.includes ('notification')||
    window.location.pathname.includes ('welcomepage') || 
    
    window.location.pathname.includes ('adduserdetail')|| 
    window.location.pathname.includes ('userkyc') ||
    window.location.pathname.includes ('businessregister') || 
    window.location.pathname.includes ('marketplacesellerviewmylist') || 
    window.location.pathname.includes ('marketplaceselleraddproduct') || 
    window.location.pathname.includes ('marketplacebrowseproduct') ||
    window.location.pathname.includes ('accountsettings') || 
    window.location.pathname.includes ('marketplacesellerorderdetail') || 
    window.location.pathname.includes ('infosys') || 
    window.location.pathname.includes ('pacs') || 
    window.location.pathname.includes ('serviceOnDemand') || 
    window.location.pathname.includes ('contactrequest') || 
    window.location.pathname.includes ('contentapps') ||
    window.location.pathname.includes ('newTransaction')  ||
    window.location.pathname.includes ('uploadproducts')
    
  ) {
      activeColor = false;


      if((
          window.location.pathname.includes ('profiledashboard') ||
          window.location.pathname.includes ('marketplacesearch') ||
         window.location.pathname.includes ('marketplaceplacedorders') ||
         

          window.location.pathname.includes ('contacts')||
          window.location.pathname.includes ('profile')||
          window.location.pathname.includes ('businessdetail')
          ||
          window.location.pathname.includes ('businessmanagement')
          || 

          window.location.pathname.includes ('businessedit') ||
          window.location.pathname.includes ('marketplacebrowseproduct') ||

          window.location.pathname.includes ('marketplaceplacedorderdetail') 
          
          
          
          )
          && window.innerWidth < 1025 ){
        activeColor = true;

      }
  }else{
    if(window.innerWidth > 1919 ){
      activeColor = false;
    }
    

  }

  return activeColor;
};


export const checkHideMessenger = () => {
  let hideMessenger = false;
  if (window.location.pathname.includes ('vault') || window.location.pathname.includes ('userkyc')){
    hideMessenger= true;
    }else{
      hideMessenger = false;
    }
    return hideMessenger;
  }

