



 import React, {Component} from 'react';
import {
  Button,
} from 'reactstrap';
import './commonAccessContainer.css';
import * as routes from '../../../constants/routes';
  

  

class CommonAccessContainer extends Component {
  constructor (props) {
    super (props);
  }
  
  
  render () {
    const { bgImage , message }= this.props;
    return (
      <div className="backgroundAvailaleSoon" style={{backgroundImage: `url(${bgImage})` }}>

      <div className="centerBGimageText">

      
    
        {message=== 'upgradeScreen'  && <p> Upgrade Your Plan</p>}
        {message=== 'comingSoonScreen'  && <p> Available Soon</p>}
        {message=== 'maintenanceScreen'  && <p> Under Maintenance </p>}
        {message=== 'not_available'  && <p> Not Available  </p>}

        {message=== 'kycScreen'  && <div className="kycContainerBox">
            <div className="kycBoxVerifyTitle">Your KYC Profile is Incomplete</div>
            <div className="kycBoxVerifySubTitle">
              <div> Complete and verify your</div>
              <div> identity with us in a few simple</div>
              <div> steps to use these features.</div>
            </div>

            <div style={{textAlign:'right'}}>
              <Button className="cancelGoButton" onClick={()=>window.location.href=routes.PROFILE_DASHBOARD}>Cancel</Button>
              <Button className="goverifyKYCButton" onClick={()=>window.location.href=routes.USER_KYC}>Verify Now</Button>

            </div>
        
        </div>}


        </div>
      </div>
       
    );
  }
}


export default  CommonAccessContainer;