import React, {Component,useCallback} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import firebase from 'firebase';
import {Button, Input, Modal, ModalBody} from 'reactstrap';
import {Carousel} from 'react-responsive-carousel';
import StarRatingComponent from 'react-star-rating-component';
import './productDetail.css';
import leftbackarrow from '../../../../images/icons/leftbackarrow.png';
import placeorder from '../../../../images/marketPlaceBuyer/placeorder.png';
// import dropdownpro from '../../../../images/marketPlaceBuyer/dropdownpro.png';
// import downBlackArrow from '../../../../images/icons/downBlackArrow.png';
import profilePlaceholder from '../../../../images/profile_placeholder.png';

import dropdownpro from '../../../../images/icons/downwhiteArrow.png';
import shareWhiteIcon from '../../../../images/icons/shareWhiteIcon.png';
import sendSmallIcon from '../../../../images/icons/sendSmallIcon.png';
import closeIcon from '../../../../images/close_icon.png';
import messageIcon from '../../../../images/icons/sidebar/messenger.png';
import shareSuccess from '../../../../images/icons/shareSuccess.png';


import yellowStar from '../../../../images/icons/yellowStar.png';

import halfYellowstar from '../../../../images/icons/halfYellowstar.png';


import {
  getProductListing,
  doOrder,
  resetdoOrderValue,
  addRecentViewProduct,
} from '../../../../actions/marketplaceaction';
import moment from 'moment';
import MarketPlaceSidemenu from '../marketplaceSidemenu/marketSideMenu';
import placeholderProfile from '../../../../images/profile/pronew.png';
import * as routes from '../../../../constants/routes';
import {getTransactionHistory} from '../../../../actions/Paymentaction';
import {Dropdown} from 'primereact/dropdown';
import RightSideContainer
  from '../../../../components/UI/rightSideContainer/rightSideContainer';

import productDetails
  from '../../../../images/marketPlaceBuyer/productDetails.png';
import nonradioPlaceholder from '../../../../images/marketPlaceBuyer/nonradioPlaceholder.png';

import {dataTimeFormat} from '../../../../utility/convertTimeString';
import {getProfileDashboard} from '../../../../actions/Authaction';
import {getAllUserContactsFunc} from '../../../../actions/contacts';
import {ShowNameAccordingToLength} from '../../../../utility/stringFunctions';

import {
  fetchMessengerList,
  shareButtonClickP2P,
  shareButtonClickGroupChat,
} from '../../../../actions/Messenger';
import {
  userSharedKey,
  CalculatedSharedSecret,
  encryptMessage,
} from '../../../../utility/encryption';

import {priceWithDecimal} from '../../../../utility/commonFunctions';

import ProductZoomDetail from '../../../UI/productZoom/productZoom';
class ProductDetail extends Component {
  constructor (props) {
    super (props);
    this.state = {
      specification: true,
      logistics: true,
      titleInfo: true,
      inspection: true,
      overView: true,
      otherInfo: true,
      dimentions: true,
      compliance: true,
      condition: true,

      productDetail: [],
      placeOrderModal: false,
      orderConfirmation: false,
      userBalance: '',
      rdCredit: '',
      productQuantity: 0,
      productCategoryType: '',
      productShareModal: false,
      sendSuccess: false,
      loginUserData: '',
      messengerListing: [],
      userProductDes: '',
      searchText:'',
      imagePreviewModal:false,
      setIsZoomed: false,
      imgIndex:0,
      streetAddress:'',
      city:'',
      state:'',
      zipcode:'',
      country:'',
    };
    

    this.handleImgLoad= this.handleImgLoad.bind(this);
    this.closeModal= this.closeModal.bind(this);
    this.showImage= this.showImage.bind(this);
    this.shareMessage= this.shareMessage.bind(this);

    

    

    
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps &&
      nextProps.product &&
      nextProps.product.productlist &&
      nextProps.product.productlist.length > 0
    ) {
      this.setState (
        {
          productDetail: nextProps.product.productlist[0],
        },
        () => {
          this.setState ({
            productCategoryType: this.state.productDetail.productCategoryType,
          });
        }
      );
      // console.log(nextProps.product.productlist);
      // this.props.history.push (routes.MARKETPLACESEARCH ,{'issearch': true});
    }
    if (nextProps && nextProps.product && nextProps.product.placeOrder) {
      if (this.state.placeOrderModal) {
        this.setState (
          {
            orderConfirmation: true,
            placeOrderModal: false,
          },
          () => {
            this.props.resetdoOrderValue ();
          }
        );
      }
    }
    if (nextProps && nextProps.payment) {
      this.setState ({
        userBalance: nextProps.payment.history &&
          nextProps.payment.history.balance
          ? nextProps.payment.history.balance
          : '',
        rdCredit: nextProps.payment.history &&
          nextProps.payment.history.rdeeBalance
          ? nextProps.payment.history.rdeeBalance
          : '',
      });
    }

    if (
      this.props.userInfo &&
      this.props.userInfo.user.Data &&
      this.props.userInfo.user.Data.userDetails &&
      this.props.userInfo.user.Data.userDetails[0] &&
      this.props.userInfo.user.Data.userDetails[0].personalDetails
    ) {
      this.setState ({
        loginUserData: this.props.userInfo.user.Data.userDetails[0]
          .personalDetails,
      });
    }
    if (nextProps) {
      let tmpMessengerList = [];
      if (
        nextProps.messengerList &&
        nextProps.messengerList.messenger &&
        nextProps.messengerList.messenger.length > 0
      ) {
        let userListing = nextProps.messengerList.messenger;
        if (nextProps.contacts && nextProps.contacts.length > 0) {
          let contactListing = nextProps.contacts;
          for (let userItem of userListing) {
            if (userItem.chatType === 'singleChat') {
              for (let items of contactListing) {
                if (items && items.contact && items.contact.contactDetail) {
                  if (
                    items.contact.contactDetail.docID ===
                    userItem.oppositeUserUid
                  ) {
                    userItem.profilePictureURL =
                      items.contact.contactDetail.profilePictureURL;
                    userItem.displayName =
                      items.contact.contactDetail.firstName +
                      ' ' +
                      items.contact.contactDetail.lastName;
                    userItem.email =items.contact.contactDetail.emailAddress ;
                  }
                }
              }
            }
          }
        }
        let newContactListing = [];
        if (nextProps.contacts && nextProps.contacts.length > 0) {
          // let contactListing = nextProps.contacts;

          for (let items of nextProps.contacts) {
            if (
              nextProps.messengerList &&
              nextProps.messengerList.messenger &&
              nextProps.messengerList.messenger.length > 0
            ) {
              for (let chatUsers of nextProps.messengerList.messenger) {
                if (
                  chatUsers.oppositeUserUid ===
                  items.contact.contactDetail.docID
                ) {
                  items.contact.contactDetail.lastMsg = chatUsers.lastMsg;
                  items.contact.contactDetail.key = chatUsers.key;
                  items.contact.contactDetail.timeStamp = chatUsers.timeStamp;
                  items.contact.contactDetail.oppositeUserUid =
                    chatUsers.oppositeUserUid;
                  items.contact.contactDetail.email =items.contact.contactDetail.emailAddress ;
                  
                }
              }
            }
            items.contact.contactDetail.displayName = ShowNameAccordingToLength (
              items.contact.contactDetail.firstName +
                ' ' +
                items.contact.contactDetail.lastName
            );
            items.contact.contactDetail.email =items.contact.contactDetail.emailAddress ;
            newContactListing.push (items.contact.contactDetail);
          }
        }

        this.setState (
          {
            messengerListing: userListing.concat (
              this.checkAlreadyExist (newContactListing)
            ),
          },
          () => {
            // console.log (this.state.messengerListing);
          }
        );
      }
    }
  }

  checkAlreadyExist (data) {
    let filterData = [];
    for (let items of data) {
      if (items && !items.key) {
        filterData.push (items);
      }
    }
    return filterData;
  }

  componentDidMount () {
    if (this.props.match.params && this.props.match.params.productId) {
      if (
        this.props.userInfo &&
        this.props.userInfo.user.Data &&
        this.props.userInfo.user.Data.userDetails &&
        this.props.userInfo.user.Data.userDetails[0] &&
        this.props.userInfo.user.Data.userDetails[0].personalDetails
      ) {
        this.setState (
          {
            loginUserData: this.props.userInfo.user.Data.userDetails[0]
              .personalDetails,
          },
          () => {
            // console.log(this.state.loginUserData);
            this.props.fetchMessengerList (this.state.loginUserData.docID);
          }
        );
      }

      let sendRequestUserProfile = {
        data: {},
      };
      this.props.getProfileDashboard (sendRequestUserProfile);

      const sendRequest = {
        data: {
          deviceType: '',
          manufacturer: '',
          location: '',
          priceUSD: '',
          sellerUID: '',
          docID: this.props.match.params.productId,
        },
      };
      this.props.getProductListing (sendRequest);
      let sendRequest1 = {
        data: {
          limit: 0,
          offset: 0,
        },
      };
      this.props.getTransactionHistory (sendRequest1);
      let orderQuantity = [];
      let count = 1;
      for (var i = 0; i < 5; i++) {
        let countValue = count++;
        orderQuantity.push ({label: countValue, value: countValue});
      }
      this.setState (
        {
          orderQuantity: orderQuantity,
        },
        () => {
          // console.log(this.state.orderQuantity);
        }
      );

      this.props.addRecentViewProduct ({
        data: {productDocID: this.props.match.params.productId},
      });
      this.searchContact ('');
    }
  }

  searchContact (string) {
    let sendContactRequest = {
      data: {
        searchString: string,
      },
    };
    this.props.getAllUserContactsFunc (sendContactRequest);
  }
  closeModal () {
    this.setState ({
      placeOrderModal: false,
      orderConfirmation: false,
      productShareModal: false,
      sendSuccess: false,
      userProductDes:'',
      imagePreviewModal:false
    });
  }

  goDetail () {
    this.setState (
      {
        placeOrderModal: false,
        orderConfirmation: false,
      },
      () => {
        this.props.history.push (routes.PLACED_ORDERS);
      }
    );
  }
  openDetailDropDown (status) {
    if (status === 'specification') {
      this.setState ({
        specification: !this.state.specification,
      });
    } else if (status === 'logistics') {
      this.setState ({
        logistics: !this.state.logistics,
      });
    } else if (status === 'titleInfo') {
      this.setState ({
        titleInfo: !this.state.titleInfo,
      });
    } else if (status === 'overView') {
      this.setState ({
        overView: !this.state.overView,
      });
    } else if (status === 'otherInfo') {
      this.setState ({
        otherInfo: !this.state.otherInfo,
      });
    } else if (status === 'dimentions') {
      this.setState ({
        dimentions: !this.state.dimentions,
      });
    } else if (status === 'compliance') {
      this.setState ({
        compliance: !this.state.compliance,
      });
    } else if (status === 'condition') {
      this.setState ({
        condition: !this.state.condition,
      });
    } else {
      this.setState ({
        inspection: !this.state.inspection,
      });
    }
  }

  doPlaceOrder () {
    const {productDetail ,streetAddress,city,state,zipcode,country} = this.state;

    const sendRequest = {
      data: {
        productIDList: [this.state.productDetail.docID],
        productQuantity: this.state.productQuantity,
        shippingAddress:{
          streetAddress:streetAddress,
          city:city,
          state:state,
          zipcode:zipcode,
          country:country,
        }
       
      },
    };

    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'New Order received!',
        body: ((loginUser.title+' ')+ShowNameAccordingToLength (
                loginUser.firstName + ' ' + loginUser.lastName
              ) +
              ' ' +
              'has placed a new order.Click to view the order.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.listedBy,
        titleName: 'New Order received!',
      },
    };

    this.props.doOrder (sendRequest, paramsAddNotiHistory);
  }

  async shareMessage (receiverUser) {
    if (receiverUser.chatType === 'singleChat') {
      const database = firebase.database ();
      let oppoUserID = _.get (receiverUser, 'oppositeUserUid', null);
      // console.log (oppoUserID);
      let UserOwnDocID = _.get (
        this.props.userInfo,
        'user.Data.userDetails[0].personalDetails.docID',
        null
      );
      let finalSecretKey;
      // console.log (UserOwnDocID);
      await database
        .ref (`Users/${UserOwnDocID}/onlineStatus`)
        .on ('value', async snapShot => {
          // console.log("snapShot>>>>> user own PUBLIC KEY", snapShot.child('publicKey').val())
          let userOwnPublicKey = snapShot.child ('publicKey').val ();

          // await database
          //   .ref (`Users/${oppoUserID}/onlineStatus`)
          //   .on ('value', snapShot => {
              // let oppositeSharedKey = userSharedKey (userOwnPublicKey);
              //  finalSecretKey = CalculatedSharedSecret (
              //   oppositeSharedKey,
              //   UserOwnDocID
              // );

             
              this.props.shareButtonClickP2P (
                receiverUser.key,
                this.state.loginUserData,
                oppoUserID,
                receiverUser.profilePictureURL,
                receiverUser.displayName,
                _.get(this.props,'userInfo.secretKeyForWeb', null),
                this.state.productDetail,
                this.state.userProductDes,
                this.props
              );

              this.setState ({
                sendSuccess: true,
              });
            // });
        });
      
    } else if (receiverUser.chatType === 'groupChat') {
      let groupData = await this.fetchGroupDataFromFirebase (receiverUser.key);
      const groupMembers = _.toArray (groupData.users);
      // console.log (groupData.groupPublicKey);
      let groupSharedKey = userSharedKey (groupData.groupPublicKey);
      let finalSecretKey = CalculatedSharedSecret (
        groupSharedKey,
        groupData.groupPublicKey
      );
      // console.log (finalSecretKey);
      // this.setState({
      //   secretKey: finalSecretKey
      // })

      this.props.shareButtonClickGroupChat (
        groupMembers,
        groupData.groupId,
        this.state.loginUserData,
        groupData.groupName,
        groupData.groupIcon,
        finalSecretKey,
        this.state.productDetail,
        groupData.groupId,
        this.state.userProductDes
      );

      this.setState ({
        sendSuccess: true,
      });
    } else {
      let keyVal = '';
      let myId = this.state.loginUserData.docID;
      let conversationId = receiverUser.docID;

      if (conversationId > myId) {
        keyVal = `${conversationId}${myId}`;
      } else {
        keyVal = `${myId}${conversationId}`;
      }

      let messengerKey = keyVal;

      const database = firebase.database ();
      let oppoUserID = _.get (receiverUser, 'docID', null);
      let UserOwnDocID = _.get (
        this.props.userInfo,
        'user.Data.userDetails[0].personalDetails.docID',
        null
      );
      await database
        .ref (`Users/${UserOwnDocID}/onlineStatus`)
        .on ('value', async snapShot => {
          // console.log("snapShot>>>>> user own PUBLIC KEY", snapShot.child('publicKey').val())
          let userOwnPublicKey = snapShot.child ('publicKey').val ();
          await database
            .ref (`Users/${oppoUserID}/onlineStatus`)
            .on ('value', snapShot => {
              let oppositeSharedKey = userSharedKey (userOwnPublicKey);
              let finalSecretKey = CalculatedSharedSecret (
                oppositeSharedKey,
                UserOwnDocID
              );
              // console.log (
              //   'oppositeSharedKey>>>>',
              //   oppositeSharedKey,
              //   'finalSecretKey>>>',
              //   finalSecretKey
              // );

              // console.log(messengerKey,
              //   this.state.loginUserData,
              //   receiverUser.docID,
              //   receiverUser.profilePictureURL,
              //   receiverUser.displayName,
              //   finalSecretKey,
              //   this.state.productDetail,
              //   this.state.userProductDes);

              this.props.shareButtonClickP2P (
                messengerKey,
                this.state.loginUserData,
                receiverUser.docID,
                receiverUser.profilePictureURL,
                receiverUser.displayName,
                finalSecretKey,
                this.state.productDetail,
                this.state.userProductDes
              );

              this.setState ({
                sendSuccess: true,
              });
            });
        });

    }
  }
  fetchGroupDataFromFirebase = async conversationId => {
    const database = firebase.database ();
    const conversationRef = database.ref (`GroupChatDetail/${conversationId}`);
    return new Promise ((resolve, reject) => {
      // Get details for this conversation id

      conversationRef.on ('value', snapshot => {
        // console.log (
        //   'snapshot.val()>>>>>fetchGroupDataFromFirebase',
        //   snapshot.val ()
        // );
        resolve (snapshot.val ());
      });
    });
  };



  handleImgLoad  () {
    // alert("zoom");
    // setIsZoomed(true)
    this.setState({
      
      setIsZoomed: true
    })
  }
 
  handleZoomChange = (shouldZoom => {
    // setIsZoomed(shouldZoom)
    this.setState({
      setIsZoomed: shouldZoom
    })
  })

  showImage(index){
    this.setState({
      imagePreviewModal: true,
      imgIndex: index
    })
  }

  _handleKeyVal (receiver) {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let keyVal = '';
    let userId = loginUser.docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goToChat () {
    const {productDetail} = this.state;
    if (productDetail  ) {

      let userChatInfo = {
        oppositeUserUid: productDetail.listedBy,
        profilePictureURL: productDetail && productDetail.profilePictureURL ? productDetail.profilePictureURL : profilePlaceholder,
        displayName: productDetail.sellerFirstName + ' ' + productDetail.sellerLastName,
        chatType: 'singleChat',
      };
      this.props.history.push (routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal (productDetail.listedBy),
      });
    }
  }



  render () {
    const {contacts} = this.props;
    const {
      productDetail,
      userBalance,
      rdCredit,
      productQuantity,
      productCategoryType,
      productShareModal,
      sendSuccess,
      loginUserData,
      messengerListing,
      userProductDes,
      searchText,
      imagePreviewModal,
      setIsZoomed,
      imgIndex,
      streetAddress,city,state,zipcode,country
    } = this.state;
      let filterContactData = searchText ? messengerListing.filter ( row =>
      (row && row.displayName  && row.displayName.toLowerCase ().indexOf (searchText.toLowerCase ()) > -1) ||
      (row && row.email  &&  row.email.toLowerCase ().indexOf (searchText.toLowerCase ()) > -1)
  ) : messengerListing;
    return (
      <div className="maineProductDEtailContainer">
        <div>
          <div style={{textAlign: 'right'}}>
            <Button
              className="marketproducbackbutton"
              onClick={() => this.props.history.goBack ()}
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>

              Go <span> Back</span>
            </Button>
          </div>
          <div className="prodetailmaindiv">
            <div>
              <div className="SiemensAvapro">

                {productDetail &&
                  productDetail.productCategoryType !== 'Radiology'
                  ? productDetail.productName
                  : productDetail.deviceSaleName}
                <p>
                  USD$
                  {' '}
                  {productDetail && productDetail.priceUSD
                    ? priceWithDecimal(productDetail.priceUSD)
                    : ''}
                </p>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{minWidth:250}}>
                  {productCategoryType === 'Radiology' &&
                    <p className="DeviceTypepro">
                      Device Type:
                      {' '}
                      {productDetail && productDetail.deviceType
                        ? productDetail.deviceType
                        : 'N/A'}
                    </p>}
                  {productCategoryType === 'Radiology' &&
                    <p className="Locationpro">
                      Location:
                      {' '}
                      {productDetail && productDetail.location
                        ? productDetail.location
                        : 'N/A'}
                    </p>}
                  {productCategoryType !== 'Radiology' &&
                    <div>

                      <p className="Locationpro">
                        Product ID #:
                        {' '}
                        {productDetail && productDetail.productID
                          ? productDetail.productID
                          : 'N/A'}
                      </p>
                      <p style={{opacity: 0.6}}>
                        Added to Marketplace:

                        {productDetail &&
                          productDetail &&
                          productDetail.productListedDate
                          ? dataTimeFormat (productDetail.productListedDate)
                          : 'N/A'}

                      </p>
                    </div>}
                </div>
                <div>
                  {productDetail && loginUserData && (loginUserData.docID !== productDetail.listedBy) &&  <Button
                  className="messageToseller"
                  onClick={() => this.goToChat ()}
                  data-rh="Message to seller"
                >
                  Message To Seller
                </Button>}
                <div style={{display: 'inline-flex'}}>
               

                  {_.get(this.props.userInfo,'userDeviceData.isWebConnectActive',false) && 
                  <Button
                    className="shareButton"
                    onClick={() =>
                      this.setState ({
                        productShareModal: true,
                      })}
                    style={{marginRight: 20}}
                    data-rh="Share"
                  >
                    <img
                      style={{width: 25, marginLeft: 5, marginRight: 10}}
                      alt="placeorder"
                      src={shareWhiteIcon}
                    />
                    Share
                  </Button>}
                  {productDetail &&
                    productDetail.inventoryQuantity > 0 &&
                    productDetail.listedBy !== loginUserData.docID &&
                    <Button
                      className="placeorderbuttonx"
                      onClick={() =>
                        this.setState ({
                          placeOrderModal: true,
                        })}
                      disabled={
                        productDetail && productDetail.inventoryQuantity
                          ? false
                          : true
                      }
                      style={{
                        cursor: productDetail && productDetail.inventoryQuantity
                          ? ''
                          : 'unset',
                      }}
                      data-rh="Place Order"
                    >
                      <img
                        style={{width: 25, marginLeft: 5}}
                        alt="placeorder"
                        src={placeorder}
                      />
                      Place Order
                    </Button>}
                  {productDetail &&
                    productDetail.inventoryQuantity <= 0 &&
                    <p
                      className="errorProductAvailable"
                      style={{fontSize: 20, fontWeight: 'bold'}}
                    >
                      Currently not available{' '}
                    </p>}

                </div>
                </div>

              </div>

              {productCategoryType !== 'Radiology' &&
                <div>
                  <div className="productHeadingTitle">
                    <span> PRODUCT </span> DESCRIPTION
                  </div>
                  <div
                    style={{marginTop: 5, marginBottom: 5}}
                    className="productDescription"
                  >
                    {productDetail && productDetail.description
                      ? productDetail.description
                      : ''}
                    {' '}
                  </div>
                  <div className="productHeadingTitle">
                    <span> PRODUCT </span> DETAILS
                  </div>

                </div>}
              {productCategoryType === 'Radiology' &&
                <div>
                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.specification
                          ? ' detaidropdActiveBG '
                          : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      SPECIFICATIONS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown ('specification')}
                    >
                      <img
                        alt="Icon"
                        src={dropdownpro}
                        className={
                          this.state.specification ? 'closeimgstyle' : ''
                        }
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>
                  {this.state.specification &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Manufacturer</div>
                        <div className="SystemNamepro">System Name</div>
                        <div className="SystemNamepro">
                          {' '}Date of Manufacture
                        </div>
                        <div className="SystemNamepro"> Working Condition</div>
                        <div className="SystemNamepro"> Condition</div>
                        <div className="SystemNamepro"> Console Type</div>
                        <div className="SystemNamepro">Available Quantity </div>

                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.manufacturer
                            ? productDetail.manufacturer
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {' '}{' '}
                          {productDetail && productDetail.systemName
                            ? productDetail.systemName
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {' '}
                          {productDetail &&
                            productDetail &&
                            productDetail.dateOfManufacture
                            ? moment (productDetail.dateOfManufacture).format (
                                'MM/DD/YYYY'
                              )
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.workingCondition
                            ? productDetail.workingCondition
                            : 'N/A'}

                        </div>
                        <div className="Symphonyprof">
                          {' '}{' '}
                          {productDetail && productDetail.condition
                            ? productDetail.condition
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {' '}{' '}
                          {productDetail && productDetail.consoleType
                            ? productDetail.consoleType
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.inventoryQuantity
                            ? productDetail.inventoryQuantity
                            : ''}
                          {productDetail &&
                            !productDetail.inventoryQuantity &&
                            <p
                              className="errorProductAvailable"
                              style={{margin: 0}}
                            >
                              Currently not available{' '}
                            </p>}

                        </div>

                      </div>

                    </div>}
                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.logistics ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      LOGISTICS
                    </div>
                    <div onClick={() => this.openDetailDropDown ('logistics')}>
                      <img
                        alt=""
                        src={dropdownpro}
                        className={this.state.logistics ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>
                  {this.state.logistics &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Location</div>
                        <div className="SystemNamepro">Accessibility</div>
                        <div className="SystemNamepro"> Rigging</div>
                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}{' '}
                          {productDetail && productDetail.logisticsLocation
                            ? productDetail.logisticsLocation
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {' '}
                          {productDetail && productDetail.accessibility
                            ? productDetail.accessibility
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {' '}
                          {productDetail && productDetail.rigging
                            ? productDetail.rigging
                            : 'N/A'}
                        </div>
                      </div>
                    </div>}
                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.titleInfo ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      TITLE INFORMATION
                    </div>
                    {/* <div className="TitleSeapro">Title Search</div> */}
                    <div onClick={() => this.openDetailDropDown ('titleInfo')}>
                      <img
                        alt="dropdownpro"
                        src={dropdownpro}
                        className={this.state.titleInfo ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>
                  {this.state.titleInfo &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Ownership Status</div>
                        <div className="SystemNamepro">Title Availability</div>
                        <div className="SystemNamepro">Title Search link</div>

                        <div className="SystemNamepro">
                          {' '}Liens/Loans on Equipment
                        </div>
                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.ownershipStatus
                            ? productDetail.ownershipStatus
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.titleAvailability
                            ? productDetail.titleAvailability
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.titleSearchLink
                            ? productDetail.titleSearchLink
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.equipmentLiensLoans
                            ? productDetail.equipmentLiensLoans
                            : 'N/A'}
                          {' '}
                        </div>
                      </div>
                    </div>}
                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.inspection ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      INSPECTION REPORT
                    </div>
                    {/* <div className="downloadrepipro">
                      {productDetail &&
                        productDetail.inspectionReportName &&
                        productDetail.inspectionReportURL
                        ? <a
                            href={productDetail.inspectionReportURL}
                            target="_blank"
                          >

                            Download Report
                          </a>
                        : 'Download Report'}

                    </div> */}
                    <div onClick={() => this.openDetailDropDown ('inspection')}>
                      <img
                        alt="inspection"
                        src={dropdownpro}
                        className={this.state.inspection ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>

                  {this.state.inspection &&
                    <div>

                      <div className="productDetailCollapsingBox">
                        <div>
                          <div className="Manufacturerpro">Type</div>
                          <div className="Manufacturerpro">File</div>
                        </div>
                        <div>
                          <div className="MRIpro">
                            Self-declared
                          </div>
                          <div className="MRIpro">
                            {' '}
                            {productDetail && productDetail.inspectionReportName
                              ? productDetail.inspectionReportName
                              : 'N/A'}
                          </div>
                        </div>
                      </div>

                    </div>}
                </div>}
              {productCategoryType !== 'Radiology' &&
                <div>

                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.overView ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      OVERVIEW
                    </div>

                    <div onClick={() => this.openDetailDropDown ('overView')}>
                      <img
                        alt="closeimgstyle"
                        src={dropdownpro}
                        className={this.state.overView ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>
                  {this.state.overView &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Seller SKU</div>
                        <div className="SystemNamepro">Brand</div>

                        <div className="SystemNamepro">
                          Expiration Date on Product?
                        </div>
                        <div className="SystemNamepro">Manufacturer</div>

                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.productSku
                            ? productDetail.productSku
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.brand
                            ? productDetail.brand
                            : 'N/A'}
                        </div>

                        <div className="Symphonyprof">
                          {productDetail && productDetail.productExpires
                            ? productDetail.productExpires
                            : 'N/A'}
                          {' '}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.manufacturer
                            ? productDetail.manufacturer
                            : 'N/A'}
                          {' '}
                        </div>
                      </div>
                    </div>}

                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.otherInfo ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      OTHER INFO
                    </div>

                    <div onClick={() => this.openDetailDropDown ('otherInfo')}>
                      <img
                        alt="icon"
                        src={dropdownpro}
                        className={this.state.otherInfo ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>

                  {this.state.otherInfo &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Catalog Number</div>
                        <div className="SystemNamepro">Generic Keywords</div>
                        <div className="SystemNamepro">
                          Importer Nameoduct ID{' '}
                        </div>

                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.catalogNumber
                            ? productDetail.catalogNumber
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.genericKeywords
                            ? productDetail.genericKeywords
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.importerName
                            ? productDetail.importerName
                            : 'N/A'}
                          {' '}
                        </div>

                      </div>
                    </div>}

                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.dimentions ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      DIMENSIONS
                    </div>

                    <div onClick={() => this.openDetailDropDown ('dimentions')}>
                      <img
                        alt="closeimgstyle"
                        src={dropdownpro}
                        className={this.state.dimentions ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>

                  {this.state.dimentions &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Size</div>
                        <div className="SystemNamepro">Color</div>
                        <div className="SystemNamepro">Weight </div>

                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.size
                            ? productDetail.size
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.color
                            ? productDetail.color
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.weight
                            ? productDetail.weight
                            : 'N/A'}
                          {' '}
                        </div>

                      </div>
                    </div>}

                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.compliance ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      COMPLIANCE
                    </div>

                    <div onClick={() => this.openDetailDropDown ('compliance')}>
                      <img
                        alt="icon"
                        src={dropdownpro}
                        className={this.state.compliance ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>

                  {this.state.compliance &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro">Country of Origin</div>
                        <div className="SystemNamepro">
                          Specific Product Use
                        </div>

                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.countryOfOrigin
                            ? productDetail.countryOfOrigin
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.specificProductUse
                            ? productDetail.specificProductUse
                            : 'N/A'}
                        </div>

                      </div>
                    </div>}

                  <div
                    className={
                      'detaidropdnpro2 ' +
                        (!this.state.condition ? ' detaidropdActiveBG ' : '')
                    }
                  >
                    <div className="SPECIFICATIONSpro">
                      CONDITION
                    </div>

                    <div onClick={() => this.openDetailDropDown ('condition')}>
                      <img
                        alt=""
                        src={dropdownpro}
                        className={this.state.condition ? 'closeimgstyle' : ''}
                        style={{marginRight: 15, width: 18}}
                      />
                    </div>
                  </div>

                  {this.state.condition &&
                    <div className="productDetailCollapsingBox">
                      <div>
                        <div className="Manufacturerpro"> Item Condition</div>
                        <div className="SystemNamepro">Condition Note</div>

                      </div>
                      <div>
                        <div className="MRIpro">
                          {' '}
                          {productDetail && productDetail.itemCondition
                            ? productDetail.itemCondition
                            : 'N/A'}
                        </div>
                        <div className="Symphonyprof">
                          {productDetail && productDetail.conditionNote
                            ? productDetail.conditionNote
                            : 'N/A'}
                        </div>

                      </div>
                    </div>}

                </div>}

            </div>

            <div style={{marginLeft: 30}}>
              <div style={{display: 'flex'}}>
                <div style={{width: 300}}>
                  <Carousel showThumbs={false}>
                    {productDetail &&
                      productDetail.productImageList &&
                      productDetail.productImageList.length > 0
                      ? productDetail.productImageList.map ((item, i) => (
                          <div key={i}>
                            <img
                              src={item || (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src = (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)                                
                              }}
                              alt="products"
                              // style={{height: 299, width: 209}}
                              style={{height: "100%", width: "100%",minHeight: 299, minWidth: 209}}

                            />

                          </div>
                        ))
                      : <div>
                          <img
                            src={(productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)}
                            onError={e => {
                              e.target.onerror = null
                              e.target.src = (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)
                            }}
                            alt="products"
                            // style={{height: 299, width: 209}}
                            style={{height: "100%", width: "100%",minHeight: 299, minWidth: 209}}

                          />

                        </div>}
                  </Carousel>
                </div>
                <div>
                  <div className="rightSideCircleImage">
                    {productDetail &&
                      productDetail.productImageList &&
                      productDetail.productImageList.length > 0 &&
                      productDetail.productImageList.map ((item, i) => (
                        <img
                          key={i}
                          alt="products"
                          
                          src={item || (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)}
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)                                
                          }}
                          className="circleimage1"
                          onClick={()=>this.showImage(i)}
                        />
                      ))}
                  </div>

                </div>
              </div>
              <div className="sellerinfobopro">
                <div style={{display: 'flex', alignItems: 'baseline'}}>
                  <div className="sellerinfoheadiung">
                    SELLER INFORMATION
                  </div>
                  <div>
                    <div className="top100pro"> TOP 10%</div>
                    <div style={{marginLeft: 135, marginTop: 10}}>
                      <StarRatingComponent
                        name={'rating'}
                        starCount={5}
                        value={
                          productDetail &&
                            productDetail.reputationDetails &&
                            productDetail.reputationDetails.reputationData &&
                            productDetail.reputationDetails.reputationData
                              .sellerRatings &&
                            productDetail.reputationDetails.reputationData
                              .sellerRatings.sellerRating
                            ? productDetail.reputationDetails.reputationData
                                .sellerRatings.sellerRating
                            : 0
                        }
                        emptyStarColor={'#E4E4E4'}
                        starColor={'#F5D028'}
                        style={{fontSize: '80px', margin: 0}}
                        // value={3.5}
                        // rating_half_star={3.5}
                        //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                       
                        />
                    </div>
                  </div>
                </div>
                <div style={{display: 'flex'}}>
                  <div>
                    <img
                      alt="product"
                      // src={productDetails}

                      src={
                        productDetail && productDetail.profilePictureURL
                          ? productDetail.profilePictureURL
                          : placeholderProfile
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = placeholderProfile;
                      }}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        marginLeft: 27,
                      }}
                    />
                  </div>
                  <div>
                    <p className="JulieMarthapro">

                      {ShowNameAccordingToLength (
                        productDetail.sellerFirstName +
                          ' ' +
                          productDetail.sellerLastName
                      )}
                    </p>
                    <p className="Reputationpro">
                      Reputation: {' '}

                      {productDetail &&
                        productDetail.reputationDetails &&
                        productDetail.reputationDetails.reputationData &&
                        productDetail.reputationDetails.reputationData.sellerRatings && productDetail.reputationDetails.reputationData.sellerRatings.sellerReputationScore
                        ? productDetail.reputationDetails.reputationData.sellerRatings.sellerReputationScore
                        : 0}
                      /5.0

                    </p>
                  </div>
                  <div>
                    <Button
                      className="veiewpeofilebuttonpro"
                      onClick={() => {
                        this.props.history.push (
                          routes.CONTACTUSERDETAIL +
                            `/${productDetail.listedBy}`
                        );
                      }}
                      data-rh="Visit Profile"
                    >
                      Visit Profile{' '}
                    </Button>
                  </div>
                </div>
                <div className="RecentRevpro">Recent Reviews:</div>
                {productDetail &&
                  productDetail.reputationDetails &&
                  productDetail.reputationDetails.latestReview.length===0 &&<div style={{textAlign:"center"}} className="RecentRevpro">No Reviews</div>
                }
                {productDetail &&
                  productDetail.reputationDetails &&
                  productDetail.reputationDetails.latestReview.length>0 &&
                  <div>
                  {productDetail.reputationDetails.latestReview[0].map ((item, i) => (<div key={i} className="reviewdivpropro">
                    <div style={{display: 'flex'}}>
                      <div>
                        <img
                          alt="profileIcon"
                          src={item.reviewerProfilePictureURL ? item.reviewerProfilePictureURL : placeholderProfile}
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = placeholderProfile;
                          }}
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                          }}
                        />
                      </div>
                      <div style={{width: 225}}>
                        <p className="FrankPaulpro">

                        {item.reviewerTitle ? item.reviewerTitle : ''}

                          

                        
{ShowNameAccordingToLength(item.reviewerFirstName ? item.reviewerFirstName : '' + ' ' +item.reviewerFirstName ? item.reviewerLastName : '')}
                  

                        </p>
                        <p className="dateprorpro">
                          {item.reviewDate ? moment (  item.reviewDate).format ('MM/DD/YYYY ; h:MMa') : 'N/A'}

                        </p>
                      </div>
                      <div>
                        <StarRatingComponent
                          name={'rating'}
                          starCount={5}
                          value={ item.reviewScore? item.reviewScore: 0 }
                          // value={
                          //   userProfile &&
                          //     userProfile.serviceProfile &&
                          //     userProfile.serviceProfile.totalServicesOffered
                          //     ? userProfile.serviceProfile.totalServicesOffered
                          //     : 0
                          // }
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          style={{fontSize: '80px', margin: 0}}
                          //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                        />
                      </div>
                    </div>
                    <div className="usersgetthes">
                      {item.reviewText ? item.reviewText : 'N/A'}
                      {' '}
                    </div>
                  </div>))}
                
                  </div>
                }
              
              
                  </div>
            </div>
          </div>

        </div>
        <RightSideContainer>
          <MarketPlaceSidemenu isSearch={true} history={this.props.history} />
        </RightSideContainer>

        <Modal
          size="sm"
          isOpen={this.state.placeOrderModal}
          centered={true}
          toggle={() => this.closeModal ()}
        >
          <ModalBody className="placeOrderModal">
            <div>

              <p className="confirmTitle">Placing Order</p>
              <div className="confirmmsg">
                Do you wish to place an order for:

              </div>

              <p className="productName_BOLD">

                {productDetail &&
                  productDetail.productCategoryType === 'Radiology'
                  ? productDetail.deviceSaleName: (productDetail.productName?productDetail.productName:'')
                  }
              </p>

              <div className="placingOrderDropdown">

                <Dropdown
                  className="sellerdropcopeinpt"
                  placeholder="Select Quantity"
                  value={this.state.productQuantity}
                  options={this.state.orderQuantity}
                  onChange={e => {
                    if (productDetail.inventoryQuantity) {
                      if (e.value <= productDetail.inventoryQuantity) {
                        this.setState ({
                          productQuantity: e.value,
                          productLengthError: false,
                        });
                      } else {
                        this.setState ({
                          productLengthError: true,
                          productQuantity: 0,
                        });
                      }
                    } else {
                      this.setState ({
                        productLengthError: true,
                        productQuantity: 0,
                      });
                    }
                  }}
                />

              </div>
              <div style={{marginLeft: 10}}>

                {this.state.productLengthError &&
                  <p className="errorProductAvailable">
                    Only
                    {' '}
                    {productDetail && productDetail.inventoryQuantity
                      ? productDetail.inventoryQuantity
                      : 'N/A'}
                    {' '}
                    Products Available
                  </p>}
                {productDetail &&
                  productDetail.priceUSD &&
                  <p className="priceItem" style={{marginTop: 10}}>
                    <span>Price USD$
                    {' '} {priceWithDecimal(productDetail.priceUSD)}</span>
                  </p>}

                <p className="priceItem">
                  Total price:
                  {' '}
                  USD$
                  {' '}
                  <span>
                    {productDetail && productDetail.priceUSD
                      ? priceWithDecimal(productDetail.priceUSD * productQuantity)
                      : 0}
                  </span>
                </p>
              </div>
              <div style={{marginTop:10}}>
              <div className="productName_BOLD" >Shipping Address</div>
              <div>
                <div className="confirmmsg inputLabels">Street Address</div>
                <input   type="text" className="inputItemFields" placeholder="Street Address*"
                
                  value={streetAddress}
                  onChange={event => {
                    this.setState ({
                      streetAddress: event.target.value,
                    });
                  }}
                  />
              </div>
              <div>
                <div className="confirmmsg inputLabels">Country</div>
                <input type="text" className="inputItemFields" placeholder="Country*" 
                 value={country}
                onChange={event => {
                    this.setState ({
                      country: event.target.value,
                    });
                  }}/>
              </div>
              <div>
                <div className="confirmmsg inputLabels">State</div>
                <input type="text" className="inputItemFields" placeholder="State*" value={state}
                onChange={event => {
                    this.setState ({
                      state: event.target.value,
                    });
                  }}/>
              </div>
              <div>
                <div className="confirmmsg inputLabels">City</div>
                <input type="text" className="inputItemFields" placeholder="City*" value={city}
                onChange={event => {
                    this.setState ({
                      city: event.target.value,
                    });
                  }}/>
              </div>
              <div>
                <div className="confirmmsg inputLabels">Zip code</div>
                <input type="text" className="inputItemFields" placeholder="Zip code*" value={zipcode}
                onChange={event => {
                    this.setState ({
                      zipcode: event.target.value,
                    });
                  }}/>
              </div>
              
              </div>

              <div className="buttonDiv" style={{marginTop: 15}}>
                <Button
                  className="buttons"
                  onClick={() => this.closeModal ()}
                  data-rh="Cancel"
                >
                  No
                </Button>
                <Button
                  style={{cursor: !this.state.productQuantity ? 'unset' : ''}}
                  disabled={!this.state.productQuantity || !streetAddress || !city || !state || !zipcode || !country}
                  className="buttons yesButton"
                  onClick={() => this.doPlaceOrder ()}
                  data-rh="Place Order"
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={this.state.orderConfirmation}
          centered={true}
          toggle={() => this.closeModal ()}
        >
          <ModalBody className="placeOrderModal">
            <div >

              <p className="confirmTitle">Order Placed!</p>
              <p className="confirmmsg" style={{marginTop:10}}>
                Your order has been placed with the seller. They will contact you shortly to confirm availability and payment.
              </p>
              <div className="buttonDiv" style={{marginTop:20}}>

                <Button
                  className="buttons viewButton"
                  onClick={() => this.goDetail ()}
                  data-rh="View My Orders"
                >
                  View My Orders
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={productShareModal}
          centered={true}
          toggle={() => this.closeModal ()}
        >
          <ModalBody className="shareModalBox">
            <div>

              <div
                className="confirmTitle"
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                }}
              >
                <img
                  style={{width: 24, height: 24, marginTop: 5}}
                  src={messageIcon}
                  alt="messageIcon"
                />
                Share in Messenger

                <Button
                  className="closeShareModal"
                  onClick={() => this.closeModal ()}
                  data-rh="Close"
                >
                  <img style={{width: 35}} src={closeIcon} alt="closeIcon" />
                </Button>
              </div>

              <div
                style={{
                  background: '#333333',
                  marginLeft: -10,
                  paddingBottom: 10,
                  paddingRight: 10,
                }}
              >
                <div className="productInfoBox">
                  <div style={{width: 100}}>
                    <img
                      src={
                        productDetail &&
                          productDetail.productImageList &&
                          productDetail.productImageList.length > 0
                          ? productDetail.productImageList[0]
                          : productDetails
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = productDetails;
                      }}
                      style={{width: 104}}
                      alt="product"
                    />
                  </div>
                  <div style={{width: 180}}>
                    <div className="product_Name">

                      {productDetail &&
                        productDetail.productCategoryType === 'Radiology'
                        ? productDetail.deviceSaleName: (productDetail.productName?productDetail.productName:'')
                        }
                      <div>
                        USD$
                        {' '}
                        {productDetail && productDetail.priceUSD
                          ? priceWithDecimal(productDetail.priceUSD)
                          : ''}
                      </div>
                    </div>
                    {productDetail &&
                      productDetail.productCategoryType === 'Radiology'
                      ?  <div className="product_location">
                          <div>
                            Device Type:
                            {' '}
                            {productDetail && productDetail.deviceType
                              ? productDetail.deviceType
                              : ''}
                          </div>
                          <div>
                            Location:
                            {' '}
                            {productDetail && productDetail.location
                              ? productDetail.location
                              : ''}
                          </div>
                        </div>:<div className="product_location">
                          <div style={{textTransform: 'capitalize'}}>
                            Product Type:
                            {' '}
                            {productDetail && productDetail.productCategoryType
                              ? productDetail.productCategoryType
                              : ''}
                          </div>
                          <div>
                            Category:
                            {' '}
                            {productDetail && productDetail.category
                              ? productDetail.category
                              : ''}
                          </div>
                        </div>
                      }

                  </div>
                </div>

                {!sendSuccess &&<input
                  type="text"
                  className="textInput"
                  placeholder="Say something about this product..."
                  value={userProductDes}
                  onChange={e => {
                    this.setState ({
                      userProductDes: e.target.value,
                    });
                  }}
                />}

              </div>
              {!sendSuccess &&
                <div>
                  <p className="searchMember">Search for members or groups:</p>
                  <input
                    type="text"
                    className="textInput"
                    placeholder="Search by name or email"
                    onChange={e => {
                      this.setState({
                        searchText: e.target.value
                      })
                      // this.setState({
                      //   filterContactData:
                      // })
                      // if(e.target.value){
                      //   this.searchContact(e.target.value);

                      // }else{
                      // this.searchContact('');

                      // }
                    }}
                  />

                  <div className="product_contactListing">
                    <p>Contacts:</p>
                    <div>
                      {filterContactData &&
                        filterContactData.length === 0 &&
                        <div style={{textAlign: 'center'}}>
                          No Contact Found
                        </div>}
                      {filterContactData &&
                        filterContactData.length > 0 &&
                        filterContactData.map ((item, i) => (
                          <div className="shareContactBox" key={i}>
                            <div>
                              <img
                                alt="proimg"
                                src={
                                  item && item.chatType === 'groupChat'
                                    ? item.groupIcon
                                        ? item.groupIcon
                                        : profilePlaceholder
                                    : item.profilePictureURL
                                        ? item.profilePictureURL
                                        : profilePlaceholder
                                }
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = profilePlaceholder;
                                }}
                                className="proimg"
                              />

                            </div>
                            <div>
                              <div className="contact_userName">

                                {item && item.displayName
                                  ? item.chatType === 'singleChat'
                                      ? ShowNameAccordingToLength (
                                          item.displayName
                                        )
                                      : item.displayName
                                  : ''}

                              </div>
                              <div className="contact_lastSeen">
                                {item && item.lastMsg ? item.lastMsg : ''}
                              </div>

                            </div>
                            <div>
                              <Button
                                data-rh="Send"
                                className="closeShareModal"
                                onClick={() => {
                                  this.shareMessage (item);

                                  // this.props.shareButtonClickP2P();
                                  // this.setState({sendSuccess:true})
                                }}
                              >
                                <img
                                  src={sendSmallIcon}
                                  onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = sendSmallIcon;
                                  }}
                                  alt="sendICon"
                                  className="sendICon"
                                />

                              </Button>
                            </div>

                          </div>
                        ))}
                    </div>

                  </div>
                </div>}
              {sendSuccess &&
                <div style={{textAlign: 'center', marginTop: 20}}>

                  <div>
                    <img
                      style={{width: 110}}
                      src={shareSuccess}
                      alt="sendMoney"
                    />

                    <p className="searchMember" style={{marginTop: 10}}>
                      {' '}Message sent!
                    </p>
                    <Button
                      className="searchMember shareAgainButton"
                      onClick={() => this.setState ({sendSuccess: false})}
                      data-rh="Share Again"
                    >
                      Share Again
                    </Button>
                    <p
                      data-rh="Go to Marketplace"
                      className="searchMember"
                      style={{
                        fontSize: 14,
                        opacity: 0.8,
                        marginTop: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => this.closeModal ()}
                    >
                      Return to Marketplace
                    </p>
                  </div>

                </div>}

            </div>
          </ModalBody>
        </Modal>


      {imagePreviewModal && <ProductZoomDetail productDetail={productDetail} imgIndex={imgIndex} closeModal={this.closeModal} showImage={this.showImage}/>}
        

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer,
    payment: state.PaymentReducer,
    userInfo: state.Authreducer,
    contacts: state.ContactsReducer.contacts,
    messengerList: state.MessengerReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getProductListing,
      doOrder,
      resetdoOrderValue,
      addRecentViewProduct,
      getTransactionHistory,
      getProfileDashboard,
      getAllUserContactsFunc,
      fetchMessengerList,
      shareButtonClickP2P,
      shareButtonClickGroupChat,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (ProductDetail);
