import React, { Component } from 'react';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import _ from 'lodash';
import moment from 'moment';

import firebase from 'firebase';
import * as routes from '../../../constants/routes';
import { onlineStatus } from '../../../utility/onlineStatusHandle'
import './MessageList.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ShowNameAccordingToLength } from "../../../utility/stringFunctions";
import { Button, Modal, ModalBody } from 'reactstrap';
import {encryptMessage } from '../../../utility/encryption';
import { fetchMessagesForP2P, getUserChatDeleteDetail, handleUserTypingInfo } from "../../../utility/Chat/fetchData";
import { _handleP2PSecretKey, onBlurTyping, onFocusTyping } from '../../../utility/Chat/secretKey';
// import menuDots from '../../../images/icons/messenger/menudots.png';
// import {environmentUsed} from '../../../config/firebase'

import blueDownScroll from '../../../images/icons/messenger/blueDownScroll.svg';

import {
  messengerGetMutedNotificationsListFunc,
  messengerMuteNotificationsFunc,
  messengerUnMuteNotificationsFunc
} from '../../../actions/notifications';

import {
  addNotificationHistory,
  sendnotification,
  fetchMessengerList,
  sendDataToMobileWhenUsingConnect
} from '../../../actions/Messenger';

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.messageKey = this.props.messengerKey;

    // console.log(this.props.userChatInfo);

    this.state = {
      // secretKey
      secretKey: '',
      messages: [],
      userOwnUid: this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
      messageText: '',
      messengerKey: this.props.messengerKey || '',
      userChatInfo: this.props.userChatInfo,
      currentuserid: this.props.currentuserid,
      confimationModal: false,
      chatID: '',
      attachSource: '',

      // used for user is typing functionality
      userTypingInfo: {
        userTypingId: null,
        isUserTyping: false,
        userName: "noUser"
      },

      gotMuteResponse: false, // will be true after getting response
      muted: false,
      showNotificationPopUpConfirmation: false,
      showDeletePopUpConfirmation: false,

      // last seen by user
      statusIcon: "",
      statusMessage: "",
      statusUpdatedAt: 1,
      statusMessageToShow: '',

      lastTimeOnline: false,
      isUserOnline: null,
      loggedIn: null,

      limitToFetchMessages: 20, // first time message fetching limit // after limit will inc by 20
      refreshing: false,  // refress loader will be there in place or load more button
      loadMoreOptionClick: false, //  this will handle the scroll to end or not in flatlist
      notLoadMoreMessages: false, // Load more button to SHOW or NOT

      usersWhoDeletedChatArray: [],
      isUserInDeleteChatList: false,
      deletionOnVal: null,
      isOpenMessage: null,
      downScrollButtonIndicator:false,
      oppositeUserPublicKey: null,
      deletedChatCallCount: 0,

    };
    this.HandleShowmore = this.HandleShowmore.bind(this);
    this.openMessage = this.openMessage.bind(this);
  }

  handleScroll = e => {
    let element = e.target;
    if ((element.scrollHeight - element.scrollTop) <= element.clientHeight) {
      
      this.setState({
        downScrollButtonIndicator: false
      })
      // do something at end of scroll
    }else{
      this.setState({
        downScrollButtonIndicator: true
      })
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    this._handleReadStatus();
  }

  async componentDidMount() {
    this._handleDataToLoadEveryTime()
  }

  async _handleDataToLoadEveryTime() {
    const database = firebase.database();
    
    let oppoUserID = _.get(this.state, 'userChatInfo.oppositeUserUid', null)
    let UserOwnDocID = _.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
    // console.log("secretKeyForWeb>>>>>>>>  in the messenger chat p2p", _.get(this.props,'userinfo.secretKeyForWeb', null))
    // if secret key is not generated then show the qr code again
    if(!_.get(this.props,'userinfo.secretKeyForWeb', null)){
      await database.ref(`Users/${UserOwnDocID}/onlineStatus`).update({isWebConnectActive: false, encryptedTextForWebToDecryptToStartConnect: null})
    } else {
      let secretKey = _.get(this.props,'userinfo.secretKeyForWeb', null);
      this.setState({
        secretKey: secretKey
      })
    }
    
    this._handleUserLastSeen(); // check online status of user
    // let secretKey = await _handleP2PSecretKey(UserOwnDocID, oppoUserID);
    // console.log("_.get(this.props,'userinfo.secretKeyForWeb', null)>>>>>>>", _.get(this.props,'userinfo.secretKeyForWeb', null))
   

    // get User Chat Delete Detail // handled deleted messages 
    let reqObj = {
      messengerKey: this.state.messengerKey,
      userUid: UserOwnDocID,
      groupChat: false
    }
    let getUserChatDeleteDetailVar = await getUserChatDeleteDetail(reqObj, (usersArray) => {
      this.setState({ usersWhoDeletedChatArray: usersArray })
    }, (userDetail) => {
      this._handleDeleteDetailsFunc(userDetail);
    });
   

    // handle any user is typing in the chat FIREBASE ON method is used and 
    // getting unsubscribe in willUnmount method
    let userTypingObj = {
      messengerKey: this.state.messengerKey,
      groupChat: false
    }
    await handleUserTypingInfo(userTypingObj, (res) => {
      if (res !== undefined && res !== null) {
        this.setState({ userTypingInfo: res })
      }
    });

    
    this._handleGetAllGroupsAndMutedList();
    await this.firstTimePullMsg(this.state.messengerKey);
  }

  // this will be called on every call when user deletes data
  _handleDeleteDetailsFunc(getUserChatDeleteDetailVar){
    const database = firebase.database();
    this.setState({
      isUserInDeleteChatList: _.get(getUserChatDeleteDetailVar, 'deletionOn', null) ? true : false,
      deletionOnVal: _.get(getUserChatDeleteDetailVar, 'deletionOn', null),
      messages: [],
      deletedChatCallCount: this.state.deletedChatCallCount + 1
    }, async ()=>{
      // console.log("this.state.deletedChatCallCount)>>>>>>>>>>", this.state.deletedChatCallCount)
      if( this.state.deletedChatCallCount > 1){
        await database.ref(`MessagesWeb/${this.state.userOwnUid}/${this.state.messengerKey}/messages`).off("child_added");
        await database.ref(`MessagesWeb/${this.state.userOwnUid}/${this.state.messengerKey}/messages`).off('child_changed');
        await this.firstTimePullMsg(this.state.messengerKey);
      }
    })
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    if (this.props.messengerKey !== prevProps.messengerKey) {
      //   console.log("prevProps>>>>> did update,,messengerKey,,", prevProps )
      // console.log("this.props.messengerKey>>>> did update,,messengerKey,,", this.props )

      let oppoUserID = _.get(prevProps, 'userChatInfo.oppositeUserUid', null)
      let UserOwnDocID = _.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
      const database = firebase.database();
      database.ref(`Messages/${prevProps.messengerKey}/usersDetail`).off('value');
      database.ref(`Messages/${prevProps.messengerKey}/isTyping`).off('value');
      database.ref(`MessagesWeb/${UserOwnDocID}/${prevProps.messengerKey}/messages`).off("child_added");
      database.ref(`MessagesWeb/${UserOwnDocID}/${prevProps.messengerKey}/messages`).off('child_changed');
      database.ref(`Users/${oppoUserID}/onlineStatus`).off('value', this.unsubscribeuserData)
      database.ref(`MessagesWeb/${UserOwnDocID}/${prevProps.messengerKey}/metadata`).off('value', this.unsubscribeRealtimeChatDeleteFromMobile)
      let reqObj = {
        messengerKey: prevProps.messengerKey,
        groupChat: false
      }
      onBlurTyping(reqObj);

      this.setState(
        {
          messages: [],
        },
        () => {
          this._handleDataToLoadEveryTime();
        }
      );
    }
    // this.scrollToBottom();
  }

  componentWillUnmount() {
    let oppoUserID = _.get(this.state, 'userChatInfo.oppositeUserUid', null)
    let UserOwnDocID = _.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
    const database = firebase.database();
    database.ref(`Messages/${this.state.messengerKey}/usersDetail`).off('value');
    database.ref(`Messages/${this.state.messengerKey}/isTyping`).off('value');
    database.ref(`MessagesWeb/${UserOwnDocID}/${this.state.messengerKey}/messages`).off("child_added");
    database.ref(`MessagesWeb/${UserOwnDocID}/${this.state.messengerKey}/messages`).off('child_changed');
    database.ref(`Users/${oppoUserID}/onlineStatus`).off('value', this.unsubscribeuserData)
    database.ref(`MessagesWeb/${UserOwnDocID}/${this.state.messengerKey}/metadata`).off('value', this.unsubscribeRealtimeChatDeleteFromMobile)
    let reqObj = {
      messengerKey: this.state.messengerKey,
      groupChat: false
    }
    onBlurTyping(reqObj);
  }

  // used to get muted groups and single chat users list
  _handleGetAllGroupsAndMutedList() {
    let params = {
      "data": {
        // "limit": '',
        "muteID": _.get(this.state, 'userChatInfo.oppositeUserUid', null)
      }
    }
    this.props.messengerGetMutedNotificationsListFunc(params, (res) => {
      // console.log("messengerGetMutedNotificationsList>>>>> P2P result>>>>", res)

      if (_.get(res, 'muteID')) {
        this.setState({
          muted: true
        })
      }
      this.setState({
        gotMuteResponse: true
      })
    })
  }

  // used to MUTE the chat user
  _handleMuteMemberOrGroup() {
    this.setState({
      showNotificationPopUpConfirmation: false
    })
    let params = {
      "data": {
        "sourceUID": this.state.userChatInfo.oppositeUserUid, // this.state.messengerKey,
        "messengerGroupID": ""
      }
    }
    this.props.messengerMuteNotificationsFunc(params, (res) => {
      // console.log("res res mute>>>>>", res)
      if (res.status) {
        this.setState({
          muted: true,
          // showNotificationPopUpConfirmation: false
        })
      }
    })
  }

  // used to UNMUTE the chat user
  _handleUnMuteMemberOrGroup() {
    this.setState({
      showNotificationPopUpConfirmation: false
    })
    let params = {
      "data": {
        "sourceUID": this.state.userChatInfo.oppositeUserUid, // this.state.messengerKey,
        "messengerGroupID": ''
      }
    }
    this.props.messengerUnMuteNotificationsFunc(params, (res) => {
      // console.log("res res UNMUTE>>>>>", res)
      if (res.status) {
        this.setState({
          muted: false,
          // showNotificationPopUpConfirmation: false
        })
      }
    })
  }

  // opposite user last seen method//
  _handleUserLastSeen() {
    const database = firebase.database();
    let oppoUserID = _.get(this.state, 'userChatInfo.oppositeUserUid', null)
    this.unsubscribeuserData = database
      .ref(`Users/${oppoUserID}/onlineStatus`)
      .on('value', snapshot => {
        // console.log("snapshot>>>>_handleUserLastSeen", snapshot.val())
        let obj = snapshot.val();
        let onlineDataGet = onlineStatus(obj);
        // console.log("obj>>>>>", obj)

        this.setState({
          statusIcon: _.get(onlineDataGet, 'statusIcon', ''),
          statusMessage: _.get(onlineDataGet, 'statusMessage', ''),
          statusUpdatedAt: _.get(onlineDataGet, 'statusUpdatedAt', 1),
          statusMessageToShow: _.get(onlineDataGet, 'statusMessageToShow', ''),

          lastTimeOnline: _.get(onlineDataGet, 'lastTimeOnline', null),
          isUserOnline: _.get(onlineDataGet, 'isOnline', false),
          loggedIn: _.get(onlineDataGet, 'loggedIn', false),
          oppositeUserPublicKey:  _.get(obj,'publicKey', null)
        })
        // if(_.get(snapshot.val(),'isUserOnline', false)){
        //   this.setState({
        //     lastTimeOnline: snapshot.val().lastTimeOnline,
        //     isUserOnline: snapshot.val().isOnline
        //   })
        // }
      })
  }

  // if user himself in a chat box then mark all messages read
  _handleReadStatus() {
    const database = firebase.database()
    if (this.state.messages.length > 0) {
      setTimeout(() => {
        database
          .ref(`Users/${_.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)}/chatList/${this.state.messengerKey}/read`).set(true)
      }, 2000);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    // console.log('nextprops ******* ', nextProps)
    return {
      messengerKey: nextProps.messengerKey,
      userChatInfo: nextProps.userChatInfo,
    };
  }

  // this method is used same as mobile// prefer using it 
  firstTimePullMsg = async (key) => {

    let messages = [];

    let reqObj = {
      threadKey: key,
      deletionOnVal: this.state.deletionOnVal,
      limitToFetchMessages: this.state.limitToFetchMessages,
      groupChat: false
    }

    fetchMessagesForP2P(reqObj, this.props,
      (item, isChildChanged) => {
        // console.log("item>>>>>>", item);
        // console.log("isChildChanged message >>>>>>", isChildChanged);
        if (isChildChanged) { // if message data is changed like read status// then this will run

          let index = _.findIndex(messages, { key: item.key });
          messages[index] = item;

          var messagesArr = messages.filter((val) => {
            return val.key !== "isTyping" && val.key !== 'usersDetail'
          })

          let uniqueMessages = _.uniqBy([...messagesArr, ...this.state.messages], (val) => {
            return val.key
          })
          this.setState({ messages: uniqueMessages, refreshing: false, isLoading: false }, () => {
            this.scrollToBottom()
          })
        } else { // it will render on every message is added
          messages.push(item);
          var finalArr = messages.filter((val) => {
            return val.key !== "isTyping" && val.key !== 'usersDetail'
          })

          // it will filter the message which are double in the message list
          let uniqueMessages = _.uniqBy([...finalArr, ...this.state.messages], (val) => val.key)

          var orderbyTimestamp = _.sortBy(uniqueMessages, function (o) { return new moment(_.get(o, 'timeStamp')); })
          // console.log("uniqueMessages>>>>>>>>>>>", uniqueMessages)
          this.setState({ messages: orderbyTimestamp, refreshing: false, isLoading: false }, () => {
            this.scrollToBottom()
          })
        }

      },
      () => {
        // console.log("not pushing into message>>>>>")
        this.setState({
          notLoadMoreMessages: true
        })
      }
    )

  }

  // this will be called on load more button press
  HandleShowmore = async (key) => {
    const database = firebase.database();
    let messagesLength = this.state.messages.length;
    if (messagesLength > 0) {
      if (!this.state.notLoadMoreMessages && _.get(this.state.messages[0], 'timeStamp')) {

        let lastMessageKey = _.get(this.state.messages[0], 'key')
        let dataFirstItemkey = null
        let messages = [];

        // *******to check the first node key of the chat **///
        const snapShotFirstObject = await database
          .ref('Messages')
          .child(key)
          .limitToFirst(1).once('value')
        // console.log("snapShotFirstObject",snapShotFirstObject)

        snapShotFirstObject.forEach(item => {
          // console.log("first itemmmm",item )
          dataFirstItemkey = item.key   //  this will check the first object of the node from firebase
        })
        //*********////// */

        let isMoreMessage;
        const snapshot = await database
          .ref('Messages')
          .child(key)
          .orderByChild('timeStamp')
          .endAt(this.state.messages[0].timeStamp)
          .limitToLast(this.state.limitToFetchMessages)
          .once('value');

        // console.log("snapshot.val() in onceeeee>>>>>handle more", snapshot.val())
        // console.log("hasChildren>>>>",snapshot.hasChild('timeStamp'))
        // console.log("hasChildren>>>>lenght",snapshot.length('timeStamp'))
        // console.log("numChildren>>>>numChildren",snapshot.numChildren())
        // console.log("exportVal()>>>>exportVal()",snapshot.exportVal())
        isMoreMessage = snapshot.hasChildren();
        console.log(isMoreMessage);
        // console.log("isMoreMessage>>>>>>>", isMoreMessage)

        if (isMoreMessage) {
          snapshot.forEach(message => {

            // it checks whether any key matches with first key
            // if matched then hide the load more button
            if (dataFirstItemkey == message.key) {
              this.setState({
                notLoadMoreMessages: true
              })
            }
            ////////***********///// */

            var item = message.val();
            item.key = message.key;

            if (this.state.deletionOnVal !== null) {
              let timestampOfMessage = _.get(item, 'timeStamp', null);
              if (timestampOfMessage !== null && timestampOfMessage > this.state.deletionOnVal) {
                messages.push(item);
              } else if (timestampOfMessage !== null && timestampOfMessage < this.state.deletionOnVal) {
                this.setState({
                  notLoadMoreMessages: true
                })
              }
            } else {
              messages.push(item);
            }
          })
        }

        //  let uniqueMessageList =  _.uniqBy(...this.state.messages, finalArr)
        var finalArr = messages.filter((val) => {
          // console.log("invalid is typing >>>>>>", val)
          return lastMessageKey !== val.key && val.key !== "isTyping" && val.key !== 'usersDetail'
        })
        // console.log("finalArr>>>>>>>finalArr",finalArr)
        this.setState({ messages: [...finalArr, ...this.state.messages], refreshing: false })
      } else {
        this.setState({
          refreshing: false
        })
      }
    }
  }






  openMessage(item) {
    if (item) {
      this.setState({
        isOpenMessage: item.key
      })
    } else {
      this.setState({
        isOpenMessage: ''
      })
    }

  }
  renderMessages() {
    // console.log(this.state.messages);
    let i = 0;
    let messageCount = this.state.messages.length;
    let messages = [];

    if (messageCount > 0) {

      while (i < messageCount) {
        let previous = this.state.messages[i - 1];
        let current = this.state.messages[i];
        if (this.state.userChatInfo) {
          current.senderName = this.state.userChatInfo.displayName;
          current.senderProfilePic = this.state.userChatInfo.profilePictureURL;

        }

        // current.senderName="";
        // console.log("current>>>>>>>>message", current)
        // console.log("current*****", current)
        let next = this.state.messages[i + 1];
        let isMine =
          current.sender ===
          this.props.userinfo.user.Data.userDetails[0].personalDetails.docID;
        let currentMoment = moment(current.timeStamp);
        let prevBySameAuthor = false;
        let nextBySameAuthor = false;
        let startsSequence = true;
        let endsSequence = true;
        let showTimestamp = true;

        if (previous) {
          let previousMoment = moment(previous.timestamp);
          let previousDuration = moment.duration(
            currentMoment.diff(previousMoment)
          );
          prevBySameAuthor = previous.sender === current.sender;

          if (prevBySameAuthor) {
            startsSequence = false;
          }

          if (previousDuration.as('hours') < 1) {
            showTimestamp = false;
          }
        }

        if (next) {
          let nextMoment = moment(next.timestamp);
          let nextDuration = moment.duration(nextMoment.diff(currentMoment));
          nextBySameAuthor = next.sender === current.sender;
          if (nextBySameAuthor) {
            endsSequence = false;
          }
        }
        const { isOpenMessage } = this.state;
        messages.push(
          <Message
            key={i}
            isMine={isMine}
            oppositeUserUid={this.state.userChatInfo.oppositeUserUid}
            secretKey={this.state.secretKey.toString()}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
            userInfo={_.get(this.props, 'userinfo.user.Data.userDetails[0].personalDetails', {})}
            chatType="singleChat"
            isOpenMessage={isOpenMessage}
            openMessage={this.openMessage}
          />
        );

        // Proceed to the next message.
        i += 1;
      }
    }


    return messages;
  }
  sendMessage = async (key, message) => {
    const database = firebase.database();
    await database.ref('Messages').child(key).push(message).key;
    await database.ref(`Users/${this.props.userinfo.user.Data.userDetails[0].personalDetails.docID}/chatList/${key}`).update({
      "timeStamp": message.timeStamp,
      "oppositeUserUid": this.state.userChatInfo.oppositeUserUid,
      "profilePictureURL": this.state.userChatInfo.profilePictureURL !== null ? this.state.userChatInfo.profilePictureURL : '',
      "displayName": this.state.userChatInfo.displayName,
      "lastMsgSentBy": this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
      "read": true,
      'chatType': 'singleChat',
      "lastMsg": message.text,
      "type": message.type
    })
    await database.ref(`Users/${this.state.userChatInfo.oppositeUserUid}/chatList/${key}`).update({
      "timeStamp": message.timeStamp,
      "oppositeUserUid": this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
      "profilePictureURL": this.props.userinfo.user.Data.userDetails[0].personalDetails.profilePictureURL !== null ? this.props.userinfo.user.Data.userDetails[0].personalDetails.profilePictureURL : '',
      "displayName": this.props.userinfo.user.Data.userDetails[0].personalDetails.firstName + " " + this.props.userinfo.user.Data.userDetails[0].personalDetails.lastName,
      "read": false,
      'chatType': 'singleChat',
      "lastMsg": message.text,
      "type": message.type

    })
    this.props.fetchMessengerList(this.props.userinfo.user.Data.userDetails[0].personalDetails.docID);
    return false;
  };

  async _handleSendClick(
    key,
    senderId, // user own UID 
    text, // text
    oppositeUserUid, // opposite user uid
    profilePictureURL,// opposite user profilepic
    displayName,  // opposite user displayname
    type, // two types : "text" and 'image'
    imgData,
    attachSource
  ) {
    {
      let userProfilePic = _.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', '');
      let userDisplayName = ShowNameAccordingToLength(this.props.userinfo.user.Data.userDetails[0].personalDetails.firstName + " " + this.props.userinfo.user.Data.userDetails[0].personalDetails.lastName)
      // let userDisplayName =  this.props.userinfo.user.Data.userDetails[0].personalDetails.firstName+ " " + this.props.userinfo.user.Data.userDetails[0].personalDetails.lastName ;

      const database = firebase.database();
      const message = {
        chatThread: key,
        sender: senderId,
        senderName: _.get(this.props, 'userinfo.user.Data.userDetails[0].personalDetails.firstName', ''),
        senderProfilePic: userProfilePic,
        text: encryptMessage(text.toString(), this.state.secretKey.toString()),
        type: type,
        chatType: 'singleChat',
        sendType: 'normal', // forward, quote
        timeStamp: Math.floor(Date.now()),
        read: false,
        sent: true,
        delivered: true,
        messageAddedInMainNode: false

      };

      // this method is used when there is only text , without image in the field
      if (text !== '' && (type == 'text' || type == 'videoCall')) {

        // NODE FOR WEB P2P MESSAGES

        let ref =  await database
        .ref('MessagesWeb')
        .child(senderId)
        .child(key)
        .child("messages")
        .push().key;

        await database.ref(`MessagesWeb/${senderId}/${key}/messages/${ref}`).update({...message, messageKey: ref})

         // ORIGINAL NODE FOR MESSAGES
        // await database
        //   .ref('Messages')
        //   .child(key)
        //   .push(message).key

        {/*THIS IS THE DATA OBJECT TO SEND TO THE MOBILE DATA TO UPDATE TJE ORIGINAL NODE OF MESSAGE STARTS*/}

      let reqObj = {
        mobilefirebaseToken: _.get(this.props.userinfo,'userDeviceData.FCMTokenForConnect', null),
        props: this.props,
        authToken: this.props.userinfo.authToken,
        messageData: {
          ...message,
          messageKey: ref,
          chatThread: key,

          // other keys than original message
          oppositeUserUid: oppositeUserUid,
          oppositeProfilePictureURL: profilePictureURL,
          oppositeUserDisplayName: displayName,
        },// this is message data
        oppositeUserPublicKey: this.state.oppositeUserPublicKey,
        
      }
      sendDataToMobileWhenUsingConnect (reqObj)

      {/*THIS IS THE DATA OBJECT TO SEND TO THE MOBILE DATA TO UPDATE TJE ORIGINAL NODE OF MESSAGE ENDSSS*/}

      }

      // when trying to send image , then this will be executed
      // else if (type == 'image') {
      else {
        const attach = {
          chatThread: key,
          sender: senderId,
          senderName: _.get(this.props, 'userinfo.user.Data.userDetails[0].personalDetails.firstName', ''),
          senderProfilePic: userProfilePic,
          attachSource: encryptMessage(attachSource.toString(), this.state.secretKey.toString()),
          mediaType: type,
          text: encryptMessage(text.toString(), this.state.secretKey.toString()),
          type: 'attach',
          chatType: 'singleChat',
          sendType: 'normal', // forward, quote
          timeStamp: Math.floor(Date.now()),
          fileName: encryptMessage(imgData.name.toString(), this.state.secretKey.toString()),
          fileSize: imgData.size,
          read: false,
          sent: true,
          delivered: true,
          messageAddedInMainNode: false
        }

        // NODE FOR WEB P2P MESSAGES
        let ref =  await database
          .ref('MessagesWeb')
          .child(senderId)
          .child(key)
          .child('messages')
          .push().key

        await database.ref(`MessagesWeb/${senderId}/${key}/messages/${ref}`).update({...attach, messageKey: ref})

        // ORIGINAL NODE FOR MESSAGES
        // await database
        //   .ref('Messages')
        //   .child(key)
        //   .push(attach).key

        {/*THIS IS THE DATA OBJECT TO SEND TO THE MOBILE DATA TO UPDATE TJE ORIGINAL NODE OF MESSAGE STARTS*/}

        let reqObj = {
          mobilefirebaseToken: _.get(this.props.userinfo,'userDeviceData.FCMTokenForConnect', null),
          props: this.props,
          authToken: this.props.userinfo.authToken,
          messageData: {
            ...attach,
            messageKey: ref,
            chatThread: key,

            // other keys than original message
            oppositeUserUid: oppositeUserUid,
            oppositeProfilePictureURL: profilePictureURL,
            oppositeUserDisplayName: displayName, 
          },// this is message data
          oppositeUserPublicKey: this.state.oppositeUserPublicKey,
          
        }
        sendDataToMobileWhenUsingConnect (reqObj)

      {/*THIS IS THE DATA OBJECT TO SEND TO THE MOBILE DATA TO UPDATE TJE ORIGINAL NODE OF MESSAGE ENDSSS*/}
      }

      // creating new node chatList in the Users node and saving data into it
      // await database
      //   .ref(`Users/${senderId}/chatList/${key}`).update({
      //     "timeStamp": Math.floor(Date.now()),
      //     "oppositeUserUid": oppositeUserUid,
      //     "profilePictureURL": profilePictureURL !== null ? profilePictureURL : '',
      //     "displayName": displayName,
      //     "lastMsgSentBy": senderId,
      //     "read": true,
      //     'chatType': 'singleChat',
      //     "lastMsg": type == 'text' ? text : type,
      //     "type": type == 'text' ? "text" : 'attach',
      //     // "lastMsg": type == 'text' ? text : "image"
      //   })

      // await database
      //   .ref(`Users/${oppositeUserUid}/chatList/${key}`).update({
      //     "timeStamp": Math.floor(Date.now()),
      //     "oppositeUserUid": senderId,
      //     "profilePictureURL": userProfilePic !== null ? userProfilePic : '',
      //     "displayName": userDisplayName,
      //     "read": false,
      //     'chatType': 'singleChat',
      //     // "lastMsg": type == 'text' ? text : "image"
      //     "lastMsg": type == 'text' ? text : type,
      //     "type": type == 'text' ? "text" : 'attach',
      //   })

      // // sending notification on sending message 
      // let objReq = {
      //   "data": {
      //     "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
      //     "sourceUID": oppositeUserUid,
      //     "title": `R-DEE Connect`,
      //     "body": `${userDisplayName} sent you a message`,
      //     "targetUID": oppositeUserUid,
      //     "chatType": "singleChat",
      //     "messengerKey": key,
      //     "thread_id": key,
      //     "imageURL": userProfilePic !== null ? userProfilePic : '',
      //     "profilePictureURL": userProfilePic !== null ? userProfilePic : '',
      //     "titleChat": userDisplayName,
      //     "oppositeUserUid": senderId, // oppositeUserUid,
      //     "screen": "singleChat" // // for push notification body
      //   }
      // }

      // this.props.sendnotification(objReq);
    }
  }

  changeText = event => {
    this.setState({ messageText: event.target.value }, () => {
      let reqObj = {
        messengerKey: this.state.messengerKey,
        userDetail: this.props.userinfo.user.Data.userDetails[0],
        text: this.state.messageText,
        groupChat: false
      }
      onFocusTyping(reqObj);
    });
  };
  updateImage = (url, fileType, filesData, captionText) => {


    this.setState(
      {
        messageText: captionText,
        attachSource: url
      },
      () => {
        this._handleSendClick(
          this.props.messengerKey,
          this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
          this.state.messageText.trim(),
          this.state.userChatInfo.oppositeUserUid,
          this.state.userChatInfo.profilePictureURL,
          this.state.userChatInfo.displayName,
          fileType,
          filesData,
          this.state.attachSource
        );
        this.setState({ messageText: '',attachSource: '' });
      }
    );
  };

  _handleDeleteConversation() {
    let oppoUserID = _.get(this.state, 'userChatInfo.oppositeUserUid', null)
    let userIdWhoDelete = _.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null);
    let userArray = [];
    let chatDeletedUsersArray = this.state.usersWhoDeletedChatArray;
    let newArrayOfUsersDeletedChat = [];
    if (chatDeletedUsersArray.length > 0 && this.state.isUserInDeleteChatList) {
      newArrayOfUsersDeletedChat = chatDeletedUsersArray.map((val) => {
        if (val.uid == userIdWhoDelete) {
          return { ...val, deletionOn: Math.floor(Date.now()) }
        } else {
          return val
        }
      })
    } else {
      chatDeletedUsersArray.push(
        { uid: userIdWhoDelete, deletionOn: Math.floor(Date.now()) }
      )
    }
    let allUsers = [...userArray, oppoUserID, userIdWhoDelete]
    let ObjTobeUpdated = {
      usersDetail: {
        users: allUsers,
        deletedBy: newArrayOfUsersDeletedChat.length > 0
          ? newArrayOfUsersDeletedChat
          : chatDeletedUsersArray
      }
    }
    const database = firebase.database();
    database.ref(`Messages/${this.state.messengerKey}`).update(ObjTobeUpdated);
    this.setState({
      messages: [],
      isUserInDeleteChatList: true,
      isUserInDeleteChatList: false,
      deletionOnVal: Math.floor(Date.now()),
      showDeletePopUpConfirmation: false,

    }, async () => {
      await database.ref(`MessagesWeb/${this.state.userOwnUid}/${this.state.messengerKey}/messages`).off("child_added");
      await database.ref(`MessagesWeb/${this.state.userOwnUid}/${this.state.messengerKey}/messages`).off('child_changed');
      await this.firstTimePullMsg(this.state.messengerKey);
      await database.ref(`Users/${userIdWhoDelete}/chatList/${this.state.messengerKey}/lastMsg`).set('');
    })
  }

  _handleViewProfile() {
    this.goToprofile();
  }

  goToprofile() {
    // console.log("goToprofile>>>>>", this.props.history)
    this.props.history.push(routes.CONTACTUSERDETAIL + `/${this.state.userChatInfo.oppositeUserUid}`);
  }

  render() {
    // console.log("thiss.statte.userChatInfo>>>>>", environmentUsed)
    const { userTypingInfo, userOwnUid, muted, statusMessageToShow, statusIcon, notLoadMoreMessages,downScrollButtonIndicator } = this.state;

    let onlineStatus = statusMessageToShow;

    let isTypingStatus = userTypingInfo.isUserTyping && (userTypingInfo.userTypingId !== userOwnUid) ? true : false

    const conversationTitle =
      this.props.conversationTitle || 'Conversation Title';

    let crumbOptions = [
      {
        name: 'View Profile',
        onClick: () => this._handleViewProfile(),
      },
      {
        name: muted ? 'Unmute Conversation' : 'Mute Conversation',
        onClick: () => this.setState({ showNotificationPopUpConfirmation: true }),
      },
      {
        name: 'Delete Conversation',
        onClick: () => this.setState({ showDeletePopUpConfirmation: true }),
      },
      {
        name: 'Video Call',
        onClick: () => {

          this._handleSendClick(
            this.props.messengerKey,
            this.props.userinfo.user.Data.userDetails[0].personalDetails
              .docID,
            "Please join meeting from the url " + "https://" + window.location.hostname + routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.userDetails[11].confBridgeCode,
            this.state.userChatInfo.oppositeUserUid,
            this.state.userChatInfo.profilePictureURL,
            ShowNameAccordingToLength(this.state.userChatInfo.displayName),
            'videoCall'
          );

          this.props.history.push(routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.userDetails[11].confBridgeCode)

        }
      },
    ];

    return (
      <div>
        <div className="message-list" id="message-list-single">
          <div className="toolbarStickySingleChat">
            <Toolbar
              title={conversationTitle}
              showMuteIcon={this.state.muted}
              userInfo={this.state.userChatInfo}
              imgSource={this.state.userChatInfo.profilePictureURL}
              onClickVideoIcon={() => {
                this._handleSendClick(
                  this.props.messengerKey,
                  this.props.userinfo.user.Data.userDetails[0].personalDetails
                    .docID,
                  "Please join meeting from the url " + "https://" + window.location.hostname + routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.userDetails[11].confBridgeCode,
                  this.state.userChatInfo.oppositeUserUid,
                  this.state.userChatInfo.profilePictureURL,
                  ShowNameAccordingToLength(this.state.userChatInfo.displayName),
                  'videoCall'
                );
                this.props.history.push(routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.userDetails[11].confBridgeCode)
              }
              }
              onlineStatus={`${isTypingStatus ? userTypingInfo.userName + 'is Typing...' : onlineStatus}`}
              // onDeleteConversation={()=> this._handleDeleteConversation()}
              rightItems={[
                <ToolbarButton
                  key="info"
                  icon="ion-ios-information-circle-outline"
                />,
                <ToolbarButton key="video" icon="ion-ios-videocam" />,
                <ToolbarButton key="phone" icon="ion-ios-call" />,
              ]}
              showCrumbs={true}
              crumbOptions={crumbOptions}
              statusIcon={statusIcon}
            />
          </div>

          {/* this is all messages list*/}
          <div className="message-list-container" onScroll={this.handleScroll}>
            {(!notLoadMoreMessages && this.state.messages.length > 19) && <div className="loadMoreDiv">
              <Button className="loadMoreChatButton" onClick={() => this.HandleShowmore(this.state.messengerKey)}>Load more</Button>
            </div>}
            {this.state.messages.length > 0 && this.renderMessages()}
           
            {downScrollButtonIndicator && <div >
              <Button className="goDownScrollSingleChatButton" onClick={()=>this.scrollToBottom()}>
                <img src={blueDownScroll} alt="blueDownScroll"/>
              </Button>
            
            </div>}
            <div style={{ float: "left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
            </div>
          </div>
        </div>




        <Compose
          onSendClick={(e) => {
            e.preventDefault();
            if (this.state.messageText.trim() !== '') {

              this._handleSendClick(
                this.props.messengerKey,
                this.props.userinfo.user.Data.userDetails[0].personalDetails
                  .docID,
                this.state.messageText,
                this.state.userChatInfo.oppositeUserUid,
                this.state.userChatInfo.profilePictureURL,
                ShowNameAccordingToLength(this.state.userChatInfo.displayName),
                'text'
              );
              this.setState({ messageText: '' });
            }
          }}
          onFocus={() => {
            let reqObj = {
              messengerKey: this.state.messengerKey,
              userDetail: this.props.userinfo.user.Data.userDetails[0],
              text: this.state.messageText,
              groupChat: false
            }
            onFocusTyping(reqObj);
          }}
          onBlur={() => {
            let reqObj = {
              messengerKey: this.state.messengerKey,
              groupChat: false
            }
            onBlurTyping(reqObj);
          }}
          value={this.state.messageText}
          onChange={this.changeText}
          updateImage={this.updateImage}
          rightItems={[
            <ToolbarButton key="photo" icon="ion-ios-camera" />,
            <ToolbarButton key="image" icon="ion-ios-image" />,
            <ToolbarButton key="audio" icon="ion-ios-mic" />,
            <ToolbarButton key="money" icon="ion-ios-card" />,
            <ToolbarButton key="games" icon="ion-logo-game-controller-b" />,
            <ToolbarButton key="emoji" icon="ion-ios-happy" />,
          ]}
        />



        <Modal
          size="sm"
          isOpen={this.state.showNotificationPopUpConfirmation}
          centered={true}
          toggle={() => this.setState({ showNotificationPopUpConfirmation: false })}
        >
          <ModalBody className="placeOrderModal">
            <div>

              <p className="confirmTitle">{muted ? 'Unmute Conversation' : "Mute Conversation"}</p>
              <p className="confirmmsg">
                {`Are you sure you wish to ${muted ? 'unmute' : 'mute'} '${conversationTitle}'?`}
              </p>
              <div className="buttonDiv">
                <Button className="buttons" onClick={() => this.setState({ showNotificationPopUpConfirmation: false })}>
                  No
                </Button>
                <Button
                  className="buttons yesButton"
                  onClick={() => muted ? this._handleUnMuteMemberOrGroup() : this._handleMuteMemberOrGroup()}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>


        <Modal
          size="sm"
          isOpen={this.state.showDeletePopUpConfirmation}
          centered={true}
          toggle={() => this.setState({ showDeletePopUpConfirmation: false })}
        >
          <ModalBody className="placeOrderModal">
            <div>

              <p className="confirmTitle">Delete Conversation</p>
              <p className="confirmmsg">
                Are you sure you want to permanently delete this conversation?
              </p>
              <div className="buttonDiv">
                <Button className="buttons" onClick={() => this.setState({ showDeletePopUpConfirmation: false })}>
                  Back
                </Button>
                <Button
                  className="buttons yesButton"
                  onClick={() => this._handleDeleteConversation()}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    chatreducer: state.MessengerReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendnotification,
      addNotificationHistory,
      fetchMessengerList,
      messengerGetMutedNotificationsListFunc,
      messengerMuteNotificationsFunc,
      messengerUnMuteNotificationsFunc
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
