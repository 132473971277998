import React, {Component} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import firebase from 'firebase';
import {bindActionCreators} from 'redux';

import Highlighter from "react-highlight-words";

import GroupInfoCard from '../../UI/GroupInfoCard';
import MyModal from '../../UI/MyModal';
import GroupDetailView from '../../UI/GroupDetails';
import GroupMembers from '../../UI/GroupMembers';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import yellowcrown from '../../../images/icons/yellowcrown.png';
import nonAdmin from '../../../images/icons/nonAdmin.png';
import removeMember from '../../../images/icons/removeMember.png';
import searchIcon from './../../../images/icons/search.svg';
import profileDefault from '../../../images/profile/profileDefault.png';
import {fetchMessengerList} from '../../../actions/Messenger';
import {Loading} from '../../../actions/LoadingAction';
import { getFileName } from '../../../utility/getFileName';
import {
  messengerGetMutedNotificationsListFunc,
  messengerMuteNotificationsFunc,
  messengerUnMuteNotificationsFunc
} from '../../../actions/notifications';

import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
} from '../../../constants/notificationtoast';
import * as routes from '../../../constants/routes';
import {Button, Modal, ModalBody} from 'reactstrap';
import './GroupDetails.css';
import _ from 'lodash';
import ConversationList from '../ConversationList';
import backIcon from '../../../images/icons/messenger/backwhiteArrow.png';
import {ShowNameAccordingToLength, capitalfirstLetter} from '../../../utility/stringFunctions';
import { _handleGroupInfoChanged, _handleGroupSecretKey } from '../../../utility/Chat/sendMessage';


class GroupDetails extends Component {
  state = {
    id: null,
    name: '',
    image: '',
    groupMembers: [],
    originalGroupMember:[],
    userContacts: this.props.userContacts.contacts,
    membersRemoved: [],
    userOwnUid: this.props.userInfo.user.Data.userDetails[0].personalDetails
      .docID,

    selectedDocID: null,
    showGroupMemberEdit: false,
    showRemoveGroupMemberPopUp: false,
    showUpdateGroupInfoPopUp: false,
    showAddNewGroupMembersPopup: false,
    showLeaveGroupPopUp: false,
    showUserCurrentAdmin: false,

    // added from mobile app //
    groupDescription: '',
    //   selectedName: '',
    //   selectedProfilePictureURL: '',
    groupAdmin: null,
    groupPublicKey: null,
    groupData: null,
    memberTobeAddedObj: {},
    removeMember: false,
    removeMemberData: '',
    groupCreatedtimeStamp: '',
    muted: false, // group notification mute state
    gotMuteResponse: false, // is mute api hit first time
    showNotificationPopUpConfirmation: false
  };

  _handleKeyVal (receiver) {
    let keyVal = '';
    let userId = this.props.userInfo.user.Data.userDetails[0].personalDetails
      .docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }
  // _handleKeyVal (receiver) {
  //   let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
  //   let keyVal = '';
  //   let userId = loginUser.docID;
  //   if (receiver > userId) {
  //     keyVal = `${receiver}${userId}`;
  //   } else {
  //     keyVal = `${userId}${receiver}`;
  //   }
  //   return keyVal;
  // }

  onMessageClicked = () => {
    this.goToChat ();
  };

  

  goToChat () {
    const {history} = this.props;
    const {memberTobeAddedObj} = this.state;
    let userChatInfo = {
      oppositeUserUid: memberTobeAddedObj.docID,
      profilePictureURL: memberTobeAddedObj &&
        memberTobeAddedObj.profilePictureURL
        ? memberTobeAddedObj.profilePictureURL
        : profilePlaceholder,
      displayName: memberTobeAddedObj && memberTobeAddedObj.firstName
        ? memberTobeAddedObj.firstName
        : '' + ' ' + (memberTobeAddedObj && memberTobeAddedObj.lastName)
            ? memberTobeAddedObj.lastName
            : '',
    };
    history.push (routes.MESSENGER, {
      userChatInfo: userChatInfo,
      messengerKey: this._handleKeyVal (memberTobeAddedObj.docID),
    });
  }

  goToprofile = () => {
    const {memberTobeAddedObj} = this.state;
    // console.log ('goToprofile()>>>>>', this.props.history);
    this.props.history.push (
      routes.CONTACTUSERDETAIL + `/${memberTobeAddedObj.docID}`
    );
  };

  // // this is working properly for making a new group admin//
  // makeUserGroupAdmin = async () => {
  //   const database = firebase.database ();
  //   const {selectedDocID, id} = this.state;
  //   this.setState ({groupAdmin: selectedDocID, showGroupMemberEdit: false});
  //   const updatedGroupDetails = {
  //     groupAdminUid: selectedDocID,
  //     groupAdminsArray: [selectedDocID],
  //   };
  //   const conversationRef = database.ref (`GroupChatDetail/${id}`);
  //   // update group data
  //   await conversationRef.update (updatedGroupDetails);
  // };

  // this will remove the member from the group
  // and will change the group info and will remove the node from the selected user
  removeUser = async () => {
    const database = firebase.database ();
    const {selectedDocID, id, groupMembers} = this.state;
    this.setState ({
      showGroupMemberEdit: false,
      showRemoveGroupMemberPopUp: false,
    });
    // const groupDetail = await this.fetchGroupDataFromFirebase(id);

    let newMembersArr = groupMembers.filter (member => {
      // console.log("member in remove from group chat ", member.docID)
      if (member.docID == selectedDocID) {
        database
          .ref (`Users/${member.docID}/chatList/${this.state.groupId}`)
          .remove ();
        return;
      } else {
        return member;
      }
    });

    // this will update users node in the firebase
    const conversationRef = database.ref (`GroupChatDetail/${id}/users`);
    await conversationRef.set (newMembersArr);

    const updatedGroupDetails = {
      noOfUsers: newMembersArr.length,
    };

    // this will update the no of users in the firebase node
    const conversationRef2 = database.ref (`GroupChatDetail/${id}`);
    await conversationRef2.update (updatedGroupDetails);
    this.setState ({
      groupMembers: newMembersArr,
    });
  };

  // handle add a member in firebase
  // this method is being used
  async _handleAddMemberInFirebase () {
    const database = firebase.database ();
    const {selectedDocID, id, memberTobeAddedObj} = this.state;
    let nodeTobeUpdate = {
      lastMsg: 'You have been added to this group',
      groupIcon: this.state.image ? this.state.image : '',
      displayName: this.state.name,
      groupId: this.state.id,
      timeStamp: Math.floor (Date.now ()),
      read: false,
      chatType: 'groupChat',
    };

    database
      .ref (`Users/${selectedDocID}/chatList/${id}`)
      .update (nodeTobeUpdate);

    const groupDetail = await this.fetchGroupDataFromFirebase (id);
    if (groupDetail) {
      const updatedGroupDetails = {
        noOfUsers: this.state.groupMembers.length + 1,
      };

      const conversationRef = database.ref (`GroupChatDetail/${id}`);
      // update group data
      await conversationRef.update (updatedGroupDetails);

      let newAddedMemberList = [...this.state.groupMembers, memberTobeAddedObj];
      const conversationRef2 = database.ref (`GroupChatDetail/${id}/users`);
      // update group data
      await conversationRef2.set (newAddedMemberList);
      this.setState (
        {
          groupMembers: newAddedMemberList,
          selectedDocID: null,
          showGroupMemberEdit: false,
          showRemoveGroupMemberPopUp: false,
          showUpdateGroupInfoPopUp: false,
          showAddNewGroupMembersPopup: false,
        },
        () => {
          createNotification (
            NOTIFICATION_TYPE_SUCCESS,
            'New Members added successfully'
          );
        }
      );
    }
  }

  updateGroupDetails = async ({name, image}) => {
    const {
      id,
      name: prevName,
      image: prevImg,
      groupMembers,
      // groupCreatedtimeStamp,
      originalGroupMember,
    } = this.state;
    this.props.Loading (true);

    let addedNewMembers = groupMembers;

    // let addedNewMembers = groupMembers.filter((member)=> {
    //  return !originalGroupMember.includes(member)
    // })
    // console.log(addedNewMembers);
    // console.log("addedNewMembers>>>>>", addedNewMembers)

    let removedMembers = originalGroupMember.filter((member)=> {
      return !groupMembers.includes(member)
     })
    //  console.log("removedMembers>>>>>", removedMembers)
    // if (name === prevName && image === prevImg) {
    //   this.setState ({
    //     showUpdateGroupInfoPopUp: false,
    //   });
    //   return;
    // }
    const groupDetail = await this.fetchGroupDataFromFirebase (id);
    let imageStorageUrl = prevImg;
    let updatedGroupDetails = {
      ...groupDetail,
      // displayName: name,
      // users: groupMembers, // does it separately 
      groupName: name,
      noOfUsers: groupMembers.length,
    };
    if (image !== prevImg) {
      const storage = firebase.storage ();
      // Upload the image blob to firebase
      // imageStorageUrl = (await storage
      //   .ref (`/attachPics/${image.name}`)
      //   .put (image)).downloadURL;

        // console.log(imageStorageUrl);
        try{
              await storage.ref(`/attachPics/${getFileName(image.name)}`).put(image).then(async (snapshot)=> {
                // console.log('Uploaded a blob or file!');
                this.props.Loading(false);
                await  snapshot.ref.getDownloadURL ().then (downloadURL => {
                  imageStorageUrl=downloadURL;

                });
              })
        }catch (e) {
            this.props.Loading(false);
            createNotification("error", e.message);
            return;
        }
        if(!imageStorageUrl){
          this.props.Loading(false);

          createNotification("error", "Please try again");
          return;
        }
    
      updatedGroupDetails = {
        ...updatedGroupDetails,
        groupIcon: imageStorageUrl,
      };
    }
    const database = firebase.database ();
    const conversationRef = database.ref (`GroupChatDetail/${id}`);

    await conversationRef.update (updatedGroupDetails);


    groupMembers.map(val => {
      database.ref (`GroupChatDetail/${id}/users/${val.docID}`).update(val)
    })

    // this is to remove the  node from user chatlist
    removedMembers.map(val=>{
      database
        .ref (`Users/${val.docID}/chatList/${this.state.id}`).remove()
    })

    // this is to remove the  node from group user node 
    removedMembers.map(val=>{
      database
        .ref (`GroupChatDetail/${id}/users/${val.docID}`).remove()
    })

 

    addedNewMembers.map (val => {

      let obj = {
        groupIcon: imageStorageUrl !== undefined &&
          imageStorageUrl !== '' &&
          imageStorageUrl !== null
          ? imageStorageUrl
          : image ? image : '',
        displayName: name,
        read: false,
        groupId: this.state.id,
        chatType: 'groupChat',
        lastMsg: groupDetail.users[val.docID] ? 'Update group Info':'You have been added to this group',
        // timeStamp: groupCreatedtimeStamp,
        timeStamp:Math.floor (Date.now ())
      };
      database
        .ref (`Users/${val.docID}/chatList/${this.state.id}`)
        .update (obj);
    });
    let reqObj = {
      messengerKey : this.state.id,
      senderId : this.state.userOwnUid, // user own UID
      addedContactsArray : _.toArray(groupMembers), // all group members
      text : `${capitalfirstLetter(_.get(this.props.userInfo,'user.Data.userDetails[0].personalDetails.firstName', ''))} has updated Group Info`, // this is text entered
      type : 'text',
      secretKey : await _handleGroupSecretKey(this.state.groupPublicKey),
      groupData: this.state.groupData
    }
    // console.log("reqObj>>>>>>group  EDIT ", reqObj)

    _handleGroupInfoChanged(reqObj, this.props)

    this.setState (
      {
        showUpdateGroupInfoPopUp: false,
        name,
        image: imageStorageUrl,
      },
      () => {
        this.props.fetchMessengerList (
          this.props.userInfo.user.Data.userDetails[0].personalDetails.docID
        );
        this.props.Loading (false);

        createNotification (
          NOTIFICATION_TYPE_SUCCESS,
          'Group details updated successfully'
        );
      }
    );
  };

  // this is to filter the contacts from the group members
  getMemberCandidates = () => {
    const {groupMembers, userContacts} = this.state;
    const memberIds = groupMembers.map (member => member.docID);
    const unavailableContacts = [...memberIds];
    const availableContacts = userContacts
      .map (obj => obj.contact)
      .filter (contact => {
        const index = unavailableContacts.indexOf (contact.contactDetail.docID);
        return index === -1;
      });
    return availableContacts;
  };

  addNewMembers = async groupMembers => {
    if (groupMembers.length > 0) {
      const {id} = this.state;
      const updatedMemberList = [...this.state.groupMembers, ...groupMembers];
      const groupDetail = await this.fetchGroupDataFromFirebase (id);
      const updatedGroupDetails = {
        ...groupDetail,
        noOfUsers: updatedMemberList.length,
      };
      const database = firebase.database ();
      const conversationRef = database.ref (`GroupChat/${id}`);
      // update group data
      await conversationRef.update (updatedGroupDetails);
      // update users inside group details
      groupMembers.forEach (val =>
        database.ref (`GroupChat/${id}/users`).push ().update (val)
      );
      this.setState (
        {
          membersRemoved: [],
          showAddNewGroupMembersPopup: false,
          groupMembers: updatedMemberList,
        },
        () => {
          createNotification (
            NOTIFICATION_TYPE_SUCCESS,
            'New Members added successfully'
          );
        }
      );
    } else {
      this.setState ({
        membersRemoved: [],
        showAddNewGroupMembersPopup: false,
      });
    }
  };

  fetchGroupDataFromFirebase = async conversationId => {
    const database = firebase.database ();
    const conversationRef = database.ref (`GroupChatDetail/${conversationId}`);
    return new Promise (resolve => {
      // Get details for this conversation id

      conversationRef.on ('value', snapshot => {
        resolve (snapshot.val ());
        // console.log("snapshot.val()>>>>>fetchGroupDataFromFirebase", snapshot.val())
      });
      // conversationRef.once("value").then(snapshot => {
      //   console.log("snapshot.val()>>>>>fetchGroupDataFromFirebase", snapshot.val())
      //   resolve(snapshot.val());
      // });
    });
  };

  _handleMakeAdmin (member) {
    // console.log("member>>>>>info", member)
    this.setState (
      {
        selectedDocID: member.docID,
      },
      () => {
        this.makeUserGroupAdmin ();
      }
    );
  }

  // this is working properly for making a new group admin//
  makeUserGroupAdmin = async () => {
    const database = firebase.database ();
    const {selectedDocID, id} = this.state;
    this.setState ({groupAdmin: selectedDocID, showGroupMemberEdit: false});
    const updatedGroupDetails = {
      groupAdminUid: selectedDocID,
      groupAdminsArray: [selectedDocID],
    };
    const conversationRef = database.ref (`GroupChatDetail/${id}`);
    // update group data
    await conversationRef.update (updatedGroupDetails);
    this.setState ({
      groupAdmin: selectedDocID,
      showUserCurrentAdmin: false,
    });
  };

  async _handleadminAndLeaveGroup () {
    // const database = firebase.database ();
    // console.log("this.state.groupData>>>>>>", this.state.groupData.groupAdminUid)
    if (this.state.userOwnUid !== this.state.groupAdmin) {
      this._handleLeaveGroupFunction (); // when user is not current admin
    } else {
      this.setState ({
        showLeaveGroupPopUp: false,
        showUserCurrentAdmin: true,
      });

      // alert("implement it properly")

      //updating new admin assigned and then leave process
      // const updatedGroupDetails = {
      //   // groupAdminUid: this.state.selectedAdmin, // select admin here then proceed to leave function
      //   // groupAdminsArray: [this.state.selectedAdmin]
      // };

      // const conversationRef = database.ref(`GroupChatDetail/${this.state.groupId}`);
      // await conversationRef.update(updatedGroupDetails);

      // this._handleLeaveGroupFunction() // this is called when admin assigned or changed
    }
  }

  // leaving group in firebase function
  // first assign admin then leave
  async _handleLeaveGroupFunction () {
    const database = firebase.database ();
    const {id, userOwnUid} = this.state;

    // this will remove the thread from the user
    database.ref(`Users/${userOwnUid}/chatList/${id}`).remove();

    //filtering the members (getting a filter array without current user)
    // let newMembersArr = await groupMembers.filter (member => {
    //   // console.log("member in remove from group chat ", member.docID)
    //   if (member.docID == userOwnUid) {
    //     return;
    //   } else {
    //     return member;
    //   }
    // });

    // updating the new users in the group info
    await database.ref(`GroupChatDetail/${id}/users/${userOwnUid}`).remove();
    // await conversationRef.set (newMembersArr);
    this.setState (
      {
        showLeaveGroupPopUp: false,
      },
      () => {
        this.props.history.goBack ();
        // window.location.href=routes.MESSENGER;
      }
    );
  }

  _handleOnAddingMember (contactDetail, index) {
    const { groupMembers, userContacts} = this.state;
    let newContactArr = [...userContacts];
    let newAddedMember = [...groupMembers];
    newAddedMember.push (contactDetail);
    newContactArr.splice (index, 1);
    this.setState ({
      groupMembers: newAddedMember,
      userContacts: newContactArr,
    });
  }
  _handleOnAremovingMember (contactDetail, index) {
    const {
      groupMembers,
      userContacts,
      groupAdmin,
      showUpdateGroupInfoPopUp,
    } = this.state;
    // console.log("contactDetail>>>>*******",contactDetail);
    if (showUpdateGroupInfoPopUp && groupAdmin !== contactDetail.docID) {
      let newContactArr = [...userContacts];
      let newAddedMember = [...groupMembers];
      newAddedMember.splice (index, 1);
      for (let item of this.props.userContacts.contacts) {
        if (
          item.contact &&
          item.contact.contactDetail.docID === contactDetail.docID
        ) {
          newContactArr.push (item);
        }
      }
      this.setState ({
        groupMembers: newAddedMember,
        userContacts: newContactArr,
      });
    }
  }

  //show all conatcts here
  contactsListRender () {
    const {groupMembers, userOwnUid} = this.state;
    let filterData = groupMembers.filter (contact => {
      if (contact.docID !== userOwnUid) {
        return contact;
      }
    });
    return (
      <div className={'conversation-list-new-group1'}>
        {filterData && filterData.length > 0
          ? filterData.map ((contact, index) => {
              const {
                firstName,
                professionalTitle,
                profilePictureURL,
                lastName,
                title,
                position
              } = contact;
              return (
                <div
                  key={index}
                  onClick={() => this._handleMakeAdmin (contact)}
                  style={{display: 'flex', marginTop: 20}}
                >
                  <div id="leavemodal6">
                    <div>
                      <img
                        alt="profileimg"
                        src={
                          profilePictureURL ? profilePictureURL : profileDefault
                        }
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 25,
                          border: '2px solid #FFFFFF',
                        }}
                        id="leavemodal7"
                      />
                    </div>
                  </div>

                  <div id="leavemodal8">
                    <p id="leavemodal9" className="MarcusBar">{title} {' '}{ShowNameAccordingToLength(firstName+' ' + lastName)}</p>
                    <p id="leavemodal10" className="lastwswef">{position} </p>
                    <p id="leavemodal11" className="lastwswef">{professionalTitle}</p>
                  </div>

                </div>
              );
            })
          : <div className="noGrpSerchContactFound">No results found.</div>}
      </div>
    );
  }

  addNewcontactsListRender () {
    const {
      searchText,
      userContacts,
      groupMembers,
    } = this.state;
    // let membersListToBeExecute = searchText.trim() == '' ? contacts : filteredContacts;
    let contactsNotAdded = [...userContacts];
    // let contactListing=[];
    // contactsNotAdded.filter (contact => {
    //   console.log ('contact>>>>>>>', contact.docID);
    //   let isAvailableInGroupMembers = false;
    //   groupMembers.map (groupMember => {
    //     console.log ('groupMember  in filter>>>>', groupMember);
    //     if (contact.docID == groupMember.docID) {
    //       isAvailableInGroupMembers = true;
    //     }
    //   });
    //   if (!isAvailableInGroupMembers) {
    //     console.log(contact.contact.contactDetail);
    //     contactListing.push(contact.contact.contactDetail);
    //     return contact;
    //   }
    // });
    let contactListing = [];
    contactsNotAdded.filter (contact => {
      // console.log ('contact>>>>>>>', contact);
      let isAvailableInGroupMembers = false;
      groupMembers.map (groupMember => {
        // console.log ('groupMember  in filter>>>>', groupMember);
        if (contact.contact.contactDetail.docID == groupMember.docID) {
          isAvailableInGroupMembers = true;
        }
      });
      if (!isAvailableInGroupMembers) {
        contactListing.push (contact.contact.contactDetail);
        // return contact;
      }
    });

    // console.log ('contactsNotAdded contactssss>>>>', contactsNotAdded);
    // let newContacts = _.get(this.props, 'contacts.contacts', []).map(contact => contact.contact.contactDetail)
    // this.setState({
    //   contacts: newContacts
    // })

    let filterData = searchText
      ? contactListing.filter (
          row =>
            row.firstName
              ?  ShowNameAccordingToLength(row.firstName+' ' +row.lastName).toLowerCase ().indexOf (searchText.toLowerCase ()) > -1
              : ''
        )
      : contactListing;
    return (
      <div className={'conversation-list-new-group1'}>
        {filterData && filterData.length > 0
          ? filterData.map ((contact, index) => {
              const {
                firstName,
                professionalTitle,
                position,
                profilePictureURL,
                title,
                lastName
              } = contact;
              return (
                <div
                  key={index}
                  onClick={() => this._handleOnAddingMember (contact, index)}
                  style={{display: 'flex', marginTop: 10,cursor:'pointer'}}
                  id={`connect-groupInfoCardContactListingRow${index}`}
                >
                  <div>
                    <div>
                      <img
                        alt="profilePictureURL"
                        src={
                          profilePictureURL ? profilePictureURL : profileDefault
                        }
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 25,
                          border: '2px solid #FFFFFF',
                        }}
                        id={`connect-groupInfoCardContactListingRowimg${index}`}
                      />
                    </div>
                  </div>

                  <div>
                    <p className="MarcusBar" id={`connect-groupInfoCardContactListingname${index}`}>
                    <Highlighter
                    highlightClassName="yelloTextHiglight"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={  title  +' '+ ShowNameAccordingToLength(firstName+' ' +lastName)}
                    id={`connect-groupInfoCardContactListingHighLight${index}`}
                  />
                    
                    </p>
                    <p className="lastwswef" id={`connect-groupInfoCardContactListingRowposition${index}`}>{position} </p>

                    <p className="lastwswef" id={`connect-groupInfoCardContactListingRowlastname${index}`}>{professionalTitle}</p>
                  </div>

                </div>
              );
            })
          : <div className="noGrpSerchContactFound">No results found.</div>}
      </div>
    );
  }

  _handleNotificationMuteUnmute(){
    if(this.state.gotMuteResponse && this.state.muted){
      this.setState({
        showNotificationPopUpConfirmation: true
      })
      // this._handleUnMuteMemberOrGroup();
    } else if (this.state.gotMuteResponse && !this.state.muted){
      // this._handleMuteMemberOrGroup();
      this.setState({
        showNotificationPopUpConfirmation: true
      })
    }
  }

  // used to get muted groups and single chat users list
  _handleGetAllGroupsAndMutedList() {
    // console.log("_handleGetAllGroupsAndMutedList")
     const { id } = this.state;
    let params = {
      "data": {
        "limit": '',
        "offset": ''
      }
    }
    this.props.messengerGetMutedNotificationsListFunc(params, (res) => {
      // console.log("messengerGetMutedNotificationsList>>>>> result>>>>", res)
      res.map((val) => {
        // console.log("mute true")
        if (val.muteID == id)
          this.setState({
            muted: true
          })
      })
      this.setState({
        mutedGroupsAndMembers: res,
        gotMuteResponse: true
      })
    })
  }

  // used to MUTE the chat user
  _handleMuteMemberOrGroup() {
    this.setState({
      showNotificationPopUpConfirmation: false
    })
    let params = {
      "data": {
        "sourceUID": "",
        "messengerGroupID": this.state.id
      }
    }
    this.props.messengerMuteNotificationsFunc(params, (res) => {
      // console.log("res res mute>>>>>",res )
      if (res.status) {
        this.setState({
          muted: true,
          // showNotificationPopUpConfirmation: false
        })
      }
    })
  }

  // used to UNMUTE the chat user
  _handleUnMuteMemberOrGroup() {
    this.setState({
      showNotificationPopUpConfirmation: false
    })
    let params = {
      "data": {
        "sourceUID": "",
        "messengerGroupID": this.state.id
      }
    }
    this.props.messengerUnMuteNotificationsFunc(params, (res) => {
      // console.log("res res UNMUTE>>>>>",res )
      if (res.status) {
        this.setState({
          muted: false,
          // showNotificationPopUpConfirmation: false
        })
      }
    })
  }

  render = () => {
    // console.log ('this.getMemberCandidates()>>>>', this.getMemberCandidates ());
    const {
      name,
      image,
      groupMembers,
      userContacts,
      userOwnUid,
      groupAdmin,
      showGroupMemberEdit,
      selectedDocID,
      showRemoveGroupMemberPopUp,
      showUpdateGroupInfoPopUp,
      showAddNewGroupMembersPopup,
      memberTobeAddedObj,
      groupCreatedtimeStamp,
      muted,
      searchText
    } = this.state;
    let memberName = _.get (memberTobeAddedObj, 'displayName', '');

    // const myDifferences = differenceBy(userContacts, groupMembers, 'value')

    // console.log("myDifferences>?????",myDifferences)
    let contactsNotAdded = [...userContacts];

    contactsNotAdded.filter (contact => {
      // console.log ('contact>>>>>>>', contact);
      let isAvailableInGroupMembers = false;
      groupMembers.map (groupMember => {
        // console.log ('groupMember  in filter>>>>', groupMember);
        if (contact.contact.contactDetail.docID == groupMember.docID) {
          isAvailableInGroupMembers = true;
        }
      });
      if (!isAvailableInGroupMembers) {
        return contact;
      }
    });

    // console.log ('contactsNotAdded contactssss>>>>', contactsNotAdded);

    // if (contact.docID == contact.contactDetail.docID) {
    //   shouldShow = true;
    // }
    // })

    // console.log (groupMembers);

    return (
      <div>
      <div style={{textAlign:'right',marginRight:20}}>
      <Button data-rh="Go back" className="messengerBack" onClick={()=>this.props.history.goBack()}> 
        <div><img src={backIcon} alt="back icon"/></div>
        Go <span>Back</span> </Button>
    </div>
      <div className="group-detail-container">
      

        <div className="scrollable sidebar">
       
          <ConversationList
            onClickConversation={data => {
              // console.log (data);

              this.props.history.push (routes.MESSENGER, {
                fetchNewGroupChat: data,
              });
              // this.props.history.push(routes.MESSENGER,
              //   { userChatInfo: userChatInfo, messengerKey: this._handleKeyVal(contact.docID) }
              //   );

              // this._handleOnPressMessenger(data)
            }}
            history={this.props.history}
          />
        </div>

        <div className="groupDetailScroller">
       
        <div className="info-container1">
          {!showUpdateGroupInfoPopUp &&
            <GroupInfoCard
              name={name}
              isMuted={this.state.muted}
              onChange={()=>{
                this._handleNotificationMuteUnmute();
              }}
              groupCreatedTime={groupCreatedtimeStamp}
              groupHeader={'Group Details'}
              goupImage={image}
              showEditButton={this.state.groupAdmin == this.state.userOwnUid}
              onEditClick={() => {
                this.setState ({
                  showUpdateGroupInfoPopUp: true,
                });
              }}
              onLeaveGroupClick={() => {
                this.setState ({
                  showLeaveGroupPopUp: true,
                });
              }}
            />}
          {showUpdateGroupInfoPopUp &&
            <div className="groupInfoCard" id="connect-groupInfoCardMaine">
              <Button className="groupTitleButton" id="connect-groupInfoCardButton">
                Group <span> Info</span>
              </Button>
              <div>

                <GroupDetailView
                  imageSrc={image}
                  name={name}
                  submit={() => alert ('click on next button')}
                  onSubmitClick={this.updateGroupDetails}
                />
              </div>
            </div>}
        </div>

        <div className="contactGroupList" id="connect-groupInfoCardContact">
          <div
            style={{
              display: 'inline-flex',
              width: '100%',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <Button className="contactAdminButtonTitle" id="connect-groupInfoCardAdminTitle">
              ADMINS & <span> MEMBERS </span>
            </Button>
            <div className="memberCount" id="connect-groupInfoCardContactMember">{groupMembers.length} Members</div>
          </div>
          <div className="addedUsersScroller"  id="connect-groupInfoCardContactMember1">
            {showUpdateGroupInfoPopUp &&
              <div>
                <p className="addNewMeberTitle"  id="connect-groupInfoCardContactMember2">Search to Add New Members:</p>
                <div className="searchMemberInputBox">
                  <img className="memberSearchIcon" src={searchIcon}  alt="searchIcon" id="connect-groupInfoCardContactMember3"/>
                  <input
                    onChange={e => this.setState ({searchText: e.target.value})}
                    type="text"
                    className="searchMemberInputBoxField"
                    placeholder="Search by name or handle"
                    id="connect-groupInfoCardContactMember4"
                  />
                </div>
                {searchText && <div style={{paddingLeft: 20, paddingTop: 10}}>
                  <p className="clicktoaddckdjd"  id="connect-groupInfoCardContactMember5">Click to add to your group.</p>
                  {this.addNewcontactsListRender ()}
                </div>}
              </div>}

            <div className="contactMemberGroupList EditListTopBar" style={{borderBottom:0}} id="connect-groupInfoCurrentMembers">
              <div style={{width: 230}} id="connect-groupInfoCurrentMembers1">
                Current Group Members:{' '}
              </div>
              <div style={{display: 'inline-flex'}} id="connect-groupInfoCurrentMembers2">
                <div style={{width: 100, fontSize: 14, textAlign: 'center'}} id="connect-groupInfoCurrentMembers3">
                  <p style={{margin: 0, color: '#F5D028', fontWeight: 'bold'}} id="connect-groupInfoCurrentMembers4">
                    ADMIN
                  </p>
                  RIGHTS

                </div>
                {showUpdateGroupInfoPopUp && this.state.groupAdmin === this.state.userOwnUid  &&
                  <div style={{width: 120, fontSize: 14, textAlign: 'center'}} id="connect-groupInfoCurrentMembers5">
                    <p style={{margin: 0, fontWeight: 'bold'}} id="connect-groupInfoCurrentMembers6">REMOVE </p>
                    FROM GROUP

                  </div>}
              </div>
            </div>
            <div className="conversation-list-new-group1" id="connect-groupInfoCurrentMembers7">

              {groupMembers &&
                groupMembers.map ((item, i) => (
                  <div key={i}>
                    <div id={`connect-groupInfoCurrentMembersListing${i}`} className="contactMemberGroupList" style={{border: groupMembers.length===i+1 && 'none'}}>
                    <div style={{width:230,display:'inline-flex'}}>
                      <div style={{width: 60, textAlign: 'center'}}>
                        <img
                          alt="userImageIcon"
                          className="userImageIcon"
                          src={
                            item.profilePictureURL
                              ? item.profilePictureURL
                              : profilePlaceholder
                          }
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = profilePlaceholder;
                          }}
                          id={`connect-groupInfoCurrentMembersListingimg${i}`} 
                        />
                      </div>
                      <div className="userNameContactBox" id={`connect-groupInfoCurrentMembersListingnamebox${i}`} >
                        <p className="userNameText" id={`connect-groupInfoCurrentMembersListingusername${i}`} >
                          {' '}{item.title} {' '}

                          {ShowNameAccordingToLength(item.firstName+' ' +item.lastName)}
                          {' '}
                        </p>
                        <p id={`connect-groupInfoCurrentMembersListingposition${i}`}  className="userPositionText" style={{fontStyle:'italic'}}>{item.position} </p>

                        <p className="userPositionText"  id={`connect-groupInfoCurrentMembersListingprofe${i}`} >
                          {item.professionalTitle}{' '}
                        </p>

                      </div>
                      </div>
                      <Button
                            className="adminShowButton"
                            id={`connect-groupInfoCurrentMembersAdminicon${i}`} 
                          >
                         
                            {groupAdmin === item.docID
                              ? <img alt="yellowcrown" style={{width: 30, marginTop: 10}} src={yellowcrown} />
                              : <img alt="nonAdmin" style={{width: 30, marginTop: 10}} src={nonAdmin} />}

                          </Button>
                      {/*<div
                        style={{
                          width: (groupAdmin === item.docID && showUpdateGroupInfoPopUp) ? 105:80,
                          textAlign: (groupAdmin === item.docID && showUpdateGroupInfoPopUp) ? 'left': 'center',
                          marginLeft: 5,
                          paddingTop: 5,
                        }}
                      >
                        {groupAdmin === item.docID
                          ? <img style={{width: 30}} src={yellowcrown} />
                          : <img style={{width: 30}} src={nonAdmin} />}
                        </div>*/}

                      {showUpdateGroupInfoPopUp &&
                        groupAdmin !== item.docID &&
                          <Button
                            id={`connect-groupInfoCurrentMembersListingRemove${i}`} 
                            className="removeMemberBtn"
                            onClick={() =>
                              this.setState ({
                                removeMember: true,
                                removeMemberData: item,
                                removeMemberIndex: i,
                              })
                            // this._handleOnAremovingMember(item,i)
                            }
                            data-rh="Remove from group"
                          >
                            <img
                              style={{width: 20, marginTop: 10}}
                              src={removeMember}
                              alt="nonAdmin"
                            />

                          </Button>}

                    </div>
                  </div>
                ))}
            </div>

          </div>

        </div>
        {/*<div className="contact-container">
          <Contacts
            contacts={groupMembers}
            userOwnUid={this.state.userOwnUid}  
            onContactClick={ (selectedMemberData) => {
              // console.log("contacts dataaaaa>>>>", selectedMemberData)
              if(selectedMemberData.docID !== this.state.userOwnUid){
                this.setState({
                  showGroupMemberEdit: true,
                  selectedDocID: selectedMemberData.docID,
                  memberTobeAddedObj: selectedMemberData
                })
              }
            }}
            header={'Group Members'}
            headerRight={<img alt=""
              src={require('../../../images/AddVector.png')} className="contacts-header-img"
              onClick={() => this.setState({ showAddNewGroupMembersPopup: true })}
            />}
            showEditOnContact
          />
          </div>*/}
          </div>

        <MyModal
          className="detail-notification-modal"
          show={showGroupMemberEdit && selectedDocID != null}
          toggle={() =>
            this.setState ({
              showGroupMemberEdit: false,
              selectedDocID: null,
            })}
        >
          <div className="member-edit-child" onClick={this.onMessageClicked}>
            {`Message ${memberName}`}
          </div>
          <div className="border-bottom" />
          {groupAdmin == userOwnUid &&
            <div
              className="member-edit-child"
              onClick={this.makeUserGroupAdmin}
            >
              Make Group Admin
            </div>}
          <div className="border-bottom" />
          {groupAdmin == userOwnUid &&
            <div className="member-edit-child" onClick={this.removeUser}>
              {`Remove ${memberName}`}
            </div>}
          <div className="border-bottom" />
          <div className="member-edit-child" onClick={this.goToprofile}>
            View Profile
          </div>
        </MyModal>

        <MyModal
          show={showRemoveGroupMemberPopUp && selectedDocID != null}
          showSubmitCancel
          title={`Remove ${memberName}`}
          onSubmit={this.removeUser}
          toggle={() =>
            this.setState ({
              showRemoveGroupMemberPopUp: false,
              selectedDocID: null,
            })}
          className="remove-member-modal"
          submitText="Yes"
        >
          <div className="remove-member-modal-body">
            {`Are you sure you wish to remove the member from the group ?`}
          </div>
        </MyModal>
        <MyModal
          // show={showUpdateGroupInfoPopUp}
          toggle={() =>
            this.setState ({
              showUpdateGroupInfoPopUp: false,
            })}
          className="group-info-modal"
        >
          <GroupDetailView
            imageSrc={image}
            name={name}
            submit={() => alert ('click on next button')}
            onSubmitClick={this.updateGroupDetails}
          />
        </MyModal>
        <MyModal
          show={showAddNewGroupMembersPopup}
          className="add-new-members-modal"
          toggle={() =>
            this.setState ({
              showAddNewGroupMembersPopup: false,
              membersRemoved: [],
            })}
        >
          <GroupMembers
            header={'Please select the members to add in the group'}
            // onRemoveClick={(id) => this.setState({
            //   membersRemoved: [...this.state.membersRemoved, id]
            // })}
            addMemberToGroup={selectedMemberData => {
              this.setState (
                {
                  selectedDocID: selectedMemberData.docID,
                  memberTobeAddedObj: selectedMemberData,
                },
                () => this._handleAddMemberInFirebase ()
              );
            }}
            submitButtonText={'Add'}
            members={this.getMemberCandidates ()}
            // onSubmitClick={()=> this._handleAddMemberInFirebase()}
          />
        </MyModal>

        <Modal
          size="sm"
          isOpen={this.state.removeMember}
          centered={true}
          toggle={() => this.setState ({removeMember: false})}
        >
          <ModalBody className="placeOrderModal" id="connectremoveMember">
            <div>

              <p className="confirmTitle" id="connectremoveMember1">Remove From Messenger Group</p>
              <p className="confirmmsg" id="connectremoveMember2">
                {`Are you sure you want to remove  ${this.state.removeMemberData.firstName} from the ${name}?`}

              </p>
              <div className="buttonDiv" id="connectremoveMember3">
                <Button
                  id="connectremoveMember4"
                  className="buttons"
                  onClick={() => this.setState ({removeMember: false})}
                >
                  No
                </Button>
                <Button
                  id="connectremoveMember5"
                  className="buttons yesButton"
                  onClick={() => {
                    this.setState (
                      {
                        removeMember: false,
                      },
                      () => {
                        this._handleOnAremovingMember (
                          this.state.removeMemberData,
                          this.state.removeMemberIndex
                        );
                      }
                    );
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={this.state.showLeaveGroupPopUp}
          centered={true}
          toggle={() => this.setState ({showLeaveGroupPopUp: false})}
        >
          <ModalBody className="placeOrderModal" id="showLeaveGroupPopUp"> 
            <div>

              <p className="confirmTitle" id="showLeaveGroupPopUp1">Leave Group</p>
              <p className="confirmmsg" id="showLeaveGroupPopUp2">
                {`Are you sure you want to leave this group?`}

              </p>
              <div className="buttonDiv" id="showLeaveGroupPopUp3">
                <Button
                  id="showLeaveGroupPopUp4"
                  className="buttons"
                  onClick={() => this.setState ({showLeaveGroupPopUp: false})}
                >
                  No
                </Button>
                <Button
                  id="showLeaveGroupPopUp4"
                  className="buttons yesButton"
                  onClick={() => {
                    this.setState (
                      {
                        removeMember: false,
                      },
                      () => {
                        this._handleadminAndLeaveGroup ();
                        // this._handleOnAremovingMember(this.state.removeMemberData,this.state.removeMemberIndex)
                      }
                    );
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

          {/* modal for notification mute and unmute*/}
        <Modal
          size="sm"
          isOpen={this.state.showNotificationPopUpConfirmation}
          centered={true}
          toggle={() => this.setState({ showNotificationPopUpConfirmation: false })}
        >
          <ModalBody className="placeOrderModal" id="muteModal">
            <div>

              <p className="confirmTitle" id="muteModal1">{muted ? 'Unmute Conversation' : "Mute Conversation"}</p>
              <p className="confirmmsg" id="muteModal2">
                {`Are you sure you wish to ${muted ? 'unmute' : 'mute'} '${name}'?`}
              </p>
              <div className="buttonDiv" id="muteModal3">
                <Button id="muteModal4" className="buttons" onClick={() => this.setState({ showNotificationPopUpConfirmation: false })}>
                  No
              </Button>
                <Button
                id="muteModal5"
                  className="buttons yesButton"
                  onClick={() => muted ? this._handleUnMuteMemberOrGroup() : this._handleMuteMemberOrGroup()}
                >
                  Yes
              </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="md"
          isOpen={this.state.showUserCurrentAdmin}
          centered={true}
          toggle={() => this.setState ({showUserCurrentAdmin: false})}
        >
          <ModalBody className="placeOrderModal" id="leavemodal">
            <div>

              <p className="confirmTitle" id="leavemodal1">Leave Group</p>
              <p className="confirmmsg" id="leavemodal2">
                {`You are the current admin of this group.please assign an admin and then try to leave group. Please select a member as admin`}

              </p>
              {this.contactsListRender ()}
              <div className="buttonDiv" id="leavemodal3">
                {/* <Button className="buttons" onClick={() => this.setState({showUserCurrentAdmin: false})}>
                No
              </Button> */}
                <Button
                  id="leavemodal4"
                  className="buttons yesButton"
                  onClick={() => {
                    this.setState (
                      {
                        showUserCurrentAdmin: false,
                      },
                      () => {
                        this.setState ({
                          showUserCurrentAdmin: false,
                        });
                        // this._handleadminAndLeaveGroup()
                        // this._handleOnAremovingMember(this.state.removeMemberData,this.state.removeMemberIndex)
                      }
                    );
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      </div>

      
    );
  };

  async handleUpdateFunctionOnEdit () {
    const conversationDetail = await this.fetchGroupDataFromFirebase (
      this.state.id
    );
    // console.log ('conversationDetail>>>', conversationDetail);
    if (conversationDetail) {
      const {
        groupName,
        groupAdminUid,
        groupDescription,
        groupIcon,
        users,
        groupPublicKey
      } = conversationDetail;
      this.setState ({
        id: this.state.id,
        name: groupName,
        image: groupIcon,
        groupAdmin: groupAdminUid,
        groupPublicKey: groupPublicKey,
        groupData: conversationDetail,
        groupDescription: groupDescription,
        groupMembers: _.toArray (users), // to change firedata into array via Lodash fucntions
      });
    }
  }

  componentDidMount = async () => {
    this.props.Loading (false);
    const {location: {search}, history, contacts} = this.props;
    const {conversationId} = queryString.parse (search);
    if (conversationId) {
      const conversationDetail = await this.fetchGroupDataFromFirebase (
        conversationId
      );
      // console.log ('conversationDetail>>>', conversationDetail);
      if (conversationDetail) {
        const {
          groupName,
          groupAdminUid,
          groupDescription,
          groupIcon,
          users,
          groupCreatedAt,
        } = conversationDetail;
        this.setState ({
          id: conversationId,
          name: groupName,
          image: groupIcon,
          groupAdmin: groupAdminUid,
          groupDescription: groupDescription,
          groupCreatedtimeStamp: groupCreatedAt,
          // groupIcon: conversationDetail.groupIcon,
          // groupName: groupName,
          groupMembers: _.toArray (users), // to change firedata into array via Lodash fucntions,
          originalGroupMember: _.toArray (users), // this to be user to check which user is removed or added
        },()=>{
          this._handleGetAllGroupsAndMutedList();
        });
       

        // console.log("contacts>>>>>this.props>>>", contacts)
        // const contactDetails = contacts.map(obj => {console.log("obj>>>>",obj.contact.contactDetail) ;});
        // console.log("contactDetails>>>>>")
        // const members = Object
        //     .keys(users)
        //     .map(key => users[key])
        //     .filter(obj => obj.docID !== groupAdminUid)
        //     .map(obj => contactDetails.filter(contact=> contact.docID === obj.docID)[0]);
        // this.setState({
        //     members
        // });
      }
    } else {
      history.goBack ();
    }
  };
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer.contacts,
    userInfo: state.Authreducer,
    userContacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      fetchMessengerList,
      Loading,
      messengerGetMutedNotificationsListFunc,
      messengerMuteNotificationsFunc,
      messengerUnMuteNotificationsFunc,
      
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (GroupDetails);
