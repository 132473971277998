import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as routes from '../../../constants/routes';
import Popover from 'react-tiny-popover';
import messenger from '../../../images/icons/messenger.png';
import searchinputicon from '../../../images/icons/seainputicon.png';
import placeholderProfile from  '../../../images/profile_placeholder.png';

import {fetchMessengerList} from '../../../actions/Messenger';
import {ShowNameAccordingToLength} from '../../../utility/stringFunctions';
import moment from 'moment';



import './userListPopover.css';

class UserListPopover extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isOpenModal: true,
      messengerList: [],
      searchText: '',
    };
  }

  componentDidMount () {
    if (
      this.props.userInfo &&
      this.props.userInfo.user.Data &&
      this.props.userInfo.user.Data.userDetails &&
      this.props.userInfo.user.Data.userDetails[0] &&
      this.props.userInfo.user.Data.userDetails[0].personalDetails
    ) {
      this.setState (
        {
          loginUserData: this.props.userInfo.user.Data.userDetails[0]
            .personalDetails,
        },
        () => {
          this.props.fetchMessengerList (this.state.loginUserData.docID);
        }
      );
    }
  }

  static getDerivedStateFromProps = nextProps => {
    const {messengerList, searchText} = nextProps;

    const messages = messengerList.messenger.map (obj => {
      const {chatType} = obj;
      let returnObj;
      const mymoment = moment (obj.timeStamp);
      const dayDifference = moment ().diff (mymoment, 'd');
      const timDisplay = dayDifference > 1 ? mymoment.format ('DD/MM/YYYY'): mymoment.fromNow ();


      if (chatType === 'groupChat') {
        returnObj = {
          photo: obj.groupIcon,
          name: obj.displayName,
          text: obj.lastMsg,
          key: obj.groupId,
          chatType,
          // read: obj.read,
          read: obj.lastMsgSentBy ===
            nextProps.userInfo.user.Data.userDetails[0].personalDetails.docID
            ? true
            : obj.read,
          lastTime: timDisplay,
          type: obj.type,
        };
      } else {
        returnObj = {
          photo: obj.profilePictureURL,
          name: obj.displayName,
          text: obj.lastMsg,
          key: obj.key,
          oppositeUserUid: obj.oppositeUserUid,
          chatType,
          read: obj.read,
          lastTime: timDisplay,
          type: obj.type,
        };
      }
      return returnObj;
    });

    return {
      messengerList: messages,
    };
  };

  goToChat (personalDetail) {
    if(personalDetail && personalDetail.key){
      window.location.href=`${routes.MESSENGER}?conversationChatId=${personalDetail.key}`;

    }
  }

  render () {
    const {isOpenModal, messengerList, searchText} = this.state;
 
    let filterData = searchText
      ? messengerList.filter (
          row =>
            row.name
              ? row.name.toLowerCase ().indexOf (searchText.toLowerCase ()) > -1
              : ''
        )
      : messengerList;
    return (
      <Popover
        isOpen={isOpenModal}
        position={['right']} // preferred position
        content={
          <div className="popoverBG" style={{minHeight: 200}}>

            <div className="shareLabelBox">

              <img src={messenger} className="msgicon " alt="message" />

              Share in Messenger
            </div>

            <div className="popoverContainer">
              <div className="searchMemberTitle">
                Search for members or groups:
              </div>

              <div>

                <div className="searchUsersInput">
                  <img
                    alt="searchinputicon"
                    src={searchinputicon}
                    style={{
                      width: 24,
                      height: 16,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                  <input
                    type="text"
                    className="searchicsdfnput"
                    onChange={val =>
                      this.setState ({
                        searchText: val.target.value,
                      })}
                    placeholder="Search by name or email"
                  />
                </div>
                {filterData && filterData.length===0 &&<div style={{textAlign:'center'}} className="searchMemberTitle">
                No Member found
              </div>}

                <div className="userSearchListingContainer">
                  {filterData.map ((item, i) => (
                    <div key={i} className="userContactSearchList" onClick={()=>this.goToChat(item)}>

                      <div className="userImageDiv">
                        <img
                          src={item.photo  ? item.photo : placeholderProfile }
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = placeholderProfile;
                          }}
                          className="userimages "
                          alt="userimages"
                        />

                      </div>
                      <div className="userInfoDiv">
                        <div className="msgSearchUsername">
                        {item.chatType == 'singleChat' ? ShowNameAccordingToLength(item.name) :item.name}
                      
                        
                        </div>
                        <div className="msgSearchUserlastseen">
                          {item.lastTime}
                        </div>

                      </div>

                    </div>
                  ))}
                </div>
              </div>

            </div>

          </div>
        }
        onClickOutside={() => this.props.closePopover ()}
      >
        <div onClick={() => this.props.closePopover ()}>
          {/*<div className="messageIconCircle">
            <img src={messenger} className="messengerIcon " alt="message" />
      </div>*/}

        </div>
      </Popover>
    );
  }
}
// export default UserListPopover;

const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
    contacts: state.ContactsReducer.contacts,
    messengerList: state.MessengerReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      fetchMessengerList,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (UserListPopover);
