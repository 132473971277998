// this file is being used to create new group
///
import React, { Component } from 'react';
import './ConversationSearch.css';
import searchGroup from '../../../images/icons/search.svg';
import menuIcon from '../../../images/icons/messenger/menus.png';
import { Button, Modal, ModalBody, Progress, Input } from 'reactstrap';
import seainputicon from '../../../images/icons/seainputicon.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification } from '../../../constants/notificationtoast';
import CreateGroupImg from '../../UI/CreateGroupImg';
import profileDefault from '../../../images/profile/profileDefault.png';
import notisetimg from '../../../images/accountSetting/notisetimg.png';
import _ from 'lodash';

import { getFileName } from '../../../utility/getFileName';
import firebase from 'firebase';
import { authErrorMessage } from '../../../constants/localMessage/auth/authHelper';
import * as authValidation from '../../../constants/localMessage/auth/authHelper';
import { Loading } from '../../../actions/LoadingAction';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import Highlighter from 'react-highlight-words';

class ConversationSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      modal: false,
      createGroupStep: Number(1),
      contacts: [],
      filteredContacts: [],
      addedMembers: [],
      groupName: '',
      imageSrc: '',
      description: '',
      groupId: null,
      groupIcon: null,
      groupCreatedAt: null,
      isCheckGroupName: '',
      searchText: '',
    };
    this.closeModal = this.closeModal.bind(this);
    this.newConversationRef = React.createRef();
  }

  componentDidMount() {
    this.props.Loading(false);
    document.addEventListener('click', this.handleClickOutside);
    let newContacts = _.get(this.props, 'contacts.contacts', []).map(
      (contact) => contact.contact.contactDetail
    );
    this.setState({
      contacts: newContacts,
    });
  }

  componentWillUnmount() {
    this.setState({
      createGroupStep: 0,
    });
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.newConversationRef.current &&
      !this.newConversationRef.current.contains(event.target)
    ) {
      this.setState({
        isOpened: false,
      });
    }
  };
  handleChange = (event) => {
    // console.log("event>>>>>>>event", event)
    this.setState({ groupName: event.target.value });
  };

  toggle = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  manageGroups = () => {
    this.setState({
      modal: true,
      createGroupStep: 1,
    });
    //do your thing.
  };

  createNewGroup = () => {
    this.setState({ modal: true });
  };

  newConversation = () => {
    //do your thing.
    this.props.showContacts(true);
  };
  closeModal = () => {
    this.setState({ modal: false, createGroupStep: 1 });
  };
  nextStep = () => {
    if (this.state.addedMembers.length > 1) {
      this.setState({
        createGroupStep: this.state.createGroupStep + Number(1),
      });
    } else {
      createNotification('error', 'Please select atleast 2 members');
    }
  };

  //show all conatcts here
  contactsListRender() {
    const { contacts, filteredContacts, searchText } = this.state;
    // let membersListToBeExecute = searchText.trim() == '' ? contacts : filteredContacts;

    let filterData = searchText
      ? contacts.filter((row) =>
          row.firstName + ' ' + row.lastName
            ? (
                row.firstName.toLowerCase() +
                ' ' +
                row.lastName.toLowerCase()
              ).indexOf(searchText.toLowerCase()) > -1
            : ''
        )
      : contacts;
    return (
      <div className={'conversation-list-new-group'} id='connectConv31'>
        {filterData && filterData.length > 0 ? (
          filterData.map((contact, index) => {
            const {
              firstName,
              lastName,
              professionalTitle,
              profilePictureURL,
            } = contact;
            return (
              <div
                key={index}
                onClick={() => this._handleOnAddingMember(contact, index)}
                style={{ display: 'flex', marginTop: 20 }}
                id={`connectConv31contacts${index}`}
              >
                <div>
                  <div>
                    <img
                      alt='imgProfile'
                      src={
                        profilePictureURL ? profilePictureURL : profileDefault
                      }
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        border: '2px solid #FFFFFF',
                      }}
                      id={`connectConv31contactsprofilePictureURL${index}`}
                    />
                  </div>
                </div>

                <div>
                  <p
                    className='MarcusBar'
                    id={`connectConv31contactsMarcusBar${index}`}
                  >
                    <Highlighter
                      highlightClassName='yelloTextHiglight'
                      searchWords={[searchText]}
                      autoEscape={true}
                      textToHighlight={ShowNameAccordingToLength(
                        firstName + ' ' + lastName
                      )}
                      id={`connectConv31contactsHigh${index}`}
                    />
                  </p>
                  <p
                    className='lastwswef'
                    id={`connectConv31contactsHighlastwswef${index}`}
                  >
                    {professionalTitle}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div className='noGrpSerchContactFound'>No results found.</div>
        )}
      </div>
    );
  }

  _handleOnAddingMember(contactDetail, index) {
    const { contacts, addedMembers } = this.state;
    let newContactArr = [...contacts];
    let newAddedMember = [...addedMembers];
    newAddedMember.push(contactDetail);
    newContactArr.splice(index, 1);
    this.setState({
      addedMembers: newAddedMember,
      contacts: newContactArr,
    });
  }

  renderAddedmembers() {
    const { addedMembers } = this.state;
    return (
      <div className={'conversation-list-new-group'} id='connectConv20'>
        {addedMembers.map((member, index) => {
          const {
            firstName,
            lastName,
            professionalTitle,
            profilePictureURL,
          } = member;
          return (
            <div
              onClick={() => this._handleRemovingMember(member, index)}
              style={{ display: 'flex', marginTop: 20 }}
              id='connectConv21'
            >
              <div id='connectConv22'>
                <img
                  alt=''
                  src={profilePictureURL ? profilePictureURL : profileDefault}
                  style={{
                    width: 50,
                    height: 50,
                    marginLeft: 30,
                    borderRadius: 25,
                    border: '2px solid #FFFFFF',
                  }}
                  id='connectConv23'
                />
              </div>
              <div>
                <p className='MarcusBar' id='connectConv24'>
                  {ShowNameAccordingToLength(firstName + ' ' + lastName)}
                </p>
                <p className='lastwswef' id='connectConv25'>
                  {professionalTitle}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  searchText(e) {
    this.setState({ searchText: e.target.value });
    // let text = searchText.toLowerCase();
    // let contactList = [...this.state.contacts]
    // let filteredName = contactList.filter((contact) => {
    //   if (contact && contact.firstName) {
    //     return contact.firstName.toLowerCase().match(text)
    //   } else if (contact && contact.firstName) {
    //     return contact.firstName.toLowerCase().match(text)
    //   }
    // })
    // if (!text || text.trim() === '') {
    //   this.setState({
    //     filteredContacts: this.state.contacts
    //   })
    // }
    // else {
    //   this.setState({
    //     filteredContacts: filteredName
    //   })
    // }
  }

  _handleRemovingMember(member, index) {
    const { contacts, addedMembers } = this.state;
    let newContactArr = [...contacts];
    let newAddedMember = [...addedMembers];
    newContactArr.push(member);
    newAddedMember.splice(index, 1);

    this.setState({
      addedMembers: newAddedMember,
      contacts: newContactArr,
    });
  }

  renderMembersSecScreen() {
    const { addedMembers } = this.state;
    return (
      <div className={'conversation-list-new-group-row'}>
        {addedMembers.map((member, index) => {
          const {
            firstName,
            lastName,
            professionalTitle,
            profilePictureURL,
          } = member;
          return (
            <div
              id={`connectConv42${index}`}
              style={{ marginTop: 5, marginLeft: 15 }}
              key={index}
            >
              <img
                id={`connectConv42Img${index}`}
                alt='groupmemberimg'
                src={profilePictureURL ? profilePictureURL : profileDefault}
                className='groupmemberimg'
              />
              <p id={`connectConv42name${index}`} className='alfredejl'>
                {firstName}{' '}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  // onclicking create group//
  async _handleCreateBtn() {
    // console.log (
    //   'this.props.userinfo>>>>>',
    //   this.props.userinfo.dashboardprofile.personalDetails
    // );
    this.setState(
      {
        isCheckGroupName: true,
      },
      async () => {
        if (!this.state.groupName.trim()) {
          return;
        }

        if (!this.state.imageSrc) {
          return;
        }

        if (this.state.groupName.trim()) {
          this.props.Loading(true);
          let userOwnDetailobj = {
            aboutMe: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.aboutMe',
              ''
            ),
            displayName: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.displayName',
              ''
            ),
            docID: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.docID',
              ''
            ),
            firstName: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.firstName',
              ''
            ),
            isAdded: true,
            lastName: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.lastName',
              ''
            ),
            position: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.professionalTitle',
              ''
            ),
            profilePictureURL: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.profilePictureURL',
              ''
            ),
            searchHandle: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.searchHandle',
              ''
            ),
            title: _.get(
              this.props.userinfo,
              'dashboardprofile.personalDetails.title',
              ''
            ),
          };

          let totalMembersDetails = [
            ...this.state.addedMembers,
            userOwnDetailobj,
          ];
          if (this.state.imageSrc !== '') {
            await this._handleFireBase(
              totalMembersDetails,
              this.state.imageSrc
            );
          } else {
            await this._handleFireBase(totalMembersDetails);
          }
          this.props.Loading(false);

          this.setState({
            isOpened: false,
            modal: false,
            createGroupStep: 1,
          });
        }
      }
    );
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async _handleFireBase(totalMembersDetails, attachSource) {
    const storage = firebase.storage();
    const database = firebase.database();

    let userDocId = _.get(
      this.props.userinfo,
      'dashboardprofile.personalDetails.docID',
      ''
    );
    let groupCreaterName = _.get(
      this.props.userinfo,
      'dashboardprofile.personalDetails.firstName',
      ''
    );

    // Upload the image blob to firebase
    let imageStorageUrl = '';
    if (
      attachSource !== undefined &&
      attachSource !== '' &&
      attachSource !== null
    ) {
      try {
        await storage
          .ref(`/attachPics/${getFileName(attachSource.name)}`)
          .put(attachSource)
          .then(async (snapshot) => {
            // console.log('Uploaded a blob or file!');
            this.props.Loading(false);
            await snapshot.ref.getDownloadURL().then((downloadURL) => {
              imageStorageUrl = downloadURL;
            });
          });
      } catch (e) {
        this.props.Loading(false);
        createNotification('error', e.message);
        return;
      }
    }
    if (!imageStorageUrl) {
      this.props.Loading(false);

      createNotification('error', 'Please try again');
      return;
    }

    this.setState({
      groupIcon: attachSource,
      groupCreatedAt: Math.floor(Date.now()),
    });
    let ref = await database.ref('GroupChatDetail').push().key;

    // object to be updated in group chat detail
    const createGroupInfo = {
      groupIcon: imageStorageUrl,
      groupName: this.state.groupName,
      groupDescription: this.state.description,
      lastMsg: 'this is last message',
      noOfUsers: totalMembersDetails.length,
      groupCreatedAt: Math.floor(Date.now()),
      groupPublicKey: this.getRandomInt(100000000, 999999999),
      groupAdminUid: userDocId,
      groupAdminsArray: [userDocId],
      groupId: ref,
      groupCreaterName: groupCreaterName,
      groupCreaterId: userDocId,
    };
    // this code will update the group details in the firebase and this detail
    // will be used in every group info , group edit and group chat screens
    await database.ref(`GroupChatDetail/${ref}`).update(createGroupInfo);
    this.setState({ groupId: ref });

    totalMembersDetails.map(async (val) => {
      await database
        .ref(`GroupChatDetail/${ref}/users/${val.docID}`)
        .update(val);
    });

    // this code will update the messenger of every member of this group
    totalMembersDetails.map(async (val) => {
      let obj = {
        lastMsg:
          val.docID == userDocId
            ? `You created this group`
            : 'You have been added to this group',
        groupIcon: imageStorageUrl,
        displayName: this.state.groupName,
        groupId: ref,
        timeStamp: Math.floor(Date.now()),
        read: true,
        chatType: 'groupChat',
      };
      database.ref(`Users/${val.docID}/chatList/${ref}`).update(obj);
    });

    // this is added later just to handle the delete functionality
    let allUsers = [...totalMembersDetails];
    let ObjTobeUpdated = {
      usersDetail: {
        users: allUsers,
        deletedBy: [],
      },
    };
    await firebase
      .database()
      .ref(`GroupMessages/${ref}`)
      .update(ObjTobeUpdated);
    // till here
  }

  render() {
    // console.log("this.statetee>>>>>>", this.state)
    const { createGroupStep, isCheckGroupName } = this.state;
    return (
      <div
        id='connectConv1'
        className='conversation-search'
        ref={this.newConversationRef}
      >
        <Button
          className='IconsDiv'
          id='Popover1'
          onClick={this.toggle}
          id='connectConv2'
          // data-rh="New Chat"
        >
          {' '}
          <img alt='menuIcon' src={menuIcon} className='menuIcon' />{' '}
        </Button>
        <div
          id='dropdownmenu'
          className={`dropdownnewwrapperMessenger ${
            this.state.isOpened ? '' : 'hide'
          }`}
          id='connectConv3'
        >
          <svg
            className='dropdownArrowGroupNewChat'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path opacity='0.8' d='M9 0L0 18H18L9 0Z' fill='#17171D' />
          </svg>

          <div
            className={`dropdownNewChat checkdropMenu`}
            isOpened={this.state.isOpened}
            onClick={this.toggle}
            id='connectConv4'
          >
            <div onClick={this.newConversation} id='connectConv5'>
              New Conversation
            </div>
            <div onClick={this.createNewGroup} id='connectConv6'>
              Create New Group
            </div>
            {/* <div onClick={this.manageGroups}>
              Manage Groups
            </div> */}
          </div>
        </div>
        <div
          style={{
            display: 'inline-flex',
            opacity: 0.7,
            width: '100%',
            maxWidth: '288px',
          }}
          id='connectConv7'
        >
          <img
            id='connectConv8'
            alt='searchGroup'
            src={searchGroup}
            className='searchIcon'
          />
          <div className='conversation-search-input' id='connectConv9'>
            <input
              type='search'
              onChange={this.props.onChange}
              className='conversation-search-inputbox'
              placeholder='Search'
              style={{ fontStyle: 'italic' }}
              id='connectConv10'
            />
          </div>
        </div>
        {/*} <div className="IconsDiv"  > <img alt="" src={addGroup} className="addGroupIcon" /> </div>
    <div className="IconsDiv" onClick={this.props.createGroup}>  <img alt="" src={plusVector} className="plusIcon" /></div>*/}

        <Modal
          // size="xl"
          size='lg'
          style={{
            maxWidth: '800px',
          }}
          isOpen={this.state.modal}
          centered={true}
          toggle={this.closeModal}
        >
          <ModalBody className='creategroupmodal' id='connectConv11'>
            <div className='CreateNew' id='connectConv12'>
              Create New Messenger Group
              <Progress value='60' className='' id='connectConv13' />
            </div>
            <div className='CREATENEWMESSENGER' id='connectConv14'>
              CREATE
              <span> NEW </span>
              MESSENGER GROUP
            </div>
            {createGroupStep === 1 && (
              <div id='connectConv15'>
                <div
                  style={{ display: 'flex', paddingTop: '2%' }}
                  id='connectConv16'
                >
                  <div>
                    <div
                      id='connectConv17'
                      className='selectmembers'
                    >{`SELECT MEMBERS from your contact list to`}</div>
                    <div id='connectConv18' className='selectmembers'>
                      add them to your new group.
                    </div>
                    <div id='connectConv19' className='addeddfs'>
                      ADDED:
                    </div>
                    {this.renderAddedmembers()}
                  </div>
                  <div>
                    <p className='labejuegd' id='connectConv26'>
                      Search for members:
                    </p>
                    <div className='searchinputmesen' id='connectConv27'>
                      <img
                        alt=''
                        src={seainputicon}
                        style={{ width: 34, height: 22, marginLeft: 10 }}
                      />
                      <Input
                        className='searchicsdfnput'
                        // value={this.state.searchText}
                        // onChange={(val)=> this.searchText(val)}
                        onChange={(val) => this.searchText(val)}
                        placeholder='Search by name or email'
                        id='connectConv28'
                      />
                    </div>

                    <div className='clicktoaddckdjd' id='connectConv29'>
                      {' '}
                      Click to add to your group.
                      {/* all contacts to be shown here */}
                      {this.contactsListRender()}
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='crestejehbutton'
                    onClick={this.nextStep}
                    data-rh='Create group'
                    id='connectConv30'
                  >
                    Create Group Profile
                  </Button>
                </div>
              </div>
            )}
            {createGroupStep === 2 && (
              <div>
                <div className='createghfeftitl' id='connectConv32'>
                  Provide a Group subject and Group icon
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <p className='grouonamwmkdhkj' id='connectConv33'>
                      Group name:
                    </p>
                    <div className='groupnameucbj' id='connectConv34'>
                      <img
                        alt=''
                        src={seainputicon}
                        style={{ width: 34, height: 22, marginLeft: 10 }}
                        id='connectConv35'
                      />
                      <Input
                        className='searchicsdfnput'
                        placeholder='30 characters max'
                        value={this.state.groupName}
                        onChange={this.handleChange}
                        id='connectConv36'
                      />
                    </div>
                    {isCheckGroupName && !this.state.groupName.trim() && (
                      <p
                        style={{ width: 327, marginLeft: 30 }}
                        className='redBoxErrorMsg'
                        id='connectConv37'
                      >
                        {authErrorMessage(authValidation.CREATE_GROUP_NAME)}
                      </p>
                    )}
                    <div className='groupmembersjkd' id='connectConv38'>
                      Group Members:
                    </div>
                    <div>{this.renderMembersSecScreen()}</div>
                  </div>
                  <div>
                    <CreateGroupImg
                      onSubmitClick={(data) => {
                        this.setState({ imageSrc: data });
                      }}
                    />
                    {isCheckGroupName && !this.state.imageSrc && (
                      <p
                        style={{ width: 250 }}
                        className='redBoxErrorMsg'
                        id='connectConv40'
                      >
                        {authErrorMessage(authValidation.CREATE_GROUP_IMAGE)}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='creategrefjlkd'
                    onClick={() => this._handleCreateBtn()}
                    data-rh='Create New Group'
                    id='connectConv39'
                  >
                    Create New Group{' '}
                  </Button>
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      Loading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSearch);
