import {LOGOUT} from './Authreducer';

let initialState = {
  contacts: [],
  ourcontactList: [],
  contactDetail: [],
  uploadImage: [],
  notifications: [],
};

export const UPDATE_CONTACTS = 'UPDATE_CONTACTS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';

export const OUR_CONTACT = 'OUR_CONTACT';
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const CONTACTS_DETAIL = 'CONTACTS_DETAIL';
export const UPLOAD_USER_IMAGE = 'UPLOAD_USER_IMAGE';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILURE = 'ADD_CONTACT_FAILURE';

export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const PROFILE_DETAIL = 'PROFILE_DETAIL';
export const DELETE_NOTIFICATION_LIST = 'DELETE_NOTIFICATION_LIST';

export const RECEIVE_REQUEST_LIST = 'RECEIVE_REQUEST_LIST';
export const SEND_REQUEST_LIST = 'SEND_REQUEST_LIST';
export const BUSINESS_DETAIL = 'BUSINESS_DETAIL';
export const BUSINESS_DETAIL_PROFILE = 'BUSINESS_DETAIL_PROFILE';




export const CANCEL_SEND_REQUEST_SUCCESS = 'CANCEL_SEND_REQUEST_SUCCESS';
export const CANCEL_SEND_REQUEST_FAILURE = 'CANCEL_SEND_REQUEST_FAILURE';

export const ACCEPT_CONTACT_SUCCESS = 'ACCEPT_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';




export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTACTS:
      return {...state, contacts: action.payload};
    case OUR_CONTACT:
      return {...state, ourcontactList: action.payload};
    case SEARCH_CONTACTS:
      return {...state, searchcontactList: action.payload, uploadImage: []};
    case CONTACTS_DETAIL:
      return {...state, contactDetail: action.payload, uploadImage: []};
    case PROFILE_DETAIL:
      return {...state, profileDetail: action.payload, uploadImage: []};

    case UPLOAD_USER_IMAGE:
      return {...state, uploadImage: action.payload,uploadUserImage:action.payload};

    case NOTIFICATION_LIST:
        for (let items of action.payload) {
          items.source = items.source? items.source.toLowerCase():items.source;
        }
      return {...state, notifications: action.payload};
      

      case ADD_CONTACT_FAILURE:
      let searchListing;
      if (state.searchcontactList && state.searchcontactList) {
        for (let items of state.searchcontactList) {
          if (items.docID === action.payload.data.contactUID) {
            items.inContactList = 'false';
          }
        }
      }
      searchListing = state.searchcontactList;
      if (state.contactDetail && state.contactDetail.length > 4) {
        if (state.contactDetail[4] && state.contactDetail[4].inContactList) {
          state.contactDetail[4].inContactList = 'false';
        }
      }

      return {...state, searchcontactList: searchListing, uploadImage: []};

    case ADD_CONTACT_SUCCESS:
      let searchList;
      if (state.searchcontactList && state.searchcontactList) {
        for (let items of state.searchcontactList) {
          if (items.docID === action.payload.data.contactUID) {
            items.inContactList = 'pending';
            items.sentContactRequestMessageID= action.payload.data.messageID;
          }
        }
      }
      searchList = state.searchcontactList;
      if (state.contactDetail && state.contactDetail.length > 4) {
        if (state.contactDetail[4] && state.contactDetail[4].inContactList) {
          state.contactDetail[4].inContactList = 'pending';
          state.contactDetail[4].sentContactRequestMessageID= action.payload.data.messageID;
        }
      }

      if( state.contactDetail &&  state.contactDetail.length>6  ){

        if(state.contactDetail[0] && state.contactDetail[0].personalDetails && state.contactDetail[0].personalDetails.docID){
       
          if( action.payload && action.payload.data && action.payload.data.contactUID){

          if(state.contactDetail[0].personalDetails.docID ===  action.payload.data.contactUID){

            // if(action.payload.accepted){
              state.contactDetail[5].inContactList="pending";
              state.contactDetail[6].pendingContactRequest="false";
            // }else{
            //   state.contactDetail[5].inContactList="false";
            //   state.contactDetail[6].pendingContactRequest="false";
            // }
          }
        }

        }
      }
      if (state.businessDetail && state.businessDetail.memberList) {
        for (let items of  state.businessDetail.memberList) {
          if (items.docID === action.payload.data.contactUID) {
            items.inContactList = 'pending';
            items.pendingContactRequest = 'false';
            if(action.payload.data.messageID){
              items.sentContactRequestMessageID = action.payload.data.messageID;
            }

          }
        }
      }


      return {...state, searchcontactList: searchList, uploadImage: [] ,businessDetail:state.businessDetail};


    case DELETE_CONTACT_SUCCESS:
    console.log(state);

    console.log(state.contactDetail)
    if(state.searchcontactList){
      let contactIndex = state.searchcontactList.findIndex (
        item => item.docID === action.payload.data.contactUID
      );
      if (contactIndex > -1) {
        state.searchcontactList.splice (contactIndex, 1);
      }
    }

    if (state.contactDetail && state.contactDetail.length > 5) {
      if (state.contactDetail[5]) {
        state.contactDetail[5].inContactList = 'false';
      }
    }
    
     
      return {...state, searchcontactList: state.searchcontactList ,contactDetail:state.contactDetail};



    case DELETE_NOTIFICATION_LIST:
      let index = state.notifications.findIndex (
        item => item.messageID === action.payload.messageID
      );
      if (index > -1) {
        state.notifications.splice (index, 1);
      }
      return {...state, notifications: state.notifications};
    case SEND_REQUEST_LIST:
      return {...state, sendRequests: action.payload, uploadImage: []};
    case RECEIVE_REQUEST_LIST:
      return {...state, receiveRequests: action.payload, uploadImage: []};

    case ACCEPT_CONTACT_SUCCESS:

    if(state.receiveRequests && state.receiveRequests.length>0){
      let acceptRequestindex = state.receiveRequests.findIndex (
        item => (item.contactDetails && item.contactDetails.docID) === action.payload.targetUID
      );
      if (acceptRequestindex > -1) {
        state.receiveRequests.splice (acceptRequestindex, 1);
      }
    }
          
      

          if( state.contactDetail &&  state.contactDetail.length>6  ){

            if(state.contactDetail[0] && state.contactDetail[0].personalDetails && state.contactDetail[0].personalDetails.docID){
               if(state.contactDetail[0].personalDetails.docID ===  action.payload.targetUID){

                if(action.payload.accepted){
                  state.contactDetail[5].inContactList="true";
                  state.contactDetail[6].pendingContactRequest="false";
                }else{
                  state.contactDetail[5].inContactList="false";
                  state.contactDetail[6].pendingContactRequest="false";
                }
              
              }
             

            }
          }

          return {...state, receiveRequests: state.receiveRequests,contactDetail: state.contactDetail};


    case BUSINESS_DETAIL:
      return {...state, businessDetail: action.payload};

    case BUSINESS_DETAIL_PROFILE:
          let updateBusinessProfile=state.businessDetail;
          updateBusinessProfile.businessProfilePictureURL=action.payload.businessProfilePictureURL;
      return {...state, businessDetail: updateBusinessProfile};
      case CANCEL_SEND_REQUEST_SUCCESS:
            if( state.sendRequests &&  state.sendRequests.length>0){
              let indx = state.sendRequests.findIndex(item => item.targetUID === action.payload);
              state.sendRequests.splice(indx, indx >= 0 ? 1 : 0);
            }


            let searchListUpdate;
            if (state.searchcontactList && state.searchcontactList) {
              for (let items of state.searchcontactList) {
                if (items.docID === action.payload) {
                  items.inContactList = 'false';
                }
              }
            }
            searchListUpdate = state.searchcontactList;

            if (state.contactDetail && state.contactDetail.length > 5) {
              // console.log(state.contactDetail);
              if (state.contactDetail[5]) {
                state.contactDetail[5].inContactList = 'false';
              }
            }


            // if (state.businessDetail && state.businessDetail.length > 5) {
            //   // console.log(state.contactDetail);
            //   if (state.contactDetail[5]) {
            //     state.contactDetail[5].inContactList = 'false';
            //   }
            // }

            if (state.businessDetail && state.businessDetail.memberList) {
              for (let items of  state.businessDetail.memberList) {
                if (items.docID === action.payload) {
                  items.inContactList = 'false';
                }
              }
            }





        return {...state, sendRequests:  state.sendRequests,searchcontactList: searchListUpdate,contactDetail: state.contactDetail , businessDetail: state.businessDetail};

    

    case LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};
