export const SIGN_IN = "/login";
export const SIGN_UP = "/signup";
export const PROFILE_COMPLETION = "/profilecomplete";

export const ONE_STEP = "/onestep";
export const EDUCATIONDETAIL = "/educationdetail";
export const PROFESIONALDETAIL = "/profesionaldetail";
export const FINDBUSINESS = "/findbusiness";
export const BUSINESSCONTACTS = "/businesscontact";

export const DASHBOARDPROCESS = "/dashboardproces";

export const INFORMATIONSUBMIT = "/informationsubmit";
export const BASINESSDATABASE = "/businessdatabase";
export const BASINESSDATABASEONERESULT = "/businessdatabaseoneresult";
export const LANDING_PAGE = "/";
export const ERRORPAGE = "/error";
export const FORGOTPASSWORD = "/forgotpassword";
export const FORGOTRESET = "/ForgotReset";
export const NEWPASSWORD = "/NewPassword";
export const PASSWORDSUCCESSFULL = "/PasswordSucessfull";
export const TERMS_CONDITIONS = "/terms-conditions";


// export const PAYMENT = "/payment";

//export const LANDING = "/"
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/forgot-password";
export const VERIFY_EMAIL = "/verifyEmailAddress";



export const HOME = `/home`;
export const WELCOME_SCREEN = `${HOME}/welcomepage`;

export const SETTINGS = `${HOME}/settings`;
export const DASH = `${HOME}/dashboard`;
export const PROFILE_DASHBOARD = `${HOME}/profiledashboard`;
export const HIGHLIGHTS = `${HOME}/highlights`;
export const BUSINESS_REGISTER = `${HOME}/businessregister`;
export const USER_KYC = `${HOME}/userkyc`;
export const REPUTATION_DETAILS = `${HOME}/reputationdetail`;

export const ACCOUNT_SETTINGS = `${HOME}/accountsettings`;
export const CONTENT_APPS = `${HOME}/contentapps`;
export const INFOSYS = `${HOME}/infosys`;
export const FILEDRIVE = `${HOME}/vault`;
export const OPEN_SHARED_FILE = `/shareLink`;





export const PAYMENT = `${HOME}/payment`;
export const NEWTRANSACTION = `${HOME}/newTransaction`;
export const MYREQUESTS = `${HOME}/myrequest`;
export const PAYMENTSTATUS = "/paymentstatus";
export const WEB_PAYMENT_WITHDRAW = `${HOME}/paymentwithdraw`;
export const PAYMENT_WITHDRAW = `/paymentwithdraw`;



export const PACS = `${HOME}/pacs`;
export const PROFILE = `${HOME}/profile`;
export const EDITPROFILE = `${HOME}/editprofile`;
export const EDITEDUCATION = `${HOME}/editeducation`;
export const EDITPROFESSIONAL = `${HOME}/professional`;
export const SERVICEONDEMAND = `${HOME}/serviceOnDemand`;
export const SERVICEONDEMANDSEARCH = `${HOME}/serviceOnDemandSearch`;
export const SERVICEONDEMANDMYREQUEST = `${HOME}/serviceOnDemandMyRequest`;
export const SERVICEONDEMANDMYREQUESTDETAIL = `${HOME}/serviceOnDemandMyRequestDetail`;

export const SERVICEONDEMANDPROFILE = `${HOME}/serviceOnDemandProfile`;
export const NOTIFICATION = `${HOME}/notification`;
export const ALLNOTIFICATION = `${HOME}/allnotification`;

export const CONTACTREQUEST = `${HOME}/contactrequest`;
export const ADD_USER_PROFILE_DETAIL = `${HOME}/adduserdetail`;






export const PROFILEDETAIL = `${HOME}/profiledetail`;
export const BUSINESSDETAIL = `${HOME}/businessdetail`;
export const EDIT_BUSINESS_DETAIL = `${HOME}/businessedit`;
export const BUSINESS_MANAGEMENT = `${HOME}/businessmanagement`;


export const CONTACT = `${HOME}/contacts`;
export const CONTACTUSERDETAIL = `${HOME}/contactsdetail`;


export const CIENT_EMAIL_DASHBOARD = `${HOME}/email`;
export const CIENT_EMAIL_MESSAGE = `${HOME}/emailmessage`;






// export const MARKETPLACE = `${HOME}/marketplacesearch`;
export const MARKETPLACESEARCH = `${HOME}/marketplacesearchresult`;
export const ADDNEWMARKETPLACE = `${MARKETPLACE}/addNew`;
export const SEARCHRESULTMARKETPLACE = `${MARKETPLACESEARCH}/result`;
export const PLACEORDER = `${HOME}/marketplaceorders`;
export const SELLERCONSOLE = `${HOME}/marketplacesellerconsole`;
export const ADDSELLERPRODUCT = `${HOME}/marketplacesellerproduct`;

export const SERVICEONDEMANDPROVIDERPROFILE = `${HOME}/serviceOnDemandProviderProfile`;
export const SERVICEONDEMANDCREATESERVICEREQUEST = `${HOME}/serviceOnDemandCreateServiceRequest`;
export const SERVICEONDEMANDCREATESERVICEREQUEST_DETAIL = `${HOME}/serviceOnDemandCreateServiceRequestDetail`;
export const SERVICEONDEMANDCOMMONCOMPONENT = `${HOME}/ServiceOnDemandCommonComponent`;


export const SERVICEONDEMAND_REQUESTRECIEVED = `${HOME}/serviceOnDemandRequestRecieved`;
export const SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL = `${HOME}/serviceOnDemandRecievedRequestDetail`;
export const SERVICEONDEMAND_CREATE_SERVICE_PROFILE = `${HOME}/serviceOnDemandCreateServiceProfile`;
export const SERVICEONDEMAND_SERVICE_PROFILE = `${HOME}/serviceOnDemandServiceProfile`;
export const SERVICEONDEMAND_SIDEBAR = `${HOME}/serviceOnDemandSidebar`;



export const MESSENGER = `${HOME}/messenger`;
export const MESSENGERCREATEGROUP = `${HOME}/messenger/createGroup`;
export const GROUPDETAILS = `${HOME}/messenger/group`;
export const PROFILEDETAILS = `${HOME}/messenger/user`;
export const VIDEO_CALL = `${HOME}/messenger/call`;


export const PRODUCT_DETAIL = `${HOME}/marketplaceproductdetail`; 
export const MARKETPLACE = `${HOME}/marketplacesearch`;
export const MARKETPLACE_NEW = `${HOME}/marketplacesearchnew`;
export const BROWSE_PRODUCT = `${HOME}/marketplacebrowseproduct`;
export const PLACED_ORDERS = `${HOME}/marketplaceplacedorders`;
export const SELLER_CONSOLE = `${HOME}/marketplacesellerconsolenew`;


export const SELLER_ADD_PRODUCT = `${HOME}/marketplaceselleraddproduct`;
export const SELLER_VIEW_LISTING = `${HOME}/marketplacesellerviewmylist`;
export const SELLER_CONSOLE_SIDEMENU = `${HOME}/marketplacesellerconsolesidemenu`;
export const SELLER_ORDER_DETAIL = `${HOME}/marketplacesellerorderdetail`;
export const BULKUPLOAD_ADD_PRODUCT = `${HOME}/uploadproducts`;




export const PLACED_ORDERS_DETAILS = `${HOME}/marketplaceplacedorderdetail`;