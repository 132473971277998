import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Input} from 'reactstrap';
// import {Carousel} from 'react-responsive-carousel';
import {Carousel} from 'primereact/carousel';
import {Dropdown} from 'primereact/dropdown';
import './marketPlaceSearch.css';
import * as routes from '../../../../constants/routes';
import leftbackarrow from '../../../../images/icons/leftbackarrow.png';
import markefilter from '../../../../images/marketPlaceBuyer/markefilter.png';
import Searchamrke from '../../../../images/marketPlaceBuyer/Searchamrke.png';
import marketfiltercloseimg
  from '../../../../images/icons/marketfiltercloseimg.png';
import productDetails
  from '../../../../images/marketPlaceBuyer/productDetails.png';
import nonradioPlaceholder from '../../../../images/marketPlaceBuyer/nonradioPlaceholder.png';

import MarketPlaceSidemenu from '../marketplaceSidemenu/marketSideMenu';

import {getProductListing ,getSearchProductListing,getTopLatestProducts ,fetchProductManufacturer} from '../../../../actions/marketplaceaction';
import  RightSideContainer from '../../../../components/UI/rightSideContainer/rightSideContainer';
import {priceWithDecimal} from '../../../../utility/commonFunctions';
import {DEV_SERVER} from '../../../../services/servicesConfig';
import {checkAvailableAccess} from '../../../../constants/localMessage/errorMessage';
import  CommonAccessContainer from '../../../../components/UI/commonAccessContainer/commonAccessContainer';

import marketPlacecoming from '../../../../images/comingsoon/Marketplacecoming.svg';

import SelectInput from '../../../InputCustom/selectInput/selectDropdown'

// const deviceType = [{label: 'MRI', value: 'MRI'}, {label: 'CT', value: 'CT'}];
// const radiologyManufacturer = [{label: 'MRI Scanners', value: 'MRI Scanners'}, 
//                         {label: 'CT Systems', value: 'CT Systems'},
//                         {label: 'Ultrasounds', value: 'Ultrasounds'}, 
//                         {label: 'X-Ray Equipment', value: 'X-Ray Equipment'},
//                         {label: 'Refurbished Systems', value: 'Refurbished Systems'}, 
//                         {label: 'Medical Imaging Spare Parts', value: 'Medical Imaging Spare Parts'},
//                         {label: 'Ultrasound Probes', value: 'Ultrasound Probes'},
//                         {label: 'Everything else', value: 'Everything else'}];
// const nonradiologyManufacturer = [{label: 'Hand Sanitizers', value: 'Hand Sanitizers'}, 
//                             {label: 'Health and Personal Care', value: 'Health and Personal Care'},
//                             {label: 'Cleaning and Hygiene Products', value: 'Cleaning and Hygiene Products'}, 
//                             {label: 'Disinfectant Wipes and Sprays', value: 'Disinfectant Wipes and Sprays'},
//                             {label: 'Face Masks (N95, KN95)', value: 'Face Masks (N95, KN95)'}, 
//                             {label: 'Face Masks (Others)', value: 'Face Masks (Others)'},
//                             {label: 'Everything else', value: 'Everything else'},
//                           ];


// const location = [{label: 'California', value: 'California'}, { label: "New york", value: "New york" }];

const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 4,
    numScroll: 4,
  },
  {
    breakpoint: '768px',
    numVisible: 4,
    numScroll: 4,
  },
  {
    breakpoint: '560px',
    numVisible: 4,
    numScroll: 4,
  },
];

class MarketPlaceSearch extends Component {
  constructor (props) {
    super (props);
    this.state = {
      step: 1,
      productListing: [],
      searchString: '',
      marketFilterBox: false,
      deviceTypeValue: '',
      manufactureValue: '',
      locationValue: '',
      priceValue: '',
      maxpriceValue:'',
      latestProductList:[],
      productCategoryType:'',
      productCategoryTypeOptions:[],
      manufactureItemInfo:[],
      manufactureCollection:[],
      priceItemCollection:[
        {label:'$0',value:'0'},
        {label:'$100',value:'100'},
        {label:'$1000',value:'1000'},
        {label:'$10000',value:'10000'},
        {label:'$50000',value:'50000'},
        {label:'$100000',value:'100000'},
        {label:'$200000',value:'200000'},
        {label:'$300000',value:'300000'},
        {label:'$400000',value:'400000'},
        {label:'$500000',value:'500000'},
        {label:'$600000',value:'600000'},
        {label:'$600000+',value:'600000+'},
      ],
      selectedMinIndex:1,
      radiologyManufacturer:[],
      nonRadioCategoryList:[],
      deviceTypeOptions:[],
      countryOriginList:[],
      locationOptions:[]
      // isAccess: null
    };
    this.productTemplate = this.productTemplate.bind (this);
  }

  componentWillReceiveProps (nextProps) {
      // console.log(nextProps);
    if(nextProps){
      

      if(nextProps.product && nextProps.product.manufactureItemValues){
          const obj= nextProps.product.manufactureItemValues;
          // console.log(obj);
          let productCategoryTypeOptions=[];
          Object.keys(obj).map((key) => {
            productCategoryTypeOptions.push({label:key,value:key});
          });
          this.setState({
            productCategoryTypeOptions:productCategoryTypeOptions,
            manufactureCollection:obj
          })
      }
      if(nextProps.product && nextProps.product.productCategorisedValues){
        const obj= nextProps.product.productCategorisedValues;

        if(obj && obj['non-radiology']){
          // console.log(obj['non-radiology']);
          let nonRadioItemValues=obj['non-radiology']?obj['non-radiology']:'';
          if(nonRadioItemValues){
            let countryOriginList=[];
            let nonRadioCategoryList=[];
            if(nonRadioItemValues.countryOfOrigin){
                Object.entries(nonRadioItemValues.countryOfOrigin).forEach(([key, value]) => {
                  // console.log(key, value);
                  countryOriginList.push({
                    label:value,value:value
                  })
                });

            }
            if(nonRadioItemValues.category){
              Object.entries(nonRadioItemValues.category).forEach(([key, value]) => {
                // console.log(key, value);
                nonRadioCategoryList.push({
                  label:value,value:value
                })
              });
            }
            
            this.setState({
              countryOriginList:countryOriginList,
              nonRadioCategoryList:nonRadioCategoryList
            },()=>{
              // console.log(this.state.countryOriginList);
              // console.log(this.state.nonRadioItemConditions);
              // console.log(this.state.expirationProductOptions);
              // console.log(this.state.nonRadioCategoryList);
            })
            
          }

        }
        if(obj && obj.radiology){
          let productManufactureOptions=[];
          let deviceTypeOptions=[];
          let locationOptions=[];
          if(obj.radiology.manufacturer){
            Object.entries(obj.radiology.manufacturer).forEach(([key, value]) => {
              // console.log(key, value);
              productManufactureOptions.push({
                label:value,value:value
              })
            });
          }
          
          if(obj.radiology.deviceType){
            Object.entries(obj.radiology.deviceType).forEach(([key, value]) => {
              // console.log(key, value);
              deviceTypeOptions.push({
                label:value,value:value
              })
            });
          }
          
          if(obj.radiology.location){
            Object.entries(obj.radiology.location).forEach(([key, value]) => {
              // console.log(key, value);
              locationOptions.push({
                label:value,value:value
              })
            });
          }
          this.setState({
              radiologyManufacturer:productManufactureOptions,
              deviceTypeOptions:deviceTypeOptions,
              locationOptions:locationOptions,
            },()=>{
              
            })
          }
          
        }
      
      if(nextProps.product && nextProps.product.latestProducts){
     
        this.setState({
          latestProductList: nextProps.product.latestProducts
        })

      }
      if (
        nextProps &&
        nextProps.product &&
        nextProps.product.productlist &&
        nextProps.product.productlist
      ) {
        this.setState ({
          productListing: nextProps.product.productlist,
        });
        // console.log(nextProps.product.productlist);
        // this.props.history.push (routes.MARKETPLACESEARCH ,{'issearch': true});
      }
    }

  }

  componentDidMount () {
    // this.fetchMarketPlaceList ();

    console.log(this.props.location);
      this.props.history.listen((location, action) => {
        console.log(location);
        if(location && location.search){
        const searchValue = location.search.indexOf("?") === 0 ? location.search.slice(1) : null;
        console.log(searchValue);

          this.setState({
            step: 2,
            searchString:searchValue
          })
        }else{
          this.setState({
            step: 1,
          })
        }
       
      });
    


    if(checkAvailableAccess ("marketplace") && checkAvailableAccess ("marketplace")=== 'marketplace'){
          this.setState({
            isAccess:true
        },()=>{
            this.props.fetchProductManufacturer();
            this.props.getTopLatestProducts({data:{}});
        })
      }else{
        this.setState({
          isAccess:false
        })
      }




  }

  fetchMarketPlaceList () {

      const { deviceTypeValue,manufactureValue,locationValue,priceValue,productCategoryType,maxpriceValue}= this.state;
    let sendRequest = {};
    if(productCategoryType && productCategoryType.toLowerCase()==='radiology'){
      sendRequest={
        data: {
          productCategoryType:productCategoryType,
          productType: deviceTypeValue?[deviceTypeValue]:'',
          manufacturer: manufactureValue?[manufactureValue]:'',
          location: locationValue,
          minPrice: priceValue,
          maxPrice: maxpriceValue,
          searchString: this.state.searchString,
          crossSearch:productCategoryType ? false : true,
        },
      }
    }else{
      sendRequest={
        data: {
          crossSearch:productCategoryType?false:true,
          productCategoryType:productCategoryType,
          category: deviceTypeValue?[deviceTypeValue]:'',
          countryOriginValue: locationValue,
          minPrice: priceValue,
          maxPrice: maxpriceValue,
          searchString: this.state.searchString,
        },
      }
    }
    // this.props.getProductListing (sendRequest);
    this.props.getSearchProductListing (sendRequest);
    
  }

  doSearch (e) {
    this.setState (
      {
        searchString: e.target.value ? e.target.value : '',
      },
      () => {}
    );
  }

  goNext = () => {
    this.setState (
      {
        step: 2,
      },
      () => {
        this.fetchMarketPlaceList ();
      }
    );
  };
  goDetailPage = item => {
    this.props.history.push (routes.PRODUCT_DETAIL + `/${item.docID}`);
  };

  productTemplate (item) {
    return (
      <div className="" id={`marketplaceSearchProduct${item.docID}`}>
        <div
          className="p-grid p-nogutter SliderBox"
          onClick={() => this.goDetailPage (item)}
          id={`marketplaceSearchProductSliderBox${item.docID}`}
        >
          <div className="p-col-12"  id={`marketplaceSearchProductSliderBoxcol${item.docID}`}>
            <img
              className="sliderProductImg"
              // src={item.profilePictureURL}
              src={item && item.productImageList && item.productImageList.length>0 ? item.productImageList[0] : (item && item.productCategoryType && item.productCategoryType==='Radiology'? productDetails:nonradioPlaceholder) }  onError={e => {
                e.target.onerror = null;
                e.target.src = (item && item.productCategoryType && item.productCategoryType==='Radiology'? productDetails:nonradioPlaceholder);
              }}
              
              alt={item.systemName}
              id={`marketplaceSearchProductsliderProductImg${item.docID}`}
            />
          </div>
          {
            <div className="p-col-12" style={{marginTop: 10}}>
              <p className="siemenavantomarke" id={`marketplaceSearchsiemenavantomarke${item.docID}`}>
                {item && item.productCategoryType==='Radiology' ?  item.deviceSaleName : (item.productName?item.productName:'')}


              </p>
              <p className="siemenavantomarke" id={`marketplaceSearch1siemenavantomarke${item.docID}`}>
                <span> USD$ {item && item.priceUSD ? priceWithDecimal(item.priceUSD) : ''}</span>
              </p>
              <p className="DeviceType" id={`marketplaceSearch1deviceType${item.docID}`}>
                Device Type:
                {' '}
                {item && item.deviceType ? item.deviceType : ''}
                {' '}
              </p>
              <p className="LocCafornian" id={`marketplaceSearch1devicelocation${item.docID}`}>
                Location: {item && item.location ? item.location : ''}
              </p>

            </div>
          }
        </div>
      </div>
    );
  }
  openFilter = () => {
    this.setState ({
      marketFilterBox: true,
    });
  };
  closeFilter = () => {
    this.setState ({
      marketFilterBox: false,
    });
  };
  render () {
    const {
      productListing,
      searchString,
      isNext,
      deviceTypeValue,
      manufactureValue,
      priceValue,
      locationValue,
      latestProductList,
      isAccess,
      selectedMinIndex,
    } = this.state;
    return (
      <div>
      {isAccess ? <div style={{display: 'inline-flex',width:'100vw'}}>
        <div className="marketPlaceInnerContainer" id="marketplaceSearch">

          <div style={{textAlign: 'right'}}  id="marketplaceSearch1">
            <Button
              className="marketplacebackbutton"
              onClick={() => this.props.history.goBack ()}
              data-rh="Go back"
              id="marketplaceSearchnback"
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>

              Go <span> Back</span>
            </Button>
          </div>
          <div className="markemaindiv"  id="marketplaceSearch2">
            <div>
              <p className="welcometomarke"  id="marketplaceSearch3">Welcome to the</p>
              <p className="Marketplace"  id="marketplaceSearch4">Marketplace</p>
            </div>
            <div  id="marketplaceSearch5">
              <p className="SearchfortheM"  id="marketplaceSearch6">
                Search for all Medical Equipment, Supplies, Healthcare and Safety products on the world’s largest and safest global medical marketplace.

              </p>
              <div style={{display: 'flex'}}  id="marketplaceSearch7">
                <input
                  type="text"
                  className="searchporoinput"
                  placeholder="Enter search term"
                  value={searchString}
                  onChange={e => this.doSearch (e)}
                  id="marketplaceSearchinput"
                />
                <div className="marketFilterWrapper"  id="marketplaceSearch8">
                  {!this.state.marketFilterBox
                    ? <div className="filtercircle" onClick={this.openFilter} data-rh="Filter"  id="marketplaceSearch9">
                        <img alt="" src={markefilter} />
                      </div>
                    : <div
                        className="marketficlosecircle"
                        onClick={this.closeFilter}
                        data-rh="Close"
                        id="marketplaceSearch10"
                      >
                        <img alt="" src={marketfiltercloseimg} />
                      </div>}
                  {this.state.marketFilterBox &&
                    <div className="marketfilterbox"  id="marketplaceSearch11Category">
                     <p className="dropdownlablemarket"  id="marketplaceSearch12Category">
                        {' '}Product Category
                      </p>
                      
                      <SelectInput
                        items={this.state.productCategoryTypeOptions}
                        value={this.state.productCategoryType}
                        className='marketplaceGlobalSearchDropdown'
                        dropDownContainerClass='marketplaceGlobalSearchContainerDropdown'
                        onChange={value => {
                          this.setState({
                            productCategoryType: value
                          })
                        }}
                        placeholder='Product Category'
                      />
                         {this.state.productCategoryType && <div>
                      <p className="dropdownlablemarket"  id="marketplaceSearch12">
                        {this.state.productCategoryType && this.state.productCategoryType==='Radiology'?'Product Type' :'Category'}
                      </p>
                      {/* <Dropdown
                        className="marketsearcyinput"
                        placeholder="select"
                        value={this.state.deviceTypeValue}
                        options={this.state.productCategoryType ?(this.state.productCategoryType==='Radiology'?this.state.deviceTypeOptions:this.state.nonRadioCategoryList):[]}
                        onChange={e =>
                          this.setState ({deviceTypeValue: e.value})}
                          id="marketplaceSearch13"
                      />   */}

                      <SelectInput
                        items={this.state.productCategoryType ?(this.state.productCategoryType==='Radiology'?this.state.deviceTypeOptions:this.state.nonRadioCategoryList):[]}
                        value={this.state.deviceTypeValue}
                        className='marketplaceGlobalSearchDropdown'
                        dropDownContainerClass='marketplaceGlobalSearchContainerDropdown'
                        onChange={value => {
                          this.setState({
                            deviceTypeValue: value
                          })
                        }}
                        placeholder={this.state.productCategoryType && this.state.productCategoryType==='Radiology'?'Product Type' :'Category'}
                      />

                      </div>}
                     {this.state.productCategoryType && this.state.productCategoryType==='Radiology'&&<div>
                      <p className="dropdownlablemarket"  id="marketplaceSearch16"> Manufacturer</p>
                      {/* <Dropdown
                        className="marketsearcyinput"
                        placeholder="select"
                        value={this.state.manufactureValue}
                        options={this.state.radiologyManufacturer}
                        onChange={e =>
                          this.setState ({manufactureValue: e.value})}
                          id="marketplaceSearch14"
                      /> */}

                      <SelectInput
                        items={this.state.radiologyManufacturer}
                        value={this.state.manufactureValue}
                        className='marketplaceGlobalSearchDropdown'
                        dropDownContainerClass='marketplaceGlobalSearchContainerDropdown'
                        onChange={value => {
                          this.setState({
                            manufactureValue: value
                          })
                        }}
                        placeholder="Manufacturer"
                      />

                      </div>
                      }
                    {this.state.productCategoryType && <div>
                      <p className="dropdownlablemarket"  id="marketplaceSearch17">
                      {this.state.productCategoryType && this.state.productCategoryType==='Radiology'?'Location' :'Country Of Origin'}</p>
                    
                      <SelectInput
                        items={this.state.productCategoryType && this.state.productCategoryType==='Radiology'?this.state.locationOptions:this.state.countryOriginList}
                        value={this.state.locationValue}
                        className='marketplaceGlobalSearchDropdown'
                        dropDownContainerClass='marketplaceGlobalSearchContainerDropdown'
                        onChange={value => {
                          this.setState({
                            locationValue: value
                          })
                        }}
                        placeholder={this.state.productCategoryType && this.state.productCategoryType==='Radiology'?'Location' :'Country Of Origin'}
                      />




                      </div>}
                     
                      {/* <p className="dropdownlablemarket"  id="marketplaceSearch15"> Price Range (Min-Max)</p> */}
                      <p className="dropdownlablemarket"  id="marketplaceSearch15"> Price Range (Min)</p>
                      
                      {/* <Dropdown
                        className="marketsearcyinput"
                        placeholder="select"
                        value={this.state.priceValue}
                        options={this.state.priceItemCollection.slice(0,10)}
                        onChange={e => this.setState ({priceValue: e.value},()=>{
                          // selectedMinIndex
                          // for(let item of priceItemCollection);
                          var findItemindex = this.state.priceItemCollection.map( (img)=> { return img.value; }).indexOf(this.state.priceValue);
                          this.setState({
                            selectedMinIndex:findItemindex+1
                          })
                        })}
                      /> */}

                      <SelectInput
                        items={this.state.priceItemCollection.slice(0,10)}
                        value={this.state.priceValue}
                        className='marketplaceGlobalSearchDropdown'
                        dropDownContainerClass='marketplaceGlobalSearchContainerDropdown'
                        onChange={value => this.setState ({priceValue: value},()=>{
                          var findItemindex = this.state.priceItemCollection.map( (img)=> { return img.value; }).indexOf(this.state.priceValue);
                          this.setState({
                            selectedMinIndex:findItemindex+1
                          })
                        })}
                        placeholder='Price (Min)'
                      />

                      {!priceValue &&
                        isNext &&
                        <p className="validationerrorMessage">
                          {' '}Min price value is required *{' '}
                        </p>}
                        <p className="dropdownlablemarket"  id="marketplaceSearch15"> Price Range (Max)</p>
                      
                       

                        <SelectInput
                        items={this.state.priceItemCollection.slice(selectedMinIndex,20)}
                        value={this.state.maxpriceValue}
                        className='marketplaceGlobalSearchDropdown'
                        dropDownContainerClass='marketplaceGlobalSearchContainerDropdown'
                        onChange={value => this.setState ({maxpriceValue: value})}
                        placeholder='Price (Max)'
                      />
                      {/* {!priceValue &&
                        isNext &&
                        <p className="validationerrorMessage">
                          {' '}Price Value is required *{' '}
                        </p>} */}
                     
                     
                     
                    </div>}
                </div>
                <Button  id="marketplaceSearch19" className="searchmarkebutton" onClick={this.goNext} data-rh="Search">
                  <img alt="Search" src={Searchamrke} />
                  <p>Search</p>
                </Button>
              </div>
              {this.state.step === 2 &&
                <div className="Marketplaceresults5"  id="marketplaceSearch20">
                  {productListing.length}
                  {' '}
                  Marketplace results for
                  <span> “{searchString}”</span>
                </div>}
            </div>
            {this.state.step === 1 &&
              <div className="marketplaceprovox"  id="marketplaceSearch21">
                <div>
                  <div className="markehgeading"  id="marketplaceSearch22">
                    <span> NEW </span> IN THE MARKETPLACE
                  </div>
                </div>
                {latestProductList &&
                  latestProductList.length === 0 &&<div className="noNewitems">
                  No Data Found
                </div>}
                {latestProductList &&
                  latestProductList.length > 0 &&
                  <div style={{marginTop: 47}}>
                    <Carousel
                      value={latestProductList}
                      itemTemplate={this.productTemplate}
                      responsiveOptions={responsiveOptions}
                      numVisible={4}
                      numScroll={4}
                      circular={true}
                    />
                  </div>}
              </div>}
            {this.state.step === 2 &&
              <div>
                <div className="productListView"  id="marketplaceSearch23">
                  {productListing &&
                    productListing.length > 0 &&
                    productListing.map ((item, i) => (
                      <div
                        key={i}
                        className="markeimagebox"
                        onClick={() => this.goDetailPage (item)}
                        id={`marketplaceSearchmarkeimagebox${i}`}
                      >
                        <img
                          alt="product"
                          src={item && item.productImageList && item.productImageList.length>0 ?item.productImageList[0]:(item && item.productCategoryType && item.productCategoryType==='Radiology'? productDetails:nonradioPlaceholder) }  onError={e => {
                            e.target.onerror = null;
                            e.target.src = (item && item.productCategoryType && item.productCategoryType==='Radiology'? productDetails:nonradioPlaceholder);
                          }}
                          className="imageSize"
                        id={`marketplaceSearchmarkeimageboximageSize${i}`}

                        />

                        <div style={{background: '#4453D6', height: 90, paddingBottom: 10}} id={`marketplaceSearchsiemenavantomarke${i}`}>
                          <p className="siemenavantomarke" id={`marketplaceSearchssiemenavantomarke${i}`}>
                         
                            {item && item.productCategoryType==='Radiology' ? item.deviceSaleName: (item.productName? item.productName:'') }

                            <br />
                            <span>
                              {' '}
                              USD$
                              {' '}
                              {item && item.priceUSD ? priceWithDecimal(item.priceUSD) : ''}
                            </span>
                          </p>
                          {item && item.productCategoryType!=='Radiology' &&<p className="DeviceType" id={`marketplaceSearchDeviceType${i}`}>
                          Category:
                          {' '}
                          {item && item.category ? item.category : ''}
                        </p>}
                          {item && item.productCategoryType==='Radiology' && <div id={`marketplaceSearchgeneric${i}`}>
                          <p className="DeviceType" id={`marketplaceSearchgenericdevice${i}`}>
                            Device Type:
                            {' '}
                            {item && item.deviceType ? item.deviceType : ''}
                          </p>
                          <p className="LocCafornian" id={`marketplaceSearchloca${i}`}>
                            Location:
                            {' '}
                            {item && item.location ? item.location : ''}
                          </p>
                          </div>}

                        </div>
                      </div>
                    ))}

                </div>
              </div>}
          </div>
        </div>
        <RightSideContainer>
        <MarketPlaceSidemenu isSearch={true} history={this.props.history}/>
        </RightSideContainer>
        </div>:
        
        
      <CommonAccessContainer bgImage={marketPlacecoming} message={checkAvailableAccess('marketplace')} />
     }
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getProductListing,
      getTopLatestProducts,
      fetchProductManufacturer,
      getSearchProductListing,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  MarketPlaceSearch
);
