import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EncryptFunction } from '../utility/encryption';
import moment from 'moment';

// import { SignInLink } from "./SignIn";
import {
  register,
  updateFirebaseToken,
  // ModifyPersonalInfo,
  signOut,
  resendEmail,
  DoRegisterModifyPersonalInfo,
  fetchStatusCode,
  sendOTP,
  verifyOTP,
  resetResetPasswordReducer,
} from '../actions/Authaction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Modal, ModalBody, Progress } from 'reactstrap';
import logo from './logo.svg';

import * as routes from '../constants/routes';
import { auth, db } from '../firebase';
// import firebase from '../config/firebase';

import * as firebase from 'firebase';

import { createNotification } from './../constants/notificationtoast';
import { Loading } from './../actions/LoadingAction';
import { uploadUserProfile } from './../actions/contacts';
import email from './../images/icons/email.svg';
import password from './../images/icons/password.svg';
import accountSuccess from './../images/icons/accountsuccess.svg';
import placeholderProfile from './../images/placeholder-profile.svg';
import leftbackarrow from './../images/icons/leftbackarrow.png';
import downWhiteArrow from './../images/icons/downwhiteArrow.svg';

import { authErrorMessage } from '../constants/localMessage/auth/authHelper';
import * as authValidation from '../constants/localMessage/auth/authHelper';

// import countryJSON from '../constants/localJSON/countryList.json';
import countryFlags from '../constants/localJSON/countryList';

import Resizer from 'react-image-file-resizer';
import {
  getBrowserName,
  // getDeviceModal,
  CapitalizeFirstLetter,
} from '../constants/helper';
import { checkAutomation } from '../utility/commonFunctions';
import { IMAGE_TYPE_ERROR } from '../constants/errorMessages';
import ImageCrop from './UI/ImageCrop';
import Calendar from './../components/UI/calendar/calendar';
import VerifyCaptcha from './UI/verifyCaptcha/verifyCaptch';
import parsePhoneNumber from 'libphonenumber-js';
import SelectInput from './InputCustom/selectInput/selectDropdown'


//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePicture: '',
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      passwordOne: '',
      passwordAgain: '',
      phoneNumber: '',
      displayName: '',
      searchHandle: '',
      showingAlert: false,
      Loading: true,
      isSubmit: false,
      invalidEmailFormat: false,
      passwordCheck: false,
      step: 1,
      invalidConfirmPass: false,
      userudId: '',
      titleDropDopwn: [
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mrs.', value: 'Mrs.' },
      ],
      genderDropDopwn: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
        { label: 'Prefer Not To Say', value: 'Prefer Not To Say' },
      ],
      agree: false,
      dateOfbirth: '',
      gender: '',
      countryCode: '+1',
      countryFlag:
        countryFlags && countryFlags.length > 0
          ? countryFlags[234].countryFlag
          : '',
      // countryCode: '',
      // countryFlag: "",

      otpValue: '',
      confirmOTPError: '',
      isChangePhone: false,
      filterCountry: '',
      time: {},
      seconds: 0,
      lat: '',
      lng: '',
      isCheckAutomation: false,
      loadedImage: '',
      isOpenDobPicker: false,
      isResetFormConfirmation: false,
      mobileCheckvalidFormat: false,
    };

    this.openDobDatepicker = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
    this.tokenExpired = this.tokenExpired.bind(this);
    this.doCallSendOTP = this.doCallSendOTP.bind(this);

    this.fetchCheckAutomation();
  }
  async fetchCheckAutomation() {
    this.setState(
      {
        isCheckAutomation: await checkAutomation(),
      },
      () => {
        // console.log(this.state.isCheckAutomation)
      }
    );
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  InitiateTimer() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  handleClickOutside = (event) => {
    if (
      this.openDobDatepicker.current &&
      !this.openDobDatepicker.current.contains(event.target)
    ) {
      this.setState({
        isOpenDobPicker: false,
      });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    localStorage.clear();
    this.props.resetResetPasswordReducer();

    this.props.Loading(false);
    this.props.fetchStatusCode();

    await navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.userinfo &&
      nextProps.userinfo.otpVerify &&
      nextProps.userinfo.otpVerify
    ) {
      this.setState({
        step: 3,
        isSubmit: false,
      });
    }
    if (
      nextProps.userinfo &&
      nextProps.userinfo.newuser &&
      nextProps.userinfo.newuser.result
    ) {
      if (this.state.step === 3) {
        setTimeout(() => {
          this.setState({
            step: 4,
          });
        }, 2000);
      }
    }
  }

  doCallSendOTP() {
    clearInterval(this.timer);
    this.timer = 0;
    this.setState(
      {
        time: {},
        seconds: 59,
      },
      () => {
        this.startTimer();
        let sendRequest = {
          data: {
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.countryCode + this.state.phoneNumber,
            recapchaToken: this.state.captchaToken,
            countryCode: this.state.countryCode,
            localPhoneNumber: this.state.phoneNumber,
          },
        };
        this.props.sendOTP(sendRequest);
      }
    );
  }

  signInWithPhone = () => {
    this.props.Loading(false);
    // console.log("ffff");
    // window.recaptchaVerifier = new firebase.auth
    //   .RecaptchaVerifier ('phoneVerify', {
    //   // 'size': 'invisible',
    //   callback: recapchaToken => {
    //     try {

    //       this.setState (
    //         {
    //           captchaToken: recapchaToken,
    //         },
    //         () => {

    //           this.doCallSendOTP();
    //         }
    //       );
    //     } catch (error) {
    //       console.log ('ERORR = ' + error);
    //     }
    //   },
    //   'expired-callback': () => {
    //     try {
    //       this.setState (
    //         {
    //           captchaToken: "",
    //         },()=>{
    //         });

    //     } catch (error) {
    //       console.log ('ERORR = ' + error);
    //     }
    //   },
    // });

    // window.recaptchaVerifier.render ().then (function (widgetId) {
    //   window.recaptchaWidgetId = widgetId;
    // });
  };
  tokenExpired(token) {
    this.setState(
      {
        captchaToken: token,
        isSubmit: false,
      },
      () => {
        // console.log("dfdfdf");
        // console.log(token);
        // console.log(this.state.captchaToken);

        if (this.state.captchaToken) {
          // console.log("call");

          this.doCallSendOTP();
        }
      }
    );
  }

  doConfirmOTP() {
    // var code = getCodeFromUserInput();
    this.setState(
      {
        isSubmit: true,
      },
      () => {
        var code = this.state.otpValue;
        // console.log (code);
        if (!code) {
          return;
        }

        let sendRequest = {
          data: {
            emailAddress: this.state.emailAddress,
            otp: this.state.otpValue,
          },
        };
        // console.log (sendRequest);

        this.props.verifyOTP(sendRequest);
        // this.props.Loading (true);

        // window.confirmationResult.confirm(code).then( (result)=> {
        //   console.log(result);
        //   // User signed in successfully.
        //   var user = result.user;
        //   this.props.Loading (false);
        //   this.setState({
        //     step:3
        //   },()=>{
        //     console.log(this.state.step);
        //   })
        //   // ...
        // }).catch((error)=> {
        //   console.log(error);
        //   this.props.Loading (false);

        //   if(error && error.message){
        //     this.setState({
        //       confirmOTPError:error.message
        //     })
        //   }

        // User couldn't sign in (bad verification code?)
        // ...
        // });
      }
    );

    // this.signInWithPhone();
    // console.log(this.state)
  }
  goNext = () => {
    // this.setState ({
    //   step: 2,
    //   isSubmit: false,
    // },()=>{
    //   setTimeout(()=>{

    //     // this.setState ({
    //     //   step: 3
    //     // })
    //     // this.signInWithPhone();
    //   },3000)
    // });
    // return;

    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const {
          emailAddress,
          passwordOne,
          passwordAgain,
          firstName,
          agree,
          phoneNumber,
          countryCode,
          isCheckAutomation,
        } = this.state;

        let checkEmail = false;
        let checkMobile = false;

        if (emailAddress) {
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const result = pattern.test(emailAddress);

          if (!result) {
            checkEmail = true;
            this.setState({
              invalidEmailFormat: true,
            });
            return;
          } else {
            checkEmail = false;
            this.setState({
              invalidEmailFormat: false,
            });
          }
        }

        const patternpass = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        const result = patternpass.test(passwordOne);

        if (!result) {
          this.setState({
            passwordCheck: true,
          });
          return;
        } else {
          // checkMobile = false;
          this.setState({
            passwordCheck: false,
          });
        }

        if (passwordOne !== passwordAgain) {
          this.setState({
            invalidConfirmPass: true,
          });
          return;
        }
        // if (
        //   !phoneNumber ||
        //   (phoneNumber.length < 7 || phoneNumber.length > 10)
        // ) {
        //   return;
        // }
        if (!countryCode) {
          return;
        }
        if (!this.state.phoneNumber) {
          return;
        }
        const checkPhoneNumber = parsePhoneNumber(
          countryCode + this.state.phoneNumber
        );

        if (
          this.state.phoneNumber.length > 0 &&
          checkPhoneNumber &&
          !checkPhoneNumber.isValid()
        ) {
          return;
        }
        if (!agree) {
          return;
        }

        this.props.Loading(true);

        let userEmail = emailAddress.toLowerCase();

        let hashKey = EncryptFunction(userEmail, passwordOne);

        auth
          .doCreateUserWithEmailAndPassword(userEmail, hashKey)
          .then((user) => {
            auth.AuthChange((user) => {
              if (user) {
                user.getIdToken().then((idToken) => {
                  // console.log (idToken);
                  // console.log ('auth user', user);
                  localStorage.setItem('authToken', idToken);
                  // localStorage.setItem (
                  //   'newUserInfo',
                  //   JSON.stringify (this.state)
                  // );
                  this.props.updateFirebaseToken(idToken);

                  db.doCreateUser(user.uid, firstName, emailAddress)
                    .then((res) => {
                      // this.props.Loading (false);
                    })
                    .catch((error) => {
                      this.props.Loading(false);
                      this.setState(byPropKey('error', error));
                      // this.timer (); //show alert message for some seconds
                    });

                  this.setState(
                    {
                      step: isCheckAutomation ? 3 : 2, // For automation testing
                      isSubmit: false,
                      userudId: user.uid,
                    },
                    () => {
                      setTimeout(() => {
                        if (isCheckAutomation) {
                          this.props.Loading(false);
                        } else {
                          // this.signInWithPhone ();
                          this.props.Loading(false);
                        }
                      }, 1000);
                    }
                  );
                });
              }
            });
          })
          .catch((err) => {
            this.props.Loading(false);
            console.error('error!!', err.message);
            this.setState(byPropKey('error', err));
            createNotification('error', err.message);
            // this.timer (); //show alert message for some seconds
            //  Loading=false
          });
        //  history.push (routes.ONE_STEP);

        //prevents refreshing
      }
    );
  };
  onChange = (e) => {
    console.warn('warning function call details');
    // let files = e.target.files;
    // let reader = new FileReader ();
    // reader.readAsDataURL (files[0]);

    const file = e.target.files[0];
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    // Check If file is not an image
    if (!validImageTypes.includes(fileType)) {
      // console.log("rfreg")
      createNotification('warning', IMAGE_TYPE_ERROR);
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          loadedImage: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    //   Resizer.imageFileResizer(
    //     files[0],
    //     300,
    //     300,
    //     'JPEG',
    //     400,
    //     0,
    //     uri => {
    //         // console.log(uri)

    //       this.setState ({
    //         profilePicture: uri,
    //       },()=>{
    //         //  console.log(this.state.profilePicture)
    //       });
    //     },
    //     'base64'
    // );

    // reader.onload = e => {
    //   this.setState (byPropKey ('profilePicture', e.target.result));
    // };
  };

  updateUserImage(croppedImage) {
    Resizer.imageFileResizer(
      croppedImage,
      300,
      300,
      'JPEG',
      400,
      0,
      (uri) => {
        const { filename } = this.state;
        this.setState(
          {
            profilePicture: uri,
          },
          () => {}
        );
      },
      'base64'
    );
  }
  onSubmit = (event) => {
    event.preventDefault();

    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const {
          profilePicture,
          title,
          firstName,
          lastName,
          emailAddress,
          passwordOne,
          phoneNumber,
          displayName,
          searchHandle,
          passwordAgain,
          gender,
          dateOfbirth,
          isCheckAutomation,
        } = this.state;

        let checkEmail = false;
        let checkMobile = false;
        if (emailAddress) {
          // const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          const result = pattern.test(emailAddress);
          // console.log (result);
          if (!result) {
            checkEmail = true;
            this.setState({
              invalidEmailFormat: true,
            });
          } else {
            checkEmail = false;
            this.setState({
              invalidEmailFormat: false,
            });
          }
        }
        const patternpass = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        const result = patternpass.test(passwordOne);

        if (!result) {
          checkMobile = true;

          this.setState({
            passwordCheck: true,
          });
        } else {
          checkMobile = false;
          this.setState({
            passwordCheck: false,
          });
        }

        const isInvalid =
          passwordOne === '' ||
          emailAddress === '' ||
          firstName === '' ||
          lastName === '' ||
          // phoneNumber === '' ||
          gender === '' ||
          dateOfbirth === '';
        // checkEmail === true ||
        // checkMobile === true

        if (isInvalid) {
          return;
        }
        if (!isCheckAutomation) {
          if (profilePicture === '') {
            return;
          }
        }

        const { history, register } = this.props;

        db.doCreateUser(this.state.userudId, firstName, emailAddress)
          .then((res) => {
            // this.props.Loading (false);
            // console.log (res);
          })
          .catch((error) => {
            // Loading = false;
            this.setState(byPropKey('error', error));
            // this.timer (); //show alert message for some seconds
          });

        this.props.Loading(true);
        // this.setState({
        //   base64Image: e.target.result,
        // })
        let sendRequest = {
          data: {
            profilePicture: profilePicture,
            filename: new Date() + 'profile.png',
            contentType: 'image/jpeg',
          },
        };
        // this.props.uploadUserProfile (sendRequest);

        const userInfosendRequest = {
          data: {
            title: title,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: moment(new Date(dateOfbirth)).format('YYYY/MM/DD'),
            gender: gender,
            // searchHandleNew: searchHandle,
            emailAddress: emailAddress,
            registrationSource: 'web',
            // deviceModel: getDeviceModal(),
            osType: '',
            browser: getBrowserName(),
            deviceLongitude: this.state.lng,
            deviceLatitude: this.state.lat,

            // "deviceManufacturer": "apple",
            // "deviceID": "8273483nu445454",
            // "osVersion": "8.0.1",
          },
        };

        this.props.DoRegisterModifyPersonalInfo(
          userInfosendRequest,
          sendRequest
        );

        // this.props.DoRegisterModifyPersonalInfo (
        //   userInfosendRequest,
        //   null
        // ); // For automation testing

        // setTimeout(()=>{
        //   this.props.Loading (true);
        // },2000)

        // history.push (routes.ONE_STEP);
        // setTimeout (() => {
        //   this.setState ({
        //     step: 3,
        //   });
        // }, 3000);
      }
    );
  };

  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };

  goBack() {
    if (this.state.step === 1) {
      this.props.history.goBack();
    } else {
      if(this.state.step === 2){
        clearInterval(this.timer);
        this.timer = 0;
        this.setState({
            time: {},
            seconds: 0,
          })
      //   this.setState ({
      //     isResetFormConfirmation: true,
      //   });
      }
      // if(this.state.step === 3){
      this.setState({
        step: Number(this.state.step) - 1,
      });
      // }
    }
  }

  emailResend = () => {
    let sendRequest = {
      data: {
        emailAddress: this.state.emailAddress,
      },
    };
    this.props.resendEmail(sendRequest);
  };

  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };

  doUpdatePhone() {
    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const { phoneNumber, countryCode } = this.state;
        // if (
        //   !phoneNumber ||
        //   (phoneNumber.length < 7 || phoneNumber.length > 10)
        // ) {
        //   return;
        // }
        if (!countryCode) {
          return;
        }

        const checkPhoneNumber = parsePhoneNumber(countryCode + phoneNumber);

        if (
          phoneNumber.length > 0 &&
          checkPhoneNumber &&
          !checkPhoneNumber.isValid()
        ) {
          return;
        }

        this.setState(
          {
            isSubmit: false,
            isChangePhone: false,
          },
          () => {
            // this.doCallSendOTP();
          }
        );
      }
    );
  }
  checkPhoneValidation() {
    const checkPhoneNumber = parsePhoneNumber(
      this.state.countryCode + this.state.phoneNumber
    );
    // console.log(this.state.countryCode+this.state.phoneNumber);
    if (checkPhoneNumber && this.state.phoneNumber.length > 0) {
      if (checkPhoneNumber.isValid()) {
        this.setState({
          mobileCheckvalidFormat: false,
        });
      } else {
        this.setState({
          mobileCheckvalidFormat: true,
        });
      }
    } else {
      this.setState({
        mobileCheckvalidFormat: true,
      });
    }
  }
  render() {
    const {
      profilePicture,
      title,
      firstName,
      lastName,
      emailAddress,
      passwordOne,
      phoneNumber,
      error,
      showingAlert,
      displayName,
      searchHandle,
      isSubmit,
      invalidEmailFormat,
      passwordCheck,
      passwordAgain,
      invalidConfirmPass,
      step,
      titleDropDopwn,
      genderDropDopwn,
      dateOfbirth,
      gender,
      countryCode,
      otpValue,
      isChangePhone,
      isCheckAutomation,
      loadedImage,
      isOpenDobPicker,
      isResetFormConfirmation,
    } = this.state;
    //a boolen to perform validation

    const lowercasedFilter = this.state.filterCountry.toLowerCase();
    const filteCountryData = countryFlags.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    return (
      <div className='div-flex gradientColorPurpleBlue' id='registercontainer'>
        <div className='maineSingupContaner' id='registercontainer1'>
          <div
            className={
              this.state.step === 1 ? 'maineSingupContanerPaddingTop' : ''
            }
          >
            {this.state.step < 4 && (
              <Button
                className='signupStepGoback'
                id='registerbackButton'
                onClick={() => this.goBack()}
              >
                <div className='signup-step-goback-container'>
                  <img src={leftbackarrow} alt='backIcon' />
                </div>
                <span className='signup-normal'>Go</span>
                <span className='signup-bold'> Back</span>
              </Button>
            )}

            {/*<div id="phoneVerify" />*/}

            {this.state.step === 1 && (
              <div id='registerstep1'>
                <div id='registerstep1logo'>
                  <img src={logo} alt='logo' className='logo' />
                </div>
                <div className='signupstep1' id='registerstep11'>
                  <div className='setupacc2' id='registerstep12'>
                    Setup Account
                  </div>
                  <div id='registerstep13'>
                    <Progress
                      value={step * 33.3}
                      className='profgress2'
                      id='progress2'
                    />
                  </div>
                  <div className='Createlo' id='registerstep14'>
                    Create login credentials
                  </div>
                  <div id='registerstep15'>
                    <p className='title2'>Email</p>
                    <div className='relative' style={{ textAlign: 'center' }}>
                      <img
                        src={email}
                        alt='Email icon'
                        className='signUpinputIcons'
                      />
                      <input
                        id='registeremailinput'
                        className='step1inpu'
                        type='text'
                        placeholder='Email'
                        value={emailAddress}
                        onChange={(e) =>
                          this.setState(
                            byPropKey('emailAddress', e.target.value)
                          )
                        }
                      />
                    </div>
                    {!emailAddress && isSubmit && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {' '}
                        Email is required *{' '}
                      </p>
                    )}
                    {invalidEmailFormat && isSubmit && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {authErrorMessage(authValidation.EMAIL_FORMAT)}
                      </p>
                    )}
                  </div>
                  <div id='registerstep15'>
                    <p className='title2'>Password</p>
                    <div className='relative' style={{ textAlign: 'center' }}>
                      <img
                        src={password}
                        alt='Email icon'
                        className='signUpinputIcons'
                        style={{ left: 30 }}
                      />

                      <input
                        id='registerpassinput'
                        type='password'
                        className='step1inpu'
                        placeholder='Password'
                        value={passwordOne}
                        onChange={(e) =>
                          this.setState(
                            byPropKey('passwordOne', e.target.value)
                          )
                        }
                      />
                    </div>

                    {!passwordOne && isSubmit && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {' '}
                        Password is required *{' '}
                      </p>
                    )}
                    {passwordCheck && (
                      <div>
                        <p
                          className='Passwordmustcontain'
                          style={{ marginLeft: 15, marginRight: 15 }}
                        >
                          {authErrorMessage(
                            authValidation.INVALID_PASSWORD_FORMAT
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className='password-again-continer'>
                    <p className='title3'>Password again</p>
                    <div class='relative' style={{ textAlign: 'center' }}>
                      <input
                        id='registerpassagaininput'
                        style={{ paddingLeft: 30 }}
                        className='step1inpu'
                        type='password'
                        name='passwordAgain'
                        placeholder='Confirm Password'
                        value={passwordAgain}
                        onChange={(e) =>
                          this.setState({
                            passwordAgain: e.target.value,
                            invalidConfirmPass: false,
                          })
                        }
                      />
                    </div>
                    {!passwordAgain && isSubmit && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {' '}
                        Password again is required *{' '}
                      </p>
                    )}
                    {invalidConfirmPass && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {authErrorMessage(
                          authValidation.INVALID_CONFIRM_PASSWORD
                        )}
                      </p>
                    )}
                  </div>

                  <div className='phone-container'>
                    <p className='title3'>Phone Number</p>
                    <div
                      className='phoneInputContainer'
                      style={{ textAlign: 'center' }}
                    >
                      <div className='phoneInputBox'>
                        <Button
                          className='selectCountryButton'
                          onClick={this.handleButtonClick}
                          id='registerselectCountry'
                        >
                          <img
                            alt='countryFlag'
                            style={{ width: 35, height: 30, marginRight: 10 }}
                            src={this.state.countryFlag}
                          />

                          {/*this.state.countryCode
                          ? this.state.countryCode
                        : 'Select Code'*/}
                          <img
                            style={{
                              transform: this.state.open
                                ? 'rotateZ(180deg)'
                                : '',
                            }}
                            src={downWhiteArrow}
                            alt='down'
                          />
                        </Button>

                        <input
                          className='phoneInput'
                          type='text'
                          placeholder='Phone Number *'
                          value={phoneNumber}
                          pattern='[0-9]*'
                          // maxLength="10"
                          onChange={(evt) => {
                            const phoneNumber = evt.target.validity.valid
                              ? evt.target.value
                              : this.state.phoneNumber;
                            this.setState(
                              {
                                phoneNumber,
                              },
                              () => {
                                this.checkPhoneValidation();
                              }
                            );
                          }}
                          id='registerselectphoneInput'
                        />
                      </div>
                    </div>

                    <div style={{ position: 'relative' }}>
                      {this.state.open && (
                        <div
                          className='countryListDropdown'
                          id='registerselectCountryList'
                        >
                          <div
                            className='searchInput'
                            style={{
                              height: 40,
                              background: '#17171D',
                              paddingTop: 10,
                            }}
                          >
                            <input
                              id='registerselectCountryInput'
                              className='searchInputField'
                              type='text'
                              placeholder='Search...'
                              onChange={(e) =>
                                this.setState(
                                  {
                                    filterCountry: e.target.value,
                                  },
                                  () => {
                                    // console.log(this.state.filterCountry)
                                  }
                                )
                              }
                            />
                          </div>
                          <div className='countryListDropdownScroller'>
                            {filteCountryData &&
                              filteCountryData.map((item, i) => (
                                <div
                                  key={i}
                                  onClick={() => {
                                    let countryCode = item.dial_code
                                      .split(' ')
                                      .join('');

                                    this.setState(
                                      {
                                        countryCode: countryCode,
                                        countryFlag: item.countryFlag,
                                        open: false,
                                        filterCountry: '',
                                      },
                                      () => {
                                        this.checkPhoneValidation();
                                      }
                                    );
                                  }}
                                >
                                  {/*<img style={{width:30,height:30}} src={
                                  `https://lipis.github.io/flag-icon-css/flags/4x3/${item.code.toLowerCase()}.svg`
                                
                                } />*/}
                                  <img
                                    alt='countryFlag'
                                    style={{ width: 35, height: 30 }}
                                    src={item.countryFlag}
                                  />

                                  {/*<span style={{fontSize:40}}>{item.flag}</span>*/}

                                  <span
                                    style={{ fontSize: 16, marginLeft: 10 }}
                                  >
                                    {item.dial_code}
                                  </span>
                                  <span style={{ fontSize: 16, marginLeft: 5 }}>
                                    {item.name}
                                  </span>
                                  {/*item.dial_code*/}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>

                    {!countryCode && isSubmit && (
                      <div
                        className='redBoxErrorMsg center'
                        style={{ padding: 10 }}
                        id='registerscountrycode'
                      >
                        {authErrorMessage(
                          authValidation.PROFILE_PHONE_COUNTRYCODE
                        )}
                      </div>
                    )}

                    {!phoneNumber && isSubmit && (
                      <div
                        className='redBoxErrorMsg center'
                        style={{ padding: 10 }}
                      >
                        {authErrorMessage(authValidation.PROFILE_PHONENUMBER)}
                      </div>
                    )}
                    {/* {phoneNumber &&
                    isSubmit &&
                    (phoneNumber.length <= 7 || phoneNumber.length > 10) &&
                    <div
                      className="redBoxErrorMsg center"
                      style={{padding: 10}}
                    >

                      {authErrorMessage (
                        authValidation.PROFILE_PHONENUMBER_FORMAT
                      )}

                    </div>} */}

                    {phoneNumber &&
                      isSubmit &&
                      this.state.mobileCheckvalidFormat && (
                        <div
                          className='redBoxErrorMsg center'
                          style={{ padding: 10 }}
                        >
                          {authErrorMessage(
                            authValidation.PROFILE_PHONENUMBER_FORMAT
                          )}
                        </div>
                      )}
                  </div>
                  <div className='termsandCondition'>
                    <input
                      checked={this.state.agree}
                      onClick={() =>
                        this.setState({ agree: !this.state.agree })
                      }
                      style={{ marginRight: 10, cursor: 'pointer' }}
                      type='checkbox'
                    />
                    I agree to the {' '}
                    <Link
                      to={routes.TERMS_CONDITIONS}
                      data-rh='Terms & Conditions, Privacy Policy and Cookies '
                    >
                      {' '}
                      Terms & Conditions, Privacy Policy and Cookies
                    </Link>
                    {!this.state.agree && isSubmit && (
                      <p
                        id='registersiagree'
                        className='redBoxErrorMsg center'
                        style={{ padding: 10 }}
                      >
                        {' '}
                        Please agree to Terms & Conditions, Privacy Policy and
                        Cookies Policy to register{' '}
                      </p>
                    )}
                  </div>

                  <div id='registerBottomBox'>
                    <Button
                      className='stepnextbu'
                      onClick={this.goNext}
                      style={{
                        cursor:
                          emailAddress === '' ||
                          passwordOne === '' ||
                          passwordAgain === ''
                            ? 'unset'
                            : '',
                      }}
                      disabled={
                        emailAddress === '' ||
                        passwordOne === '' ||
                        passwordAgain === ''
                      }
                      data-rh='Next'
                      id='registerBottomnext1'
                    >
                      Next
                    </Button>
                  </div>
                  <div className='Already' id='registerAlready'>
                    Already have an account?{' '}
                    <Link to={routes.SIGN_IN} data-rh='Login'>
                      Login here
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 2 && (
              <div id='registerStep2'>
                <div id='registerStep22'>
                  <img alt='logo' src={logo} className='logo' />
                </div>
                <div className='signupstep1' id='registerStep23'>
                  <div className='setupacc2' id='registerStep24'>
                    Setup Account
                  </div>
                  <div id='registerStep25'>
                    <Progress value={step * 33.3} className='profgress2' />
                  </div>
                  {isChangePhone && (
                    <div id='registerStep26'>
                      <p className='title3'>Phone Number</p>
                      <div
                        className='phoneInputContainer'
                        style={{ textAlign: 'center' }}
                        id='registerStep27'
                      >
                        <div className='phoneInputBox' id='registerStep28'>
                          <Button
                            className='selectCountryButton'
                            onClick={this.handleButtonClick}
                            id='registerStep29'
                          >
                            <img
                              alt='country'
                              style={{ width: 35, height: 30, marginRight: 10 }}
                              src={this.state.countryFlag}
                            />
                            {/*this.state.countryCode
                          ? this.state.countryCode
                        : 'Select Code'*/}
                            <img
                              style={{
                                transform: this.state.open
                                  ? 'rotateZ(180deg)'
                                  : '',
                              }}
                              src={downWhiteArrow}
                              alt='down'
                            />
                          </Button>
                          <input
                            className='phoneInput'
                            type='text'
                            placeholder='Phone Number *'
                            value={phoneNumber}
                            pattern='[0-9]*'
                            // maxLength="10"
                            onChange={(evt) => {
                              const phoneNumber = evt.target.validity.valid
                                ? evt.target.value
                                : this.state.phoneNumber;
                              this.setState(
                                {
                                  phoneNumber,
                                },
                                () => {
                                  this.checkPhoneValidation();
                                }
                              );
                            }}
                            id='registerStep2searchInput'
                          />
                        </div>
                      </div>

                      {this.state.open && (
                        <div
                          className='countryListDropdown'
                          id='registerStep29Step'
                        >
                          <div
                            className='searchInput'
                            style={{
                              height: 40,
                              background: '#17171D',
                              paddingTop: 10,
                            }}
                          >
                            <input
                              className='searchInputField'
                              type='text'
                              placeholder='Search...'
                              id='registerStep2searchInput'
                              onChange={(e) =>
                                this.setState(
                                  {
                                    filterCountry: e.target.value,
                                  },
                                  () => {
                                    // console.log(this.state.filterCountry)
                                  }
                                )
                              }
                            />
                          </div>
                          <div className='countryListDropdownScroller'>
                            {filteCountryData &&
                              filteCountryData.map((item, i) => (
                                <div
                                  key={i}
                                  onClick={() => {
                                    let countryCode = item.dial_code
                                      .split(' ')
                                      .join('');
                                    this.setState(
                                      {
                                        countryCode: countryCode,
                                        countryFlag: item.countryFlag,
                                        open: false,
                                        filterCountry: '',
                                      },
                                      () => {
                                        this.checkPhoneValidation();
                                      }
                                    );
                                  }}
                                >
                                  <img
                                    alt='countryFlag'
                                    style={{ width: 35, height: 30 }}
                                    src={item.countryFlag}
                                  />

                                  <span
                                    style={{ fontSize: 16, marginLeft: 10 }}
                                  >
                                    {item.dial_code}
                                  </span>
                                  <span style={{ fontSize: 16, marginLeft: 5 }}>
                                    {item.name}
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                      {!countryCode && isSubmit && (
                        <div
                          className='redBoxErrorMsg center'
                          style={{ padding: 10 }}
                          id='registerStep2Error'
                        >
                          {authErrorMessage(
                            authValidation.PROFILE_PHONE_COUNTRYCODE
                          )}
                        </div>
                      )}
                      {!phoneNumber && isSubmit && (
                        <div
                          className='redBoxErrorMsg center'
                          style={{ padding: 10 }}
                        >
                          {authErrorMessage(authValidation.PROFILE_PHONENUMBER)}
                        </div>
                      )}
                      {/* {phoneNumber &&
                    isSubmit &&
                    (phoneNumber.length <= 7 || phoneNumber.length > 10) &&
                    <div
                      className="redBoxErrorMsg center"
                      style={{padding: 10}}
                    >

                      {authErrorMessage (
                        authValidation.PROFILE_PHONENUMBER_FORMAT
                      )}

                    </div>} */}

                      {phoneNumber &&
                        isSubmit &&
                        this.state.mobileCheckvalidFormat && (
                          <div
                            className='redBoxErrorMsg center'
                            style={{ padding: 10 }}
                          >
                            {authErrorMessage(
                              authValidation.PROFILE_PHONENUMBER_FORMAT
                            )}
                          </div>
                        )}

                      <div
                        id='registerStep2Bottom'
                        style={{
                          textAlign: 'center',
                          marginBottom: '10px',
                          width: '100%',
                        }}
                      >
                        <Button
                          id='registerStep2NExt'
                          // style={{cursor: this.state.captchaToken ? '' : 'unset'}}
                          className='stepnextbu'
                          onClick={() => this.doUpdatePhone()}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {!isChangePhone && (
                    <div id='registerStep2ChangePhone'>
                      <p className='title3' id='registerStep2ChangeTitle'>
                        We'll send an SMS with a 6-digit OTP code to{' '}
                        {this.state.countryCode}
                        {this.state.phoneNumber}. Please check the reCAPTCHA
                        below to proceed.
                      </p>

                      {this.state.captchaToken && (
                        <div className='opt-mobile-container'>
                          <div className='otpMobileEllipse'>
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}
                              {this.state.time.s}
                            </span>{' '}
                            s
                          </div>
                          <input
                            className='step1inpu'
                            type='text'
                            placeholder='Enter 6 digit code'
                            value={otpValue}
                            onChange={(e) =>
                              this.setState({
                                otpValue: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}

                      {this.state.time.s === 0 && this.state.captchaToken && (
                        <div style={{ width: '100%' }}>
                          <Button
                            className='stepnextbu'
                            onClick={() => {
                              this.timer = 0;
                              this.setState(
                                {
                                  captchaToken: '',
                                },
                                () => {
                                  // this.signInWithPhone();
                                  this.setState(
                                    {
                                      step: null,
                                    },
                                    () => {
                                      this.setState({
                                        step: 2,
                                      });
                                    }
                                  );
                                }
                              );

                              // this.timer = 0;
                              // this.doCallSendOTP();
                              // this.onSubmit ();
                            }}
                            id='registerStep2Resend'
                          >
                            Resend OTP
                          </Button>
                        </div>
                      )}
                      <div>
                        {!otpValue && isSubmit && (
                          <div
                            id='registerStep2authValidation'
                            className='redBoxErrorMsg'
                            style={{ padding: 10 }}
                          >
                            {authErrorMessage(
                              authValidation.PROFILE_PHONENUMBER_OTP
                            )}
                          </div>
                        )}
                        {this.state.confirmOTPError && (
                          <div
                            id='registerStep2confirmOTPError'
                            className='redBoxErrorMsg'
                          >
                            {this.state.confirmOTPError}
                          </div>
                        )}
                      </div>

                      <VerifyCaptcha tokenExpired={this.tokenExpired} />
                      {/*<div
                      style={{
                        textAlign: 'center',
                        paddingLeft: 35,
                        marginTop: 15,
                      }}
                    >
                      <div id="phoneVerify" />
                      
                    </div>
                    */}
                      {this.state.time.s > 0 && (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <Button
                            style={{
                              cursor: this.state.captchaToken ? '' : 'unset',
                            }}
                            disabled={!this.state.captchaToken}
                            className='stepnextbu'
                            onClick={() => this.doConfirmOTP()}
                            id='registerStep2stepnextbu'
                          >
                            Next
                          </Button>
                        </div>
                      )}
                      {!this.state.captchaToken && (
                        <div style={{ textAlign: 'center' }}>
                          <p
                            className='changePhoneText'
                            onClick={() =>
                              this.setState({
                                isChangePhone: true,
                              })
                            }
                          >
                            Change Phone Number
                          </p>
                        </div>
                      )}

                      {!this.state.captchaToken && (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <p className='cantchangePhoneText'>
                            After robot can't be change mobile number you have
                            to wait for 60 seconds to change the mobile number
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {this.state.step === 3 && (
              <div id='registerStep3'>
                <div id='registerStep31'>
                  <img src={logo} alt='logo' className='logo' />
                </div>
                <Form onSubmit={this.onSubmit}>
                  <div className='signmaindiv' id='registerStep32'>
                    {/*showingAlert &&
                    <Alert color="danger" onLoad={this.timer}>
                      {error.message}
                  </Alert>*/}
                    <div className='setupacc' id='registerStep33'>
                      Setup Account
                    </div>
                    <div id='registerStep34'>
                      <Progress value={step * 33.3} className='profgress' />
                    </div>
                    <div className='insertpersod' id='registerStep35'>
                      Insert your{' '}
                      <span style={{ fontWeight: 'bold' }}>PERSONAL</span>{' '}
                      DETAILS
                    </div>
                    <div
                      style={{
                        display: 'flex',

                        alignItems: 'center',
                      }}
                      id='registerStep36'
                    >
                      <div style={{ textAlign: 'center' }} id='registerStep37'>
                        {!profilePicture && (
                          <div>
                            <img
                              src={placeholderProfile}
                              className='placeholder'
                            />
                          </div>
                        )}
                        {profilePicture && (
                          <div>
                            <img
                              src={profilePicture}
                              className='placeholder'
                              style={{
                                width: 100,
                                height: 100,
                                borderRadius: 100,
                              }}
                            />
                          </div>
                        )}
                        {!profilePicture && isSubmit && (
                          <p className='validationerrorMessage'>
                            {authErrorMessage(authValidation.PROFILE_PICTURE)}
                          </p>
                        )}
                      </div>
                      <div
                        className='inlinePictureOrButton'
                        id='registerStep38'
                      >
                        <div style={{ marginLeft: 20 }} id='registerStep39'>
                          <div className='placetexj' id='registerStep39Profle'>
                            Display Profle Picture
                          </div>
                          <div
                            className='placetexj2'
                            id='registerStep39Profleupload'
                          >
                            Please upload a photo of type JPEG or PNG
                          </div>
                        </div>
                        <div id='registerStep39Proflefile'>
                          <label
                            className='uploasscxd'
                            id='registerStep39Profleuploasscxd'
                          >
                            <input
                              type='file'
                              name='profilePicture'
                              onChange={(e) => this.onChange(e)}
                              accept='image/*'
                            />
                            <i className='fa fa-cloud-upload' />
                            Upload
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className='signUpProfileBox'
                      id='registerStep39ProfileBox'
                    >
                      <div id='registerStep39ProfileTitle'>
                        <p className='title'>Title</p>

                        {/* <Dropdown
                          className='drinpu'
                          style={{ width: 90, textAlign: 'center' }}
                          value={title}
                          options={titleDropDopwn}
                          onChange={(e) => this.setState({ title: e.value })}
                          id='registerTitle'
                        /> */}
                         <SelectInput
                            items={titleDropDopwn}
                            value={title}
                            className='registerProfileTitleDropdown'
                            dropDownContainerClass='registerProfileTitleContainerDropdown'
                            onChange={value => {
                              this.setState({
                                title: value
                              })
                            }}
                          />

                        {!title && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 10 }}
                            id='registerTitle1'
                          >
                            {authErrorMessage(authValidation.PROFILE_TITLE)}
                          </p>
                        )}
                      </div>
                      <div id='registerFirstname'>
                        <p className='title' id='registerFirstname1'>
                          First Name
                        </p>
                        <input
                          className='nameinpu'
                          type='text'
                          // name="firstname"
                          // id="firstname"
                          // placeholder=" First Name*"
                          value={CapitalizeFirstLetter(firstName)}
                          onChange={(e) =>
                            this.setState(
                              byPropKey('firstName', e.target.value)
                            )
                          }
                          id='registerFirstnameInput'
                        />
                        {!firstName && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 20 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_FIRSTNAME)}
                          </p>
                        )}
                      </div>
                      <div className='register-lastname'>
                        <p className='title' id='registerLastname'>
                          Last Name
                        </p>
                        <input
                          className='nameinpu'
                          type='text'
                          // name="lastname"
                          // id="lastname"
                          // placeholder="Last Name"
                          value={CapitalizeFirstLetter(lastName)}
                          onChange={(e) =>
                            this.setState(byPropKey('lastName', e.target.value))
                          }
                          id='registerLAstnameInput'
                        />
                        {!lastName && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 20 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_LASTNAME)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='signUpProfileBoxDOBFields'>
                      <div
                        style={{ position: 'relative', flexGrow: '0.5' }}
                        ref={this.openDobDatepicker}
                      >
                        <p className='title'>Date of Birth</p>

                        <div
                          className='datePickerinput'
                          onClick={() =>
                            this.setState({
                              isOpenDobPicker: !isOpenDobPicker,
                            })
                          }
                        >
                          {this.state.dateOfbirth
                            ? moment(this.state.dateOfbirth).format(
                                'MMM DD, yyyy'
                              )
                            : 'MM/DD/YYYY'}
                        </div>

                        {isOpenDobPicker && (
                          <div style={{ position: 'absolute' }}>
                            <Calendar
                              // minDate={sendDate}
                              minDate={new Date('01/01/1900')}
                              maxDate={new Date()}
                              selectedDate={this.state.dateOfbirth}
                              onSubmit={(value) => {
                                this.setState({
                                  isOpenDobPicker: false,
                                });
                                if (value) {
                                  this.setState({
                                    dateOfbirth: value,
                                  });
                                }
                              }}
                            />
                          </div>
                        )}

                        {/*<DatePicker
                        className="datePickerinput"
                        maxDate={new Date ()}
                        placeholder="MM/DD/YYYY"
                        dateFormat="MMM dd, yyyy"
                        selected={this.state.dateOfbirth}
                        onChange={date => {
                          this.setState ({dateOfbirth: date});
                        }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        id="registerdatePicker"
                      />*/}
                        {!dateOfbirth && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 20 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_DOB)}
                          </p>
                        )}

                        {/*<p className="title">Preferred Display Name</p>
                      <input
                        className="nameinpu2"
                        type="text"
                        name="displayName"
                        id="displayName"
                        // placeholder="Preferred Display Name"
                        value={displayName}
                        onChange={e =>
                          this.setState (
                            byPropKey ('displayName', e.target.value)
                          )}
                      />
                      {!displayName &&
                        isSubmit &&
                        <p className="validationerrorMessage" style={{paddingLeft:20}}>
                        
                          {authErrorMessage(authValidation.PROFILE_DISPLAYNAME)}
                        </p>}
                      */}
                      </div>
                      <div>
                        <p className='title'>Gender</p>

                        {/* <Dropdown
                          className='drinpu'
                          style={{ width: 300 }}
                          value={gender}
                          options={genderDropDopwn}
                          onChange={(e) => this.setState({ gender: e.value })}
                          id='registergender'
                        /> */}
                         <SelectInput
                            items={genderDropDopwn}
                            value={gender}
                            className='registerProfileGenderDropdown'
                            dropDownContainerClass='registerProfileGenderContainerDropdown'
                            onChange={value => {
                              this.setState({
                                gender: value
                              })
                            }}
                          />


                        {!gender && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 10 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_GENDER)}
                          </p>
                        )}
                        {/*<p className="title">Preferred Search Handle</p>
                      <input
                        className="nameinpu2"
                        // type="text"
                        name="searchHandle"
                        id="searchHandle"
                        placeholder="@"
                        value={searchHandle}
                        onChange={e =>
                          this.setState (
                            byPropKey ('searchHandle', e.target.value)
                          )}
                      />
                      {!searchHandle &&
                        isSubmit &&
                        <p className="validationerrorMessage" style={{paddingLeft:20}}>
                          {authErrorMessage(authValidation.PROFILE_SEARCHHANDLE)}
                        
                      </p>}*/}
                      </div>
                    </div>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <Button
                        type='submit'
                        className='nextsignu'
                        onClick={this.onSubmit}
                        id='registernextsignu'
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {this.state.step === 4 && (
              <div id='registerStep4'>
                <div id='registerStep41' style={{ textAlign: 'center' }}>
                  <img src={logo} alt='logo' className='logo' />
                </div>
                <div id='registerStep42' className='step3maindi'>
                  <div className='setupacc2'>Setup Account</div>
                  <div id='registerStep43'>
                    <Progress value={step * 33.3} className='profgress2' />
                  </div>
                  <div id='registerStep44' className='successAccount'>
                    <img src={accountSuccess} alt='success' />

                    <div className='AccountCr' id='registerStep45'>
                      Account Created!
                    </div>

                    <div className='Thankyoumsg' id='registerStep46'>
                      <p> To log into your account, lets verify your email. </p>
                      <p
                        style={{
                          paddingTop: 20,
                        }}
                      >
                        We’ve sent an email with instructions to verify your
                        email address to <span>{this.state.emailAddress} </span>{' '}
                        Click the URL link in the email message within 24 hours.
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'center' }}
                    className='successAccountFooter'
                    id='registerStep46'
                  >
                    <Button
                      className='step3buttonl'
                      onClick={() => this.props.history.push(routes.SIGN_IN)}
                    >
                      Proceed to Login
                    </Button>

                    <div className='Thankyoumsg' id='registerStep47'>
                      <Button
                        className='resendConfirmation'
                        onClick={() => this.emailResend()}
                      >
                        <p>Resend confirmation email</p>
                      </Button>
                    </div>
                    <Button
                      className='startMeetingAuthButtons'
                      onClick={() =>
                        (window.location.href =
                          'http://connect.radiologex.com/')
                      }
                      data-rh='Start meeting'
                    >
                      Start a Meeting
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal
          size='sm'
          isOpen={isResetFormConfirmation}
          centered={true}
          toggle={() => {
            this.setState({
              isResetFormConfirmation: false,
            });
          }}
          // backdropClassName="contactModalBackdrops"
          id='signupConfirmResetForm'
        >
          <ModalBody
            className='signupResetConfirmationModal'
            id='signupConfirmResetForm1'
          >
            <div>
              <p className='confirmTitle' id='signupConfirmResetForm2'>
                Reset Registration Form?
              </p>
              <p className='confirmmsg' id='signupConfirmResetForm3'>
                Are you sure you want to go to previous step? It will reset all
                the data entered in previous steps.
              </p>
              <div className='buttonDiv'>
                <Button
                  className='buttons noButton'
                  onClick={() => {
                    this.setState({
                      isResetFormConfirmation: false,
                    });
                  }}
                  id='signupConfirmResetFormnoButton'
                >
                  No
                </Button>
                <Button
                  id='signupConfirmResetFormyesButton'
                  className='buttons yesButton'
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                    // this.props.resetResetPasswordReducer ();
                    // this.props.Loading (false);
                    // this.props.fetchStatusCode ();
                    // this.setState({
                    //   step:1,
                    //   isResetFormConfirmation: false,
                    //   emailAddress:'',
                    //   passwordOne:'',
                    //   passwordAgain:'',
                    //   firstName:'',
                    //   agree:false,
                    //   phoneNumber:'',
                    //   countryCode:'',
                    // })
                    // setTimeout (() => {
                    //   this.closeModal ();
                    // }, 2000);
                    // this.props.addUserContactFunc (this.state.sendRequestData);
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {loadedImage && (
          <ImageCrop
            image={loadedImage}
            onSubmitClick={(croppedImage) => {
              this.setState({
                loadedImage: '',
              });

              this.updateUserImage(croppedImage);
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
    profileUpdated: state.ContactsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      register,
      Loading,
      updateFirebaseToken,
      uploadUserProfile,
      DoRegisterModifyPersonalInfo,
      signOut,
      resendEmail,
      fetchStatusCode,
      sendOTP,
      verifyOTP,
      resetResetPasswordReducer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
