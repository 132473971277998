import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from 'reactstrap';

import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import searchIcon from '../../../images/icons/search.svg';
import PaymentDashBoard from '../dashboard/index';
import profilePlaceholder from '../../../images/profile_placeholder.png';

import questionIcon from '../../../images/pay/questionIcon.png';
import {getAllUserContactsFunc} from '../../../actions/contacts';
import {
  sendAmountTransaction,
  askFormoney,
} from '../../../actions/Paymentaction';

import * as routes from '../../../constants/routes';
import {EncryptFunction} from '../../../utility/encryption';

import PinInput from 'react-pin-input';
import  RightSideContainer from '../../../components/UI/rightSideContainer/rightSideContainer';
import {RDEE_PASS_PRASE } from '../../../services/servicesConfig';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import {notificationdataTimeFormat} from '../../../utility/convertTimeString';
import {priceWithDecimal} from '../../../utility/commonFunctions';
import {InputMask} from 'primereact/inputmask';
import './newTransaction.css';

class NewTransaction extends Component {
  constructor (props) {
    super (props);
    this.state = {
      searching: '',
      note: '',
      amount: '',
      isDetails: false,
      recipientDetail: '',
      isSendError: false,
      pinView: false,
      sendmoneyerror: '',
      newpin: '',
      moneySend: false,
      isTransactionStatus: '',
      invalidNumberValue:false
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    
    if (nextProps.payment.sendmoneyerror) {
      this.setState ({
        sendmoneyerror: nextProps.payment.sendmoneyerror,
      });
    }
    if (nextProps.payment.sendmoney) {
      this.setState ({
        moneySend: true,
      });
    }

    if (nextProps.payment.askSendMoney) {
      this.setState ({
        pinView: true,
        moneySend: true,
      });
    }
  }

  componentDidMount () {
    this.fetchUserContact ();

    if (this.props.match.params && this.props.match.params.status) {
      if (
        this.props.match.params.status === 'send' ||
        this.props.match.params.status === 'request'
      )
        this.setState ({
          isTransactionStatus: this.props.match.params.status,
        });
      // let sendInventoryRequest = {
      //   data: {
      //     docID: this.props.match.params.productId,
      //   },
    }
  }

  fetchUserContact () {
    this.props.getAllUserContactsFunc ({
      data: {
        searchString: this.state.searching,
      },
    });
  }
  selectRecipient (item) {

    if (item.walletAddress) {
      this.setState ({
        recipientDetail: item,
      });
    } else {
      this.setState ({
        recipientDetail: '',
      });
    }
  }
  goSend () {
    if (!this.state.amount || !this.state.note) {
      this.setState ({
        isSendError: true,
        invalidNumberValue:false
      });
      return;
    }

    const patternpass = /^(0|[1-9]\d*)(\.\d+)?$/;
    const result = patternpass.test (this.state.amount);

    if(!result || this.state.amount<=0){
      this.setState ({
        isSendError: true,
        invalidNumberValue:true
      });
      return;
    }


   

    this.setState ({
      isSendError: false,
      invalidNumberValue:false

    });
    

    if (this.state.isTransactionStatus === 'request') {
      let sendRequest = {
        address: this.state.recipientDetail.walletAddress,
        amount: Number(this.state.amount),
        passphrase: RDEE_PASS_PRASE,

        // passphrase: this.state.askpinValue,
      };
      this.props.askFormoney (sendRequest);
    } else {
      this.setState ({
        isSendError: false,
        pinView: true,
      });
    }
  }

  sendAmount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

    let hashKeyPIN = EncryptFunction (
      loginUser.emailAddress,
      this.state.newpin
    );
    
    const {recipientDetail} = this.state;

    let walletAddress = localStorage.getItem ('address');
    let sendRequest = {
      sender_address: walletAddress,
      recipient_address: this.state.recipientDetail.walletAddress,
      amount: Number(this.state.amount),
      passphrase: hashKeyPIN,
      notification_message:recipientDetail.title +" "+ loginUser.firstName  +" " + loginUser.lastName +" has sent you " +priceWithDecimal(this.state.amount) +" R-DEE credits for "+this.state.note,
      transaction_timestamp:new Date().getTime(),
      transfer_purposes: this.state.note,

    };


    let receiverData={
      subject:'R-DEE Credits Received' ,
      titleName: 'R-DEE Credits Received',
      body:  notificationdataTimeFormat(new Date()) + " " + recipientDetail.title +" "+ loginUser.firstName  +" " + loginUser.lastName +" has sent you " +priceWithDecimal(this.state.amount) +" R-DEE credits for "+this.state.note ,
      imageURL:loginUser.profilePictureURL,
      targetUID:recipientDetail.docID,
      sourceUID:loginUser.docID

    }

    this.props.sendAmountTransaction (sendRequest ,receiverData);
  }

  render () {
    const {contacts} = this.props;
    const {
      
      recipientDetail,
      pinView,
      isTransactionStatus,
      invalidNumberValue
    } = this.state;
    return (
      <div style={{display: 'flex'}} id="settleNewTransaction1">
        <div>
          <div>
            <Button
              className="floatR stepGoBackIcon"
              onClick={() => this.props.history.goBack ()}
              data-rh="Go back"
              id="settleNewTransaction2">
              <div>
                <img src={leftbackarrow} alt="backIcon" id="settleNewTransaction3"/>
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className="historycontaonermaindiv" id="settleNewTransaction4">
            <div className="newTransactionContainer" id="settleNewTransaction5">
              <div className="newTransactionTextMainDiv" id="settleNewTransaction6">
                <div className="newTransactionheaderDiv" id="settleNewTransaction7">
                  <div className="newTransactionTextDiv" id="settleNewTransaction8">
                    <p className="newTransactionText" id="settleNewTransaction9">
                      NEW SETTLE TRANSACTION
                    </p>
                  </div>
                  {!this.state.moneySend &&
                    <div className="sendFundText" id="settleNewTransaction10">
                      {isTransactionStatus} Funds
                    </div>}
                  {this.state.moneySend &&
                    <div className="sendFundText" id="settleNewTransaction11">
                      {isTransactionStatus === 'send'
                        ? 'Funds Sent! '
                        : 'Request Sent! '}
                    </div>}

                  <div className="newTransactionDotBoldDiv" id="settleNewTransaction12"/>
                </div>
                {!pinView &&
                  <div className="newTransactionMainContactDiv" id="settleNewTransaction13">
                    <div className="newTransactionContact" id="settleNewTransaction14">
                      <div className="selectFromContactText" id="settleNewTransaction15">
                        Select from Contacts
                        <img
                          src={questionIcon}
                          alt="icon"
                          style={{height: 20, marginLeft: 10}}
                          id="settleNewTransaction16"
                        />
                      </div>
                      <div className="contactSearchNewTransaction " id="settleNewTransaction17">
                        <img
                          src={searchIcon}
                          alt="Search"
                          className="searchContactIcon"
                          id="settleNewTransaction18"
                        />
                        <input
                          type="text"
                          className="searchInput"
                          placeholder={
                            'Search for ' +
                              (isTransactionStatus === 'send'
                                ? ''
                                : 'request') +
                              'recipient'
                          }
                          value={this.state.searching}
                          onChange={e =>
                            this.setState ({searching: e.target.value}, () => {
                              this.fetchUserContact ();
                            })}
                            id="settleNewTransaction19"
                        />
                      </div>
                      {contacts.map ((obj, i) => (
                        <div id={`settleNewTransactioncontacts${i}`}>
                          <div id={`settleNewTransactioncontactsborder${i}`} className="newTransactionDotBoldDiv" />
                          <div
                            className=""
                            onClick={() =>
                              this.selectRecipient (obj.contact.contactDetail)}
                          >
                            <div id={`settleNewTransactioncontactsNameBox${i}`} className="displayFlexNewTran nameNewTransactionDiv">
                              <img
                                src={
                                  obj &&
                                    obj.contact &&
                                    obj.contact.contactDetail &&
                                    obj.contact.contactDetail.profilePictureURL
                                    ? obj.contact.contactDetail
                                        .profilePictureURL
                                    : profilePlaceholder
                                }
                                className="profileIconNewTransaction"
                              />
                              <div className="mLeft10" id={`settleNewTransactioncontactsInfo${i}`}>
                                <p className="nameNewTransaction" id={`settleNewTransactioncontactsName${i}`}>

                                {obj &&
                                  obj.contact &&
                                  obj.contact.contactDetail?ShowNameAccordingToLength(obj.contact.contactDetail.firstName+' ' + obj.contact.contactDetail.lastName):''}

                                  

                                  {/*obj &&
                                    obj.contact &&
                                    obj.contact.contactDetail &&
                                    obj.contact.contactDetail.lastName
                                    ? obj.contact.contactDetail.lastName
                                  : ''*/}

                                </p>
                                <p className="designationNewTransaction" id={`settleNewTransactioncontactsposition${i}`}>
                                  {' '}
                                  {obj &&
                                    obj.contact &&
                                    obj.contact.contactDetail &&
                                    obj.contact.contactDetail.position
                                    ? obj.contact.contactDetail.position
                                    : ''}
                                </p>
                                <p className="instituteNewTransaction" id={`settleNewTransactioncontactsTransaction${i}`}>
                                  {' '}{' '}
                                  {obj &&
                                    obj.contact &&
                                    obj.contact.contactDetail &&
                                    obj.contact.contactDetail.professionalTitle
                                    ? obj.contact.contactDetail.professionalTitle
                                    : ''}
                                </p>
                              </div>
                            </div>

                          </div>  {' '}
                        </div>
                      ))}

                    </div>
                    {recipientDetail &&
                      <div className="newTransactionSendingBlock" id="settleNewTransaction20" >
                        {recipientDetail &&
                          <div>
                            <div>
                              <div className="sendingToText"  id="settleNewTransaction121">
                                {isTransactionStatus === 'send'
                                  ? 'Sending to :'
                                  : 'Requesting From :'}
                              </div>
                              <div style={{marginTop: 10}}>
                                <div className="displayFlexNewTran nameNewTransactionDiv"  id="settleNewTransaction22">
                                  <img
                                    src={
                                      recipientDetail.profilePictureURL
                                        ? recipientDetail.profilePictureURL
                                        : profilePlaceholder
                                    }
                                    className="profileIconNewTransaction"
                                    id="settleNewTransaction23"
                                  />
                                  <div className="mLeft10"  id="settleNewTransaction24">
                                    <p className="nameNewTransaction"  id="settleNewTransaction25">

                                     
                                        {recipientDetail?ShowNameAccordingToLength(recipientDetail.firstName+' ' + recipientDetail.lastName):''}
                                      {' '}
                                      {/*recipientDetail.lastName
                                        ? recipientDetail.lastName
                                      : ''*/}
                                    </p>
                                    <p className="designationNewTransaction"  id="settleNewTransaction26">
                                      {recipientDetail.position
                                        ? recipientDetail.position
                                        : ''}
                                    </p>
                                    <p className="instituteNewTransaction"  id="settleNewTransaction27">
                                      {' '}
                                      {recipientDetail.firmName
                                        ? recipientDetail.firmName
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mTop20"  id="settleNewTransaction28">
                              <div className="sendingToText"  id="settleNewTransaction29">
                                How much?
                              </div>
                              <div  id="settleNewTransaction30">
                                <p className="enterAmountText" id="settleNewTransaction31">Enter amount:</p>
                                {<input
                                  type="text"
                                  className="enterAmountInputType"
                                  placeholder="Enter amount"
                                  value={this.state.amount}
                                  // pattern="[0-9]*"
                                  pattern="^\d*(\.\d{0,2})?$"
                                  onChange={evt => {
                                    const amount = evt.target.validity.valid
                                      ? evt.target.value
                                      : this.state.amount;
                                    this.setState ({
                                      amount,
                                    });
                                  }}
                                  id="settleNewTransaction32"
                                />}
                               
                                {this.state.isSendError && !this.state.amount  &&
                                  <p className="enterAmountError" id="settleNewTransaction33">
                                    Please enter amount *
                                  </p>}
                                  {this.state.isSendError && invalidNumberValue  &&
                                    <p className="enterAmountError" id="settleNewTransaction34">
                                      Please enter valid amount *
                                    </p>}

                                  
                              </div>
                            </div>
                            <div className="mTop20" id="settleNewTransaction35">
                              <div className="sendingToText" id="settleNewTransaction36">
                                Purpose of Transaction
                              </div>
                              <div>
                                <p className="RecipientText" id="settleNewTransaction37">
                                  Recipient will see this note
                                </p>
                                <textarea
                                  placeholder="Enter description"
                                  name=""
                                  className="textArea"
                                  value={this.state.note}
                                  onChange={e =>
                                    this.setState ({note: e.target.value})}
                                    id="settleNewTransaction38"
                                />

                                {this.state.isSendError && !this.state.note &&
                                  <p className="enterAmountError" id="settleNewTransaction39">
                                  Purpose is required *
                                  </p>}
                              </div>
                            </div>
                            <div className="mTop20" id="settleNewTransaction40">
                              <Button
                                className="sendFundButton"
                                onClick={() => this.goSend ()}
                                data-rh="Send payment"
                                id="settleNewTransaction41"
                              >
                                {isTransactionStatus} Funds
                              </Button>
                            </div>
                          </div>}
                      </div>}

                  </div>}
                {pinView &&
                  <div className="newTransactionMainContactDiv" id="settleNewTransaction42">
                    <div className="newTransactionContact" id="settleNewTransaction43">
                      {!this.state.moneySend &&
                        <div className="sendingToText" id="settleNewTransaction44">
                          {isTransactionStatus === 'send'
                            ? 'Sending to :'
                            : 'Requesting From :'}
                        </div>}

                      {this.state.moneySend &&
                        <div className="sendingToTextGray" id="settleNewTransaction45">
                          {isTransactionStatus === 'send'
                            ? 'Funds sent to :'
                            : 'Request sent to :'}
                        </div>}
                      <div>
                        <div className="displayFlexNewTran nameNewTransactionDiv" id="settleNewTransaction46">
                          <img
                            src={
                              recipientDetail.profilePictureURL
                                ? recipientDetail.profilePictureURL
                                : profilePlaceholder
                            }
                            className="profileIconNewTransaction"
                            id="settleNewTransaction47"
                          />
                          <div className="mLeft10" id="settleNewTransaction48">
                            <p className="nameNewTransaction" id="settleNewTransaction49">
                              {' '}
                                {recipientDetail?ShowNameAccordingToLength(recipientDetail.firstName+' ' + recipientDetail.lastName):''}

                            </p>
                            <p className="designationNewTransaction" id="settleNewTransaction50"> 
                              {' '}
                              {recipientDetail.position
                                ? recipientDetail.position
                                : ''}
                            </p>
                            {
                              <p className="instituteNewTransaction" id="settleNewTransaction51">
                                {recipientDetail.firmName
                                  ? recipientDetail.firmName
                                  : ''}
                              </p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="sendingToText mTop20" id="settleNewTransaction52">
                        Amount: USD$ {this.state.amount? priceWithDecimal(this.state.amount):0} 
                      </div>
                      <div className="mTop20" id="settleNewTransaction53">
                        <div className="sendingToText"  id="settleNewTransaction54">
                          Purpose of Transaction
                        </div>
                        <div>
                          <p className="RecipientText"  id="settleNewTransaction55">{this.state.note}</p>

                        </div>
                        {this.state.moneySend &&
                          <div style={{marginTop: 25}}  id="settleNewTransaction56">
                            <Button
                              className="sendAnotherTransaction"
                              onClick={() => this.props.history.goBack ()}
                              data-rh="Another transaction"
                              id="settleNewTransaction57"
                            >
                              Send Another Transaction
                            </Button>
                            <Button
                              className="returnToDashboard"
                              onClick={() =>
                                this.props.history.push (
                                  routes.PROFILE_DASHBOARD
                                )}
                              id="settleNewTransaction58"
                            >
                              Return to Dashboard
                            </Button>

                          </div>}

                      </div>

                    </div>
                    {!this.state.moneySend &&
                      isTransactionStatus === 'send' &&
                      <div className="newTransactionSendingBlock"  id="settleNewTransaction59">
                        <div className="pinBox"  id="settleNewTransaction60">
                          <div className="enterPinTextDiv"  id="settleNewTransaction61">
                            <p className="enterPinText"  id="settleNewTransaction62">ENTER PIN</p>
                          </div>
                          <div className="textCenter mTop20" id="settleNewTransaction63">
                            <p className="enterConfirmPinText" id="settleNewTransaction64">
                              Enter your PIN to confirm sending money
                            </p>
                            <PinInput
                              length={4}
                              focus
                              secret
                              ref={p => (this.pin = p)}
                              type="numeric"
                              style={{
                                padding: '10px',
                                display: 'inline-flex',
                                width: 200,
                              }}
                              inputStyle={{
                                borderColor: 'transparent',
                                borderBottomColor: '#00D8A4',
                                borderBottomWidth: 2,
                                borderTopWidth: 0,
                                borderRadius: 0,
                                color: 'white',
                                fontSize: 30,
                              }}
                              inputFocusStyle={{borderBottomColor: '#4092B7'}}
                              // onChange={this.onChange}
                              onComplete={(value, index) => {
                                this.setState (
                                  {
                                    newpin: value,
                                  },
                                  () => {
                                    this.sendAmount ();
                                  }
                                );
                              }}
                              id="settleNewTransaction65"
                            />

                          </div>
                          {this.state.sendmoneyerror &&
                            <div
                              className="redBoxErrorMsg"
                              style={{padding: 10}}
                              id="settleNewTransaction66"
                            >
                              {this.state.sendmoneyerror
                                ? this.state.sendmoneyerror
                                : null}
                            </div>}

                        </div>

                      </div>}

                  </div>}
              </div>

            </div>
          </div>
        </div>
        <RightSideContainer>
          <PaymentDashBoard history={this.props.history} />
        </RightSideContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer.contacts,
    payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getAllUserContactsFunc,
      sendAmountTransaction,
      askFormoney,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (NewTransaction);
