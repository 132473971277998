import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Input } from 'reactstrap'
import StarRatingComponent from 'react-star-rating-component'
import './marketplaceSidemenu.css'
import * as routes from '../../../../constants/routes'
import productDetails from '../../../../images/marketPlaceBuyer/productDetails.png'
import nonradioPlaceholder from '../../../../images/marketPlaceBuyer/nonradioPlaceholder.png'

import Searchamrke from '../../../../images/marketPlaceBuyer/Searchamrke.png'
import markefilter from '../../../../images/marketPlaceBuyer/markefilter.png'
import marketfiltercloseimg from '../../../../images/icons/marketfiltercloseimg.png'
import {
  getrecentViewHistory,
  getMarketPlaceActionOrder,
  marketPlaceReviews,
  getProductListing,
  fetchProductManufacturer,
  getSearchProductListing
} from '../../../../actions/marketplaceaction'
import { resetEducationDetail } from '../../../../actions/Authaction'
import { ShowNameAccordingToLength } from '../../../../utility/stringFunctions'
import { priceWithDecimal } from '../../../../utility/commonFunctions'
import SelectInput from '../../../InputCustom/selectInput/selectDropdown'

import moment from 'moment'

import { dataTimeFormat } from '../../../../utility/convertTimeString'
class MarketPlaceSidemenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      recentViewsList: [],
      reviewList: '',
      latestActionList: [],
      marketFilterBox: false,
      browsedeviceType: '',
      browselocation: '',
      browsemanufacture: '',
      browseprice: '',
      productCategoryType: 'Radiology',
      priceItemCollection: [
        { label: '$0', value: '0' },
        { label: '$100', value: '100' },
        { label: '$1000', value: '1000' },
        { label: '$10000', value: '10000' },
        { label: '$50000', value: '50000' },
        { label: '$100000', value: '100000' },
        { label: '$200000', value: '200000' },
        { label: '$300000', value: '300000' },
        { label: '$400000', value: '400000' },
        { label: '$500000', value: '500000' },
        { label: '$600000', value: '600000' },
        { label: '$600000+', value: '600000+' }
      ],
      selectedMinIndex: 1,
      productCategoryTypeOptions: [],
      manufactureCollection: [],
      manufactureItemInfo: [],
      maxpriceValue: '',
      browseCategoryType: 'Radiology',
      browseManufactureItemInfo: [],
      selectedBrowseMinIndex: 1,
      browseMaxpriceValue: '',
      nonRadioCategoryList: [],
      deviceTypeOptions: [],
      countryOriginList: [],
      locationOptions: [],
      searchTextString: ''
    }
  }

  componentDidMount () {
    let sendRequest = {
      data: {}
    }
    this.props.resetEducationDetail()
    this.props.fetchProductManufacturer()

    this.props.getrecentViewHistory(sendRequest)

    let getReviewPayload = {
      data: {
        offset: 0,
        limit: 30
      }
    }
    this.props.marketPlaceReviews(getReviewPayload)

    let sendActionRequest = {
      data: {
        limit: 10,
        offset: 0,
        orderType: 'actionRequired'
      }
    }
    this.props.getMarketPlaceActionOrder(sendActionRequest)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.product && nextProps.product.productCategorisedValues) {
        const obj = nextProps.product.productCategorisedValues

        if (obj && obj['non-radiology']) {
          // console.log(obj['non-radiology']);
          let nonRadioItemValues = obj['non-radiology']
            ? obj['non-radiology']
            : ''
          if (nonRadioItemValues) {
            let countryOriginList = []
            let nonRadioCategoryList = []
            if (nonRadioItemValues.countryOfOrigin) {
              Object.entries(nonRadioItemValues.countryOfOrigin).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  countryOriginList.push({
                    label: value,
                    value: value
                  })
                }
              )
            }
            if (nonRadioItemValues.category) {
              Object.entries(nonRadioItemValues.category).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  nonRadioCategoryList.push({
                    label: value,
                    value: value
                  })
                }
              )
            }

            this.setState(
              {
                countryOriginList: countryOriginList,
                nonRadioCategoryList: nonRadioCategoryList
              },
              () => {
                // console.log(this.state.countryOriginList);
                // console.log(this.state.nonRadioItemConditions);
                // console.log(this.state.expirationProductOptions);
                // console.log(this.state.nonRadioCategoryList);
              }
            )
          }
        }
        if (obj && obj.radiology) {
          let productManufactureOptions = []
          let deviceTypeOptions = []
          let locationOptions = []
          if (obj.radiology.manufacturer) {
            Object.entries(obj.radiology.manufacturer).forEach(
              ([key, value]) => {
                // console.log(key, value);
                productManufactureOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }

          if (obj.radiology.deviceType) {
            Object.entries(obj.radiology.deviceType).forEach(([key, value]) => {
              // console.log(key, value);
              deviceTypeOptions.push({
                label: value,
                value: value
              })
            })
          }

          if (obj.radiology.location) {
            Object.entries(obj.radiology.location).forEach(([key, value]) => {
              // console.log(key, value);
              locationOptions.push({
                label: value,
                value: value
              })
            })
          }
          this.setState(
            {
              browseManufactureItemInfo: productManufactureOptions,
              manufactureItemInfo: productManufactureOptions,
              deviceTypeOptions: deviceTypeOptions,
              locationOptions: locationOptions
            },
            () => {}
          )
        }
      }
      if (nextProps.product && nextProps.product.manufactureItemValues) {
        const obj = nextProps.product.manufactureItemValues
        let productCategoryTypeOptions = []
        Object.keys(obj).map(key => {
          productCategoryTypeOptions.push({ label: key, value: key })
        })
        this.setState({
          productCategoryTypeOptions: productCategoryTypeOptions,
          manufactureCollection: obj
        })
      }
      if (nextProps.product && nextProps.product.recentViews) {
        this.setState({
          recentViewsList: nextProps.product.recentViews
        })
      }
      if (nextProps.product && nextProps.product.usersReview) {
        this.setState(
          {
            reviewList: nextProps.product.usersReview
          },
          () => {}
        )
      }
      if (nextProps.product && nextProps.product.placeActionOrderList) {
        this.setState({
          latestActionList: nextProps.product.placeActionOrderList
        })
      }
    }
  }
  // placeActionOrderList

  openFilter = () => {
    this.setState({
      marketFilterBox: true
    })
  }
  closeFilter = () => {
    this.setState({
      marketFilterBox: false
    })
  }
  doBrowseSearch () {
    const {
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      browseCategoryType,
      browseMaxpriceValue
    } = this.state
    const sendRequest = {
      data: {
        productCategoryType: browseCategoryType,
        productType: browsedeviceType,
        manufacturer: browsemanufacture,
        location: browselocation,
        minPrice: browseprice,
        maxPrice: browseMaxpriceValue
      }
    }

    // this.props.getProductListing (sendRequest);
    this.props.history.push(routes.BROWSE_PRODUCT, {
      searchObject: sendRequest.data
    })
  }

  fetchMarketPlaceList () {
    // console.log(this.state);

    const {
      productCategoryType,
      deviceTypeValue,
      manufactureValue,
      locationValue,
      priceValue,
      maxpriceValue,
      searchTextString
    } = this.state
    let sendRequest = {}
    if (
      productCategoryType &&
      productCategoryType.toLowerCase() === 'radiology'
    ) {
      sendRequest = {
        data: {
          productCategoryType: productCategoryType,
          productType: deviceTypeValue ? [deviceTypeValue] : '',
          manufacturer: manufactureValue ? [manufactureValue] : '',
          location: locationValue,
          minPrice: priceValue,
          maxPrice: maxpriceValue,
          searchString: this.state.searchTextString,
          crossSearch: !productCategoryType
        }
      }
    } else {
      sendRequest = {
        data: {
          crossSearch: !productCategoryType,
          productCategoryType: productCategoryType,
          category: deviceTypeValue ? [deviceTypeValue] : '',
          countryOriginValue: locationValue,
          minPrice: priceValue,
          maxPrice: maxpriceValue,
          searchString: this.state.searchTextString
        }
      }
    }
    this.props.getSearchProductListing(sendRequest)
    this.props.history.push(
      `${routes.MARKETPLACE}?${searchTextString || 'search'}`
    )
  }
  render () {
    const {
      recentViewsList,
      reviewList,
      latestActionList,
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      selectedMinIndex,
      browseCategoryType
    } = this.state

    return (
      <div>
        <div className='sidemenumaindiv' id='marketplaceSearchsidemenumaindiv1'>
          {latestActionList && latestActionList.length > 0 && (
            <div
              className='quiringActionsedemn'
              id='marketplaceSearchsidemenumaindiv2'
            >
              Orders Requiring Action
            </div>
          )}
          {latestActionList &&
            latestActionList.length > 0 &&
            latestActionList.map((item, i) => (
              <div
                key={i}
                className='ordersrequiresedmen'
                onClick={() => {
                  // this.props.history.push (routes.PRODUCT_DETAIL + `/${item.docID}`)

                  window.location.href =
                    routes.PLACED_ORDERS_DETAILS + `/${item.docID}`
                }}
                id={`marketplaceSearchsidemenumaindiv1${i}`}
                style={{ display: 'flex', padding: '10px' }}
              >
                <div>
                  <img
                    alt='productDetails'
                    src={
                      item.productDetails[0] &&
                      item.productDetails[0] &&
                      item.productDetails[0].productCategoryType
                        ? item &&
                          item.productDetails &&
                          item.productDetails[0] &&
                          item.productDetails[0].productImageList &&
                          item.productDetails[0].productImageList[0]
                          ? item.productDetails[0].productImageList[0]
                          : item.productDetails[0].productCategoryType ===
                            'Radiology'
                            ? productDetails
                            : nonradioPlaceholder
                        : productDetails
                    }
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        item &&
                        item.productDetails &&
                        item.productDetails[0] &&
                        item.productDetails[0] &&
                        item.productDetails[0].productCategoryType
                          ? item.productDetails[0].productCategoryType ===
                            'Radiology'
                            ? productDetails
                            : nonradioPlaceholder
                          : productDetails
                    }}
                    style={{ height: 90, width: 90, borderRadius: 10 }}
                    id={`marketplaceSearchsidemenumaindivimg${i}`}
                  />
                </div>
                <div
                  style={{ display: 'flex', padding: '0 10px' }}
                  id={`marketplaceSearchsidemenumaindivBox${i}`}
                >
                  <div>
                    <p
                      className='Siemenssedmekjpr'
                      id={`marketplaceSearchsidemenumaSiemenssedmekjpr${i}`}
                    >
                      {item &&
                      item.productDetails &&
                      item.productDetails[0] &&
                      item.productDetails[0]
                        ? item &&
                          item.productDetails[0].productCategoryType &&
                          item.productDetails[0].productCategoryType ===
                            'Radiology'
                          ? item.productDetails[0].deviceSaleName
                          : item.productDetails[0] &&
                            item.productDetails[0].productName
                            ? item.productDetails[0].productName
                            : 'N/A'
                        : 'N/A'}
                    </p>
                    <p
                      className='OrderID10sefv'
                      id={`marketplaceSearchsidemenuOrderID10sefv${i}`}
                    >
                      Order ID: {item && item.orderID ? item.orderID : 'N/A'}
                    </p>
                    <p
                      className='OrderPlacecdcdfvfd'
                      id={`marketplaceSearchsidemenuOrderPlace${i}`}
                    >
                      Quantity:
                      {item && item.productQuantity ? item.productQuantity : 0}
                    </p>
                    <p
                      className='OrderPlacecdcdfvfd'
                      id={`marketplaceSearchsidemenuOrderPlacecd${i}`}
                    >
                      Order Placed:
                      {item && item.orderDate
                        ? dataTimeFormat(item.orderDate)
                        : 'N/A'}
                    </p>
                  </div>
                  <div style={{ width: '55%' }}>
                    <div
                      className='Awaitinsefkrnf'
                      id={`marketplaceSearchsidemenuAwaitinsefkrnf${i}`}
                    >
                      <p className='greenlightsedemnei' />
                      {item && item.orderStatus === 'seller confirmed'
                        ? 'Awaiting Payment'
                        : ''}
                      {item && item.orderStatus === 'shipped'
                        ? 'Confirm Delivery'
                        : ''}
                      {item &&
                      (item.orderStatus === 'completed' ||
                        item.orderStatus === 'delivered')
                        ? 'Leave Feedback'
                        : ''}
                    </div>
                    <div
                      className='US7scsdvf500'
                      id={`marketplaceSearchsidemenuUSDPrice${i}`}
                    >
                      USD${' '}
                      {item && item.totalOrderPrice
                        ? priceWithDecimal(item.totalOrderPrice)
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {this.props.isSearch && (
            <div>
              <div
                className='SearchMarksidemeni'
                id='marketplaceSearchsidemenusearch'
              >
                Search Marketplace
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 19
                }}
                id='marketplaceSearchsidemenusearch1'
              >
                <div
                  className='searchmenuinptsidemn'
                  id='marketplaceSearchsidemenusearch2'
                >
                  <img
                    alt='Searchamrke'
                    src={Searchamrke}
                    style={{ height: 28, width: 28, marginLeft: 10 }}
                    id='marketplaceSearchsidemenusearch3'
                  />
                  <Input
                    className='tersearchsidemneu'
                    type='text'
                    placeholder='Enter search term'
                    id='marketplaceSearchsidemenusearch4'
                    value={this.state.searchTextString}
                    onChange={e =>
                      this.setState({ searchTextString: e.target.value })
                    }
                  />
                </div>
                {!this.state.marketFilterBox && (
                  <div className='filtclesedemni' onClick={this.openFilter}>
                    <img alt='markefilter' src={markefilter} />
                  </div>
                )}
              </div>

              <div
                className='marketFilterWrapper'
                id='marketplaceSearchsidemenusearch5'
              >
                {this.state.marketFilterBox && (
                  <div
                    className='marketficlosecircleMenu'
                    onClick={this.closeFilter}
                    style={{ margin: 'auto' }}
                    id='marketplaceSearchsidemenusearch6'
                  >
                    <img
                      alt='marketfiltercloseimg'
                      src={marketfiltercloseimg}
                    />
                  </div>
                )}
                {this.state.marketFilterBox && (
                  <div
                    className='marketfilterbox'
                    id='marketplaceSearchsidemenusearch7'
                  >
                    <p
                      className='dropdownlablemarket'
                      id='marketplaceSearchsidemenusearchcat8'
                    >
                      {' '}
                      Product Category
                    </p>
                    <SelectInput
                      items={this.state.productCategoryTypeOptions}
                      value={this.state.productCategoryType}
                      className='marketSearchFilterinputDropdown'
                      dropDownContainerClass='marketSearchFilterinputContainerDropdown'
                      onChange={value => {
                        this.setState({
                          productCategoryType: value
                        })
                      }}
                      placeholder='Product Category'
                    />
                    <p
                      className='dropdownlablemarket'
                      id='marketplaceSearchsidemenusearch8'
                    >
                      {' '}
                      {this.state.productCategoryType &&
                      this.state.productCategoryType === 'Radiology'
                        ? 'Product Type'
                        : 'Category'}
                    </p>

                    <SelectInput
                      items={
                        this.state.productCategoryType
                          ? this.state.productCategoryType === 'Radiology'
                            ? this.state.deviceTypeOptions
                            : this.state.nonRadioCategoryList
                          : []
                      }
                      value={this.state.deviceTypeValue}
                      className='marketSearchFilterinputDropdown'
                      dropDownContainerClass='marketSearchFilterinputContainerDropdown'
                      onChange={value => {
                        this.setState({
                          deviceTypeValue: value
                        })
                      }}
                      placeholder={
                        this.state.productCategoryType &&
                        this.state.productCategoryType === 'Radiology'
                          ? 'Product Type'
                          : 'Category'
                      }
                    />

                    {this.state.productCategoryType &&
                      this.state.productCategoryType === 'Radiology' && (
                      <div>
                        <p
                          className='dropdownlablemarket'
                          id='marketplaceSearchsidemenusearch10'
                        >
                          {' '}
                            Manufacturer
                        </p>

                        <SelectInput
                          items={this.state.manufactureItemInfo}
                          value={this.state.manufactureValue}
                          className='marketSearchFilterinputDropdown'
                          dropDownContainerClass='marketSearchFilterinputContainerDropdown'
                          onChange={value => {
                            this.setState({
                              manufactureValue: value
                            })
                          }}
                          placeholder='Manufacturer'
                        />
                      </div>
                    )}
                    {this.state.productCategoryType && (
                      <div>
                        <p
                          className='dropdownlablemarket'
                          id='marketplaceSearchsidemenusearch14'
                        >
                          {this.state.productCategoryType &&
                          this.state.productCategoryType === 'Radiology'
                            ? 'Location'
                            : 'Country Of Origin'}
                        </p>

                        <SelectInput
                          items={
                            this.state.productCategoryType &&
                            this.state.productCategoryType === 'Radiology'
                              ? this.state.locationOptions
                              : this.state.countryOriginList
                          }
                          value={this.state.locationValue}
                          className='marketSearchFilterinputDropdown'
                          dropDownContainerClass='marketSearchFilterinputContainerDropdown'
                          onChange={value => {
                            this.setState({
                              locationValue: value
                            })
                          }}
                          placeholder={this.state.productCategoryType &&
                          this.state.productCategoryType === 'Radiology'
                            ? 'Location'
                            : 'Country Of Origin'}
                        />
                      </div>
                    )}

                    <p
                      className='dropdownlablemarket'
                      id='marketplaceSearchsidemenusearch12'
                    >
                      {' '}
                      Price Range (Min)
                    </p>

                    <SelectInput
                      items={this.state.priceItemCollection.slice(0, 10)}
                      value={this.state.priceValue}
                      className='marketSearchFilterinputDropdown'
                      dropDownContainerClass='marketSearchFilterinputContainerDropdown'
                      onChange={value => {
                        this.setState({ priceValue: value }, () => {
                          var findItemindex = this.state.priceItemCollection
                            .map(img => {
                              return img.value
                            })
                            .indexOf(this.state.priceValue)
                          this.setState({
                            selectedMinIndex: findItemindex + 1
                          })
                        })
                      }}
                      placeholder='Price (Min)'
                    />

                    <p
                      className='dropdownlablemarket'
                      id='marketplaceSearchsidemenusearch12'
                    >
                      {' '}
                      Price Range (Max)
                    </p>

                    <SelectInput
                      items={this.state.priceItemCollection.slice(
                        selectedMinIndex,
                        20
                      )}
                      value={this.state.maxpriceValue}
                      className='marketSearchFilterinputDropdown'
                      dropDownContainerClass='marketSearchFilterinputContainerDropdown'
                      onChange={value => {
                        this.setState({ maxpriceValue: value })
                      }}
                      placeholder='Price (Max)'
                    />
                  </div>
                )}

                <div style={{ textAlign: 'right' }}>
                  <Button
                    id='marketplaceglobalSearchsidemenusearch'
                    data-rh='Search'
                    className='browsebuttondisdem'
                    onClick={() => this.fetchMarketPlaceList()}
                    disabled={!this.state.searchTextString}
                  >
                    Search{' '}
                  </Button>
                </div>
              </div>
              <div className='diveidersiddemenu' />
              <div
                className='browsemarkwsedmenu'
                id='marketplaceSearchsidemenusearch16'
              >
                Browse Marketplace
              </div>
              <div
                className='browseMarketplaceWrapper'
                id='marketplaceSearchsidemenusearch17'
              >
                <SelectInput
                  items={this.state.productCategoryTypeOptions}
                  value={browseCategoryType}
                  className='marketBrowseinputDropdown'
                  dropDownContainerClass='marketBrowseinputContainerDropdown'
                  onChange={value => {
                    this.setState({
                      browseCategoryType: value
                    })
                  }}
                  placeholder='Product Category'
                />

                {this.state.browseCategoryType && (
                  <SelectInput
                    items={
                      this.state.browseCategoryType
                        ? this.state.browseCategoryType === 'Radiology'
                          ? this.state.deviceTypeOptions
                          : this.state.nonRadioCategoryList
                        : []
                    }
                    value={browsedeviceType}
                    className='marketBrowseinputDropdown'
                    dropDownContainerClass='marketBrowseinputContainerDropdown'
                    onChange={value => {
                      this.setState({
                        browsedeviceType: value
                      })
                    }}
                    placeholder={
                      this.state.browseCategoryType === 'Radiology'
                        ? 'Device Type'
                        : 'Category'
                    }
                  />
                )}
              </div>
              <div
                className='browseMarketplaceWrapper'
                id='marketplaceSearchsidemenusearchss17'
              >
                {this.state.browseCategoryType &&
                  this.state.browseCategoryType === 'Radiology' && (
                  <SelectInput
                    items={this.state.browseManufactureItemInfo}
                    value={browsemanufacture}
                    className='marketBrowseinputDropdown'
                    dropDownContainerClass='marketBrowseinputContainerDropdown'
                    onChange={value => {
                      this.setState({
                        browsemanufacture: value
                      })
                    }}
                    placeholder='Manufacturer'
                  />
                )}

                {this.state.browseCategoryType && (
                  <SelectInput
                    items={
                      this.state.browseCategoryType &&
                      this.state.browseCategoryType === 'Radiology'
                        ? this.state.locationOptions
                        : this.state.countryOriginList
                    }
                    value={browselocation}
                    className='marketBrowseinputDropdown'
                    dropDownContainerClass='marketBrowseinputContainerDropdown'
                    onChange={value => {
                      this.setState({
                        browselocation: value
                      })
                    }}
                    placeholder={
                      this.state.browseCategoryType === 'Radiology'
                        ? 'Location'
                        : 'Country Of Origin'
                    }
                  />
                )}
              </div>
              <div
                className='browseMarketplaceWrapper'
                id='marketplaceSearchsidemenusearch20'
              >
                <SelectInput
                  items={this.state.priceItemCollection.slice(0, 10)}
                  value={browseprice}
                  className='marketBrowseinputDropdown'
                  dropDownContainerClass='marketBrowseinputContainerDropdown'
                  onChange={value => {
                    this.setState({ browseprice: value }, () => {
                      var findItemindex = this.state.priceItemCollection
                        .map(img => {
                          return img.value
                        })
                        .indexOf(this.state.browseprice)
                      this.setState({
                        selectedBrowseMinIndex: findItemindex + 1
                      })
                    })
                  }}
                  placeholder='Price Min'
                />
                <SelectInput
                  items={this.state.priceItemCollection.slice(
                    this.state.selectedBrowseMinIndex,
                    20
                  )}
                  value={this.state.browseMaxpriceValue}
                  className='marketBrowseinputDropdown'
                  dropDownContainerClass='marketBrowseinputContainerDropdown'
                  onChange={value => {
                    this.setState({ browseMaxpriceValue: value })
                  }}
                  placeholder='Price Max'
                />
              </div>
              <div
                className='browsebuttondisdemWrapper'
                id='marketplaceSearchsidemenusearch23'
              >
                <Button
                  id='marketplaceSearchsidemenusearch24'
                  data-rh='Browse'
                  className='browsebuttondisdem'
                  onClick={() => this.doBrowseSearch()}
                >
                  Browse{' '}
                </Button>
              </div>
            </div>
          )}

          {this.props.isSearch && <div className='diveidersiddemenu' />}
          {recentViewsList && recentViewsList.length > 0 && (
            <div id='marketplaceSearchsidemenusearch25'>
              <div
                className='RecentlyViewed'
                id='marketplaceSearchsidemenusearch26'
              >
                Recently Viewed
              </div>
              {recentViewsList &&
                recentViewsList.map((item, i) => (
                  <div
                    key={i}
                    className='detailsdivforside'
                    onClick={() => {
                      // this.props.history.push (routes.PRODUCT_DETAIL + `/${item.docID}`)

                      window.location.href =
                        routes.PRODUCT_DETAIL + `/${item.docID}`
                    }}
                    id={`marketplaceSearchsidemenusearchReview${i}`}
                  >
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <div>
                        <img
                          alt='productDetails'
                          src={
                            item && item.productCategoryType
                              ? item &&
                                item.productImageList &&
                                item.productImageList[0]
                                ? item.productImageList[0]
                                : item.productCategoryType === 'Radiology'
                                  ? productDetails
                                  : nonradioPlaceholder
                              : productDetails
                          }
                          onError={e => {
                            e.target.onerror = null
                            e.target.src =
                              item && item.productCategoryType
                                ? item.productCategoryType === 'Radiology'
                                  ? productDetails
                                  : nonradioPlaceholder
                                : productDetails
                          }}
                          style={{
                            height: 90,
                            width: 90,
                            borderRadius: 10,
                            marginRight: 38
                          }}
                          id={`marketplaceSearchsidemenusearchReviewimg${i}`}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p
                          className='Siemensside'
                          id={`marketplaceSearchsidemenusearchReviewProductname${i}`}
                        >
                          {item && item.productCategoryType !== 'Radiology'
                            ? item.productName
                            : item && item.deviceSaleName
                              ? item.deviceSaleName
                              : ''}
                        </p>
                        {item && item.productCategoryType === 'Radiology' && (
                          <p
                            className='DateoMaside'
                            id={`marketplaceSearchsidemenusearchReviewProType${i}`}
                          >
                            {' '}
                            Date of Manufacture :{' '}
                            {item && item.dateOfManufacture
                              ? moment(new Date(item.dateOfManufacture)).format(
                                'MM/DD/YYYY'
                              )
                              : 'N/A'}
                          </p>
                        )}
                        {item && item.productCategoryType !== 'Radiology' && (
                          <p
                            className='DateoMaside'
                            id={`marketplaceSearchsidemenusearchReviewProductType${i}`}
                          >
                            {' '}
                            Product Type : {item && item.productCategoryType}
                          </p>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                          id={`marketplaceSearchsidemenusearchReviewProductBox${i}`}
                        >
                          {item && item.productCategoryType === 'Radiology' && (
                            <p
                              className='locationSide'
                              id={`marketplaceSearchsidemenusearchReviewProductBoxlocal${i}`}
                            >
                              {item && item.location ? item.location : ''}
                            </p>
                          )}
                          {item && item.productCategoryType !== 'Radiology' && (
                            <p
                              className='locationSide'
                              id={`marketplaceSearchsidemenusearchReviewProductBoxloca${i}`}
                            >
                              {item && item.category ? item.category : ''}
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={{ alignSelf: 'center' }}>
                        <p
                          className='priceValueText'
                          id={`marketplaceSearchsidemenusearchReviewProductBoxUsd${i}`}
                        >
                          USD${' '}
                          {item && item.priceUSD
                            ? priceWithDecimal(item.priceUSD)
                            : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

              <div className='diveidersiddemenu' />
            </div>
          )}
          <div className='MyLatessidem'>My Latest Feedback</div>

          {reviewList &&
          reviewList.buyerReviews &&
          reviewList.buyerReviews.length > 0 ? (
              reviewList.buyerReviews.map((item, i) => (
                <div
                  key={i}
                  className='feedbacksidemenudiv'
                  style={{ marginTop: 10 }}
                  id={`marketplaceSearchsidemenusearchReviewListBox${i}`}
                >
                  <div style={{ alignSelf: 'center' }}>
                    <img
                      alt=''
                      src={
                        item && item.reviewerProfilePictureURL
                          ? item.reviewerProfilePictureURL
                          : productDetails
                      }
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = productDetails
                      }}
                      style={{
                        height: 50,
                        width: 50,
                        borderRadius: 50,
                        marginLeft: 10
                      }}
                      id={`marketplaceSearchsidemenusearchReviewListBoximg${i}`}
                    />
                  </div>
                  <div
                    className='descriptionFeedback'
                    id={`marketplaceSearchsidemenusearchReviewListBoxFeed${i}`}
                  >
                    <div
                      className='Beverlysidemen'
                      id={`marketplaceSearchsidemenusearchReviewListBoxReviewername${i}`}
                    >
                      {item && item.reviewerFirstName
                        ? ShowNameAccordingToLength(
                          item.reviewerFirstName + ' ' + item.reviewerLastName
                        )
                        : ''}

                      {/* item && item.reviewerLastName
                        ? item.reviewerLastName
                      : '' */}
                    </div>
                    <div
                      className='Ordersedeminr'
                      id={`marketplaceSearchsidemenusearchReviewListBoxOrderid${i}`}
                    >
                    Order #{item && item.orderID ? item.orderID : 'N/A'}
                    </div>
                  </div>
                  <div
                    className='starRating'
                    id={`marketplaceSearchsidemenusearchReviewListBoxorderRating${i}`}
                  >
                    <StarRatingComponent
                      id={`marketplaceSearchsidemenusearchReviewListBoxorderRatingContent${i}`}
                      name={'rating'}
                      starCount={5}
                      value={item && item.reviewScore ? item.reviewScore : 0}
                      emptyStarColor={'#E4E4E4'}
                      starColor={'#F5D028'}
                      style={{ fontSize: '80px', margin: 0 }}
                    //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className='nosellerFeedback'>No Feedback</div>
            )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getrecentViewHistory,
      getMarketPlaceActionOrder,
      marketPlaceReviews,
      getProductListing,
      resetEducationDetail,
      fetchProductManufacturer,
      getSearchProductListing
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketPlaceSidemenu)
