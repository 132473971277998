import { LOGOUT } from './Authreducer';

let initialState = {
    messenger:[],
};

export const UPDATE_MESSENGER = 'UPDATE_MESSENGER';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';


export default (state=initialState,action) => {
    switch (action.type) {
        case UPDATE_MESSENGER:
            return {...state, messenger:action.payload, deletechat:false};
        case DELETE_CONVERSATION:
            return {...state, deletechat:action.payload};
        case ADD_CONVERSATION:
            return {
                ...state,
                messenger: [...state.messenger, action.payload ],
                deletechat:false
            };
        case LOGOUT:
            return {...initialState};
        default:
            return state;
    }
};