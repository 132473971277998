import React, { Component } from 'react';
// Dependencies
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebase from '../config/firebase';
// Components
import { Button, Alert, Modal, ModalBody, Progress } from 'reactstrap';
// Utils
import { EncryptFunction } from '../utility/encryption';
// Routes
import * as routes from '../constants/routes';
// Actions
import {
  login,
  updateFirebaseToken,
  registerDeviceToken,
  resendEmail,
  resetResetPasswordReducer,
  checkEmailExist,
  fetchStatusCode,
  signOut,
  businessAccountsSetActiveAccount,
} from '../actions/Authaction';
import { Loading } from '../actions/LoadingAction';
// Constants
import { createNotification } from './../constants/notificationtoast';
import { authErrorMessage } from '../constants/localMessage/auth/authHelper';
import * as authValidation from '../constants/localMessage/auth/authHelper';
import { getBrowserName } from '../constants/helper';
import { messaging } from './../init-fcm';
// Icons MUST BE SVG!
import radiologexLogo from '../images/logo/radiologex-logo.svg';
import emailIcon from './../images/icons/email.svg';
import password from './../images/icons/password.svg';
import accountSuccess from './../images/icons/accountsuccess.svg';

class SignInForm extends Component {
  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      // email:"shubhamvishwakarma19+21@gmail.com",
      // password:"Shubham@123#",
      emailnew: '',
      token: '',
      modal: false,
      isSubmit: false,
      invalidEmailFormat: false,
      isVerification: false,
      agree: false,
      existEmail: false,
      isSubmitEmail: false,
      isSubmittedEmail: false,
      serverErrorMessage: '',
      accountStatus: '',
      accountStatusModal: false,
      trusonaVideourl: 'https://vimeo.com/463056900',
      trusonaGuideVideo: false,
      accountMessage: '',
      isConfirmBusiness:false,
      businessListing:[],
      selectedBusiness:'',
      ownBusinessId:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // I am commenting the code since it is clearing the local storage and is not persisting the data
  /*componentWillMount(){
  localStorage.clear();
}*/

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      console.log (nextProps.userinfo);
      if (
        nextProps &&
        nextProps.userinfo &&
        nextProps.userinfo.userExist &&
        nextProps.userinfo.userExist.Data
      ) {
        //  if(nextProps.userinfo.userExist.status){
        if (
          nextProps.userinfo.userExist.Data &&
          nextProps.userinfo.userExist.Data.emailRegistered
        ) {
          this.setState({
            existEmail: nextProps.userinfo.userExist.Data.emailRegistered,
            isSubmittedEmail: false,
            // serverErrorMessage:nextProps.userinfo.userExist.message
          });
        }
        if (
          nextProps.userinfo.userExist.Data &&
          nextProps.userinfo.userExist.Data.emailRegistered === false
        ) {
          this.setState({
            existEmail: false,
            isSubmittedEmail: true,
            serverErrorMessage: nextProps.userinfo.userExist.message,
          });
        }

        //  }
        //  if(nextProps.userinfo.userExist.status===false){
        //    console.log("rferfer")
        //   this.setState({
        //     existEmail:false,
        //     isSubmittedEmail: true,
        //     // serverErrorMessage:nextProps.userinfo.userExist.message
        //   })
        //  }
      }

      // if (nextProps && nextProps.userinfo && nextProps.userinfo.user) {
      //   if(nextProps.userinfo.user.Data && nextProps.userinfo.user.Data &&  nextProps.userinfo.user.Data.registrationStepsCompleted && nextProps.userinfo.user.Data.registrationStepsCompleted<3){
      //     if (nextProps.userinfo.user.status === true) {
      //     this.props.history.push(routes.PROFILE_COMPLETION,{userEmail:this.state.email});
      //     }else{

      //       // if (nextProps.userinfo.user.Data.emailVerified === false) {
      //       //   this.setState ({
      //       //     isVerification: true,
      //       //   });
      //       // }
      //     }

      //   }else{

      //   if (nextProps.userinfo.user.status === true) {
      //     // console.log (nextProps.userinfo.user);

      //     if (
      //       nextProps.userinfo.user &&
      //       nextProps.userinfo.user.Data &&
      //       nextProps.userinfo.user.Data.userDetails
      //     ) {
      //       // console.log(nextProps.userinfo.user.Data.userDetails[6]);
      //       if (nextProps.userinfo.user.Data.userDetails[6].registrationStepsCompleted && nextProps.userinfo.user.Data.userDetails[6].registrationStepsCompleted<3) {
      //         this.props.history.push(routes.PROFILE_COMPLETION,{userEmail:this.state.email});
      //       }else{

      //       if (nextProps.userinfo.user.Data.userDetails[0].personalDetails) {
      //         this.setState ({
      //           modal: false,
      //         });

      //         const checkVerified = nextProps.userinfo.user.Data
      //           .userDetails[6] &&
      //           nextProps.userinfo.user.Data.userDetails[6].emailVerified
      //           ? nextProps.userinfo.user.Data.userDetails[6].emailVerified
      //           : false;

      //         if (checkVerified) {
      //           localStorage.setItem (
      //             'loginuser',
      //             JSON.stringify (
      //               nextProps.userinfo.user.Data.userDetails[0].personalDetails
      //             )
      //           );
      //           if (
      //             nextProps.userinfo.user.Data.userDetails[0].personalDetails
      //               .walletAddress
      //           ) {
      //             localStorage.setItem (
      //               'address',
      //               nextProps.userinfo.user.Data.userDetails[0].personalDetails
      //                 .walletAddress
      //             );
      //           } else {
      //             localStorage.setItem ('address', '');
      //           }

      //           if(this.props.location &&this.props.location.state && this.props.location.state.lastlocation){
      //             this.props.history.push (this.props.location.state.lastlocation);
      //           }else{
      //             this.props.history.push (routes.PROFILE_DASHBOARD);
      //           }

      //         } else {
      //           this.setState ({
      //             isVerification: true,
      //           });
      //         }
      //       }
      //     }

      //     }
      //   } else {
      //     if (nextProps.userinfo.user && nextProps.userinfo.user.Data) {

      //       if (nextProps.userinfo.user.Data.emailVerified === false) {
      //         this.setState ({
      //           isVerification: true,
      //         });
      //       }else{

      //         if(nextProps.userinfo.user.Data.accountStatus){
      //           this.setState({
      //             accountStatus:nextProps.userinfo.user.Data.accountStatus,
      //             accountStatusModal:true
      //           })
      //         }
      //       }
      //     }
      //   }
      // }

      // }

      if (nextProps && nextProps.userinfo && nextProps.userinfo.user) {
        if (nextProps.userinfo.user && nextProps.userinfo.user.status) {
          localStorage.setItem(
            'loginuser',
            JSON.stringify(
              nextProps.userinfo.user.Data.userDetails[0].personalDetails
            )
          );
          if (
            nextProps.userinfo.user.Data.userDetails[0].personalDetails
              .walletAddress
          ) {
            localStorage.setItem(
              'address',
              nextProps.userinfo.user.Data.userDetails[0].personalDetails
                .walletAddress
            );
          } else {
            localStorage.setItem('address', '');
          }


          console.log (nextProps.userinfo);
          if(nextProps.userinfo.isbusinessProfile){
            // console.log(nextProps.userinfo.isbusinessProfile);
            this.setState({
              ownBusinessId:nextProps.userinfo.isbusinessProfile.docID
            },()=>{
                console.log(this.state.ownBusinessId);
            })
          }
          if(nextProps.userinfo.userbusinessListing && nextProps.userinfo.userbusinessListing.length>0){
              this.setState({
                businessListing:nextProps.userinfo.userbusinessListing,
                isConfirmBusiness:true
              })
          }
          if(nextProps.userinfo.userbusinessListing && nextProps.userinfo.userbusinessListing.length===0){
            console.log("redirect");
            this.redirectUserToDashboard(nextProps);
          }
          if(nextProps.userinfo.setNewBusiness && nextProps.userinfo.setNewBusiness){
            console.log("redirect1");
            this.redirectUserToDashboard(nextProps);
          }

          
         
        } else {
          if (
            nextProps.userinfo.user.Data &&
            nextProps.userinfo.user.Data.registrationStepsCompleted &&
            nextProps.userinfo.user.Data.registrationStepsCompleted === 3
          ) {
            if (nextProps.userinfo.user.Data.emailVerified === false) {
              this.setState({
                isVerification: true,
              });
            }
            if (nextProps.userinfo.user.Data.accountStatus) {
              // console.log(nextProps.userinfo.user);
              this.setState(
                {
                  accountStatus: nextProps.userinfo.user.Data.accountStatus,
                  accountStatusModal: true,
                  accountMessage: nextProps.userinfo.user.Data.accountStatusMsg,
                },
                () => {
                  // alert(this.state.accountMessage)
                }
              );
            }
          } else {
            this.props.history.push(routes.PROFILE_COMPLETION, {
              userEmail: this.state.email,
            });
          }
        }
      }
    }
  }

  redirectUserToDashboard(nextProps){
     // user redirect to dashboard start//
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.lastlocation
          ) {
            this.props.history.push(this.props.location.state.lastlocation);
          } else {
            if (nextProps.userinfo.user.Data.userDetails.length > 6) {
              if (
                nextProps.userinfo.user.Data.userDetails[6] &&
                nextProps.userinfo.user.Data.userDetails[6]
                  .seenWelcomeScreen === false
              ) {
                this.props.history.push(routes.WELCOME_SCREEN);
              } else {
                this.props.history.push(routes.PROFILE_DASHBOARD);
              }
            }
          }
           // user redirect to dashboard end//
  }

  async componentDidMount() {
    // console.log(this.props.location.state.lastlocation);
    // let history = this.props.location.state.history;
    // console.log(this.props.location.pathname);
    // history.push(this.props.location.pathname)
    // var lastLocation = this.props.history.entries[this.props.history.index - 1];
    // var lastUrl = lastLocation.pathname;
    // console.log(lastUrl);

    // alert("ee");
    // let businessListing=[];
    // for (let index = 0; index < 20; index++) {
    //   businessListing.push(index);
      
    // }
    // this.setState({
    //   businessListing:businessListing
    // })

    localStorage.clear();
    this.props.updateFirebaseToken('');
    this.props.resetResetPasswordReducer();
    this.props.fetchStatusCode();
    this.props.Loading(false);

    if (getBrowserName() !== 'Safari') {
      // alert(getBrowserName());

      messaging
        .requestPermission()
        .then(async () => {
          const token = await messaging.getToken();

          // const sendrequest={
          //   data: {
          //     deviceRegToken: token,
          //     devicePlatform: "web"
          //   }
          // }
          // this.props.registerDeviceToken(sendrequest)
        })
        .catch(function(err) {
          console.log('Unable to get permission to notify.', err);
        });
    }

    navigator.serviceWorker.addEventListener('message', (message) => {
      // this.props.history.push(routes.NOTIFICATION);
    });
    navigator.serviceWorker.addEventListener('notificationclick', (event) => {
      // alert(JSON.stringify(event));
      // this.props.history.push (routes.NOTIFICATION);
      // console.log (event);
    });
    this.props.fetchStatusCode();
  }

  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({
      modal: false,
      invalidEmailFormat: false,
      emailnew: '',
      isSubmit: false,
    });
  }
  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {}
    );
  }

  doCheckEmail() {
    this.setState(
      {
        isSubmitEmail: true,
        // password: hashKey
      },
      () => {
        const { email } = this.state;

        if (!email) {
          return false;
        }
        let sendRequest = {
          data: {
            emailAddress: email,
          },
        };
        this.props.checkEmailExist(sendRequest);
      }
    );
  }

  onSubmit = (e) => {
    this.setState(
      {
        isSubmit: true,
        // password: hashKey
      },
      () => {
        const { email, password } = this.state;

        if (!email) {
          return false;
        }

        let checkvalidation = email !== '' && password !== '';
        if (!checkvalidation) {
          return false;
        }
        this.props.Loading(true);

        let hashKey = EncryptFunction(this.state.email, this.state.password);

        firebase
          .auth()
          .signInWithEmailAndPassword(this.state.email, hashKey)
          .then((res) => {
            // console.log(res);
            if (res) {
              if (res.user && res.user.ya) {
                this.props.updateFirebaseToken(res.user.ya);
                const sendrequest = {
                  data: {
                    emailAddress: this.state.email,
                    password: hashKey,
                    useTrusona: false,
                  },
                };
                this.props.login(sendrequest);
              }

              // console.log("user at firebase",res)
              // return <Redirect to={routes.HOME} />;
            } else {
              console.log('user signed out or still need to sign in', res);
              this.props.Loading(false);

              // return <Redirect to={routes.SIGN_IN} />;
            }
          })
          .catch((e) => {
            console.log('error', e);
            createNotification('error', e.message);
            this.props.Loading(false);
          });
      }
    );
  };

  truSonaLogin() {
    const { email } = this.state;

    const sendrequest = {
      data: {
        // emailAddress: 'hitesh@gmail.com',
        // emailAddress:'forturate@gmail.com',
        // emailAddress: emailnew,
        emailAddress: email,
        password: '',
        useTrusona: true,
        loginSource: 'web',
      },
    };
    this.props.login(sendrequest);
  }

  emailResend = () => {
    let sendRequest = {
      data: {
        emailAddress: this.state.email,
      },
    };
    this.props.resendEmail(sendRequest);
  };
  onSelectBusiness(value){
    this.setState({
      selectedBusiness:value
    })
  }

  updateNewBusiness(){
    const {selectedBusiness }= this.state;
    let sendrequest={
      data:{
        businessAccount: this.state.selectedBusiness
      }
    }
    if(selectedBusiness ===this.state.ownBusinessId){
      this.props.history.push(routes.PROFILE_DASHBOARD);
    }else{
      this.props.businessAccountsSetActiveAccount(sendrequest);

    }
  }

  render() {
    const {
      emailnew,
      error,
      showingAlert,
      isSubmit,
      invalidEmailFormat,
      isVerification,
      existEmail,
      isSubmitEmail,
      isSubmittedEmail,
      accountStatus,
      accountStatusModal,
      trusonaGuideVideo,
      trusonaVideourl,
      isConfirmBusiness,
      businessListing,
      selectedBusiness
    } = this.state;

    return (
      <div
        id='loginScreen1'
        className='div-flex gradientColorPurpleBlue responsiveScroll'
      >
        <div id='loginScreen2' className='responsiveLoginForm'>
          <div>

             <Modal size='lg' isOpen={isConfirmBusiness} centered={true}>
              <ModalBody >
                <div id='businessconfirmModal'>
                    <div className='chooseBusinesslabel' >
                      Select which business you want to login :
                    </div>

                    <div className="businessSelectionBox">
                    {this.state.ownBusinessId && <label className="chooseBusinessBoxList" onClick={()=>this.onSelectBusiness(this.state.ownBusinessId)} > 
                      
                    
                      <div className="radioBusinessSelection ">
                        {selectedBusiness && selectedBusiness ===this.state.ownBusinessId &&<div className="checkmarkRadio" />}
                      </div>
                      
                        Own Business
                      </label>}
                      {this.state.ownBusinessId &&<div style={{textAlign:"center",marginBottom:5}}> OR</div>}
                      {businessListing && businessListing.length>0 && businessListing.map((item,i)=>(
                      <label className="chooseBusinessBoxList" key={i+1} onClick={()=>{
                        this.onSelectBusiness(item.docID);
                      }}> 
                      <div className="radioBusinessSelection ">
                      {selectedBusiness && selectedBusiness ===item.docID &&<div className="checkmarkRadio" />}
                      </div>
                      
                       {item.displayedBusinessName?item.displayedBusinessName:''}
                      </label>))}
                    </div>
                    <div style={{textAlign:'center'}}>
                    <Button
                      className='lologinbutt'
                      data-rh='Next'
                      disabled={selectedBusiness?false:true}
                      onClick={()=>{
                        this.updateNewBusiness();
                      }}
                    >
                      Next
                    </Button>

                    </div>
                </div>
              </ModalBody>
            </Modal>



            <Modal size='sm' isOpen={this.state.modal} centered={true}>
              <ModalBody className='passlessmod'>
                <div id='loginScreenpasslessModal'>
                  {/* <div>
                    <img
                      src={close_icon}
                      className="closeimg"
                      onClick={() => this.closeModal ()}
                    />
                  </div> */}
                  <p className='passworfless'>Passwordless Authentication</p>
                  <label className='modelLableText'>
                    Please enter your email ID:
                  </label>
                  <input
                    type='text'
                    name='emailnew'
                    // id="exampleEmail"
                    placeholder='E-mail ID'
                    value={emailnew}
                    onChange={this.handleChange}
                    className='passwordlesinpu'
                  />
                  {!emailnew && isSubmit && (
                    <div className='redBoxErrorMsg center'>
                      {authErrorMessage(authValidation.EMAIL_BLANK)}
                    </div>
                  )}
                  {invalidEmailFormat && isSubmit && (
                    <p className='redBoxErrorMsg center'>
                      {authErrorMessage(authValidation.EMAIL_FORMAT)}
                    </p>
                  )}

                  <div className='buttonDiv' id='loginScreenpassAction'>
                    <Button
                      className='passlessbacbu'
                      onClick={() => this.closeModal()}
                    >
                      Back
                    </Button>
                    <Button
                      type='submit'
                      onClick={() => this.truSonaLogin()}
                      value='submit'
                      className='passlesslogbu'
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal size='lg' isOpen={isVerification} centered={true}>
              <ModalBody>
                <div id='loginScreennotVerifyModal'>
                  <div
                    className='notverificationModel'
                    id='loginScreennotVerifyModal1'
                  >
                    <div
                      className='setupacc2'
                      id='loginScreennotVerifyModalSetup'
                    >
                      Setup Account
                    </div>
                    <div id='loginScreenprogressbar'>
                      <Progress value={100} className='profgress2' />
                    </div>
                    <div className='successAccount'>
                      <img src={accountSuccess} alt='success' />

                      <div className='AccountCr' id='loginScreenotverify'>
                        Account Not Verified!
                      </div>

                      <div className='Thankyoumsg' id='loginScreenThankyou'>
                        <p> To log into your account, </p>
                        <p>Lets verify your email.</p>

                        <p>
                          We’ve sent an email with instructions to verify your
                          email address to <span>{this.state.email} </span>{' '}
                          Click the URL link in the email message within 24
                          hours.
                        </p>
                      </div>
                    </div>
                    <div
                      style={{ textAlign: 'center' }}
                      className='successAccountFooter'
                      id='loginScreenotActionsbutton'
                    >
                      <Button
                        className='step3buttonl'
                        id='loginScreenotproceedButton'
                        onClick={() =>
                          this.setState(
                            {
                              isVerification: false,
                            },
                            () => {
                              this.props.resetResetPasswordReducer();
                            }
                          )
                        }
                      >
                        Proceed to Login
                      </Button>
                      <Button
                        className='step3buttonl'
                        id='loginScreenotproceedButtonmeeting'
                        onClick={() =>
                          (window.location.href =
                            'http://connect.radiologex.com/')
                        }
                        data-rh='Start meeting'
                      >
                        Start a Meeting
                      </Button>
                      <div className='Thankyoumsg'>
                        <Button
                          className='resendConfirmation'
                          onClick={() => this.emailResend()}
                          id='loginScreenotrensendButton'
                        >
                          <p>Resend confirmation email</p>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal size='lg' isOpen={accountStatusModal} centered={true}>
              <ModalBody>
                <div id='loginScreenverify'>
                  <div className='notverificationModel' id='loginScreenverify1'>
                    <div className='accountDisableTitle'>{accountStatus}</div>

                    <div className='successAccount' id='loginScreenverify2'>
                      <img src={accountSuccess} alt='success' />

                      <div
                        className='Thankyoumsg'
                        style={{ marginTop: 15, padding: 10 }}
                        id='loginScreenverify3'
                      >
                        {this.state.accountMessage &&
                          this.state.accountMessage.length > 0 && (
                            <p id='loginScreenverify4'>
                              {' '}
                              {/* We are verifying your details and your account will be activated automatically upon successful verification. */}
                              {this.state.accountMessage[0]}{' '}
                            </p>
                          )}
                        <div
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 20,
                          }}
                          id='loginScreenverify5'
                        >
                          {this.state.accountMessage &&
                          this.state.accountMessage.length > 1
                            ? this.state.accountMessage[1]
                            : ''}
                          {/* {accountStatus && accountStatus==='Blocked'&&<p id="blockmessage">
                          Your account is blocked. Please write to us at 
                          {' '}
                          {' '}
                          {' '}
                          <span> support@radiologex.com</span>.
                          </p>}
                          {accountStatus && accountStatus==='Deleted'&&<p id="blockmessage1">
                          Your account is deleted. Please write to us at 
                          {' '}
                          {' '}
                          {' '}
                          <span> support@radiologex.com </span>
                          </p>}
                          {accountStatus && accountStatus==='Pending Activation'&&<p id="blockmessage2">
                          Please write to us at
                          {' '}
                          {' '}
                          {' '}
                          <span> support@radiologex.com </span>
                          {' '}
                          if this is taking longer than expected. 
                          </p>*/}

                          {accountStatus &&
                            accountStatus === 'Inactive-blocked' && (
                              <p id='blockmessage3'>
                                Your account is inactive and is been blocked.
                                Please write to us at
                                <span> support@radiologex.com </span>.
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ textAlign: 'center' }}
                      className='successAccountFooter'
                      id='loginScreenverify6'
                    >
                      <Button
                        id='loginScreenverifyLogout'
                        className='step3buttonl'
                        onClick={() =>
                          this.setState(
                            {
                              accountStatus: '',
                              accountStatusModal: false,
                            },
                            () => {
                              this.props.signOut();
                            }
                          )
                        }
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              size='lg'
              isOpen={trusonaGuideVideo}
              centered={true}
              toggle={() => this.setState({ trusonaGuideVideo: false })}
              backdropClassName='trusonaBackdropVideo'
            >
              <ModalBody>
                <div id='loginScreenpasswordLessEnable'>
                  <div
                    className='passlessLoginModal'
                    id='loginScreenpasswordLessEnable1'
                  >
                    <div
                      className='accountDisableTitle'
                      id='loginScreenpasswordLessEnable2'
                    >
                      Enable Passwordless Authentication
                    </div>

                    <div
                      className='passlessLoginVideoBox'
                      id='loginScreenpasswordLessEnable3'
                    >
                      <ReactPlayer
                        id='loginScreenpasswordLessEnable4'
                        className='trusonalPlayerVideo'
                        url={trusonaVideourl}
                        light={true}
                        controls={true}
                        playing={true}
                        playsinline={true}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>

          <div id='loginScreen3'>
            <img alt='logo' src={radiologexLogo} className='logo' />
            <div id='loginScreen4' className='loginmaindiv'>
              <div id='loginScreenWelcome' className='WELCOME'>
                WELCOME! Please login to continue.
              </div>
              {showingAlert && (
                <Alert color='danger' onLoad={this.timer}>
                  {error.message}
                </Alert>
              )}
              <div id='loginScreenemail' className='email'>
                Email
              </div>
              <div
                id='loginScreenemailbox'
                className='relative'
                style={{ textAlign: 'center', marginBottom: '25px' }}
              >
                <img
                  src={emailIcon}
                  alt='Email icon'
                  className='signUpinputIcons'
                />
                <div id='loginScreenEmailInput' className='emalinppu'>
                  <input
                    id='emailinput'
                    placeholder='Email'
                    type='text'
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({
                        isSubmittedEmail: false,
                        email: e.target.value
                          ? e.target.value.toLowerCase()
                          : '',
                      });
                    }}
                    readOnly={existEmail}
                  />
                </div>
              </div>

              {isSubmittedEmail && (
                <p className='redBoxErrorMsg center'>
                  {this.state.serverErrorMessage}
                </p>
              )}

              {!this.state.email && isSubmitEmail && (
                <p className='redBoxErrorMsg center'>
                  {authErrorMessage(authValidation.EMAIL_BLANK)}
                </p>
              )}
              {existEmail && (
                <div className='password-container'>
                  <div id='loginScreenpass' className='password'>
                    Password{' '}
                  </div>
                  <div
                    id='loginScreenpassimage'
                    className='relative'
                    style={{ textAlign: 'center', marginBottom: '19px' }}
                  >
                    <img
                      src={password}
                      alt='password icon'
                      className='signUpinputIcons'
                    />
                    <div id='loginScreenpassinput' className='passwordinpu'>
                      <input
                        type='password'
                        name='password'
                        id='passwordinput'
                        placeholder='Password'
                        value={this.state.password}
                        onChange={(e) => {
                          this.setState({
                            password: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {!this.state.password && isSubmit && (
                    <p className='redBoxErrorMsg center'>
                      {authErrorMessage(authValidation.PASSWORD_BLANK)}
                    </p>
                  )}

                  <div id='loginScreenforgotpass' className='forgotPassw'>
                    <Link to={routes.PASSWORD_FORGET} data-rh='Forgot Password'>
                      Forgot Password
                    </Link>
                  </div>
                </div>
              )}
              <div style={{ textAlign: 'center' }} id='loginScreenActionButton'>
                {existEmail && (
                  <Button
                    id='loginScreenloginButton'
                    className='lologinbutt'
                    type='submit'
                    onClick={this.onSubmit}
                    value='submit'
                    data-rh='Login'
                    style={{ marginBottom: '19px' }}
                  >
                    Login
                  </Button>
                )}
                {!existEmail && (
                  <Button
                    id='loginScreennextButton'
                    className='lologinbutt'
                    onClick={() => this.doCheckEmail()}
                    value='submit'
                    data-rh='Next'
                    style={{ marginBottom: '30px' }}
                  >
                    Next
                  </Button>
                )}
              </div>
              {existEmail && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  {this.props.userinfo &&
                  this.props.userinfo.userExist &&
                  this.props.userinfo.userExist.status ? (
                    <Button
                      id='loginScreenpassless'
                      className='passwordless'
                      // onClick={() => this.openModal ()}
                      onClick={() => this.truSonaLogin()}
                      data-rh='Passwordless Authentication'
                    >
                      <span>Passwordless Authentication</span>
                    </Button>
                  ) : (
                    <Button
                      id='loginScreenpasslessEnable'
                      className='passwordless'
                      // onClick={() => this.openModal ()}
                      onClick={() => this.setState({ trusonaGuideVideo: true })}
                      data-rh='Passwordless Authentication'
                    >
                      <span style={{ opacity: 0.7 }}>
                        Enable Passwordless Authentication
                      </span>
                    </Button>
                  )}
                </div>
              )}
              <div className='Don’thave' id='loginScreendonthave'>
                Don’t have an account yet?{' '}
                <Link to={routes.SIGN_UP} data-rh='Register'>
                  {' '}
                  Register here{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
    // dialogData: state.ShowDialog.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
      updateFirebaseToken,
      registerDeviceToken,
      Loading,
      resendEmail,
      resetResetPasswordReducer,
      checkEmailExist,
      fetchStatusCode,
      signOut,
      businessAccountsSetActiveAccount
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
