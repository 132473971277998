import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import StarRatingComponent from 'react-star-rating-component';
import './sellerConsoleSidemenu.css';
import * as routes from '../../../../../constants/routes';
import createlistimg from '../../../../../images/icons/createlistimg.png';
import bulkProductIcon from '../../../../../images/icons/bulkProductIcon.png';

import productDetails from '../../../../../images/marketPlaceBuyer/productDetails.png';
import nonradioPlaceholder from '../../../../../images/marketPlaceBuyer/nonradioPlaceholder.png';

import Searchamrke from '../../../../../images/marketPlaceBuyer/Searchamrke.png';
import markefilter from '../../../../../images/marketPlaceBuyer/markefilter.png';
import downWhiteArrow from '../../../../../images/icons/downwhiteArrow.png';
import upArrow from '../../../../../images/icons/upArrowAddProduct.png';
import closeIcon from '../../../../../images/largecross.png';

import {
  getSellerActionOrder,
  marketPlaceReviews,
  fetchProductManufacturer,
} from '../../../../../actions/marketplaceaction';
import { resetEducationDetail } from '../../../../../actions/Authaction';
import { dataTimeFormat } from '../../../../../utility/convertTimeString';
import { ShowNameAccordingToLength } from '../../../../../utility/stringFunctions';
import { priceWithDecimal } from '../../../../../utility/commonFunctions';
import { createNotification } from '../../../../../constants/notificationtoast';
class SellerConsoleSidemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionOrderList: [],
      reviewList: '',
      isAddNew: false,
      showMenu: false,
      isCheckBusinesProfile: '',
      productCategoryType: [],
    };
  }
  goNext = (type) => {
    // console.log(type)
    let sendType = type;
    // if(type==='Non-Radiology Products'){
    //   sendType='Non-Radiology';
    // }
    // if(type==='Radiology Products'){
    //   sendType='Radiology';
    // }
    // console.log(routes.SELLER_ADD_PRODUCT+`/${sendType}/create`);
    this.props.history.push(routes.SELLER_ADD_PRODUCT + `/${sendType}/create`, {
      productType: sendType,
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      // console.log(nextProps);
      if (nextProps.product && nextProps.product.productCategories) {
        const obj = nextProps.product.productCategories;
        let productCategoryTypeOptions = [];
        Object.entries(obj).forEach(([key, value]) => {
          productCategoryTypeOptions.push({
            label: value.label,
            value: value.value,
          });
        });
        this.setState(
          {
            productCategoryType: productCategoryTypeOptions,
          },
          () => {
            // console.log(this.state.productCategoryType)
          }
        );
      }
      if (nextProps.product && nextProps.product.usersReview) {
        this.setState({
          reviewList: nextProps.product.usersReview,
        });
      }
      if (nextProps.product && nextProps.product.sellerActionOrders) {
        this.setState({
          actionOrderList: nextProps.product.sellerActionOrders,
        });
      }

      if (nextProps.userInfo && nextProps.userInfo.dashboardprofile) {
        if (
          nextProps.userInfo.dashboardprofile &&
          nextProps.userInfo.dashboardprofile.businessProfile
        ) {
          if (
            nextProps.userInfo.dashboardprofile.businessProfile &&
            nextProps.userInfo.dashboardprofile.businessProfile.businessType
          ) {
            this.setState({
              isCheckBusinesProfile:
                nextProps.userInfo.dashboardprofile.businessProfile
                  .businessType,
            });
          }
        }
      }
    }
  }
  componentDidMount() {
    this.props.resetEducationDetail();
    let activeSellerOrderRequest = {
      data: {
        orderType: 'actionRequired',
      },
    };

    this.props.getSellerActionOrder(activeSellerOrderRequest);

    let getReviewPayload = {
      data: {
        offset: 0,
        limit: 10,
      },
    };
    this.props.marketPlaceReviews(getReviewPayload);
    this.props.fetchProductManufacturer();
  }

  showErrorMessage() {
    createNotification(
      'warning',
      'Please update your profile by setting your business type as Vendor to add products to R-DEE'
    );
  }

  openAddnew() {
    if (this.state.isCheckBusinesProfile !== 'MARKETPLACE_VENDOR') {
      this.showErrorMessage();
      return;
    }
    this.setState({
      isAddNew: true,
    });
  }
  closeModal() {
    this.setState({
      isAddNew: false,
    });
  }
  openMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  }
  render() {
    const {
      actionOrderList,
      reviewList,
      showMenu,
      productCategoryType,
    } = this.state;
    return (
      <div>
        <div className="sellersidemenumaindiv" id="sellerConsoleSidemenu">
          <div style={{ textAlign: 'center' }} id="sellerConsoleSidemenu1">
            <Button
              className="createistinbuttong"
              onClick={() => this.openAddnew()}
              data-rh="Add new product"
              id="sellerConsoleSidemenu2"
            >
              <img
                alt="createlistimg"
                src={createlistimg}
                style={{ width: 27, height: 27, marginRight: 15 }}
                id="sellerConsoleSidemenu3"
              />
              <p>Create New Listing</p>
            </Button>
            <p className="orCenterText" id="sellerConsoleSidemenu4">
              {' '}
              -OR-{' '}
            </p>
            <Button
              className="bulkUploadbutton"
              onClick={() => {
                if (this.state.isCheckBusinesProfile !== 'MARKETPLACE_VENDOR') {
                  this.showErrorMessage();
                  return;
                }
                this.props.history.push(routes.BULKUPLOAD_ADD_PRODUCT);
              }}
              data-rh="Bulk upload"
              id="sellerConsoleSidemenu5"
            >
              <img
                alt="bulkupload"
                src={bulkProductIcon}
                style={{ width: 30, marginRight: 15 }}
                id="sellerConsoleSidemenu6"
              />
              Bulk Product Upload
            </Button>
          </div>

          <div className="sellersalesActionsedemn" id="sellerConsoleSidemenu7">
            Sales Requiring Action
          </div>
          {actionOrderList &&
            actionOrderList.length > 0 &&
            actionOrderList.map((item, i) => (
              <div
                className="sellersalesrequimen"
                key={i}
                onClick={() =>
                  // this.props.history.push (routes.SELLER_ORDER_DETAIL +
                  // `/${item.docID}`)}
                  (window.location.href =
                    routes.SELLER_ORDER_DETAIL + `/${item.docID}`)
                }
                id={`sellerConsoleSidemenuAction${i}`}
              >
                <div
                  id={`sellerConsoleSidemenuActionInner${i}`}
                  style={{ alignSelf: 'center' }}
                >
                  <img
                    alt="productimg"
                    src={
                      item.products[0] &&
                      item.products[0] &&
                      item.products[0].productCategoryType
                        ? item &&
                          item.products &&
                          item.products[0] &&
                          item.products[0].productImageList &&
                          item.products[0].productImageList[0]
                          ? item.products[0].productImageList[0]
                          : item.products[0].productCategoryType === 'Radiology'
                          ? productDetails
                          : nonradioPlaceholder
                        : productDetails
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        item &&
                        item.products &&
                        item.products[0] &&
                        item.products[0] &&
                        item.products[0].productCategoryType
                          ? item.products[0].productCategoryType === 'Radiology'
                            ? productDetails
                            : nonradioPlaceholder
                          : productDetails;
                    }}
                    style={{ height: 90, width: 90, borderRadius: 10 }}
                    id={`sellerConsoleSidemenuActionInnerImg${i}`}
                  />
                </div>
                <div
                  style={{ width: '100%' }}
                  id={`sellerConsoleSidemenuActionProductBox${i}`}
                >
                  <div>
                    <p
                      className="sellernssedmekjpr"
                      id={`sellerConsoleSidemenuActionProductBoxProdName${i}`}
                    >
                      {item &&
                      item.products &&
                      item.products[0] &&
                      item.products[0] &&
                      item.products[0].productCategoryType &&
                      item.products[0].productCategoryType === 'Radiology'
                        ? item.products[0].deviceSaleName
                        : (item &&
                          item.products &&
                          item.products[0] &&
                          item.products[0].productName
                        ? item.products[0].productName
                        : 'N/A')}
                    </p>
                    <p
                      className="sellerrID10sefv"
                      id={`sellerConsoleSidemenuActionProductBoxProdOrderID${i}`}
                    >
                      Order ID: {item && item.orderID ? item.orderID : 'N/A'}
                    </p>
                    <p
                      className="sellerrID10sefv"
                      id={`sellerConsoleSidemenuActionProductBoxProdQty${i}`}
                    >
                      Quantity:{' '}
                      {item && item.productQuantity ? item.productQuantity : 0}
                    </p>
                    <p
                      className="sellelacecdcdfvfd"
                      id={`sellerConsoleSidemenuActionProductBoxPlaceOrder${i}`}
                    >
                      Order Placed:{' '}
                      {item && item.orderDate
                        ? dataTimeFormat(item.orderDate)
                        : 'N/A'}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                    }}
                    id={`sellerConsoleSidemenuActionProductBoxOrderStatus${i}`}
                  >
                    <div
                      className="sellerAwinsefkrnf"
                      id={`sellerConsoleSidemenuActionProductBoxOrderStatusBox${i}`}
                    >
                      <p
                        className="sellerlightsedemnei"
                        id={`sellerConsoleSidemenuActionProductBoxOrderStatusITem${i}`}
                      />
                      {item && item.orderStatus === 'ordered'
                        ? 'Confirm Order'
                        : ''}
                      {item && item.orderStatus === 'paid'
                        ? 'Confirm Payment'
                        : ''}
                      {item &&
                      (item.orderStatus === 'completed' ||
                        item.orderStatus === 'delivered')
                        ? 'Leave Feedback'
                        : ''}
                      {item &&
                      item.orderStatus &&
                      item.orderStatus === 'returned'
                        ? 'Refund Payment'
                        : ''}
                      {item &&
                      item.orderStatus &&
                      item.orderStatus === 'payment verified'
                        ? 'Confirm Shipment'
                        : ''}
                    </div>
                    <div
                      className="sellerscsdvf500"
                      id={`sellerConsoleSidemenuActionProductBoxORderPrice${i}`}
                    >
                      USD${' '}
                      {item && item.totalOrderPrice
                        ? priceWithDecimal(item.totalOrderPrice)
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="sellerdiversiddemenu" id="sellerconsoleMenuBorder" />
          <div className="sellerMyessidem" id="sellerconsoleLatestfeedback">
            My Latest Feedback
          </div>
          {reviewList &&
          reviewList.sellerReviews &&
          reviewList.sellerReviews.length > 0 ? (
            reviewList.sellerReviews.map((item, i) => (
              <div
                key={i}
                className="sellerfbidemenudiv"
                style={{ marginTop: 15 }}
                id={`sellerConsoleSidemenuFeedbackProductBox${i}`}
              >
                <div>
                  <img
                    alt=""
                    src={
                      item && item.reviewerProfilePictureURL
                        ? item.reviewerProfilePictureURL
                        : productDetails
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = productDetails;
                    }}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 50,
                      marginLeft: 10,
                    }}
                    id={`sellerConsoleSidemenuFeedbackProductBoxProdimg${i}`}
                  />
                </div>
                <div
                  className="sellerdptionFeedback"
                  id={`sellerConsoleSidemenuFeedbackProductBoxItem${i}`}
                >
                  <div
                    className="sellerBrlysidemen"
                    id={`sellerConsoleSidemenuFeedbackProductBoxItemName${i}`}
                  >
                    {' '}
                    {item && item.reviewerFirstName
                      ? ShowNameAccordingToLength(
                          item.reviewerFirstName + ' ' + item.reviewerLastName
                        )
                      : ''}{' '}
                  </div>
                  <div
                    className="sellerrsedeminr"
                    id={`sellerConsoleSidemenuFeedbackProductBoxItemOrderid${i}`}
                  >
                    Order #{item && item.orderID ? item.orderID : 'N/A'}
                  </div>
                </div>
                <div
                  className="starRating"
                  id={`sellerConsoleSidemenuFeedbackProductBoxItemRating${i}`}
                >
                  <StarRatingComponent
                    id={`sellerConsoleSidemenuFeedbackProductBoxItemRatingCompo${i}`}
                    name={'rating'}
                    starCount={5}
                    // value={
                    //   userProfile &&
                    //     userProfile.serviceProfile &&
                    //     userProfile.serviceProfile.totalServicesOffered
                    //     ? userProfile.serviceProfile.totalServicesOffered
                    //     : 0
                    // }
                    emptyStarColor={'#E4E4E4'}
                    starColor={'#F5D028'}
                    style={{ fontSize: '80px', margin: 0 }}
                    value={item && item.reviewScore ? item.reviewScore : 0}
                    //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                  />
                </div>
              </div>
            ))
          ) : (
            <div
              className="nosellerFeedback"
              id="sellerConsoleSidemenuFeedbackNo"
            >
              No Feedback
            </div>
          )}
        </div>

        <Modal
          size="sm"
          isOpen={this.state.isAddNew}
          centered={true}
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className="createNewProductModal"
            id="sellerConsoleAddNewconfirm"
          >
            <div className="headerTitle" id="sellerConsoleAddNewconfirm1">
              Add New Product
              <Button
                className="closeButtonIcon"
                onClick={() => this.closeModal()}
                id="sellerConsoleAddNewconfirm2"
              >
                <img src={closeIcon} alt="closeIcon" />
              </Button>
            </div>
            <div>
              <div className="descriptionText" id="sellerConsoleAddNewconfirm3">
                <div id="sellerConsoleAddNewconfirm4">What type of product</div>
                <div id="sellerConsoleAddNewconfirm5">
                  {' '}
                  are you adding to the{' '}
                </div>
                R-DEE Marketplace today?
              </div>
              <div style={{position:'relative'}}  id="sellerConsoleAddNewconfirmMainbox6">
              <div
                className="selectDownMenu"
                onClick={() => this.openMenu()}
                id="sellerConsoleAddNewconfirm6"
              >
                Select...
                {showMenu ? (
                  <img src={upArrow} alt="downIcon" />
                ) : (
                  <img src={downWhiteArrow} alt="downIcon" />
                )}
              </div>
              {/* {showMenu && <div className="dropDownDiv"  id="sellerConsoleAddNewconfirm7">
                  <div onClick={()=>this.goNext('radiology')}  id="sellerConsoleAddNewconfirm8">
                    Radiology & Equipment
                  </div>
                  <div onClick={()=>this.goNext('generic')} className="ppeDiv"  id="sellerConsoleAddNewconfirm9">Products & PPE</div>
                </div>} */}
              {showMenu &&
                productCategoryType &&
                productCategoryType.length > 0 && (
                  <div className="dropDownDiv" id="sellerConsoleAddNewconfirm7">
                    {productCategoryType.map((item, i) => (
                      <div
                        key={i}
                        onClick={() => this.goNext(item.value)}
                        id={`sellerConsoleAddNewconfirmdd8${i}`}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                )}

            </div>
              <div
                className="selectOptionsOpacity"
                id="sellerConsoleAddNewconfirm10"
              >
                {' '}
                Select an option to proceed
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.MarketplaceReducer,
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSellerActionOrder,
      marketPlaceReviews,
      resetEducationDetail,
      fetchProductManufacturer,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SellerConsoleSidemenu));
