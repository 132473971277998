import * as firebase from "firebase";

//** config DEV server **//
const RDEE_DEV = {
    GOOGLE_FCM_KEY: "AAAAlwyEguQ:APA91bFJYzODcxW16HOzQljtrCyV4TZZQq0fgFhN3ycrxXcKNd38DVdRC4OYAAL3dLIC4LyA95r-GXJEmE_x5CBK6EjTBzCvQFKgtVDJku4UBlEUsm4dWBXCHMTBOmgU2j-VG0htl1Jc", // DEV
    config:{
        apiKey: "AIzaSyBMeCvEECTCr98nxgI5uhO2CcHAhuX8cpw",
        authDomain: "radiologexapp.firebaseapp.com",
        databaseURL: "https://radiologexapp.firebaseio.com",
        projectId: "radiologexapp",
        storageBucket: "radiologex",
        messagingSenderId: "648750072548",
        appId: "1:648750072548:web:aaaacb8a1f3f802f"
    }
}

//** config UAT server **//
const RDEE_UAT = {
    GOOGLE_FCM_KEY: "AAAA1_bVZ_Y:APA91bFRMReSiZUyEoYfE5XyDCpoYGKp-5qe3po13iAQkurtR0Bw3uHmHsmEXAoZMdqesfrxOJVvw6RsHg4zFXtvPlfzOqKEHmovB7ntpYPvnElmCGErHOKcwPq7xsX2EXAqbz-Fl75E",
    config:{
        apiKey: "AIzaSyABT5ua9_FeTcWNDWAP2RCCVKycoPFlfrU",
        authDomain: "rdee-uat.firebaseapp.com",
        databaseURL: "https://rdee-uat.firebaseio.com",
        projectId: "rdee-uat",
        storageBucket: "radiologex-uat",
        messagingSenderId: "927559149558",
        appId: "1:927559149558:web:1d6767273f35265edec9d7",
        measurementId: "G-NS48MXPW95"
    }
}

//** config Prod server **//
const RDEE_PROD = {
    GOOGLE_FCM_KEY: "AAAA6QpUkrk:APA91bFt-DqLakhBG0xk6f5UaaCRIruQuu-YP6oxFiD6oRp0nvFjKwPeuLLRVGEkdFUdR1YkU8yfjUKd8cYnZf5zKMtNB82BcVGFH-DffS8D14isdan6Z5tiDAYnWdO8WSaiFu-80Pi4",
    config:{
            apiKey: "AIzaSyCvJoTt4wWvTdkPFFU_qG5qtCXKLyO8f2Q",
            authDomain: "rdee-prod.firebaseapp.com",
            databaseURL: "https://rdee-prod.firebaseio.com",
            projectId: "rdee-prod",
            storageBucket: "rdee-prod.appspot.com",
            messagingSenderId: "1000900694713",
            appId: "1:1000900694713:web:3a1eae540422f46ec18168",
            measurementId: "G-FKP5NE7G0N"
          }
};

export const environmentUsed = RDEE_UAT // RDEE_DEV // RDEE_UAT // RDEE_PROD

export default !firebase.apps.length ? firebase.initializeApp(environmentUsed.config) : firebase.app();