import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

import messenger from '../../../images/icons/messenger.png';
import marketPlace from '../../../images/icons/marketPlace.png';
import profilePlaceholder from '../../../images/profile_placeholder.png';

import wallet from '../../../images/icons/wallet.png';
import * as routes from '../../../constants/routes';

import './UserCard.css';

const sampleBruceWayneImg =
  'https://www.altfg.com/film/wp-content/uploads/images/2012/01/christian-bale-bruce-wayne.jpg';

export default function UserCard({
  userImage = sampleBruceWayneImg,
  userName = '',
  getGreetingTime = '',
  history,
  updateStatus,
  onChangeUpdate,
  statusMessage
}) {
  



  // const [isStatusValue, setisStatusValue] = useState(statusMessage);
  // if(!statusMessage){
  //   setisStatusValue(statusMessage);
  // }

  return (
    <div className="user-card" id="ProfileDashuser-card">
      <div className="inneruserContainer" id="ProfileDashinneruserContainer">
        <div className="displayInlineFlex" id="ProfileDashdisplayInlineFlex">
          <div style={{ width: 110 }} className="profileImageBox" id="ProfileDashprofileImageBox">
            <img src={userImage} className="userImage" onError={e => {
              e.target.onerror = null;
              e.target.src = profilePlaceholder;
            }}/>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <p className="getGreetingTimeCss" id="ProfileDashgetGreetingTimeCss"> {getGreetingTime},</p>
            <p className="userName"  id="ProfileDashgetuserName">  {userName.slice(0,20)}{userName && userName.length>20 && <span>..</span>}</p>
          </div>
        </div>

        <div className="displayInlineFlex whatsToday">

          {/*<div className="todayStatus">
            What would you like to do today ?
          </div>
          <textarea type="text" className="todayStatus userStatusInput" value={statusMessage} onChange={(e) => {
            onChangeUpdate(e.target.value);
          }
          } onBlur={() => updateStatus()} placeholder="What would you like to do today ?"></textarea>


          */}
          <div className="displayInlineFlex"  id="ProfileDashgdisplayInlineFlex">
            <div
              data-rh="Connect"
              className="IconsCircleBox"
              onClick={() => history.push(routes.MESSENGER)}
              id="ProfileDashIconsCircleBox"
            >
              <div className="messengerCircle" id="ProfileDashmessengerCircle">
                <img src={messenger} alt="wallet" />
              </div>
              <p className="subtitle"  id="ProfileDashsubtitle">Connect</p>
            </div>
            <div
              data-rh="Settle"
              className="IconsCircleBox"
              onClick={() => history.push(routes.PAYMENT)}
              id="ProfileDashmessengerCircle1"
            >
              <div className="RDEEpayCircle"  id="ProfileDashmessengerCircle2">
                <img src={wallet} alt="wallet" />
              </div>
              <p className="subtitle" id="ProfileDashsubtitle1" >Settle</p>
            </div>
            <div
              data-rh="Marketplace"
              className="IconsCircleBox"
              onClick={() => history.push(routes.MARKETPLACE)}
              id="ProfileDashMarketplace"
            >
              <div className="marketPlaceCircle"  id="ProfileDashmessengerCircle3">
                <img src={marketPlace} alt="wallet" />
              </div>
              <p className="subtitle" id="ProfileDashsubtitle2">Marketplace</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

UserCard.propTypes = {
  userImage: PropTypes.string,
  userName: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
  onChangeUpdate: PropTypes.func.isRequired,
};
