import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import { SignInLink } from "./SignIn";
import StarRatingComponent from 'react-star-rating-component';

import { Button, Media} from 'reactstrap';
import _ from 'lodash'

import localtion from '../../../images/profile/location.svg';
import building from '../../../images/profile/building.svg';
import work from '../../../images/profile/work.svg';
import profilePlaceholder from './../../../images/profile_placeholder.png';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './userprofile.css';
import repuimg from '../../../images/profile/repuimg.png';
import repuimg2 from '../../../images/profile/repuimg2.png';
import repuimg3 from '../../../images/profile/repuimg3.png';
import UserContactListPage from '../usercontacts/userList';
import {
  getAllUserContacts_v2,
  getUserDetail,
  addUserContactFunc,
  acceptContactFunc,
  deleteUserContactFunc,
  cancelSendUserContactRequest
} from '../../../actions/contacts';

import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import moment from 'moment';


import * as routes from '../../../constants/routes';
import  RightSideContainer from '../../../components/UI/rightSideContainer/rightSideContainer';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';

class UserProfileDetail extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      personalDetail: [],
      educationDetail: [],
      professionalDetail: [],
      businessDetail: '',
      userid: '',
      checkuserChatStatus: '',
      checkGetRequest:'',
      sendRequestMessageId:'',
      loginUserid:''
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps.contacts &&
        nextProps.contacts.contactDetail &&
        nextProps.contacts.contactDetail.length > 0
      ) {
        let getData = nextProps.contacts.contactDetail;
        // console.log(getData);

        this.setState (
          {
            personalDetail: getData[0].personalDetails,
            professionalDetail: getData[1].professionalDetails,
            educationDetail: getData[2].educationDetails,
            businessDetail: getData[3].businessProfile &&
              getData[3].businessProfile
              ? getData[3].businessProfile
              : '',
            reputationDetails: (getData[4] && getData[4].reputationDetails)  ? getData[4].reputationDetails
            : '',
            checkuserChatStatus: getData[5].inContactList,
            checkGetRequest:getData[6]

          },
          () => {
            this.setState({
              sendRequestMessageId: getData[5].sentContactRequestMessageID
            })

           
          }
        );
      }
    }
  }

  componentDidMount () {
    let geturl = this.props.match.params;

    if (geturl && geturl.userId) {
      let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

      this.setState ({
        userid: geturl.userId,
        loginUserid:loginUser.docID
      });
      let sendRequest = {
        data: {
          getPersonalDetails: true,
          getProfessionalDetails: true,
          getEducationalDetails: true,
          getBusinessProfile: true,
          getReputationDetails: true,
          docID: geturl.userId,
        },
      };
      this.props.getUserDetail (sendRequest);
    }
  }

  goToBusinessprofile () {
   this.props.history.push (routes.BUSINESSDETAIL + `/${this.state.businessDetail.documentID}`);
  }

  addNewContact () {
    let sendRequest = {
      data: {
        contactUID: this.state.userid,
      },
    };
    this.props.addUserContactFunc (sendRequest);
  }

  deleteFromContact () {
    let sendRequest = {
      data: {
        contactUID: this.state.userid,
        targetUID:this.state.personalDetail.docID
      },
    };
    this.props.deleteUserContactFunc (sendRequest);
  }
  

  goToChat () {
 
    const {
      personalDetail
    } = this.state;
    let userChatInfo = {
      oppositeUserUid:personalDetail.docID,
      profilePictureURL: personalDetail && personalDetail.profilePictureURL
        ? personalDetail.profilePictureURL
        : profilePlaceholder,
        displayName: 
        personalDetail && personalDetail.firstName
            ? personalDetail.firstName
            : '',
    };
     this.props.history.push(routes.MESSENGER, { userChatInfo: userChatInfo, messengerKey: this._handleKeyVal(personalDetail.docID) });
  }
  _handleKeyVal(receiver) {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let keyVal = "";
    let userId= loginUser.docID
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goReputationDetail() {
    
    this.props.history.push (routes.REPUTATION_DETAILS + `/${this.state.userid}`);
  };

  acceptRequest(status){
    let sendRequest = {
      data: {
        messageID: this.state.checkGetRequest.pendingContactRequestMessageID,
        accepted: status
      },
    };

    this.props.acceptContactFunc(sendRequest,this.state.personalDetail.docID);
  }

  cancelSendRequest(){
    let sendRequest={
      data:{
        messageID:this.state.sendRequestMessageId,
        targetUID:this.state.personalDetail.docID
      }
    }
    
    this.props.cancelSendUserContactRequest(sendRequest,this.state.userid);
  }
  
  render () {
    const {
      personalDetail,
      professionalDetail,
      educationDetail,
      businessDetail,
      checkuserChatStatus,
      reputationDetails,
      checkGetRequest,
      loginUserid,
      userid
    } = this.state;


    return (
      <div className="flex " id="userprofilePage">
        <div className="userProfilemainContainer" id="userprofilePageuserProfilemainContainer">

          <div className="userprofileContent"  id="userprofileuserprofileContent">
            <div style={{textAlign: 'right'}}>
              <Button
                id="userprofileuserProfilebackbutton"
                className="userProfilebackbutton"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>

            <div className="profileinnerContainer" id="userprofilePageprofileinnerContainer">
              <div>
                <div className="userProfileBoxDetail" id="userprofilePageuserProfileBoxDetail">
                  <Media>
                    <Media left>
                      <Media
                        className="profileImage"
                        src={
                          personalDetail && personalDetail.profilePictureURL
                            ? personalDetail.profilePictureURL
                            : profilePlaceholder
                        }
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = profilePlaceholder;
                        }}
                        alt="Generic placeholder image"
                      />
                    </Media>
                    <Media body>
                      {userid !== loginUserid &&<div className="buttonBox">
                        {checkuserChatStatus === 'true' &&
                          <Button
                            className="ProfileButtons"
                            onClick={() => this.goToChat ()}
                            data-rh="Go Chat"
                            id="userprofilePageProfileButtons"
                          >
                            Send Message
                          </Button>}
                        {checkuserChatStatus === 'true' &&
                          <Button
                            className="removeProfileButtons"
                            onClick={() => this.deleteFromContact ()}
                            data-rh="Remove from contact"
                            id="userprofilePageremoveProfileButtons"
                          >
                            Remove from Contacts
                          </Button>}
                        {checkuserChatStatus === 'false' &&  (checkGetRequest && checkGetRequest.pendingContactRequest&& checkGetRequest.pendingContactRequest==='false')  &&
                          <Button
                            className="ProfileButtons"
                            onClick={() => this.addNewContact ()}
                            data-rh="Send Request"
                            id="userprofilePageSendRequestButton"
                          >
                            Add Friend
                          </Button>}

                        {checkuserChatStatus === 'pending' &&
                          <Button disabled={!this.state.sendRequestMessageId} className="ProfileButtons" onClick={()=>this.cancelSendRequest()}
                          id="userprofilePageCancelRequestButton"
                          
                          >
                            Cancel Request
                          </Button>}
                          {checkGetRequest && checkGetRequest.pendingContactRequest && checkGetRequest.pendingContactRequest==='true' &&
                          <Button
                            className="ProfileButtons"
                            onClick={() => this.acceptRequest (true)}
                            data-rh="Accept Request"
                            id="userprofilePageAcceptRequestButton"
                          >
                            Approve Request
                          </Button>}
                          { checkGetRequest && checkGetRequest.pendingContactRequest && checkGetRequest.pendingContactRequest==='true' &&
                          <Button
                            className="removeProfileButtons"
                            onClick={() => this.acceptRequest (false)}
                            data-rh="Delete Request"
                            id="userprofilePageDeleteRequestButton"
                          >
                            Remove 
                          </Button>}
                      </div>}
                      <div>

                        <div className="searchAbleName" id="searchAbleName" style={{display:'inline-flex'}}>
                          {' '}
                          {personalDetail && personalDetail.searchHandle &&<div style={{marginRight:50}}>
                          {personalDetail && personalDetail.searchHandle
                            ? '@'+ personalDetail.searchHandle
                            : ''}
                            </div>}

                            {personalDetail && personalDetail.statusMessage && <div 
                              id="userprofilePageprofileUserStatus" className="profileUserStatus" style={{display:'inline-flex'}}>
                              {(personalDetail.statusMessage==="Active" || personalDetail.statusMessage==="active") &&<div className="userActiveStatusCircle" style={{background:'#4AEA87'}} />}
                              {personalDetail.statusMessage==="Away" &&<div className="userActiveStatusCircle" style={{background:'#FFBF00'}} />}
                              {personalDetail.statusMessage==="Do Not Disturb" &&<div className="userActiveStatusCircle" style={{background:'#ff0b34'}} />}
                              {personalDetail.statusMessage==="Invisible" &&<div className="userActiveStatusCircle" style={{border:'1px solid #4AEA87'}} />}
                              <span style={{opacity:0.5}}> Status: { personalDetail.statusMessage}</span>
                              </div>}

                            
                        </div>

                        <div> 
                        <div className="otherUserprofileTaglineMessage" id="userprofilePageotherUserprofileTaglineMessage" > {personalDetail && personalDetail.taglineMessage ? `“${personalDetail.taglineMessage}”`: ' '}</div>

                          
                       </div>

                        <p className="username" id="userprofilePageusername">
                          {personalDetail && personalDetail.title
                            ? personalDetail.title
                            : ''}
                          {' '}

                          {personalDetail && personalDetail.firstName ? ShowNameAccordingToLength(personalDetail.firstName + ' ' +personalDetail.lastName):''}

                          
                        </p>

                      </div>
                      <p className="workplace" id="userprofilePageworkplace">
                        {' '}<img src={work} alt="workICon"/>

                        {personalDetail &&  personalDetail.professionalTitle
                          ? personalDetail.professionalTitle
                          : 'N/A'}

                      </p>
                      <p className="userProfileBoxDetailunivercityplace">
                        {' '}
                        <img src={building} alt="icon"/>
                        {' '}
                        {professionalDetail &&  professionalDetail.length>0 && 
                          professionalDetail[professionalDetail.length-1].firmName
                          ? professionalDetail[professionalDetail.length-1].firmName
                          : 'N/A'}
                      </p>
                      <p className="localtion" id="userprofilePagelocaltion">
                        {' '}
                        <img src={localtion} />
                        {' '}
                        {personalDetail && personalDetail.location
                          ? personalDetail.location
                          : 'N/A'}
                        {' '}
                      </p>

                    </Media>
                  </Media>
                </div>

                <div className="padd14">
                  <div style={{display: 'inline-flex'}}>

                    <div className="profileCardBox" id="userprofilePageprofileCardBox">
                      <div className="headeingname">
                        PERSONAL <span className="boldText">DETAILS </span>
                      </div>
                      <div className="innerContentBox" id="userprofilePageinnerContentBox">
                        <div style={{marginTop: 10}}>
                          <div style={{display: 'inline-flex'}}>
                            <div className="profileContentTitleDiv" id="userprofileprofileContentTitleDiv">
                              <p className="titleP titlePColor" id="userprofileprofileContentTitleDiv1">Title</p>
                              <p className="titleP nameStyle" id="userprofileprofileContentTitleDiv2">
                                {personalDetail && personalDetail.title
                                  ? personalDetail.title
                                  : ' '}
                                {' '}
                              </p>
                            </div>
                            <div className="profileContentDiv" id="userprofileprofileContentTitleDiv3">
                              <p className="titleP titlePColor" id="userprofileprofileContentTitleDiv4">First Name</p>
                              <p className="titleP nameStyle" id="userprofileprofileContent5">
                                {personalDetail && personalDetail.firstName
                                  ? personalDetail.firstName
                                  : ''}
                              </p>
                            </div>
                            <div className="profileContentDiv" id="userprofileprofileContent6">
                              <p className="titleP titlePColor" id="userprofileprofileContent7">Last Name</p>
                              <p className="titleP nameStyle" id="userprofileprofileContent8">
                                {personalDetail && personalDetail.lastName
                                  ? personalDetail.lastName
                                  : ''}
                              </p>
                            </div>
                            <div className=" profileContentDiv" id="userprofileprofileContent9">
                              <p className="titleP titlePColor" id="userprofileprofileContent10">
                                Search Handle
                              </p>
                              <p className="titleP nameStyle" id="userprofileprofileContent11">
                                {personalDetail && personalDetail.searchHandle
                                  ? personalDetail.searchHandle
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop: 15}}>
                          <p className="titleP titlePColor" id="userprofileprofileContent12">About Me</p>
                          <p className="titleP nameStyle" id="userprofileprofileContent13">
                            {personalDetail && personalDetail.aboutMe
                              ? personalDetail.aboutMe
                              : ''}
                          </p>
                        </div>
                        {/*<div style={{marginTop: 15}}>
                          <p className="titleP titlePColor">Service Offers</p>
                          <p className="titleP nameStyle">
                            {personalDetail && personalDetail.servicesOffered
                              ? personalDetail.servicesOffered
                              : ''}
                          </p>
                          <div className="servicewdklhfs">
                            Neurological Surgery{' '}
                          </div>
                          <div className="servicewdklhfs">
                            Treatment in CNS disorders and diseases{' '}
                          </div>
                            </div>*/}
                      </div>
                    </div>
                    <div>
                      <div className="profileCardBox" id="userprofileprofileContent15">
                        <div className="reputationHeading" id="userprofileprofileContent16">
                          REPUTATION
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <div className="ratingdiv" id="userprofileprofileContent17">
                            {reputationDetails && reputationDetails.reputationData && reputationDetails.reputationData.overallReputationScore ? reputationDetails.reputationData.overallReputationScore:0} <span style={{fontSize: 25}}>/ 5.0</span>

                             
                            </div>
                            <div id="userprofileprofileContent18">
                              <StarRatingComponent
                                name={'rating'}
                                starCount={5}
                                // value={
                                //   userProfile &&
                                //     userProfile.serviceProfile &&
                                //     userProfile.serviceProfile.totalServicesOffered
                                //     ? userProfile.serviceProfile.totalServicesOffered
                                //     : 0
                                // }
                                emptyStarColor={'#E4E4E4'}
                                starColor={'#F5D028'}
                                style={{fontSize: '80px', margin: 0}}
                                value={reputationDetails && reputationDetails.reputationData && reputationDetails.reputationData.overallReputationScore ? reputationDetails.reputationData.overallReputationScore:0}
                            
                              />
                              <p className="reviiewscn">{reputationDetails && reputationDetails.reputationData && reputationDetails.reputationData.totalReviewCount ? reputationDetails.reputationData.totalReviewCount:0}  Reviews</p>
                              {reputationDetails&&  reputationDetails.providerData && reputationDetails.providerData.topTenPercent &&<Button className="top100"> TOP 10%</Button>}
                            </div>
                          </div>
                          <div>
                            <div className="reputationsStatus" id="userprofileprofileContent19">
                              <img alt="repuimg" src={repuimg} style={{width: 30}} />
                              {reputationDetails && reputationDetails.reputationData && reputationDetails.reputationData.ordersCompleted ? reputationDetails.reputationData.ordersCompleted:0}
                         
                              <span>
                                {' '} Orders Completed
                              </span>
                            </div>
                            <div className="reputationsStatus" id="userprofilepagereputationsStatus"> 
                              <img alt="repuimg" src={repuimg2} style={{width: 30}} />

                              {reputationDetails && reputationDetails.providerData && reputationDetails.providerData.servicesProvided ? reputationDetails.providerData.servicesProvided:0}  <span> Services Provided</span>

                            </div>
                            <div className="reputationsStatus" id="userprofilepagereputationsStatus1">
                              <img alt="repuimg" src={repuimg3} style={{width: 30}} />

                              <span style={{paddingLeft: 10}}>
                                {' '}Typically responds in{' '}
                              </span>
                              {' '}
                              {reputationDetails && reputationDetails.providerData && reputationDetails.providerData.responseTime ? reputationDetails.providerData.responseTime:0}
                              {' '}
                              <span> minutes</span>

                            </div>

                          </div>
                        </div>


                        {reputationDetails &&
                          reputationDetails.latestReview &&
                          reputationDetails.latestReview.length===0 && <div className="noReviewText">
                            No Review

                          </div>}
                        {reputationDetails &&
                          reputationDetails.latestReview &&
                          reputationDetails.latestReview.length>0 && <div>
                          {reputationDetails.latestReview[0].map ((item, i) => (<div key={i} className="infodiv" style={{marginTop: 20}} id="userprofilepagereputationinfodiv">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{display: 'flex'}}>
                                <img
                                  alt="Profile Image"
                                  src={ item && item.reviewerProfilePictureURL ? item.reviewerProfilePictureURL : profilePlaceholder
                                  }
                                  onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = profilePlaceholder;
                                  }}
                                  style={{width: 50, height: 50,borderRadius:86,border:'2px solid #4453D6'}}
                                />
                                <div>
                                  <p className="FrankPa" id="userprofilepagereputationinfoTitle">
                                    {item && item.reviewerTitle
                                      ? item.reviewerTitle
                                      : ''}
                                    {' '}

                        {item ? ShowNameAccordingToLength(item.reviewerFirstName + " "+ item.reviewerLastName ):''}


                                  </p>
                                  <p className="date2601" id="userprofilepagereputationinfoDate">
                                    {item &&
                                      item.reviewDate
                                      ? moment ( item.reviewDate).format ('DD/MM/YYYY ; HH:ss a')
                                      : ''}

                                  </p>
                                </div>
                              </div>
                              <StarRatingComponent
                                name={'rating'}
                                starCount={5}
                                emptyStarColor={'#E4E4E4'}
                                starColor={'#F5D028'}
                                style={{fontSize: '80px', margin: 0}}
                                value={
                                  item && item.reviewScore  ? item.reviewScore : 0
                                }
                              />
                            </div>
                            <div className="timelkcnfjrf" id="userprofilepagereputationinforeviewText">
                              {item &&item.reviewText
                                ? item.reviewText
                                : ''}

                            </div>
                          </div>))}
                          </div>}
                        
                        {/*<div
                          style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                          <Button
                            className="viewallbuton"
                            onClick={()=>this.goReputationDetail()}
                          >
                            View All
                          </Button>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                  <div style={{marginTop: 20, display: 'inline-flex'}}>
                      <div className="educationCardBox" id="userprofilepageEducationinfodiv">
                        <div className="educationBlueButton">
                          EDUCATION
                        </div>
                        {educationDetail.map ((item, index) => (
                          <div key={index} className="marginT10">
                            <div className="innerBox" id={`userprofilepageEducationinfoinnerBox${index}`}>
                              <p className="titleP nameStyle" id={`userprofilepageEducationinfoinnerBoxTitle${index}`}>
                                {item.university ? item.university : ''}
                                {' '}
                                -
                                {' '}
                                {item.university ? item.yearCompleted : ''}
                              </p>
                              <p className="titleP titlePColor" id={`userprofilepageEducationinfoinnerBoxMajor${index}`}>
                                {item.courseMajor ? item.courseMajor : ''}
                                {' '}
                                ,
                                {' '}
                                {item.degreeType ? item.degreeType : ''}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="educationCardBox">
                        <div className="headeingname">
                          <span className="boldText"> WORK </span> EXPERIENCE
                        </div>

                        {professionalDetail.map ((item, index) => (
                          <div key={index} className="marginT10" id={`userprofilepageProfessionalinfoinnerBox${index}`}>
                            <div className="innerBox" id={`userprofilepageProfessionalinfoinnerBoxdiv${index}`}>
                              <p className="titleP nameStyle" id={`userprofilepageProfessionalinfoinnerBoxTitle${index}`}>
                                {item.firmName ? item.firmName : ''}

                              </p>
                              <p className="titleP dateText" id={`userprofilepageProfessionalinfoinnerBoxDateText${index}`}>
                                {/*11 july 2007 - 30 july 2012*/}
                        
                                      {item && item.startDate
                                        ? moment (new Date (item.startDate)).format ('MM/YY')
                                        : 'N/A'}
                                      {' - '}
                
                                      {item && item.currentlyWorking === 'true'
                                        ? 'Present'
                                        : item && item.endDate
                                            ? moment (new Date (item.endDate)).format ('MM/YY')
                                            : 'N/A'}

                              </p>
                              <p className="titleP titlePColor" id={`userprofilepageProfessionalinfoinnerBoxExperienceType${index}`}>
                                {item.experienceType ? item.experienceType : ''}
                                {' '}
                                -
                                {' '}
                                {' '}
                                {item.position ? item.position : ''}
                              </p>
                            </div>
                          </div>
                        ))}

                      </div>
                 
                      <div className="educationCardBox" id="userprofilepageBusinessCardBox">

                        <div>
                          <div className="headeingname" id="userprofilepageBusinessCardBoxheadeingname">
                            <span style={{fontWeight: 'bold'}}>BUSINESS </span>
                            {' '}
                            PROFILE
                          </div>
                        </div>
                        <div className="registerBoxInnerContent" id="userprofilepageBusinessCardBoxInnerContent">

                          <p
                            className="titleP titlePColor"
                            style={{marginTop: 10}}
                            id="userprofilepageBusinessCardBoxRegisteredprofile"
                          >
                            Registered Business Profile
                          </p>
                          <p className="titleP nameStyle" id="userprofilepageBusinessCardBoxRegisteredprofilenameStyle">
                            {businessDetail &&
                              businessDetail.registeredBusinessName
                              ? businessDetail.registeredBusinessName
                              : ''}
                          </p>
                        </div>
                        {/*<div className="marginT10  ">
                          <div className="registerBoxInnerContent">
                            <p className="titleP titlePColor">Search Handle</p>
                            <p className="titleP nameStyle">
                              {personalDetail && personalDetail.searchHandle
                                ? personalDetail.searchHandle
                                : 'N/A'}
                            </p>
                          </div>
                              </div>*/}
                        <div className="marginT10  ">
                          <div className="registerBoxInnerContent" id="userprofilepageBusinessCardBoxregisterBoxInnerContent">
                            <div style={{display:'inline-flex',justifyContent:'space-between',width:'100%'}}>
                              <div>
                                <p className="titleP titlePColor" id="userprofilepageBusinessCardBoxregisterBoxCity">City</p>
                                <p className="titleP nameStyle" id="userprofilepageBusinessCardBoxregisterBoxCity1">
                                  {businessDetail && businessDetail.businessCity
                                    ? businessDetail.businessCity
                                    : 'N/A'}
                                </p>
                              </div>
                              <div>
                                <p className="titleP titlePColor" id="userprofilepageBusinessCardBoxregisterBoxState">State</p>
                                <p className="titleP nameStyle" id="userprofilepageBusinessCardBoxregisterBoxState1">
                                  {businessDetail &&
                                    businessDetail.businessState
                                    ? businessDetail.businessState
                                    : 'N/A'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="marginT10 ">
                          <div className="registerBoxInnerContent" id="userprofilepageregisterBoxInnerContent">
                            <p className="titleP titlePColor" id="userprofilepageBusinessCardBoxregisterBoxCountry">Country</p>
                            <p className="titleP nameStyle" id="userprofilepageBusinessCardBoxregisterBoxCountry1">
                              {businessDetail && businessDetail.businessCountry
                                ? businessDetail.businessCountry
                                : 'N/A'}
                            </p>
                          </div>
                        </div>
                        {businessDetail && businessDetail.documentID && <div style={{textAlign:'right'}}>
                          <Button
                            className="BusinessDetailContactbuttonStyle"
                            onClick={() => this.goToBusinessprofile ()}
                            data-rh="View  business profile"
                          >
                            Visit Business Profile
                          </Button>
                        </div>}

                      </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
          <RightSideContainer>
          <UserContactListPage history={this.props.history} />
          </RightSideContainer>

          {/*<div className="contact-container-parent blueBg">
            <UserContactListPage history={this.props.history} />
                              </div>*/}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getAllUserContacts_v2,
      getUserDetail,
      addUserContactFunc,
      acceptContactFunc,
      deleteUserContactFunc,
      cancelSendUserContactRequest
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  UserProfileDetail
);
