import React, { Component } from 'react';
// Dependencies
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
// Components
import UserCard from '../UI/UserCard';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { messaging } from './../../init-fcm';
import { Progress, Button, Modal, ModalBody } from 'reactstrap';
import RightSideContainer from './../../components/UI/rightSideContainer/rightSideContainer';
import CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';
// Actions
import { getUserNotificationHistoryFunc } from '../../actions/notifications';
import {
  getAllUserContactsFunc,
  acceptContactFunc,
  readUserNotifications,
  deleteNotifications,
} from '../../actions/contacts';
import {
  getProfileDashboard,
  resetProfileDashboard,
} from '../../actions/Authaction';
import { getChatRecentMessagesDashboard } from '../../actions/Messenger';
import {
  registerDeviceToken,
  ModifyPersonalInfo,
  fetchStatusCode,
} from '../../actions/Authaction';
// Icons
import profilePlaceholder from '../../images/profile_placeholder.png';
import searchIcon from '../../images/icons/search.svg';
import rightArrow from '../../images/icons/rightArrow.svg';
import rightCheck from '../../images/icons/rightCheck.svg';
import bluePlusIcon from '../../images/icons/bluePlusIcon.svg';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import videoCamera from '../../images/icons/videoCamera.svg';
import nextWhiteArrow from '../../images/icons/carousel/nextWhiteArrow.svg';
import previousWhiteArrow from '../../images/icons/carousel/previousWhiteArrow.svg';
import close_icon from '../../images/close-icon.svg';
// Routes
import * as routes from '../../constants/routes';
// Constants
import {
  MESSENGER,
  ADD_USER_PROFILE_DETAIL,
  NOTIFICATION,
  BUSINESS_REGISTER,
  USER_KYC,
  CONTACTREQUEST,
} from './../../constants/routes';
import { getBrowserName } from '../../constants/helper';
import { checkAvailableAccess } from '../../constants/localMessage/errorMessage';
// Utils
import {
  dataFormat,
  notificationDateFormat,
  notificationdataTimeFormat,
} from '../../utility/convertTimeString';
import { ShowNameAccordingToLength } from '../../utility/stringFunctions';
// Styles
import './profileDashboard.scss';

const Arrow = ({ text, className }) => {
  return (
    <div className={className}>
      {' '}
      <img className={className} src={text} alt='arrow' />
    </div>
  );
};
const ArrowLeft = Arrow({
  text: previousWhiteArrow,
  className: 'arrow-prev arrowStyle',
});
const ArrowRight = Arrow({
  text: nextWhiteArrow,
  className: 'arrow-next arrowStyle',
});

class ProfileDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardProfile: [],
      profileCompletion: '',
      notificationsList: [],
      userStatus: '',
      checkChangeUserStatus: '',
      searching: '',
      isShowProfileCompletedDate: true,
      boxOrder: [],
      contactListing: [],
      latestMessageListing: [],
      dashboardCards: [],
      recentMessages: [],
      isAccess: true,
    };
    this.updateStatus = this.updateStatus.bind(this);
    this.onChangeUpdate = this.onChangeUpdate.bind(this);
  }

  async componentDidMount() {
    this.props.fetchStatusCode();
    this.props.resetProfileDashboard();

    if (
      checkAvailableAccess('dashboard') &&
      checkAvailableAccess('dashboard') === 'dashboard'
    ) {
      this.props.getUserNotificationHistoryFunc({ data: {} });

      this.props.getAllUserContactsFunc({
        data: {
          searchString: '',
        },
      });
      let sendRequestUserProfile = {
        data: {},
      };
      this.props.getProfileDashboard(sendRequestUserProfile);

      if (getBrowserName() !== 'Safari') {
        messaging
          .requestPermission()
          .then(async () => {
            const token = await messaging.getToken();
            // console.log (token);
            const sendrequest = {
              data: {
                deviceRegToken: token,
                devicePlatform: 'web',
              },
            };
            this.props.registerDeviceToken(sendrequest);
          })
          .catch(function(err) {
            console.log('Unable to get permission to notify.', err);
          });
      }
      navigator.serviceWorker.addEventListener('message', (message) =>
        console.log(message)
      );

      // let userId = this.props.userInfo.user.Data.userDetails[0].personalDetails
      //   .docID
      if (
        this.props.userInfo &&
        this.props.userInfo.user.Data &&
        this.props.userInfo.user.Data.userDetails &&
        this.props.userInfo.user.Data.userDetails[0] &&
        this.props.userInfo.user.Data.userDetails[0].personalDetails &&
        this.props.userInfo.user.Data.userDetails[0].personalDetails.docID
      ) {
        this.props.getChatRecentMessagesDashboard(
          this.props.userInfo.user.Data.userDetails[0].personalDetails.docID
        );
      }
    } else {
      this.setState({
        isAccess: false,
      });
    }
  }

  _handleKeyVal(receiver) {
    let keyVal = '';
    let userId = this.props.userInfo.user.Data.userDetails[0].personalDetails
      .docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      // console.log(nextProps.userInfo);
      if (nextProps.userInfo && nextProps.userInfo.recentMessages) {
        this.setState({
          recentMessages: nextProps.userInfo.recentMessages,
        });
      }

      if (nextProps.userInfo && nextProps.userInfo.dashboardprofile) {
        // console.log(nextProps.userInfo.dashboardprofile);
        if (
          nextProps.userInfo.dashboardprofile.dashboardCards &&
          nextProps.userInfo.dashboardprofile.dashboardCards.featuredAds
        ) {
          this.setState({
            dashboardCards:
              nextProps.userInfo.dashboardprofile.dashboardCards.featuredAds,
          });
        }
        this.setState(
          {
            dashboardProfile: nextProps.userInfo.dashboardprofile,
            profileCompletion:
              nextProps.userInfo.dashboardprofile.profileCompletion,
            notificationsList:
              nextProps.userInfo.dashboardprofile.notifications,
          },
          () => {
            const { profileCompletion, notificationsList } = this.state;

            let contactList = [];
            let messageList = [];

            // for(let item of notificationsList){
            //   if(item.source === "messenger"){
            //     messageList.push(item);
            //   }
            //   if(item.source === "contact"){
            //     contactList.push(item);
            //   }
            // }
            this.setState({
              contactListing:
                nextProps.userInfo.dashboardprofile.pendingContactRequests,
              latestMessageListing: notificationsList,
            });

            let tempCompletionStep = [];
            tempCompletionStep.push(
              {
                kycSetup: profileCompletion.kycSetup,
                key: 'kycSetup',
                itemValue: profileCompletion.kycSetup,
              },
              {
                userProfileSetup: profileCompletion.userProfileSetup,
                key: 'userProfileSetup',
                itemValue: profileCompletion.userProfileSetup,

              },
              {
                businessProfileSetup: profileCompletion.businessProfileSetup,
                key: 'businessProfileSetup',
                itemValue: profileCompletion.businessProfileSetup,
              }
              // {
              //   trusonaSetup: profileCompletion.trusonaSetup,
              //   key: 'trusonaSetup',
              //   itemValue: profileCompletion.trusonaSetup,
              // },
            );
            this.setState({
              boxOrder: tempCompletionStep.sort((a, b) => {
                return a.itemValue - b.itemValue;
              }),
            });
            const { dashboardProfile } = this.state;

            if (dashboardProfile) {
              if (
                dashboardProfile.personalDetails &&
                dashboardProfile.personalDetails.statusMessage &&
                !this.state.userStatus
              ) {
                this.setState({
                  userStatus: dashboardProfile.personalDetails.statusMessage,
                  checkChangeUserStatus:
                    dashboardProfile.personalDetails.statusMessage,
                });
              }
            }
          }
        );
      }
    }
  }

  greetingMessages(currentTime = new Date()) {
    const currentHour = currentTime.getHours();
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return 'Good Afternoon';
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return 'Good Evening';
    }
    // Between dawn and noon
    return 'Good Morning';
  }

  goToEditProfile(status) {
    this.props.history.push(ADD_USER_PROFILE_DETAIL, { activeWindow: status });
  }
  goToChat(personDetail) {
    // console.log ('goToChat(personalDetail)>>>', personDetail);

    let personalDetail = personDetail.contactDetail;
    let userChatInfo = {
      oppositeUserUid: personalDetail.docID,
      profilePictureURL:
        personalDetail && personalDetail.profilePictureURL
          ? personalDetail.profilePictureURL
          : profilePlaceholder,
      displayName:
        personalDetail && personalDetail.firstName
          ? personalDetail.firstName
          : '',
    };
    this.props.history.push(MESSENGER, {
      userChatInfo: userChatInfo,
      messengerKey: this._handleKeyVal(personalDetail.docID),
    });
  }

  goNotificationToChat(personalDetail) {
    if (personalDetail.status === 'unread') {
      let sendRequest = {
        data: {
          messageID: personalDetail.messageID,
        },
      };
      this.props.readUserNotifications(sendRequest);
    }
    if (personalDetail.source === 'messenger') {
      let userChatInfo = {
        oppositeUserUid: personalDetail.senderUID,
        profilePictureURL:
          personalDetail && personalDetail.senderProfilePictureURL
            ? personalDetail.senderProfilePictureURL
            : profilePlaceholder,
        displayName:
          personalDetail && personalDetail.senderFirstName
            ? personalDetail.senderFirstName
            : '',
      };
      this.props.history.push(MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal(personalDetail.docID),
      });
    }

    if (personalDetail.source === 'rdeemarketplace') {
      if (personalDetail.navigateToID) {
        if (personalDetail.orderScreenType === 'buyer') {
          this.props.history.push(
            routes.PLACED_ORDERS_DETAILS + `/${personalDetail.navigateToID}`
          );
        }
        if (personalDetail.orderScreenType === 'seller') {
          this.props.history.push(
            routes.SELLER_ORDER_DETAIL + `/${personalDetail.navigateToID}`
          );
        }
      }
    }
  }
  updateStatus() {
    if (this.state.checkChangeUserStatus !== this.state.userStatus) {
      let sendrequest = {
        data: {
          statusMessage: this.state.userStatus,
        },
      };
      this.props.ModifyPersonalInfo(sendrequest);
    }
  }

  onChangeUpdate(value) {
    this.setState({
      userStatus: value,
    });
  }
  acceptRequest(item, status) {
    let sendRequest = {
      data: {
        messageID: item.messageID,
        accepted: status,
      },
    };

    this.props.acceptContactFunc(sendRequest, item.sourceUID);
  }
  goToprofile(item) {
    this.props.history.push(routes.CONTACTUSERDETAIL + `/${item.docID}`);
  }

  goToMessengerChat(personalDetail) {
    if (personalDetail.chatType === 'singleChat') {
      let userChatInfo = {};
      userChatInfo = {
        oppositeUserUid: personalDetail.oppositeUserUid,
        profilePictureURL:
          personalDetail && personalDetail.profilePictureURL
            ? personalDetail.profilePictureURL
            : profilePlaceholder,
        displayName:
          personalDetail && personalDetail.displayName
            ? personalDetail.displayName
            : '',
      };
      this.props.history.push(MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal(personalDetail.lastMsgSentBy),
      });
    } else {
      this.props.history.push(MESSENGER, {
        userChatInfo: personalDetail,
      });
    }
  }

  goToDetail(personDetail) {
    if (personDetail.status === 'unread') {
      let sendRequest = {
        data: {
          messageID: personDetail.messageID,
        },
      };
      this.props.readUserNotifications(sendRequest);
    }
  }
  openModal(item) {
    this.setState({
      modal: true,
      notificationdetail: item,
    });
  }
  closeModal() {
    this.setState({
      modal: false,
    });
  }

  deleteNotification() {
    let sendRequest = {
      data: {
        messageID: this.state.notificationdetail.messageID,
      },
    };

    this.props.deleteNotifications(sendRequest);
    setTimeout(() => {
      this.closeModal();
    }, 500);
  }

  startMeeting() {
    const { userInfo } = this.props;
    if (
      userInfo &&
      userInfo.user &&
      userInfo.user.Data &&
      userInfo.user.Data.userDetails
    ) {
      let userDataresponse = userInfo.user.Data.userDetails;
      if (userDataresponse.length > 10) {
        if (userDataresponse[11] && userDataresponse[11].confBridgeCode) {
          this.props.history.push(
            `${routes.VIDEO_CALL}/${userDataresponse[11].confBridgeCode}`
          );
        }
      }
    }
  }

  render() {
    const {
      boxOrder,
      contactListing,
      latestMessageListing,
      dashboardCards,
      recentMessages,
      isAccess,
    } = this.state;
    const {
      userInfo: {
        user: {
          Data: { userDetails },
        },
      },
      contacts,
      userprofile,
    } = this.props;
    const [userPersonalDetails] = userDetails;
    // const { personalDetails } = userPersonalDetails;

    const { personalDetails } =
      userprofile &&
      userprofile.profileDetail &&
      userprofile.profileDetail.length > 0
        ? userprofile.profileDetail[0]
        : userPersonalDetails;
    // console.log (personalDetails);
    // // const [ userPersonalDetails ] = userDetails;
    // // const { personalDetails } = userPersonalDetails;
    const { profilePictureURL, title, firstName, lastName } = personalDetails
      ? personalDetails
      : {
          profilePictureURL: '',
          title: '',
          firstName: '',
          lastName: '',
          statusMessage: '',
          displayName: '',
        };

    const userName =
      title +
      ' ' +
      (firstName ? ShowNameAccordingToLength(firstName + ' ' + lastName) : '');
    // const greetingMessages= this.getGreetingTime(12);
    const greetingMessages = this.greetingMessages();

    const { profileCompletion, userStatus } = this.state;

    let checkHours =
      profileCompletion && profileCompletion.profileCompletedDateAtISO
        ? moment().diff(
            moment(profileCompletion.profileCompletedDateAtISO),
            'hours',
            true
          )
        : 0;
    return (
      <div className=' mainDashboardContainer' id='profileDashContainer'>
        {isAccess && (
          <div
            className='dashboard-container mainDashboardView '
            id='profileDashChildContainer'
          >
            <div className='dashBoradInnerContainer' id='profileDashUserCard'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    this.startMeeting();
                  }}
                  className='startDashboardMetting'
                  data-rh='Start meeting'
                >
                  {' '}
                  <img
                    style={{ width: 30, height: 30, paddingRight: 7 }}
                    src={videoCamera}
                    alt='videoCamera'
                  />{' '}
                  <p>Start a Meeting</p>
                </Button>
              </div>

              <div
                className='notification-user-card-container'
                id='profileDashUserCard1'
              >
                <div className='user-card-container' id='profileDashUserCard2'>
                  <UserCard
                    userImage={
                      profilePictureURL ? profilePictureURL : profilePlaceholder
                    }
                    userName={userName ? userName : ''}
                    getGreetingTime={greetingMessages}
                    history={this.props.history}
                    updateStatus={this.updateStatus}
                    statusMessage={userStatus}
                    onChangeUpdate={this.onChangeUpdate}
                  />
                </div>

                <div style={{ height: 40 }} />
                {/*profileCompletion.percentageComplete < 100 &&
                checkHours < 24 &&*/}

                {profileCompletion.percentageComplete < 100 && checkHours < 24 && (
                  <div
                    className='profileContainer'
                    style={{ paddingTop: 27 }}
                    id='profileDashCompletion'
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      id='profileDashCompletion1'
                    >
                      <div className='notificationButton'>Your Profile</div>

                      <div
                        style={{ width: 200, marginRight: 38 }}
                        id='profileDashCompletion2'
                      >
                        <p
                          className='completeProfileTitle'
                          id='profileDashCompletion3'
                        >
                          {profileCompletion &&
                          profileCompletion.percentageComplete
                            ? profileCompletion.percentageComplete
                            : 0}
                          % Complete
                        </p>
                        <Progress
                          value={
                            profileCompletion &&
                            profileCompletion.percentageComplete
                              ? profileCompletion.percentageComplete
                              : 0
                          }
                          className='progresPercentage'
                          id='profileDashCompletionprogress'
                        />
                      </div>
                    </div>
                    <div
                      className='padding31'
                      style={{ paddingTop: 10 }}
                      id='profileDashCompletion5'
                    >
                      <div
                        className='profileCompleteTitle'
                        id='profileDashCompletion6'
                      >
                        Your profile is{' '}
                        {profileCompletion &&
                        profileCompletion.percentageComplete
                          ? profileCompletion.percentageComplete
                          : 0}
                        % complete.
                      </div>
                      <div
                        className='profileCompleteDescription'
                        id='profileDashCompletion7'
                      >
                        Complete the following action items to finish your
                        profile and unlock all Radiologex features.
                      </div>

                      <div
                        className='profileStep-container '
                        id='profileDashCompletionBox'
                      >
                        {boxOrder &&
                          boxOrder.length > 0 &&
                          boxOrder.map((item, i) => (
                            <div key={i}>
                              {item.key === 'kycSetup' && (
                                <div id='profileDashCompletionBoxkycSetup'>
                                  <div
                                    className={
                                      'profileCompletionBox ' +
                                      (profileCompletion &&
                                      profileCompletion.kycSetup
                                        ? '  boxBlackColor boxWhiteBg '
                                        : i === 0
                                        ? ' boxWhiteColor boxBlueBg  '
                                        : ' boxWhiteColor darkGrayBg ')
                                    }
                                  >
                                    <h3 className='stepTitle'>
                                    {profileCompletion &&
                                      profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus.Title?profileCompletion.kycrealtimeStatus.Title:''}
                                      {/* {profileCompletion &&
                                      profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonText &&
                                      profileCompletion.kycrealtimeStatus.ButtonText.toLowerCase() ===
                                        'start'
                                        ? ' Complete '
                                        : ''}
                                      {profileCompletion &&
                                      profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonText &&
                                      profileCompletion.kycrealtimeStatus.ButtonText.toLowerCase() ===
                                        'continue'
                                        ? ' Continue to Complete '
                                        : ''}
                                      {profileCompletion &&
                                      profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonText &&
                                      profileCompletion.kycrealtimeStatus.ButtonText.toLowerCase() !==
                                        'continue' &&
                                      profileCompletion.kycrealtimeStatus.ButtonText.toLowerCase() !==
                                        'start'
                                        ? profileCompletion.kycrealtimeStatus
                                            .ButtonText + ' '
                                        : ''}
                                      Your KYC{' '} */}
                                    </h3>

                                    

                                     {profileCompletion && profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus.DescriptionText && (
                                        <p className='stepDescription'>
                                          {profileCompletion.kycrealtimeStatus.DescriptionText}
                                        </p>
                                      )}

                                    {/* {profileCompletion &&
                                      profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonState &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonState !== 'inactive' && (
                                        <p className='stepDescription'>
                                          Complete your KYC verification so that
                                          you can buy or sell products, hire
                                          service providers, and complete
                                          payments on Radiologex.
                                        </p>
                                      )} */}
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: i === 0 ? '50%' : '',
                                      marginRight: i === 2 ? '50%' : '',
                                    }}
                                    className='boxesBorder'
                                  />
                                  {profileCompletion &&
                                  (profileCompletion.kycSetup ||
                                    (profileCompletion.kycrealtimeStatus &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonState &&
                                      profileCompletion.kycrealtimeStatus
                                        .ButtonState !== 'active')) ? (
                                    <div className='CompletedBlueCircle'>
                                      <img src={rightCheck} alt='rightArrow' />
                                    </div>
                                  ) : (
                                    <div
                                      className='RemainingGrayCircle'
                                      onClick={() => {
                                        this.props.history.push(USER_KYC);
                                      }}
                                    >
                                      <img src={rightArrow} alt='rightArrow' />
                                    </div>
                                  )}
                                </div>
                              )}
                              {item.key === 'userProfileSetup' && (
                                <div id='profileDashCompletionBoxuserProfileSetup'>
                                  <div
                                    id='profileDashCompletionBoxuserProfileSetup1'
                                    className={
                                      'profileCompletionBox ' +
                                      (profileCompletion &&
                                      profileCompletion.userProfileSetup
                                        ? '  boxBlackColor boxWhiteBg '
                                        : i === 0
                                        ? ' boxWhiteColor boxBlueBg  '
                                        : ' boxWhiteColor darkGrayBg ')
                                    }
                                  >
                                    <h3
                                      className='stepTitle'
                                      id='profileDashCompletionBoxuserProfileSetup2'
                                    >
                                      Complete User Profile
                                    </h3>
                                    <p
                                      className='stepDescription'
                                      id='profileDashCompletionBoxuserProfileSetup3'
                                    >
                                      Complete your User Profile setup so that
                                      others can search for you and send
                                      messages through Messenger.
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: i === 0 ? '50%' : '',
                                      marginRight: i === 2 ? '50%' : '',
                                    }}
                                    className='boxesBorder'
                                  />
                                  {profileCompletion &&
                                  profileCompletion.userProfileSetup ? (
                                    <div className='CompletedBlueCircle'>
                                      <img src={rightCheck} alt='rightArrow' />
                                    </div>
                                  ) : (
                                    <div
                                      className='RemainingGrayCircle'
                                      onClick={() => this.goToEditProfile(1)}
                                    >
                                      <img src={rightArrow} alt='rightArrow' />
                                    </div>
                                  )}
                                </div>
                              )}

                              {item.key === 'businessProfileSetup' && (
                                <div>
                                  <div
                                    className={
                                      'profileCompletionBox ' +
                                      (profileCompletion &&
                                      profileCompletion.businessProfileSetup
                                        ? '  boxBlackColor boxWhiteBg '
                                        : i === 0
                                        ? ' boxWhiteColor boxBlueBg  '
                                        : ' boxWhiteColor darkGrayBg ')
                                    }
                                    id='profileDashCompletionBoxbusinessProfileSetup'
                                  >
                                    <h3
                                      className='stepTitle'
                                      id='profileDashCompletionBoxbusinessProfileSetup1'
                                    >
                                      Complete Business Registration
                                    </h3>
                                    <p
                                      className='stepDescription'
                                      id='profileDashCompletionBoxbusinessProfileSetup2'
                                    >
                                      Link or setup your Business Profile to
                                      easily find - and be found - by
                                      co-workers.
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: i === 0 ? '50%' : '',
                                      marginRight: i === 2 ? '50%' : '',
                                    }}
                                    className='boxesBorder'
                                    id='profileDashCompletionBoxbusinessProfileSetup3'
                                  />
                                  {profileCompletion &&
                                  profileCompletion.businessProfileSetup ? (
                                    <div className='CompletedBlueCircle'>
                                      <img src={rightCheck} alt='rightArrow' />
                                    </div>
                                  ) : (
                                    <div
                                      className='RemainingGrayCircle'
                                      onClick={() => {
                                        this.props.history.push(
                                          BUSINESS_REGISTER
                                        );
                                      }}
                                    >
                                      <img src={rightArrow} alt='rightArrow' />
                                    </div>
                                  )}
                                </div>
                              )}

                              {/*{item.key === 'trusonaSetup' &&
                              <div>

                                <div
                                  className={
                                    'profileCompletionBox ' +
                                      (profileCompletion &&
                                        profileCompletion.trusonaSetup
                                        ? '  boxWhiteColor boxBlueBg'
                                        : ' boxBlackColor boxWhiteBg ')
                                  }
                                >
                                  <p className="stepTitle">
                                    Setup Trusona
                                    Authentication
                                  </p>
                                  <p className="stepDescription">
                                    Complete your passwordless authentication setup for a more secure and simple login.
                                  </p>

                                </div>
                                <div
                                  style={{
                                    marginLeft: i === 0 ? '50%' : '',
                                    marginRight: i === 3 ? '50%' : '',
                                  }}
                                  className="boxesBorder"
                                />
                                {profileCompletion &&
                                  profileCompletion.trusonaSetup
                                  ? <div className="CompletedBlueCircle">
                                      <img src={rightCheck} alt="rightArrow" />
                                    </div>
                                  : <div className="RemainingGrayCircle">
                                      <img src={rightArrow} alt="rightArrow" />
                                    </div>}
                                </div>}*/}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

                {dashboardCards && dashboardCards.length > 0 && (
                  <div className='highLightsBox' id='profileDashHighlights'>
                    <div className='highLightSectionHeading'>
                      Radiologex Highlights
                    </div>

                    <div
                      className='highLightBox-Listcontainer'
                      id='profileDashHighlightsBox'
                    >
                      {dashboardCards.map((item, i) => (
                        <div
                          key={i}
                          id={`profileDashHighlights${i}`}
                          className='highLightBox'
                          onClick={() => {
                            window.open(item.redirectionLink, '_blank');
                          }}
                        >
                          <div className='highfeedTitle'>
                            {item.type ? item.type : ''}
                          </div>
                          <div
                            className='imgContentBox'
                            style={{
                              backgroundImage: 'url(' + item.imageURL + ')',
                            }}
                          ></div>
                          <div
                            className='WhiteBoxDescription'
                            id={`profileDashHighlights${1 + i}`}
                          >
                            <p className='highLightDate'>
                              {item && item.scheduledAtTimestamp
                                ? dataFormat(item.scheduledAtTimestamp)
                                : 'N/A'}
                            </p>
                            <p className='highLightTitle'>{item.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{ height: 40, marginTop: 15 }}
                      id='profileDashHighlightsViewall'
                    >
                      <div
                        id='profileDashHighlightsViewall1'
                        data-rh='View all'
                        className='allViewHighLightsButton'
                        onClick={() =>
                          this.props.history.push(routes.HIGHLIGHTS)
                        }
                      >
                        View All
                      </div>
                    </div>
                  </div>
                )}
                {contactListing && contactListing.length > 0 && (
                  <div
                    className='blueBg'
                    style={{
                      paddingTop: 30,
                      borderRadius: 10,
                      marginBottom: 15,
                    }}
                    id='profileDashContactRequest'
                  >
                    <div className='contactButton'>Contact Requests</div>
                    <div style={{ padding: 15 }}>
                      <div>
                        {/* className="contacts-Listcontainer " */}
                        <ScrollMenu
                          alignCenter={false}
                          data={contactListing.map((item, i) => (
                            <div
                              key={i}
                              className='contactGrayBox'
                              id={`profileDashContactRequest${i}`}
                            >
                              <div
                                className='userInfoBox'
                                id={`profileDashContactRequest${i + i}`}
                              >
                                <div className='imgBox' id={`imgBox${i}`}>
                                  <img
                                    src={
                                      item.contactDetails &&
                                      item.contactDetails.profilePictureURL
                                        ? item.contactDetails.profilePictureURL
                                        : profilePlaceholder
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = profilePlaceholder;
                                    }}
                                    alt='img'
                                  />
                                </div>
                                <div
                                  className='usernameBox'
                                  onClick={() =>
                                    this.goToprofile(item.contactDetails)
                                  }
                                  id={`usernameBox${i}`}
                                >
                                  <p
                                    className='contactUsername'
                                    id={`contactUsername${i}`}
                                  >
                                    {item && item.contactDetails
                                      ? ShowNameAccordingToLength(
                                          item.contactDetails.firstName +
                                            ' ' +
                                            item.contactDetails.lastName
                                        )
                                      : ''}

                                    {/* item.contactDetails && item.contactDetails.firstName? item.contactDetails.firstName.slice(0,15)+(item.contactDetails.displayName.length>15?'...':''):item.contactDetails && item.contactDetails.firstName? item.contactDetails.firstName:''*/}
                                  </p>
                                  <p
                                    className='contactUserposition'
                                    id={`contactUserposition${i}`}
                                  >
                                    {item.contactDetails &&
                                    item.contactDetails.position
                                      ? item.contactDetails.position
                                      : ''}
                                  </p>
                                </div>
                                <div
                                  className='contactRequestDate'
                                  id={`contactRequestDate${i}`}
                                >
                                  {item &&
                                  item.pendingActionDetails &&
                                  item.pendingActionDetails.lastUpdatedAtISO
                                    ? notificationDateFormat(
                                        item.pendingActionDetails
                                          .lastUpdatedAtISO
                                      )
                                    : ''}
                                </div>
                              </div>
                              <div className='buttonsBox'>
                                <Button
                                  id={`approveButton${i}`}
                                  className='approveButton'
                                  onClick={() =>
                                    this.acceptRequest(
                                      item.pendingActionDetails,
                                      true
                                    )
                                  }
                                >
                                  Approve contact
                                </Button>
                                <Button
                                  id={`removeRequestButton${i}`}
                                  className='removeRequestButton'
                                  onClick={() =>
                                    this.acceptRequest(
                                      item.pendingActionDetails,
                                      false
                                    )
                                  }
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                          arrowLeft={ArrowLeft}
                          arrowRight={ArrowRight}
                        />
                      </div>
                    </div>
                    <div style={{ height: 70 }}>
                      <div
                        id='RequestButtonViewAll'
                        data-rh='Contact Requests'
                        className='allViewnotificationButton'
                        onClick={() => this.props.history.push(CONTACTREQUEST)}
                      >
                        View All
                      </div>
                    </div>
                  </div>
                )}
                {latestMessageListing && latestMessageListing.length > 0 && (
                  <div
                    className='purpleNotificationsBg'
                    style={{
                      paddingTop: 27,
                      borderRadius: 10,
                      marginBottom: 22,
                    }}
                    id='profileDashLatestNoti'
                  >
                    <div
                      className='contactButton'
                      id='profileDashLatestNotilabel'
                    >
                      Latest Notifications
                    </div>
                    <div>
                      {latestMessageListing &&
                        latestMessageListing.length === 0 && (
                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <p className='contactUsername'>
                              No new notifications{' '}
                            </p>
                          </div>
                        )}
                      <div>
                        {/*  className="message-Listcontainer "*/}

                        <ScrollMenu
                          alignCenter={false}
                          data={latestMessageListing.map((item, index) => (
                            <div
                              key={index}
                              // onClick={() => this.goToDetail (item)}
                              className='DashboardnotificationMessagescontainer'
                              style={{
                                background:
                                  item.status === 'unread'
                                    ? '#17171D'
                                    : '#333333',
                              }}
                              id={`profileDashLatestNoti${index}`}
                            >
                              <div
                                className='topTitleHeader'
                                style={{ justifyContent: 'space-between' }}
                                id={`topTitleHeader${index}`}
                              >
                                <div
                                  className='displayInlineFlex headingContainer'
                                  onClick={() => this.goToDetail(item)}
                                  id={`headingContainer${index}`}
                                >
                                  {item.status === 'unread' && (
                                    <div className='dashboardUnreadEllipse' />
                                  )}
                                  <span className='messageDate'>
                                    R-DEE
                                    {item.source === 'rdeemarketplace'
                                      ? ' Marketplace '
                                      : ''}
                                    {item.source === 'rdeepay'
                                      ? ' Settle '
                                      : ''}
                                    {item.source === 'pacs' ? ' Scan ' : ''}
                                    {item.source === 'system' ? ' System ' : ''}
                                    <span
                                      style={{ marginLeft: 6, marginRight: 6 }}
                                    >
                                      {' • '}
                                    </span>
                                    <span
                                      style={{ textTransform: 'uppercase' }}
                                    >
                                      {item && item.updatedAtISO
                                        ? notificationdataTimeFormat(
                                            item.updatedAtISO
                                          )
                                        : ''}
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  <Button
                                    className='removeButton'
                                    onClick={() => this.openModal(item)}
                                    data-rh='Delete'
                                    id={`removeButton${index}`}
                                  >
                                    <img src={close_icon} alt='close_icon' />
                                  </Button>
                                </div>
                              </div>
                              <div
                                className='bottomContent'
                                id={`bottomContent${index}`}
                                onClick={() => this.goToDetail(item)}
                              >
                                <div
                                  className='notification-info '
                                  id={`notification-info${index}`}
                                >
                                  <div
                                    className='notification-heading'
                                    id={`notification-heading${index}`}
                                  >
                                    {item.source === 'rdeemarketplace'
                                      ? item.subject
                                        ? item.subject
                                        : ''
                                      : item.titleName
                                      ? item.titleName
                                      : ''}
                                  </div>
                                  <div
                                    className='dashboardnotification-body'
                                    id={`dashboardnotification-body${index}`}
                                  >
                                    {item.body}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          arrowLeft={ArrowLeft}
                          arrowRight={ArrowRight}
                        />
                      </div>
                    </div>
                    {latestMessageListing && latestMessageListing.length > 0 && (
                      <div style={{ height: 70 }}>
                        <div
                          id='allViewLatestNotificationButton'
                          data-rh='Notification'
                          className='allViewLatestNotificationButton'
                          onClick={() => this.props.history.push(NOTIFICATION)}
                        >
                          View All
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/*} <div
                className="blueBg"
                style={{paddingTop: 20, borderRadius: 10}}
              >
                <div className="notificationButton">Notifications</div>

                <div className="notification-Listcontainer ">

                  <Notifications
                    notifications={notificationsList}
                    props={this.props}
                  />
                </div>
                <div style={{height: 70}}>
                  <div
                    className="allViewnotificationButton"
                    onClick={() => this.props.history.push (NOTIFICATION)}
                  >
                    View All
                  </div>
                </div>

                            </div>*/}

                {recentMessages && recentMessages.length > 0 && (
                  <div
                    id='profileDashConnect'
                    className='darkGrayBg'
                    style={{
                      paddingTop: 27,
                      borderRadius: 10,
                      marginBottom: 22,
                    }}
                  >
                    <div
                      className='messengerButton'
                      id='profileDashConnectlabel'
                    >
                      {' '}
                      Connect
                    </div>
                    <div style={{ padding: '15 0' }}>
                      <div>
                        <ScrollMenu
                          alignCenter={false}
                          data={recentMessages.map((item, i) => (
                            <div
                              key={i}
                              className='messageWhiteBox'
                              onClick={() => this.goToMessengerChat(item)}
                              id={`messageWhiteBox${i}`}
                            >
                              <div
                                className='messageDate'
                                id={`messageDate${i}`}
                              >
                                {item && item.timeStamp
                                  ? notificationDateFormat(item.timeStamp)
                                  : ''}
                                {item.read === false && (
                                  <div className='unreadBox' />
                                )}
                              </div>
                              <div className='messageBox' id={`messageBox${i}`}>
                                <div
                                  className='imgBox'
                                  id={`messageBoximgBox${i}`}
                                >
                                  {item.chatType === 'singleChat' ? (
                                    <img
                                      src={
                                        item.profilePictureURL
                                          ? item.profilePictureURL
                                          : profilePlaceholder
                                      }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = profilePlaceholder;
                                      }}
                                      alt='imgBox'
                                    />
                                  ) : (
                                    <img
                                      src={
                                        item.groupIcon
                                          ? item.groupIcon
                                          : profilePlaceholder
                                      }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = profilePlaceholder;
                                      }}
                                      alt='imgBox'
                                    />
                                  )}
                                </div>
                                <div
                                  className='usernameBox'
                                  id={`messageBoxusernameBox${i}`}
                                >
                                  <p
                                    className='contactUsername'
                                    id={`messageBoxcontactUsername${i}`}
                                  >
                                    {item && item.displayName
                                      ? ShowNameAccordingToLength(
                                          item.displayName
                                        )
                                      : ''}
                                  </p>
                                  <p
                                    className='contactUserposition'
                                    id={`messageBoxcontactUserposition${i}`}
                                  >
                                    {item.lastMsg ? item.lastMsg : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                          arrowLeft={ArrowLeft}
                          arrowRight={ArrowRight}
                        />
                      </div>
                    </div>
                    {recentMessages && recentMessages.length > 0 && (
                      <div style={{ height: 70 }}>
                        <div
                          data-rh='Messenger'
                          className='allViewHighLightsButton'
                          onClick={() => this.props.history.push(MESSENGER)}
                          id='MessengerAllButtons'
                        >
                          View All
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div style={{ display: 'flex', flex: 1 }} />
            </div>
            <RightSideContainer>
              <div>
                <div className='contactContainer' id='contactContainer'>
                  <div className='contactHeader' id='contactHeader'>
                    <div className='titleContact' id='titleContact'>
                      Contacts
                    </div>
                    <div className='titleContact' id='titleContact1'>
                      <Button
                        id='newContactButton'
                        className='newContactButton'
                        onClick={() => this.props.history.push(routes.CONTACT)}
                      >
                        <img
                          style={{ width: 20, height: 20, marginRight: 10 }}
                          src={bluePlusIcon}
                          alt='bluePlusIcon'
                        />
                        New Contact
                      </Button>
                    </div>
                  </div>
                  <div className='contactHeader' id='contactHeader'>
                    <div
                      className='contactsearchBoxInput'
                      id='contactsearchBoxInput'
                    >
                      <img
                        src={searchIcon}
                        alt='Search'
                        className='searchContactIcon'
                      />
                      <input
                        id='searchContactInput'
                        type='text'
                        className='searchInput'
                        placeholder='Search by name or handle'
                        value={this.state.searching}
                        onChange={(e) =>
                          this.setState({ searching: e.target.value }, () => {
                            this.props.getAllUserContactsFunc({
                              data: {
                                searchString: this.state.searching,
                              },
                            });
                          })
                        }
                      />
                    </div>
                  </div>

                  <div
                    className='contactListContainer'
                    id='contactListContainer'
                  >
                    {contacts && contacts.length == 0 && (
                      <div className='noContacts'> Contact Not Found</div>
                    )}
                    {contacts.map((obj, i) => (
                      <div
                        id={`dashboardcontactList${i}`}
                        className='dashboardcontactList'
                        key={i}
                        // onClick={() => this.goToChat (obj.contact)}
                        onClick={() => {
                          this.props.history.push(
                            routes.CONTACTUSERDETAIL +
                              `/${obj.contact.contactDetail.docID}`
                          );
                        }}
                      >
                        <div
                          className='imageBox'
                          id={`dashboardcontactListimageBox${i}`}
                        >
                          <img
                            className='avatarimage'
                            src={
                              obj &&
                              obj.contact &&
                              obj.contact.contactDetail &&
                              obj.contact.contactDetail.profilePictureURL
                                ? obj.contact.contactDetail.profilePictureURL
                                : profilePlaceholder
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = profilePlaceholder;
                            }}
                            alt='ProfileImg'
                          />
                        </div>

                        <div>
                          <p
                            className='dashboardcontactListUsername'
                            id={`dashboardcontactdashboardcontactListUsername${i}`}
                          >
                            {obj && obj.contact && obj.contact.contactDetail
                              ? ShowNameAccordingToLength(
                                  obj.contact.contactDetail.firstName +
                                    ' ' +
                                    obj.contact.contactDetail.lastName
                                )
                              : ''}
                          </p>
                          {obj &&
                            obj.contact &&
                            obj.contact.contactDetail &&
                            obj.contact.contactDetail.position && (
                              <p
                                className='dashboardcontactListWorkPosition'
                                id={`dashboardashboardcontactListWorkPosition${i}`}
                              >
                                {obj &&
                                obj.contact &&
                                obj.contact.contactDetail &&
                                obj.contact.contactDetail.position
                                  ? obj.contact.contactDetail.position
                                  : ''}
                              </p>
                            )}

                          {obj &&
                            obj.contact &&
                            obj.contact.contactDetail &&
                            obj.contact.contactDetail.professionalTitle && (
                              <p
                                className='dashboardcontactListWorkPlace'
                                id={`dashboardashboardcontactListWorkPlace${i}`}
                              >
                                {obj &&
                                obj.contact &&
                                obj.contact.contactDetail &&
                                obj.contact.contactDetail.professionalTitle
                                  ? obj.contact.contactDetail.professionalTitle
                                  : ''}
                              </p>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*<Contacts
             bgColor={'blueBg'}
             gradientBg={'ContactgradientBg'}

            contacts={contacts.map(obj => obj.contact)}
            onContactClick={(userId) => history.push(`${MESSENGER}/${userId}`)}
          />*/}
                </div>
              </div>
            </RightSideContainer>
          </div>
        )}

        {!isAccess && (
          <CommonAccessContainer
            bgImage={InfoSyscoming}
            message={checkAvailableAccess('dashboard')}
          />
        )}

        <div>
          <Modal
            size='sm'
            isOpen={this.state.modal}
            centered={true}
            toggle={() => this.closeModal()}
            id='profileDashdeleteNoti'
          >
            <ModalBody className='deleteModal' id='profileDashdeleteModal'>
              <div>
                <p className='confirmTitle' id='profileDashconfirmTitle'>
                  Delete Notification?
                </p>
                <p className='confirmmsg' id='profileDashconfirmmsg'>
                  Are you sure you wish to remove this from your notifications?
                </p>
                <div className='buttonDiv' id='profileDashbuttonDiv'>
                  <Button
                    id='profileDashnoButton'
                    className='buttons noButton'
                    onClick={() => this.closeModal()}
                  >
                    No
                  </Button>
                  <Button
                    id='profileDashyesButton'
                    className='buttons yesButton'
                    onClick={() => this.deleteNotification()}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Authreducer,
    contacts: state.ContactsReducer.contacts,
    notifications: state.NotificationsReducer.notifications,
    userprofile: state.ContactsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserNotificationHistoryFunc,
      getAllUserContactsFunc,
      registerDeviceToken,
      getProfileDashboard,
      ModifyPersonalInfo,
      acceptContactFunc,
      readUserNotifications,
      fetchStatusCode,
      getChatRecentMessagesDashboard,
      resetProfileDashboard,
      deleteNotifications,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDashboard);
