


// Environment = "RDEEDEV" Or "RDEEPROD"

// RDEEPROD : {
// APIUrl = "https://us-central1-rdee-prod.cloudfunctions.net",
// WalletAPIUrl ="https://bcapi-prod.radiologex.com",
// MessengerUrl = "https://rdee-prod.firebaseio.com"
// }

// RDEEDEV : {
// APIUrl = "https://us-central1-radiologexapp.cloudfunctions.net",
// WalletAPIUrl ="https://bcapi.radiologex.com",
// MessengerUrl = "https://radiologexapp.firebaseio.com"
// }

// passprase for DEV is  "rivannetatsibwuraugh"
// passprase for PROD is  "sjfu12twhf68HHjtshe"




// Environment = "RDEEUAT" 

export const RDEEAPI_URL  = "https://us-central1-rdee-uat.cloudfunctions.net";
export const RDEE_BC_URL  = "https://bcapi-uat.radiologex.com/";
export const RDEE_BC_TOKEN  = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicmFkaW9sb2dleC1mcm9udGVuZCIsImlhdCI6MTU3Njc1ODg4NywiZXhwIjoxNjA4Mjk0ODg3fQ.6vc14BTXXyBpyx-SvMimSFAovWAQdE7Wnxumvx2vdUI";
export const RDEE_PASS_PRASE  = "rivannetatsibwuraugh";
export const RDEE_VAULT_URL  = "https://rdee-uat.mn-ws.com/";


// Environment = "RDEEDEV" 

// export const RDEEAPI_URL  = "https://us-central1-radiologexapp.cloudfunctions.net";
// export const RDEE_BC_URL  = "https://bcapi.radiologex.com/";
// export const RDEE_BC_TOKEN  = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicmFkaW9sb2dleC1mcm9udGVuZCIsImlhdCI6MTU3Njc1ODg4NywiZXhwIjoxNjA4Mjk0ODg3fQ.6vc14BTXXyBpyx-SvMimSFAovWAQdE7Wnxumvx2vdUI";
// export const RDEE_PASS_PRASE  = "rivannetatsibwuraugh";
// export const RDEE_VAULT_URL  = "https://rdee.mn-ws.com/";



// Environment  "RDEEPROD"

// export const RDEEAPI_URL  = "https://us-central1-rdee-prod.cloudfunctions.net";
// export const RDEE_BC_URL  = "https://bcapi-prod.radiologex.com/";
// export const RDEE_BC_TOKEN  = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicmFkaW9sb2dleC1mcm9udGVuZCIsImlhdCI6MTU4NzEzMDk0OCwiZXhwIjoxNjE4NjY2OTQ4fQ.4k0G5Ouwe5xv8diUjml9T7Ngf1u6fRaBMtwGvRm8ndQ";
// export const RDEE_PASS_PRASE  = "sjfu12twhf68HHjtshe";
// export const RDEE_VAULT_URL  = "https://rdee-prod.mn-ws.com/";


/* Flag for dev/prod */

export const DEV_SERVER= true; 