import React, { Fragment, useEffect,useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';

import './App.css';
import * as routes from '../constants/routes';

// TEST UI BRANCH

//nav stuff
/* import Navigation from "./Navigation";
import LandingPage from "./Landing"; */
import SignUpPage from './SignUp';
import OneStepPage from './OneStep';
import EducationDetailPage from './EducationDetail';
import ProfesionalDetailPage from './ProfesionalDetail';
import FindBusinessPage from './registerPage/FindBusiness';
import BusinessContactPage from './registerPage/BusinessContacts';
import ErrorPage from './errorPage/errorPage';

import DashBoardProcessPage from './registerPage/DashBoardProcess';
import InformationSubmitPage from './registerPage/InformationSubmited';
import BusinessDatabasePage from './registerPage/BusinessDatabase';
import BusinessDatabaseOneResultPage from './registerPage/BusinessDatabaseOneResult';
import ForgotPasswordPage from './forgotPassword/ForgotPassword';
import ForgotResetPage from './forgotPassword/ForgotReset';
import NewPasswordPage from './forgotPassword/NewPassword';
import PasswordSuccessfullPage from './forgotPassword/password-successful/PasswordSucessfull';
import SignInPage from './SignIn';
import MessengerPage from './messanger/App';
import PasswordForgetPage from './PasswordForget';
import AccountPage from './Account';
import PaymentPage from './paymentPage/Payment';
import AppContainer from './UI/AppContainer';
import PacsPage from './pacs/pacs';
import serviceOnDemandPage from './service-On-Demand/serviceOnDemand';
import serviceOnDemandProfilePage from './service-On-Demand-Profile/serviceOnDemandProfile';
import serviceOnDemandSearchPage from './service-On-Demand-Search/serviceonDemandSearch';
import serviceOnDemandMyRequestPage from './Service-On-Demand-MyRequest/serviceOnDemandMyRequest';
import serviceOnDemandMyRequestDetailPage from './service-On-Demand-MyRequestDetail/serviceOnDemandMyRequestDetail';
import Landingpage from './../components/landingPage/landingPage';
import ProfilePage from './profile/profile';
import ProfileDetail from './profile/profiledetail/index';
import BusinessDetailPage from './profile/businessdetail/index';
import BusinessEditPage from './profile/businessdetailEdit/index';
import BusinessManagement from './profile/businessManagement';

import ContactListPage from './contacts/contacts';
import UserProfileDetail from './contacts/userprofiles/index';
import editUserDetail from './profile/editProfile/editUser';
import EducationEditPage from './profile/editEducation/education';
import ProfesionalEditDetailPage from './profile/editProfessional/editProfessional';
import notificationPage from './notification/notification';
import allnotificationPage from './notification/allnotification/allnotification';
import serviceOnDemandProvierProfilePage from './service-On-Demand-Provider-Profile/serviceOnDemandProviderProfile';
import serviceOnDemandCreateServiceRequestPage from './Service-On-Demand-CreateServiceRequest/Service-On-Demand-CreateServiceRequest';
import serviceOnDemandCreateServiceRequestDetailPage from './service-On-Demand-CreateServiceRequestDetail/serviceonDemandCreateServiceRequestDetail';
import ServiceOnDemandCommanComponentPage from './Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';

import serviceOnDemandRequestRecievedPage from './serviceOnDemand/serviceProiders/requestRecieved/requestRecieved';
import serviceOnDemandRecievedRequestDetailPage from './serviceOnDemand/serviceProiders/recievedRequestDetail/recievedRequestDetail';
import serviceOnDemandCreateServiceProfilePage from './serviceOnDemand/serviceProiders/createServiceProfile/createServiceProfile';
import serviceOnDemandServiceProfilePage from './serviceOnDemand/serviceProiders/serviceProfile/serviceProfile';

import UserKycPage from '../components/userKyc/userKyc';

import SettingsPage from '../components/settingsPage/settingsPage';

import contactRequest from './contactRequest/contactRequest';

import AddUserProfileDetail from '../components/addUserProfileDetail/addUserProfile';
import serviceOnDemandSidebarPage from './serviceOnDemandSidebar/serviceOnDemandSidebar';
import ProfileDashboard from './profileDashboard';

import withAuthentication from './withAuthentication';
import LoaderSpinner from './globalLoader/index';
import CustomLoaderSpinner from './globalLoader/customLoader/index';

import businessRegister from '../components/businessRegister/businessRegister';
import 'react-phone-number-input/style.css';
import { SHOW_ERROR } from './../reducers/errorReducer';
import ReputationDetails from '../components/reputationDetails/reputationDetails';
import AccountSettings from '../components/accountSettings/accountSettings';
import NewTransaction from './paymentPage/newTransaction/index';
import MyRequest from './paymentPage/myRequest/index';
import MarketPlaceSearch from '../components/marketplace/Components/marketPlaceSearch/marketPlaceSearch';
import ProductDetail from '../components/marketplace/Components/marketPlaceProductDetail/productDetail';

import BrowseProduct from '../components/marketplace/Components/browseProducts/browseProducts';

import MarketPlacedOrders from '../components/marketplace/Components/marketplacePlacedorder/marketPlacePlacedOrders';
import SellerConsole from '../components/marketplace/Components/sellerConsole/sellerConsole';
import SellerAddProduct from '../components/marketplace/Components/sellerConsole/sellerAddProduct/sellerAddProduct';
import SellerViewListing from '../components/marketplace/Components/sellerConsole/sellerViewlisting/sellerViewListing';
import SellerConsoleSidemenu from '../components/marketplace/Components/sellerConsole/sellerSidemenu/sellerConsoleSidemenu';
import PlacedOrderDetails from '../components/marketplace/Components/marketplacePlacedorder/placeorderdetails/placeOrderDetails';

import SellerOrderDetails from '../components/marketplace/Components/sellerConsole/sellerOrderDetail/sellerOrderDetail';
import ContentApps from '../components/contentApps/contentApps';
import InfoSys from '../components/InfoSys/InfoSys';

import TermsConditionPage from '../components/termsCondition';
import ProfileCompletion from '../components/auth/profile-completion/profileCompletionProcess';
import PaymentSuccess from '../components/paymentPage/paymentSuccess/paymentSuccess';
import VerifyEmail from '../components/auth/verifyEmail/verifyEmail';

import HighLightsPage from '../components/highlights/highlights';

import VideoCalling from '../components/messanger/videoCalling/videoCalling';
import BulkUploadAddProduct from '../components/marketplace/Components/sellerConsole/bulkUploadProduct/bulkUploadProduct';

import FileDrive from '../components/fileDrive/fileDrive';
import DownLoadShareFile from '../components/fileDrive/openShareFile/openFile';
import WithDraw from '../components/paymentPage/withdrawPayment';
import WelcomePage from '../components/welcomeScreen/welcomeScreen';

import EmailDashboardPage from '../components/emailClient/emailDashboard/dashboard';
import MessageContent from '../components/emailClient/emailDashboard/message-content/MessageContent';




// ** Start Mobile Components **//

import LoginComponent from '../mobileComponents/components/authComponent/login/login';

// ** end Mobile Components **//

import { checkScreenSize } from '../utility/checkScreenSize';

import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
const ReactHint = ReactHintFactory(React);
window.onclick = function(event) {
  if (!event.target.matches('.checkdropMenu')) {
    var element = document.getElementById('dropdownmenu');
    if (element) {
      element.classList.add('hide');
    }
  } else {
    var element = document.getElementById('dropdownmenu');
    element.classList.remove('hide');
  }
};

// window.onclick = function (event) {
//   if (!event.target.matches ('.checkActivedropMenu')) {
//     var element = document.getElementById ('activeUserStatus');
//     if (element) {
//       element.classList.add ('hide');
//     }
//   } else {
//     var element = document.getElementById ('activeUserStatus');
//     element.classList.remove ('hide');
//   }
// };

const App = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  // use effect in place of component did mount
  useEffect(() => {
    // console.log("useEffect>>>>>.  hokks   APP")
    // window.addEventListener('resize', checkScreenSize());
    // alert("hihhhi")
  });

  // <Provider store={store}>

  // console.log(checkScreenSize());

  const updateNetwork = () => {
    console.log(window.navigator.onLine);
    setNetwork(window.navigator.onLine);
 };

    useEffect(() => {
        window.addEventListener("offline", updateNetwork);
        window.addEventListener("online", updateNetwork);
        return () => {
            window.removeEventListener("offline", updateNetwork);
            window.removeEventListener("online", updateNetwork);
          };
      });
      // return isOnline;
    // };
  return (
    <BrowserRouter>
      <Fragment>
      {!isOnline && <Modal
          isOpen={true}
          centered={true}
          toggle={() => window.location.reload()}
          size='sm'
        >
          <ModalBody className='noConnectionModal'>
            <div>
              <p className='noConnectionText'>You are offline</p>
              <p className='noConnectionText' style={{ fontSize: 18 }}>
                Please check your internet connection
              </p>
            </div>
          </ModalBody>
        </Modal>}
        <Switch>
          {/*  <Navigation /> */}
          {/*<Route exact path={'*'} 
/>*/}

          <Route exact path={routes.LANDING_PAGE} component={Landingpage} />

          {/*} <Route exact path={routes.SIGN_IN} component={SignInPage } />*/}
          <Route
            exact
            path={routes.SIGN_IN}
            component={SignInPage}

            // component={checkScreenSize() ? LoginComponent : SignInPage}
          />

          {/* <Route exact path={routes.LANDING} component={LandingPage} /> */}
          <Route exact path={routes.SIGN_UP} component={SignUpPage} />
          <Route exact path={routes.ONE_STEP} component={OneStepPage} />
          <Route
            exact
            path={routes.PROFILE_COMPLETION}
            component={ProfileCompletion}
          />

          <Route
            exact
            path={routes.EDUCATIONDETAIL}
            component={EducationDetailPage}
          />
          <Route
            exact
            path={routes.PROFESIONALDETAIL}
            component={ProfesionalDetailPage}
          />
          <Route
            exact
            path={routes.FINDBUSINESS}
            component={FindBusinessPage}
          />
          <Route
            exact
            path={routes.BUSINESSCONTACTS}
            component={BusinessContactPage}
          />

          <Route
            exact
            path={routes.DASHBOARDPROCESS}
            component={DashBoardProcessPage}
          />

          <Route
            exact
            path={routes.INFORMATIONSUBMIT}
            component={InformationSubmitPage}
          />
          <Route
            exact
            path={routes.BASINESSDATABASE}
            component={BusinessDatabasePage}
          />
          <Route
            exact
            path={routes.BASINESSDATABASEONERESULT}
            component={BusinessDatabaseOneResultPage}
          />
          <Route
            exact
            path={routes.FORGOTPASSWORD}
            component={ForgotPasswordPage}
          />
          <Route exact path={routes.FORGOTRESET} component={ForgotResetPage} />
          <Route exact path={routes.NEWPASSWORD} component={NewPasswordPage} />
          <Route
            exact
            path={routes.PASSWORDSUCCESSFULL}
            component={PasswordSuccessfullPage}
          />
          <Route exact path={routes.ERRORPAGE} component={ErrorPage} />

          {/*<Route exact path={routes.PAYMENT} component={PaymentPage} />*/}
          <Route
            path={`${routes.PAYMENTSTATUS}/:status`}
            component={PaymentSuccess}
          />
          <Route path={`${routes.VERIFY_EMAIL}`} component={VerifyEmail} />

          <Route exact path={routes.PAYMENT_WITHDRAW} component={WithDraw} />
          <Route
            path={`${routes.OPEN_SHARED_FILE}`}
            component={DownLoadShareFile}
          />

          <Route
            path={routes.HOME}
            component={(pathParams) => {
              return (
                <AppContainer {...pathParams}>
                  <Switch>
                    <Route
                      exact
                      path={`${routes.MESSENGER}/:conversationId?`}
                      component={MessengerPage}
                      {...pathParams}
                    />

                    <Route
                      exact
                      path={`${routes.VIDEO_CALL}/:conversationId`}
                      component={VideoCalling}
                      {...pathParams}
                    />

                    <Route
                      exact
                      path={routes.WELCOME_SCREEN}
                      component={WelcomePage}
                      {...pathParams}
                    />

                    <Route
                      exact
                      path={routes.PROFILE_DASHBOARD}
                      component={ProfileDashboard}
                      {...pathParams}
                    />
                    <Route
                      exact
                      path={routes.HIGHLIGHTS}
                      component={HighLightsPage}
                    />

                    <Route
                      exact
                      path={routes.ADD_USER_PROFILE_DETAIL}
                      component={AddUserProfileDetail}
                    />
                    <Route
                      exact
                      path={routes.BUSINESS_REGISTER}
                      component={businessRegister}
                    />
                    <Route
                      exact
                      path={routes.USER_KYC}
                      component={UserKycPage}
                    />

                    <Route
                      exact
                      path={routes.ACCOUNT_SETTINGS}
                      component={AccountSettings}
                    />

                    <Route
                      exact
                      path={routes.PAYMENT}
                      component={PaymentPage}
                    />

                    <Route
                      exact
                      path={routes.WEB_PAYMENT_WITHDRAW}
                      component={WithDraw}
                    />
                    <Route
                      path={routes.MARKETPLACE}
                      component={MarketPlaceSearch}
                    />

                    {/*<Route
                  path={`${routes.PAYMENTSTATUS}/:status`}
                  component={PaymentSuccess}
                />*/}
                    <Route path={routes.MYREQUESTS} component={MyRequest} />

                    <Route path={routes.PACS} component={PacsPage} />
                    <Route
                      path={routes.SERVICEONDEMAND}
                      component={serviceOnDemandPage}
                    />
                    <Route
                      path={routes.SERVICEONDEMANDSEARCH}
                      component={serviceOnDemandSearchPage}
                    />
                    <Route
                      path={routes.SERVICEONDEMANDMYREQUEST}
                      component={serviceOnDemandMyRequestPage}
                    />
                    <Route
                      path={`${routes.SERVICEONDEMANDMYREQUESTDETAIL}/:serviceId`}
                      component={serviceOnDemandMyRequestDetailPage}
                    />
                    <Route
                      path={`${routes.SERVICEONDEMANDPROVIDERPROFILE}/:providerId`}
                      component={serviceOnDemandProvierProfilePage}
                    />
                    <Route
                      path={routes.SERVICEONDEMANDCREATESERVICEREQUEST}
                      component={serviceOnDemandCreateServiceRequestPage}
                    />
                    <Route
                      path={`${routes.SERVICEONDEMANDCREATESERVICEREQUEST_DETAIL}/:providerId`}
                      component={serviceOnDemandCreateServiceRequestDetailPage}
                    />
                    <Route
                      path={routes.SERVICEONDEMANDCOMMONCOMPONENT}
                      component={ServiceOnDemandCommanComponentPage}
                    />
                    <Route
                      exact
                      path={routes.SETTINGS}
                      component={SettingsPage}
                    />

                    <Route
                      path={routes.SERVICEONDEMAND_REQUESTRECIEVED}
                      component={serviceOnDemandRequestRecievedPage}
                    />
                    <Route
                      path={`${routes.SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL}/:serviceId`}
                      component={serviceOnDemandRecievedRequestDetailPage}
                    />
                    <Route
                      path={routes.SERVICEONDEMAND_CREATE_SERVICE_PROFILE}
                      component={serviceOnDemandCreateServiceProfilePage}
                    />
                    <Route
                      path={`${routes.SERVICEONDEMAND_SERVICE_PROFILE}/:providerId`}
                      component={serviceOnDemandServiceProfilePage}
                    />
                    <Route
                      path={routes.SERVICEONDEMAND_SIDEBAR}
                      component={serviceOnDemandSidebarPage}
                    />

                    <Route path={routes.PROFILE} component={ProfilePage} />
                    <Route
                      path={`${routes.PROFILEDETAIL}/:userId`}
                      component={ProfileDetail}
                    />
                    <Route
                      exact
                      path={`${routes.REPUTATION_DETAILS}/:userId`}
                      component={ReputationDetails}
                    />
                    <Route
                      path={`${routes.BUSINESSDETAIL}/:userId`}
                      component={BusinessDetailPage}
                    />
                    <Route
                      path={`${routes.EDIT_BUSINESS_DETAIL}/:businessId`}
                      component={BusinessEditPage}
                    />
                    <Route
                      path={`${routes.BUSINESS_MANAGEMENT}/:businessId`}
                      component={BusinessManagement}
                    />

                    <Route path={routes.CONTACT} component={ContactListPage} />
                    <Route
                      path={`${routes.CONTACTUSERDETAIL}/:userId`}
                      component={UserProfileDetail}
                    />
                    <Route
                      path={`${routes.EDITPROFILE}/:userId`}
                      component={editUserDetail}
                    />
                    <Route
                      path={`${routes.EDITEDUCATION}/:userId`}
                      component={EducationEditPage}
                    />
                    <Route
                      path={routes.EDITPROFESSIONAL}
                      component={ProfesionalEditDetailPage}
                    />
                    <Route
                      path={routes.NOTIFICATION}
                      component={notificationPage}
                    />
                    <Route
                      path={routes.ALLNOTIFICATION}
                      component={allnotificationPage}
                    />

                    <Route
                      path={routes.CONTACTREQUEST}
                      component={contactRequest}
                    />
                    <Route
                      exact
                      path={`${routes.NEWTRANSACTION}/:status`}
                      component={NewTransaction}
                    />

                    {/*(<Route path={routes.MARKETPLACE_NEW} component={MarketPlaceSearch} />*/}

                    <Route
                      path={`${routes.PRODUCT_DETAIL}/:productId`}
                      component={ProductDetail}
                    />
                    <Route
                      path={routes.PLACED_ORDERS}
                      component={MarketPlacedOrders}
                    />
                    <Route
                      path={routes.BROWSE_PRODUCT}
                      component={BrowseProduct}
                    />
                    <Route
                      path={routes.SELLER_CONSOLE}
                      component={SellerConsole}
                    />

                    <Route
                      path={routes.BULKUPLOAD_ADD_PRODUCT}
                      component={BulkUploadAddProduct}
                    />

                    <Route
                      path={`${routes.SELLER_ADD_PRODUCT}/:productType/:productId`}
                      component={SellerAddProduct}
                    />
                    <Route
                      path={`${routes.SELLER_VIEW_LISTING}/:productId`}
                      component={SellerViewListing}
                    />
                    <Route
                      path={routes.SELLER_CONSOLE_SIDEMENU}
                      component={SellerConsoleSidemenu}
                    />
                    <Route
                      path={`${routes.PLACED_ORDERS_DETAILS}/:orderId`}
                      component={PlacedOrderDetails}
                    />
                    <Route
                      path={`${routes.SELLER_ORDER_DETAIL}/:orderId`}
                      component={SellerOrderDetails}
                    />

                    <Route
                      exact
                      path={routes.CONTENT_APPS}
                      component={ContentApps}
                    />
                    <Route exact path={routes.INFOSYS} component={InfoSys} />
                    <Route
                      exact
                      path={routes.FILEDRIVE}
                      component={FileDrive}
                    />
                     <Route
                      exact
                      // path={routes.CIENT_EMAIL_DASHBOARD}
                      path={`${routes.CIENT_EMAIL_DASHBOARD}/:item`}
                      component={EmailDashboardPage}
                    />

                    <Route
                      exact
                      // path={routes.CIENT_EMAIL_DASHBOARD}
                      path={`${routes.CIENT_EMAIL_MESSAGE}/:id`}
                      component={MessageContent}
                    />


                    

                    {/* Default redirection when url not found */}
                    <Redirect path='*' to={routes.PROFILE_DASHBOARD} />
                  </Switch>
                </AppContainer>
              );
            }}
          />
          <Route
            exact
            path={routes.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          {/* <Route exact path={routes.DASH} component={Dashboard} /> */}
          <Route exact path={routes.ACCOUNT} component={AccountPage} />
          <Route
            exact
            path={routes.TERMS_CONDITIONS}
            component={TermsConditionPage}
          />

          <Route path='*' component={ErrorPage} />
        </Switch>
        <LoaderSpinner />
        <CustomLoaderSpinner />

        <ReactHint
          autoPosition
          position='bottom'
          events
          delay={{ show: 100, hide: 500 }}
        />
      </Fragment>
    </BrowserRouter>
  );
};

// </Provider>

// class App extends Component {
//   //holds info about if an user is signed in or not
//   state = {
//     authUser: null
//   };

//   render() {
//     return (
//       // <div className="App">
//       <BrowserRouter>
//         <div>
//           <Navigation authUser={this.state.authUser} />

//           <hr />

//           <Route exact path={routes.LANDING} component={LandingPage} />
//           <Route exact path={routes.SIGN_UP} component={SignUpPage} />
//           <Route exact path={routes.SIGN_IN} component={SignInPage} />
//           <Route
//             exact
//             path={routes.PASSWORD_FORGET}
//             component={PasswordForgetPage}
//           />
//           <Route exact path={routes.HOME} component={TermsConditionPage} />
//           <Route exact path={routes.ACCOUNT} component={AccountPage} />
//         </div>
//       </BrowserRouter>
//       // </div>
//     );
//   }
// }

// export default App;
export default withAuthentication(App); //using HoC to handle session
